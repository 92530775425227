import { useCallback, useState } from 'react'

const useModal = (defaultOpen = false) => {
  const [isOpen, setIsOpen] = useState(defaultOpen)

  const toggle = useCallback((open?: boolean) => {
    setIsOpen(prev => (typeof open === 'boolean' ? open : !prev))
  }, [])

  return {
    isOpen,
    toggle,
  }
}

export default useModal
