import axios, { AxiosResponse, CancelTokenSource } from 'axios'
import { getHomeworkAwsUploadUrl } from '@/services/class'

type IMultipartUploadOptions = {
  onProgress?: (progress: number) => void
  onSuccess?: (res: AxiosResponse) => void
  onInit?: (cancelTokenSrc: CancelTokenSource) => void
}

export async function multipartUpload(
  signedUrl: string,
  file: Blob,
  { onProgress, onSuccess, onInit }: IMultipartUploadOptions
) {
  const cancelTokenSrc = axios.CancelToken.source()
  onInit && onInit(cancelTokenSrc)
  const res = await axios.put(signedUrl, file, {
    cancelToken: cancelTokenSrc.token,
    headers: {
      'x-amz-acl': 'public-read',
    },
    onUploadProgress: progressEvent => {
      if (onProgress) {
        if (progressEvent.total) {
          onProgress(progressEvent.loaded / progressEvent.total)
        } else {
          onProgress(0)
        }
      }
    },
  })
  onSuccess && onSuccess(res)
  return res
}
