import { useReducer } from "react";
import pick from "lodash/pick";
import { RecordType } from "../recordableDrawing/model/recordType";
import type { ActionsInterval } from "../recordableDrawing/model/actionsInterval";

type IRecorderStatus = "draw" | "rec" | "rec:preview";

export type IRecorderState = {
  canvasWidth: number;
  canvasHeight: number;
  drawable: boolean;
  recordState: RecordType;
  drawingData: ActionsInterval[] | null;
  isDrawing: boolean;
  status?: IRecorderStatus | null;
  strokeStyle: string;
  strokeWidth: number;
};

type IRecorderActionType =
  | "start:draw"
  | "stop:draw"
  | "start:rec"
  | "stop:rec"
  | "start:preview"
  | "set:color"
  | "play:drawing"
  | "stop:play"
  | "set:stroke-width";

type IRecorderActionPayload = {
  canvasWidth?: number;
  canvasHeight?: number;
  drawingData?: ActionsInterval[];
  strokeStyle?: string;
  strokeWidth?: number;
};

type IRecorderAction = {
  type: IRecorderActionType;
  payload?: IRecorderActionPayload;
};

type IPayloadKeys = keyof IRecorderActionPayload;

type IUseRecorderProps = Pick<IRecorderState, "strokeStyle" | "strokeWidth">;

const INIT_STATE: IRecorderState = {
  strokeStyle: "rgb(0, 0, 0)",
  canvasWidth: 0,
  canvasHeight: 0,
  drawable: false,
  recordState: RecordType.READY,
  drawingData: null,
  isDrawing: false,
  strokeWidth: 1,
};

const picker = (
  payload: IRecorderActionPayload | Partial<IRecorderState>,
  keys: IPayloadKeys[]
) => {
  return pick(payload, keys);
};

const recorderStateReducer = (
  state: Partial<IRecorderState>,
  { type, payload = {} }: IRecorderAction
): Partial<IRecorderState> => {
  switch (type) {
    case "start:draw":
      return {
        ...state,
        isDrawing: true,
        drawable: true,
        status: "draw",
        ...picker(payload, ["canvasHeight", "canvasWidth"]),
      };
    case "stop:draw":
    case "stop:play":
      return {
        ...state,
        status: null,
        canvasWidth: 0,
        canvasHeight: 0,
        drawable: false,
        recordState: RecordType.READY,
        drawingData: null,
        isDrawing: false,
      };
    case "start:rec":
      return {
        ...state,
        status: "rec",
        isDrawing: true,
        drawable: true,
        recordState: RecordType.START,
        ...picker(payload, ["canvasWidth", "canvasHeight"]),
      };
    // 并不退出recording状态，进入preview
    case "stop:rec":
      return {
        ...state,
        status: "rec:preview",
        recordState: RecordType.STOP,
        drawable: false,
        isDrawing: false,
      };
    case "play:drawing":
      return {
        ...state,
        drawable: false,
        ...picker(payload, ["canvasHeight", "canvasWidth"]),
      };
    // case "start:play:recording":
    //   return {
    //     ...state,
    //     drawable: false,
    //     status: "play:rec",
    //     ...picker(payload, ["canvasHeight", "canvasWidth", "drawingData"]),
    //   };
    case "start:preview":
      return {
        ...state,
        recordState: RecordType.PLAY,
      };
    case "set:color":
      return {
        ...state,
        strokeStyle: payload.strokeStyle,
      };
    case "set:stroke-width":
      return {
        ...state,
        strokeWidth: payload.strokeWidth,
      };
    default:
      throw new Error(`Unknown action ${type}`);
  }
};

export default function useRecorderOverlay({
  strokeStyle,
  strokeWidth,
}: IUseRecorderProps) {
  const [state, dispatcher] = useReducer(recorderStateReducer, {
    ...INIT_STATE,
    strokeStyle: strokeStyle || "rgb(0, 0, 0)",
    strokeWidth: strokeWidth || 1,
  });

  return { state, dispatcher };
}
