import React, { Fragment } from 'react'
import { useMediaQuery } from '@mantine/hooks'
import DesktopHeader from '@/components/HeaderBar/desktop'
import { useAuth } from '@/containers/auth'
import MobileHeader from '@/components/HeaderBar/mobile'

export default function Header() {
  const { isAuthenticated } = useAuth()
  const matchLessThanMd = useMediaQuery('(max-width: 992px)')

  return (
    <Fragment>
      {matchLessThanMd ? (
        <div className="z-100">
          <MobileHeader isAuthenticated={isAuthenticated} />
        </div>
      ) : (
        <div>
          <DesktopHeader isAuthenticated={isAuthenticated} />
        </div>
      )}
      <div className={'bg-black h-navMob md:h-navPc w-full'} />
    </Fragment>
  )
}
