import React from 'react'
import { createStyles, Popover } from '@mantine/core'
import { isArray } from 'lodash'
import { NSPage } from '@/@types/page'
import ImageComponent from '@/components/ImageComponent'

interface ImagePopoverProps {
  image: string
  width?: number
}
const useImagePopoverStyles = createStyles(() => ({
  dropdown: {
    padding: 0,
  },
}))
const ImagePopover: React.FC<React.PropsWithChildren<ImagePopoverProps>> = ({
  children,
  image,
  width = 129,
}) => {
  const { classes } = useImagePopoverStyles()
  return (
    <Popover
      classNames={classes}
      width={width}
      position="bottom"
      withArrow
      shadow="md"
    >
      <Popover.Target>{children}</Popover.Target>
      <Popover.Dropdown>
        <ImageComponent src={image} />
      </Popover.Dropdown>
    </Popover>
  )
}

const MediaLink: React.FC<{ media: NSPage.LinkGroup; className?: string }> = ({
  media,
  className,
}) => {
  return (
    <div className={className}>
      <div className="flex items-center space-x-1">
        {Boolean(media.icon?.data?.attributes.url) && (
          <ImageComponent src={media.icon!.data!.attributes.url} />
        )}
        <span>{media.label}</span>
      </div>
      <ul className="space-y-3 mt-6">
        {media.links.map(l => {
          return (
            <li key={l.label}>
              {l.url && (
                <a
                  href={l.url}
                  target={l.blank ? '_blank' : '_self'}
                  rel="noreferrer"
                  className="no-underline opacity-70 hover:opacity-100 hover:text-brand"
                >
                  {l.label}
                </a>
              )}
              {l.qrcode?.data?.attributes.url && (
                <ImagePopover image={l.qrcode?.data?.attributes.url}>
                  <a className="cursor-pointer no-underline opacity-70 hover:opacity-100 hover:text-brand">
                    {l.label}
                  </a>
                </ImagePopover>
              )}
            </li>
          )
        })}
      </ul>
    </div>
  )
}

interface FooterProps {
  footerLink: NSPage.FooterLink
}

export default function Footer({ footerLink }: FooterProps) {
  return (
    <footer className="bg-black text-[#FAFAFA] pt-10 pb-4 px-4 md:px-1">
      <div className="flex flex-col md:flex-row mx-auto container space-y-6 md:space-y-0 md:space-x-20">
        <div className="grid grid-cols-2 md:grid-cols-6 gap-y-6 flex-1">
          {footerLink.groups.map((g, idx) => {
            return (
              <React.Fragment key={idx}>
                {isArray(g) ? (
                  <div className="grid grid-rows-2 gap-16">
                    {g.map(i => (
                      <MediaLink media={i} key={i.name} />
                    ))}
                  </div>
                ) : (
                  <MediaLink media={g} />
                )}
              </React.Fragment>
            )
          })}
        </div>
      </div>

      <h1 className="text-center mx-auto text-xs pt-16 px-3 opacity-50 max-w-xl">
        精竞网络科技（上海）有限公司是Gen.G全球电子竞技俱乐部中国分公司，
        旗下包含电子俱乐部运营、创新性电竞在线人才培养平台及电竞留学等相关业务。
      </h1>

      <div className="pt-4 text-xs text-center mx-auto flex flex-col md:flex-row justify-center items-center space-y-4 md:space-y-0 md:space-x-11 opacity-50">
        <span>
          <a
            className="flex space-x-1 justify-center text-xs no-underline"
            target="_blank"
            href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=31010602006524"
            rel="noreferrer"
          >
            <span className="w-4 h-4">
              <ImageComponent
                alt="沪公网安备 31010602006524号"
                className="w-full h-full"
                src="/images/备案图标.png"
              />
            </span>
            <span>沪公网安备 31010602006524号</span>
          </a>
        </span>
        <a
          className="no-underline"
          href="https://beian.miit.gov.cn/#/Integrated/index"
          target="_blank"
          rel="noreferrer"
        >
          沪ICP备19027575号-3
        </a>
      </div>
      <div className="mt-4 md:mt-7 text-xs text-center mx-auto opacity-50">
        增值电信业务经营许可证：合字B2-20220106
      </div>
    </footer>
  )
}
