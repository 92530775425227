import React, { useEffect, useState } from 'react'
import { finishUserRecordApi } from '@/services/class'
import {
  ICoursewareModalContexts,
  useCoursewareModal,
} from '@/hooks/myPages/CoursewareModalProvider'
import useRawContent from '@/hooks/useRawContent'
import PublicCourseComment from './PublicCourseComment'

type IPageTaskProps = {
  ctx?: ICoursewareModalContexts
}

export const PageTask = ({ ctx }: IPageTaskProps) => {
  const { courseware, info, classId, isVodLibrary, syllabusId, notification } =
    useCoursewareModal(ctx)

  const { parsed, renderReplacedVideo } = useRawContent({
    content: courseware.raw_content || '',
  })
  const [trialId, setTrialId] = useState<number>()

  useEffect(() => {
    // 更新课程完成情况
    if (courseware.record && courseware.record) {
      setTrialId(courseware.record.last_trial_id)
    } else {
      if (classId) {
        finishUserRecordApi(classId, info.id).then(res => {
          if (res && res.data && res.data.data) {
            setTrialId(res.data.data.id)
          }
        })
      }
    }
  }, [classId, courseware.record, info.id])

  useEffect(() => {
    renderReplacedVideo()
  }, [renderReplacedVideo])

  return parsed ? (
    <div
      className={
        "overflow-auto bg-repeat bg-[url('https://res.gengesports.cn/user-web/images/bg-faq.png')]"
      }
    >
      <div className={'max-w-screen-xl mx-auto bg-white bg-opacity-60 p-2'}>
        <div
          className="w-full gga-page-view mb-4"
          dangerouslySetInnerHTML={{
            __html: parsed,
          }}
        />
        {trialId && (
          <div className={'w-full max-w-screen-lg mx-auto pb-2'}>
            {isVodLibrary && syllabusId && courseware && (
              <PublicCourseComment
                trialId={trialId}
                notification={notification}
                classUuid={courseware.class.identity_id}
                courseId={syllabusId}
              />
            )}
          </div>
        )}
      </div>
    </div>
  ) : null
}
