import { NSFeedback } from 'gga-types'
import { request } from '@/utils/request'
import { IResData } from '@/@types/global'

export async function getHasTrialFeedback(trialId: number) {
  return request<IResData<NSFeedback.IHasTrialFeedbackResponse>>({
    url: `/v2/TrialFeedback/has-feedback/trial/${trialId}`,
  })
}

type IPostTrialFeedbackDto = {
  coach_rating: number
  platform_rating: number
  wanna_try_more: boolean
  other_notes: string
}

export async function postTrialFeedback(
  trialId: number,
  dto: Partial<IPostTrialFeedbackDto>
) {
  return request({
    url: `/v2/TrialFeedback/trial/${trialId}`,
    method: 'post',
    data: dto,
  })
}
