import { ReactElement } from 'react'
import { WEB_HOST } from '@/constants/define'
import GoogleGtmScript from '@/components/monitor/GoogleGtmScript/index_zh_CN'
import PageMeta from '@/components/page-meta'
import { IMetaTags } from '../type'

export default function MetaTagsZhCN({
  pageProps,
  asPath,
}): ReactElement<IMetaTags> {
  return (
    <>
      <GoogleGtmScript />
      <PageMeta {...pageProps} />

      <link rel="preconnect" href="https://fonts.googleapis.com" />
      <link
        rel="preconnect"
        href="https://fonts.gstatic.com"
        crossOrigin="true"
      />
      <link
        href="https://fonts.googleapis.com/css2?family=Comic+Neue&display=swap"
        rel="stylesheet"
      />
      <meta property="og:url" content={WEB_HOST + asPath} />

      <meta property="og:site_name" content="Gen.G国际电竞人才培养平台" />
    </>
  )
}
