import { Menu, MenuProps } from '@mantine/core'
import React, { useCallback, useMemo } from 'react'
import { useRouter } from 'next/router'
import classNames from 'classnames'
import NavLink from '@/components/NavLink'
import ImageComponent from '@/components/ImageComponent'

interface MenuLinkProps {
  menu: {
    text: string
    children: { text: string; href: string; image?: string; image1?: string }[]
  }
  trigger?: MenuProps['trigger']
}
const MenuLink: React.FC<MenuLinkProps> = ({ trigger, menu }) => {
  const { asPath } = useRouter()

  const isSubActive = useMemo(() => {
    return menu.children.some(m => asPath.startsWith(m.href))
  }, [menu, asPath])

  const isActive = useCallback(
    (link: string) => {
      return link === asPath
    },
    [asPath]
  )

  return (
    <Menu
      trigger={trigger}
      styles={{
        dropdown: {
          border: 'rgba(16, 17, 19, 0.7)',
          background: 'rgba(16, 17, 19, 0.7) !important',
          boxShadow: '0px 4px 10px 0px rgba(0, 0, 0, 0.3)',
          minWidth: '140px',
        },
        item: {
          backgroundColor: 'transparent !important',
          '&:hover': {
            backgroundColor: 'transparent !important',
          },
        },
      }}
      position="bottom-start"
    >
      <Menu.Target>
        <a
          className={classNames(
            'flex text-white justify-center items-center px-4 no-underline bg-black cursor-pointer',
            isSubActive ? 'bg-gold' : 'bg-black'
          )}
        >
          {menu.text}
          <span className="ml-2">▾</span>
        </a>
      </Menu.Target>
      <Menu.Dropdown>
        {menu.children.map((m, index) => {
          return (
            <Menu.Item key={index}>
              <NavLink
                href={m.href!}
                className={classNames(
                  'no-underline text-white hover:text-brand group',
                  {
                    'text-brand': isActive(m.href),
                  }
                )}
              >
                <span className="flex space-x-4 items-center">
                  {Boolean(m.image) && (
                    <span>
                      <ImageComponent
                        src={m.image}
                        className="inline-block group-hover:hidden"
                      />
                      <ImageComponent
                        src={m.image1 || m.image}
                        className="hidden group-hover:inline-block"
                      />
                    </span>
                  )}
                  <span className="group-hover:underline group-hover:underline-offset-8">
                    {m.text}
                  </span>
                </span>
              </NavLink>
            </Menu.Item>
          )
        })}
      </Menu.Dropdown>
    </Menu>
  )
}

export default MenuLink
