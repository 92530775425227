import { CloseButton } from '@mantine/core'
import React, { useCallback } from 'react'
import { useAuth } from '@/containers/auth'
import { StyledModal } from '@/lib/ui/modals/styled-modal'
import { AuthForm } from '../auth-components'

export const ZhCNAuthModal: React.FC = () => {
  const {
    openAuthModal,
    setOpenAuthModal,
    refreshUser,
    initialLoginMethod,
    onAuthModalClose,
    authModalState,
  } = useAuth()

  const handleClose = useCallback(
    (isLoggedIn?: boolean) => {
      onAuthModalClose?.(isLoggedIn)
      setOpenAuthModal(false)
    },
    [onAuthModalClose, setOpenAuthModal]
  )

  const onLogged = useCallback(async () => {
    await refreshUser()
    handleClose(true)
  }, [handleClose, refreshUser])

  return (
    <StyledModal
      closeOnClickOutside={false}
      closeOnEscape={false}
      opened={openAuthModal}
      onClose={handleClose}
    >
      <div className="relative">
        <AuthForm
          onLogged={onLogged}
          initialStage="login"
          initialLoginMethod={initialLoginMethod}
          {...authModalState}
        />

        <span className="absolute -top-3 -right-2">
          <CloseButton
            onClick={() => handleClose(false)}
            variant="transparent"
            aria-label="Close modal"
          />
        </span>
      </div>
    </StyledModal>
  )
}
