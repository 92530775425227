import { yupResolver } from '@hookform/resolvers/yup'
import { Anchor, Button, PasswordInput, Stack } from '@mantine/core'
import React, { useCallback, useMemo } from 'react'
import isMobilePhone from 'validator/lib/isMobilePhone'
import { get } from 'lodash'
import { useTranslation } from 'next-i18next'
import { Controller, useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import Yup from '@/utils/yup'
import { SmsVerification } from '@/components/FormComponents/SmsVerification'
import PhoneNumberInput from '@/components/inputs/PhoneNumberInput'
import { SmsTypeEnum } from '@/services'
import { resetPasswordApi } from '@/services/user'
import { convertPhoneCodeTolocale } from '@/utils/helper'
import { Jwt } from '@/services/jwt'
import { useStyles } from './utils'

type ResetFormValue = {
  phone: {
    phone_code: string
    phone_number: string
  }
  sms_verify_code: string
  password: string
}

const schema = Yup.object().shape({
  password: Yup.string().required().min(6),
  sms_verify_code: Yup.string().required(),
  phone: Yup.object({
    phone_code: Yup.string().required(),
    phone_number: Yup.string()
      .required()
      .omitStartZero()
      .phoneNumber('phone_code'),
  }),
})

interface ResetFormProps {
  onLogged?: () => void
  onLogin: () => void
}

export const ResetForm: React.FC<ResetFormProps> = ({ onLogged, onLogin }) => {
  const { t } = useTranslation()
  const { classes } = useStyles()
  const { control, formState, handleSubmit, watch, register } =
    useForm<ResetFormValue>({
      mode: 'all',
      defaultValues: {
        phone: {
          phone_code: '86',
          phone_number: '',
        },
        password: '',
      },
      resolver: yupResolver(schema),
    })

  const { errors, isSubmitting, isValid, isDirty } = formState

  const { phone_code: watchPhoneCode, phone_number: watchPhoneNumber } =
    watch('phone')

  const isValidPhoneNumber = useMemo(() => {
    return watchPhoneNumber && !errors?.phone?.phone_number?.message
  }, [errors?.phone?.phone_number?.message, watchPhoneNumber])

  const onSubmit = useCallback(
    async (values: ResetFormValue) => {
      const { phone, ...rest } = values
      const postData = {
        ...rest,
        ...phone,
      }

      const res = await resetPasswordApi(postData)
      if (!res.success) {
        toast.error(res.message)
      }
      const accessToken = res.data.data?.access_token ?? null
      if (accessToken) {
        Jwt.setToken(accessToken)
        onLogged?.()
      }
    },
    [onLogged]
  )

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="text-center text-[22px] text-[#FAFAFA] font-bold">
        重置密码
      </div>
      <p className="text-center mt-2 mb-3">
        <Anchor
          onClick={onLogin}
          sx={{
            color: '#FAFAFA',
            opacity: 0.7,
          }}
          underline
          className="underline-offset-4 "
        >
          登录
        </Anchor>
      </p>
      <Stack>
        <Controller
          name="phone"
          control={control}
          render={({ field }) => {
            return (
              <PhoneNumberInput
                classes={{ label: classes.label }}
                label={t('phone_number')}
                placeholder={t('phone_number')}
                inputProps={{
                  maxLength: 36,
                  error: get(errors, 'phone.phone_number.message'),
                }}
                {...field}
              />
            )
          }}
        />

        <Controller
          name="password"
          control={control}
          render={({ field }) => {
            return (
              <PasswordInput
                classNames={{ label: classes.label }}
                placeholder={t('forget_password_form.new_password')}
                label={t('forget_password_form.new_password')}
                {...field}
                error={get(errors, 'password.message')}
              />
            )
          }}
        />

        <Controller
          name="sms_verify_code"
          control={control}
          render={() => {
            return (
              <SmsVerification
                classes={{ label: classes.label }}
                mode={'imageCaptcha'}
                phoneCode={watchPhoneCode}
                phoneNumber={watchPhoneNumber}
                id="sms_verify_code"
                maxLength={6}
                registerFn={() => register('sms_verify_code')}
                type={SmsTypeEnum.ResetPassword}
                disabled={!isValidPhoneNumber}
                invalid={get(errors, 'sms_verify_code.message')}
                label={t('send_verification_code_via_text')}
              />
            )
          }}
        />

        <p className="text-center">
          <Button
            type="submit"
            color="brand"
            disabled={!isDirty || !isValid}
            loading={isSubmitting}
          >
            重置并登录
          </Button>
        </p>
      </Stack>
    </form>
  )
}
