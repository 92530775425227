import { REFERRER, REFERRER_ENTRANCE } from '@/constants/define'
import { getSessionStorage, setSessionStorage } from './sessionstorage'

export const getWebAnalyticsInfo = (): {
  registration_referring_site: string
  registration_url: string
} => {
  return {
    registration_referring_site: getSessionStorage(REFERRER),
    registration_url: getSessionStorage(REFERRER_ENTRANCE),
  }
}

export const setReferrer = () => {
  if (!getSessionStorage(REFERRER_ENTRANCE)) {
    if (!getSessionStorage(REFERRER)) {
      setSessionStorage(REFERRER, document.referrer)
    }
    setSessionStorage(REFERRER_ENTRANCE, location.href)
  }
}

/**
 * 给url带上（新）的utm相关的search params
 * @param url
 * @param override
 * @returns
 */
export const appendUtm = (url: string, override = false) => {
  const entrance = new URL(getSessionStorage(REFERRER_ENTRANCE))
  const currentURL = new URL(url)

  if (override || !currentURL.search.includes('utm_')) {
    for (const [key, value] of entrance.searchParams.entries()) {
      if (key.includes('utm_') && !currentURL.searchParams.has(key)) {
        currentURL.searchParams.append(key, value)
      }
    }
  }

  return currentURL.toString()
}
