import React, {
  createRef,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import classNames from "classnames";
import { IBoundData } from "../GreatVideoPlayer/type";
import ProvideRecordableContainer, {
  useRecordableContainerContext,
  RecordableEventEmitter,
} from "../container/RecordableContainer";
import {
  StatefulPlaybackControls,
  StatefulRecordingControls,
} from "../components/StatefulControls";
import {
  StatefulPlaybackOverlay,
  StatefulRecordingOverlay,
} from "../components/StatefulOverlays";
import { RecordingControlsProps } from "../recordableDrawing/RecordingControls";
import { PDFViewer } from "../components/PDFViewer";
import { ActionsInterval } from "../recordableDrawing/model/actionsInterval";
import { IUseAudioState } from "../recordableDrawing/PlaybackOverlay";
import { IUseVideoRefState } from "../hooks/useVideoRef";
import { IRecorderState } from "../hooks/useRecorderOverlay";
import noop from "../utils/noop";

type IGreatPdfPlayerProps = {
  config: {
    id: string;
    filePath: string;
    controllable?: boolean;
    recordable?: boolean;
    strokeStyle?: string;
    btnProps?: RecordingControlsProps["btnProps"];
    currentPage?: number;
  };
  init: (player) => void;
  onStartRecording?: (e?: Error) => void;
  onStopRecording?: (drawingData: ActionsInterval[]) => void;
  onStartDrawing?: () => void;
  onStartPreview?: () => void;
  onColorChanged?: (st: string) => void;
  onUndo?: () => void;
  onRedo?: () => void;
  onClear?: () => void;
  onDiscard?: () => void;
  onExitPlayback?: () => void;
  onAudioRecorderSaved?: (
    audio: Blob | null,
    drawing: ActionsInterval[]
  ) => void;
  onAudioPlayback?: (audioState: IUseAudioState) => void;
  onVideoPlaying?: (videoState: IUseVideoRefState) => void;
  onStatusChange?: (status: IRecorderState["status"]) => void; // 正在绘画 或者 正在录制
  onVideoLoaded?: () => void;
  onPageChange?: (currentPage: number) => void;
  className?: string;
};

const GreatPdfPlayerComponent = ({
  config,
  onStartRecording,
  onStopRecording,
  onStartDrawing,
  onStartPreview,
  onColorChanged,
  onUndo,
  onClear,
  onDiscard,
  onExitPlayback,
  onAudioRecorderSaved = noop,
  className,
  onRedo,
  onStatusChange,
  onPageChange = noop,
  init,
}: IGreatPdfPlayerProps) => {
  const {
    id,
    filePath,
    controllable = true,
    recordable = true,
    btnProps,
    currentPage,
  } = config;
  const {
    controlActions,
    overlayRef,
    playbackRef,
    recorderState,
    recorderDispatcher,
    componentState,
    setComponentState,
    playbackProps,
    setPlaybackProps,
  } = useRecordableContainerContext();

  const viewerRef = createRef<HTMLDivElement>();
  const [bounds, setBounds] = useState<IBoundData>();

  // 开始录制音频与绘画
  const handleStartRecording = useCallback(
    async e => {
      setComponentState("rec");
      onStartRecording && onStartRecording(e);
    },
    [onStartRecording, setComponentState]
  );

  // 开始绘制
  const handleStartDrawing = useCallback(() => {
    setComponentState("rec");
    onStartDrawing && onStartDrawing();
  }, [onStartDrawing, setComponentState]);

  // 停止录制音频与绘画
  const handleStopRecording = useCallback(
    drawingData => {
      onStopRecording && onStopRecording(drawingData);
    },
    [onStopRecording]
  );

  // 停止绘制
  const handleDiscard = useCallback(() => {
    setComponentState(null);
    onDiscard && onDiscard();
  }, [onDiscard, setComponentState]);

  // 开始预览
  const handleStartPreview = useCallback(() => {
    onStartPreview && onStartPreview();
  }, [onStartPreview]);

  // 切换颜色
  const handleColorChanged = useCallback(
    (st: string) => {
      onColorChanged && onColorChanged(st);
    },
    [onColorChanged]
  );

  const handleUndo = useCallback(() => {
    onUndo && onUndo();
  }, [onUndo]);

  const handleRedo = useCallback(() => {
    onRedo && onRedo();
  }, [onRedo]);

  const handleClear = useCallback(() => {
    onClear && onClear();
  }, [onClear]);

  const handleExitPlayback = useCallback(() => {
    setComponentState(null);
    onExitPlayback && onExitPlayback();
  }, [onExitPlayback, setComponentState]);

  const handlePlaybackReady = useCallback(() => {
    playbackRef.current?.start();
  }, [playbackRef]);

  useEffect(() => {
    RecordableEventEmitter.on("onAudioRecorderSaved", onAudioRecorderSaved);
    RecordableEventEmitter.on("onStartRecording", handleStartRecording);
    RecordableEventEmitter.on("onStartDrawing", handleStartDrawing);
    RecordableEventEmitter.on("onStopRecording", handleStopRecording);
    RecordableEventEmitter.on("onStartPlaying", handleStartPreview);
    RecordableEventEmitter.on("onUndo", handleUndo);
    RecordableEventEmitter.on("onRedo", handleRedo);
    RecordableEventEmitter.on("onClear", handleClear);
    RecordableEventEmitter.on("onDiscard", handleDiscard);
    RecordableEventEmitter.on("onColorChanged", handleColorChanged);
    RecordableEventEmitter.on("onExitPlayback", handleExitPlayback);
    RecordableEventEmitter.on("onPlaybackReady", handlePlaybackReady);
    return () => {
      RecordableEventEmitter.off("onAudioRecorderSaved", onAudioRecorderSaved);
      RecordableEventEmitter.off("onStartRecording", handleStartRecording);
      RecordableEventEmitter.off("onStartDrawing", handleStartDrawing);
      RecordableEventEmitter.off("onStopRecording", handleStopRecording);
      RecordableEventEmitter.off("onStartPlaying", handleStartPreview);
      RecordableEventEmitter.off("onUndo", handleUndo);
      RecordableEventEmitter.off("onRedo", handleRedo);
      RecordableEventEmitter.off("onClear", handleClear);
      RecordableEventEmitter.off("onDiscard", handleDiscard);
      RecordableEventEmitter.off("onColorChanged", handleColorChanged);
      RecordableEventEmitter.off("onExitPlayback", handleExitPlayback);
      RecordableEventEmitter.off("onPlaybackReady", handlePlaybackReady);
    };
  }, [
    handleClear,
    handleColorChanged,
    handleDiscard,
    handleExitPlayback,
    handlePlaybackReady,
    handleRedo,
    handleStartDrawing,
    handleStartPreview,
    handleStartRecording,
    handleStopRecording,
    handleUndo,
    onAudioRecorderSaved,
  ]);

  const onPlayerReset = useCallback(() => {
    controlActions.reset();
    recorderDispatcher({
      type: "stop:play",
    });
    overlayRef.current?.reset();
    playbackRef.current?.pause();
    RecordableEventEmitter.emit("onExitPlayback");
  }, [controlActions, overlayRef, playbackRef, recorderDispatcher]);

  const methods = useMemo(
    () => ({
      exitPlayback: () => onPlayerReset(),
      reset: () => onPlayerReset(),
      startPlayDrawing: ({ playbackTime, canvasDataPlayback }) => {
        onPlayerReset();
        setComponentState("rec");
        window.setTimeout(() => {
          if (playbackTime >= 0 && canvasDataPlayback) {
            controlActions.playing();
            recorderDispatcher({
              type: "play:drawing",
              payload: {
                canvasWidth: bounds?.width,
                canvasHeight: bounds?.height,
              },
            });
            overlayRef!.current!.setCanvasData(canvasDataPlayback);
          }
        }, 200);
      },
      startPlayRecording: async ({
        drawingDataPlayback,
        audioPlayback,
        playbackTime,
        recordingPlaybackWidth,
      }) => {
        onPlayerReset();
        setComponentState("play");
        if (playbackTime >= 0) {
          setPlaybackProps({
            audioSrc: audioPlayback as string,
            drawingData: drawingDataPlayback || [],
            recordingPlaybackWidth: recordingPlaybackWidth || 1,
          });
        }
      },
      stopRecordings: () => {
        overlayRef.current?.stopAudioRecording();
        recorderDispatcher({ type: "stop:rec" });
      },
      getRecorderState: () => {
        return recorderState;
      },
      getCanvasData: () => {
        return overlayRef.current!.getCanvasData();
      },
      getCanvasDimension: () => {
        return {
          width: recorderState.canvasWidth || 0,
          height: recorderState.canvasHeight || 0,
        };
      },
    }),
    [
      bounds?.height,
      bounds?.width,
      controlActions,
      onPlayerReset,
      overlayRef,
      recorderDispatcher,
      recorderState,
      setComponentState,
      setPlaybackProps,
    ]
  );

  const renderControls = useMemo(() => {
    if (controllable) {
      if (componentState === "play") {
        return <StatefulPlaybackControls />;
      } else if (bounds && bounds.width && bounds.height) {
        return (
          <StatefulRecordingControls
            recordable={recordable}
            width={bounds.width}
            height={bounds.height}
            btnProps={btnProps}
          />
        );
      }
    }
    return null;
  }, [bounds, btnProps, componentState, controllable, recordable]);

  const renderOverlay = useMemo(() => {
    if (bounds) {
      // 只用于播放录像录音，画图用RecordingOverlay
      return (
        <>
          {componentState === "play" && playbackProps && (
            <StatefulPlaybackOverlay
              id={id}
              {...playbackProps}
              bound={bounds!}
            />
          )}
          <div
            style={{
              display:
                controllable && bounds && componentState === "rec"
                  ? "initial"
                  : "none",
            }}
          >
            <StatefulRecordingOverlay bound={bounds!} />
          </div>
        </>
      );
    }
    return null;
  }, [bounds, componentState, controllable, id, playbackProps]);

  useEffect(() => {
    init && init(methods);
  }, [init, methods]);

  useEffect(() => {
    onStatusChange && onStatusChange(recorderState.status);
  }, [onStatusChange, recorderState.status]);

  return (
    <div className={classNames(className, "gvp-player-relative")}>
      <div id={id} ref={viewerRef}>
        <PDFViewer
          onRendered={() => {
            const dom = document.getElementById(id);
            if (dom) {
              const rectBounds = dom.getBoundingClientRect();
              setBounds({
                width: rectBounds.width,
                height: rectBounds.height,
                top: rectBounds.top - rectBounds.y,
                left: rectBounds.left - rectBounds.x,
              });
            }
          }}
          filePath={filePath}
          onPageChange={onPageChange}
          pageValue={currentPage}
        />
      </div>
      {renderOverlay}
      {renderControls}
    </div>
  );
};

export default function GreatPdfPlayer(props: IGreatPdfPlayerProps) {
  const { config } = props;
  return (
    <ProvideRecordableContainer
      recorderSettings={{
        strokeStyle: (config || {}).strokeStyle || "rgb(252, 186, 3)",
      }}
    >
      <GreatPdfPlayerComponent {...props} />
    </ProvideRecordableContainer>
  );
}
