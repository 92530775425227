import React from 'react'
import { useTranslation } from 'next-i18next'
import { SimpleGrid } from '@mantine/core'
import { SwiperSlide } from 'swiper/react'
import { NSProduct } from 'gga-types'
import ResponsiveSwiper from '@/components/swiper-containers/ResponsiveSwiper/ResponsiveSwiper'
import { Card } from './Card'

interface ProductCardsProps {
  products: NSProduct.Product[]
  onSelect: (product: NSProduct.Product) => void
}

export default function ProductCards({
  products,
  onSelect,
}: ProductCardsProps) {
  const { t } = useTranslation('common')
  return (
    <>
      <div className={'block md:hidden'}>
        <ResponsiveSwiper>
          {products.map(p => {
            return (
              <SwiperSlide key={p.id}>
                <Card
                  key={p.id}
                  product={p}
                  onClick={p => {
                    onSelect(p)
                  }}
                  buttonText={
                    p.type === 'Normal'
                      ? t('courses_page.join_free_trial')
                      : t('subscribe', {
                          ns: 'payment-subscription',
                        })
                  }
                />
              </SwiperSlide>
            )
          })}
        </ResponsiveSwiper>
      </div>
      <div className={'hidden md:block mb-6'}>
        <SimpleGrid cols={4}>
          {products.map((p, idx) => (
            <Card
              key={p.id}
              product={p}
              onClick={p => {
                onSelect(p)
              }}
              buttonText={
                <span className={'uppercase'}>
                  {p.type === 'Normal'
                    ? t('courses_page.join_free_trial')
                    : t('subscribe', {
                        ns: 'payment-subscription',
                      })}
                </span>
              }
            />
          ))}
        </SimpleGrid>
      </div>
    </>
  )
}
