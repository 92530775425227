import React, { useCallback, useEffect, useMemo } from 'react'
import { useRequest } from 'ahooks'
import { useTranslation } from 'next-i18next'
import { VideoUploadForm } from '@/components/Form/Courseware/VideoUploadForm'
import { useCoursewareModal } from '@/hooks/myPages/CoursewareModalProvider'
import { finishUserRecordApi, submitClassWorkApi } from '@/services/class'
import useRawContent from '@/hooks/useRawContent'

type ISeminarCoachUploadProps = {
  onSuccess(): void
}

export default function SeminarCoachUpload({
  onSuccess,
}: ISeminarCoachUploadProps) {
  const { courseware, info, requestClose, classId } = useCoursewareModal()

  const { renderReplacedVideo, parsed } = useRawContent({
    content: courseware.content || '',
  })

  const { i18n } = useTranslation()

  // 通过i18n.language记录file_upload_platform值
  // 韩国使用aws而zh_CN使用aliyun
  const platform = useMemo(() => {
    switch (i18n.language) {
      case 'kr':
        return 'aws'
      default:
        return 'aliyun'
    }
  }, [i18n.language])

  const submitData = useCallback(
    async data => {
      try {
        await submitClassWorkApi(classId, info.id, {
          content: data.url,
          file_path: data.file_name,
          file_upload_platform: data.file_upload_platform || platform,
          file_size: data.file_size,
        })
        await finishUserRecordApi(classId, info.id)
        onSuccess()
      } catch (e) {
        console.error(e)
      }
    },
    [classId, info.id, onSuccess, platform]
  )

  const { runAsync, loading: submitting } = useRequest(submitData, {
    manual: true,
  })

  useEffect(() => {
    renderReplacedVideo()
  }, [renderReplacedVideo])
  return (
    <div className={'overflow-auto'}>
      <div
        className="w-full gga-page-view"
        dangerouslySetInnerHTML={{ __html: parsed }}
      />
      <VideoUploadForm
        submitting={submitting}
        requestClose={requestClose}
        onSubmit={runAsync}
      />
    </div>
  )
}
