import { Input } from 'stripe-ui'
import { useTranslation } from 'next-i18next'
import ImageCaptcha from '@/components/ImageCaptcha'

type IFormImageCaptchaProps = {
  onSend: (code, id) => Promise<boolean>
}

export default function FormImageCaptcha({ onSend }: IFormImageCaptchaProps) {
  const { t } = useTranslation()
  return (
    <ImageCaptcha
      className="px-0 w-full"
      selfVerify
      verifyText={t('send_verification_code', {
        ns: 'common',
      })}
      onSend={onSend}
    >
      {({ onChange, value }) => {
        return (
          <Input
            placeholder={t('image_captcha', { ns: 'common' })}
            value={value}
            onChange={e => {
              onChange(e.target.value)
            }}
          />
        )
      }}
    </ImageCaptcha>
  )
}
