import { createStore } from 'zustand/vanilla'
import { create } from 'zustand'
import { NSCoupon, NSPromoCode } from 'gga-types'

export type TCouponWithStatus = NSCoupon.Item & {
  _type: 'coupon'
  _disabled: boolean
  _offAmount: number
  _finnalAmount: number
}

export type TPromoWithStatus = NSPromoCode.ItemDto & {
  _type: 'promo'
  _disabled: boolean
  _offAmount: number
  _finnalAmount: number
}

type TCheckoutStore = {
  selectedCoupon: TCouponWithStatus | TPromoWithStatus | undefined
  coupons: (TCouponWithStatus | TPromoWithStatus)[] | undefined
  originalAmount: number
  offAmount: number
  finalAmount: number

  setOriginalAmount: (amount: number) => void
  setOffAmount: (amount: number) => void
  setCoupons: (
    coupons: NSCoupon.Item[] | undefined,
    promoCode?: NSPromoCode.ItemDto[] | undefined | null
  ) => void
  setSelectedCoupon: (coupon?: TCouponWithStatus | TPromoWithStatus) => void
}

function toDecimal(x: number | string) {
  let f = parseFloat(x as string)
  if (Number.isNaN(f)) {
    return
  }
  f = Math.round((x as number) * 100) / 100
  return f
}

function getOffAmount(
  originalAmount: number,
  item: TCouponWithStatus | TPromoWithStatus
) {
  const key = item._type === 'coupon' ? 'coupon_type' : 'discount_type'
  switch (item[key]) {
    case 'Cash':
      return item.discount

    case 'Discount':
      return (originalAmount * item.discount) / 100
    default:
      return 0
  }
}

export const checkoutStore = createStore<TCheckoutStore>((set, get) => {
  let originalCoupons: NSCoupon.Item[] | undefined
  let originalPromoCodes: NSPromoCode.ItemDto[] | undefined | null

  function setCoupons() {
    const { originalAmount } = get()

    const newCoupons: (TCouponWithStatus | TPromoWithStatus)[] = []

    originalCoupons?.forEach(coupon => {
      const couponWithStatus: TCouponWithStatus = {
        ...coupon,
        _type: 'coupon',
        _disabled: false,
        _offAmount: 0,
        _finnalAmount: 0,
      }
      const offAmount = getOffAmount(originalAmount, couponWithStatus)
      const finalAmount = originalAmount - offAmount

      newCoupons.push({
        ...couponWithStatus,
        _disabled: 0 > finalAmount,
        _offAmount: offAmount,
        _finnalAmount: finalAmount,
      })
    })

    originalPromoCodes?.forEach(promoCode => {
      const promoWithStatus: TPromoWithStatus = {
        ...promoCode,
        _type: 'promo',
        _disabled: false,
        _offAmount: 0,
        _finnalAmount: 0,
      }
      const offAmount = getOffAmount(originalAmount, promoWithStatus)
      const finalAmount = originalAmount - offAmount

      newCoupons.push({
        ...promoWithStatus,
        _disabled: 0 > finalAmount,
        _offAmount: offAmount,
        _finnalAmount: finalAmount,
      })
    })

    newCoupons.sort(
      (a, b) =>
        (a._finnalAmount < 0 ? Infinity : a._finnalAmount) -
        (b._finnalAmount < 0 ? Infinity : b._finnalAmount)
    )

    set(() => ({
      coupons: newCoupons,
    }))

    // clear selected coupon first and try to set selected coupon
    get().setSelectedCoupon()
    if (newCoupons && newCoupons.length) {
      if (!newCoupons[0]._disabled) {
        get().setSelectedCoupon(newCoupons[0])
      }
    }
  }

  return {
    coupons: undefined,
    selectedCoupon: undefined,
    originalAmount: 0,
    offAmount: 0,
    finalAmount: 0,

    setCoupons: (coupons, promoCode) => {
      originalCoupons = coupons
      originalPromoCodes = promoCode
      setCoupons()
    },

    setSelectedCoupon: coupon => {
      const { originalAmount, setOffAmount } = get()

      if (coupon) {
        setOffAmount(getOffAmount(originalAmount, coupon))
      } else {
        setOffAmount(0)
      }

      set(() => ({
        selectedCoupon: coupon,
      }))
    },

    setOriginalAmount: originalAmount => {
      const { offAmount } = get()
      const finalAmount = toDecimal(Math.max(originalAmount - offAmount, 0.01))

      set(() => ({
        originalAmount,
        finalAmount,
      }))

      setCoupons()
    },
    setOffAmount: offAmount => {
      const { originalAmount } = get()
      const finalAmount = toDecimal(Math.max(originalAmount - offAmount, 0.01))

      set(() => ({
        offAmount,
        finalAmount,
      }))
    },
  }
})

export const useCheckoutStore = create(checkoutStore)
