import React, { useEffect, useRef, useMemo, useLayoutEffect } from "react";

import { IBoundData } from "../GreatVideoPlayer/type";
import useVoiceRecorder from "../hooks/useVoiceRecorder";
import type { ActionsInterval } from "./model/actionsInterval";
import type { RecordType } from "./model/recordType";
import DrawableService from "./service/drawableService";
import RecordableService from "./service/recordableService";

export type RecordingOverlayHandle = {
  undo: () => void;
  redo: () => void;
  clear: () => void;
  reset: () => void;
  initVoiceRecorder: () => Promise<void>;
  startAudioRecording: () => void;
  stopAudioRecording: () => void;
  startAudioPlaying: () => void;
  stopAudioPlaying: () => void;
  getAudioData: () => Blob | undefined;
  getDrawingData: () => ActionsInterval[];
  getCanvasData: () => string;
  setCanvasData: (imgDataURL: string) => void;
  // setColor: (rgbString: string) => void;
};

export type RecordingOverlayProps = {
  init: (handle: RecordingOverlayHandle) => void;
  onAudioRecorderSaved?: (blob: Blob) => void;
  state: RecordType;
  drawable?: boolean;
  strokeStyle?: string;
  lineWidth?: number | string;
  className?: string;
  bound: IBoundData;
};

const RecordingOverlay = (props: RecordingOverlayProps) => {
  const {
    init,
    drawable = false,
    state,
    strokeStyle = "rgb(0, 0, 0)",
    onAudioRecorderSaved,
    lineWidth = 1,
    className = "",
    bound,
  } = props;

  const drawableService = useRef<DrawableService>(new DrawableService());
  const recordableService = useRef<RecordableService>(new RecordableService());
  const canvasRef = useRef<HTMLCanvasElement>(null);

  const { VoiceRecorder } = useVoiceRecorder({
    onRecorderSaved: onAudioRecorderSaved,
  });

  const RecordableOverlayMethods: RecordingOverlayHandle = useMemo(
    () => ({
      undo() {
        drawableService.current.undoHandler();
      },
      redo() {
        drawableService.current.redoHandler();
      },
      clear() {
        drawableService.current.clearHandler();
      },

      async initVoiceRecorder() {
        console.log("RecordingOverlay.initVoiceRecorder");
        await VoiceRecorder.init();
      },

      startAudioRecording() {
        recordableService.current.reset();
        VoiceRecorder.startRecording();
      },
      stopAudioRecording() {
        console.log("RecordingOverlay.stopAudioRecording");
        VoiceRecorder.stopRecording();
      },
      startAudioPlaying() {
        VoiceRecorder.play();
      },
      stopAudioPlaying() {
        VoiceRecorder.stopPlaying();
      },

      // resetPlayInfo() {
      //   recordableService.current.setPlayInfo(new PlayState(0, PlayStateType.MODIFIED));
      // },

      getAudioData() {
        console.log("RecordingOverlay.getAudioData");
        return VoiceRecorder.getData();
      },
      getDrawingData() {
        return recordableService.current.actionsIntervalList;
      },
      getCanvasData() {
        // debugger;
        return drawableService.current.canvas.toDataURL();
      },
      // setDrawingData(data) {
      //   recordableService.current.actionsIntervalList = data;
      // },
      setCanvasData(imgDataURL) {
        // debugger;
        const img = new Image();

        // need to use img.onload here instead of setting the src first and then drawImage
        // https://stackoverflow.com/questions/4776670/should-setting-an-image-src-to-data-url-be-available-immediately
        img.onload = () =>
          drawableService.current.context.drawImage(
            img,
            0,
            0,
            drawableService.current.canvas.width,
            drawableService.current.canvas.height
          );

        img.src = imgDataURL;
      },
      reset() {
        recordableService.current.reset();
      },
      // setCanvasScale(scale) {
      //   // todo: why not working?
      //   // console.log('RecordingOverlay.setCanvasScale', scale);
      //   // drawableService.current.context.scale(scale, scale);
      //   // recordableService.current.drawableService.current.context.scale(scale, scale);
      //
      //   recordableService.current.replayScale = scale;
      // },

      // resetSnapshot() {
      //   debugger;
      //   drawableService.current.resetSnapshot();
      // },
    }),
    [VoiceRecorder, drawableService, recordableService]
  );

  useLayoutEffect(() => {
    const canvasElement = canvasRef.current;
    drawableService.current.init(canvasElement!, drawable);
    recordableService.current.setDrawableService(drawableService.current);
    init(RecordableOverlayMethods);
  }, [
    RecordableOverlayMethods,
    drawable,
    drawableService,
    init,
    recordableService,
  ]);

  // const handleResize = () => {
  //   debugger;
  //   //reset/re-init after resizing to clear drawableService's snapshots
  //   const canvasElement = canvasRef.current;
  //   if (canvasElement === null) {
  //     return;
  //   }
  //   console.log(canvasElement.offsetHeight, canvasElement.offsetWidth);
  //   drawableService.current.init(canvasElement, drawable);
  //   recordableService.current.setDrawableService(drawableService);
  // };
  useLayoutEffect(() => {
    console.log("drawableCanvas.useEffect[props.strokeStyle]");
    if (strokeStyle) {
      drawableService.current.setStrokeStyle(strokeStyle);
    }
  }, [drawableService, strokeStyle]);

  useLayoutEffect(() => {
    if (lineWidth) {
      drawableService.current.setLineWidth(lineWidth as number);
    }
  }, [drawableService, lineWidth]);

  useLayoutEffect(() => {
    drawableService?.current.setIsDrawable(drawable || false);
  }, [drawable, drawableService]);

  useEffect(() => {
    //   console.log('RecordableDrawCanvas  useEffect  state:', props.state);
    //   console.log(props);
    recordableService?.current.setState(state);
  }, [recordableService, state]);

  return (
    <canvas
      style={{
        position: "absolute",
        top: bound.top,
        left: bound.left,
      }}
      className={className}
      ref={canvasRef}
      width={bound.width}
      height={bound.height}
      onMouseDown={drawableService.current.mouseDownHandler}
      onMouseUp={drawableService?.current.mouseUpHandler}
      onMouseMove={drawableService?.current.mouseMoveHandler}
    />
  );
};

export default RecordingOverlay;
