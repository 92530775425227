import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import classNames from "classnames";
import { RecordType } from "../recordableDrawing/model/recordType";
import type { ActionsInterval } from "../recordableDrawing/model/actionsInterval";
import DrawableService from "../recordableDrawing/service/drawableService";
import RecordableService from "../recordableDrawing/service/recordableService";

import { RecordableEventEmitter } from "../container/RecordableContainer";
import useAudio from "../hooks/useAudio";
import { IBoundData } from "../GreatVideoPlayer/type";

export type IUseAudioState = {
  currentTime: number;
  duration: number;
};

export type IPlaybackOverlayMethods = {
  reset: () => void;
  start: () => void;
  pause: () => void;
  resume: () => void;
  stop: () => void;
  seek: (time: number) => void;
  setAudioVolume: (v: number) => void;
  restart: () => void;
};

type IPlaybackOverlayOnChangeData = {
  audioState: IUseAudioState;
};

type IPlaybackOverlayProps = {
  id: string;
  className?: string;
  init: (playbackControls: IPlaybackOverlayMethods) => void;
  onChange?: (state: PlaybackState, data: IPlaybackOverlayOnChangeData) => void;
  onStopped?: (
    state: PlaybackState,
    data: IPlaybackOverlayOnChangeData
  ) => void;
  drawingDataPlayback?: ActionsInterval[];
  audioPlayback: string;
  recordingPlaybackWidth?: number;
  bound: IBoundData;
};

export type PlaybackState = {
  status: "playing" | "paused" | "stopped";
};

export default function PlaybackOverlay({
  id,
  className,
  init,
  audioPlayback,
  drawingDataPlayback,
  recordingPlaybackWidth = 1.0,
  onChange,
  bound,
}: IPlaybackOverlayProps) {
  const canvasId = useMemo(() => `${id}-playback-canvas`, [id]);

  const [audioMethods, audioState] = useAudio({
    id,
  });

  const drawableService = useRef<DrawableService>(new DrawableService());
  const recordableService = useRef<RecordableService>(new RecordableService());

  const [status, setStatus] = useState<PlaybackState["status"]>("stopped");

  const handleChange = useCallback((state: PlaybackState) => {
    setStatus(state.status);
  }, []);

  const drawingScale = useMemo(() => {
    return recordingPlaybackWidth && recordingPlaybackWidth > 0
      ? bound.width / recordingPlaybackWidth
      : 1.0;
  }, [recordingPlaybackWidth, bound.width]);

  const methods = useMemo(
    () => ({
      reset: () => {
        recordableService.current.setState(RecordType.READY);
        recordableService.current.playbackSetup({
          actionList: drawingDataPlayback || [],
          scale: drawingScale,
        });
      },
      start: () => {
        audioMethods.play();
        recordableService.current.setState(RecordType.PLAY);
        handleChange({
          status: "playing",
        });
      },
      restart: () => {
        audioMethods.restart();
        recordableService.current.setState(RecordType.PLAY);
        handleChange({
          status: "playing",
        });
      },
      pause: () => {
        audioMethods.pause();
        recordableService.current.setState(RecordType.PAUSE);
        handleChange({
          status: "paused",
        });
      },
      resume: () => {
        audioMethods.play();
        recordableService.current.setState(RecordType.RESUME);
        handleChange({
          status: "playing",
        });
      },
      stop: () => {
        audioMethods.pause();
        recordableService.current.setState(RecordType.READY);
        handleChange({
          status: "paused",
        });
      },
      seek: (time: number) => {
        recordableService.current.setState(RecordType.PAUSE);
        recordableService.current.seek(time).then(() => {
          audioMethods.pause();
          audioMethods.seek(time);
        });
        handleChange({
          status: "paused",
        });
      },
      setAudioVolume: (v: number) => {
        audioMethods.setVolume(v);
      },
    }),
    [audioMethods, drawingDataPlayback, drawingScale, handleChange]
  );

  const getReady = useCallback(async () => {
    const canvasDom = document.getElementById(canvasId) as HTMLCanvasElement;
    drawableService.current.init(canvasDom, false);
    recordableService.current.setDrawableService(drawableService.current);
    recordableService.current.setState(RecordType.READY);
    recordableService.current.playbackSetup({
      actionList: drawingDataPlayback || [],
      scale: drawingScale,
    });
    await audioMethods.setAudioSrc(audioPlayback);
  }, [
    audioMethods,
    audioPlayback,
    canvasId,
    drawingDataPlayback,
    drawingScale,
  ]);

  useEffect(() => {
    getReady();
    init(methods);
    RecordableEventEmitter.emit("onPlaybackReady");
  }, [getReady, init, methods]);

  useEffect(() => {
    onChange && onChange({ status }, { audioState });
  }, [audioState, onChange, status]);

  useEffect(() => {
    if (
      status === "playing" &&
      audioState.duration > 0 &&
      audioState.duration === audioState.currentTime
    ) {
      handleChange({
        status: "stopped",
      });
    } else if (status === "playing" && audioState.duration > 0) {
      handleChange({
        status: "playing",
      });
    }
  }, [audioState.currentTime, audioState.duration, handleChange, status]);

  return (
    <div
      style={{
        display: "absolute",
        top: bound.top,
        left: bound.left,
      }}
      className={classNames(className)}
    >
      <canvas id={canvasId} width={bound.width} height={bound.height} />
    </div>
  );
}
