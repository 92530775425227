import { Trans, useTranslation } from 'next-i18next'
import { Button } from 'stripe-ui'
import React from 'react'

export function PendingUploadView({
  filename,
  onOk,
  onCancel,
}: {
  filename: string
  onOk: () => void
  onCancel: () => void
}) {
  const { t } = useTranslation()
  return (
    <div className="p-4">
      <div className="text-center text-xl font-semibold mb-4">
        <Trans
          t={t}
          i18nKey="course_form.confirm_upload"
          defaults="You are going to submit the following file to the homework:"
        />
      </div>
      <pre className="text-center mb-4">{filename}</pre>
      <div className="text-center mx-auto my-4">
        <Button type="button" onClick={onCancel} className="mx-2">
          {t('course_form.cancel_btn')}
        </Button>
        <Button type="button" onClick={onOk} className="mx-2" variant="brand">
          Ok
        </Button>
      </div>
    </div>
  )
}
