import * as Sentry from '@sentry/nextjs'
import { Integrations } from '@sentry/tracing'
import { CaptureConsole } from '@sentry/integrations'
import Constant from '@/config/config'

if (Constant.SentryDSN) {
  Sentry.init({
    dsn: Constant.SentryDSN,
    integrations: [
      new Integrations.BrowserTracing({
        tracingOrigins: [
          'api-test.gengesports.cn',
          'api-test.ggacademy.gg',
          'api.gengesports.cn',
          'api.ggacademy.gg',
        ],
      }),
      new CaptureConsole({
        levels: ['error'],
      }),
    ],
    tracesSampleRate: 1.0,
    environment: Constant.ENV,
    release: process.env.GIT_REVERSION,
  })
}
