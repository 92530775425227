import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { get, groupBy } from 'lodash'
import { useRouter } from 'next/router'
import { IGameCategory, NSClass, NSProduct } from 'gga-types'
import { useStore } from '@/context/store'
import { APP_LOCALE } from '@/constants/define'

export type CourseCenterState = ReturnType<typeof useCourseCenterContext>

const CourseCenterContext = createContext<CourseCenterState>({} as any)

const programTypeTranslationMap = {
  Plus: '집중반 PLUS',
  Basic: '취미반 PLAY',
  Trial: '1회 체험',
}

const getUnitTrans = (timeSpan?: number, unit?: string) => {
  if (!timeSpan || !unit) {
    return ''
  }

  if (unit === 'Month') {
    if (APP_LOCALE === 'kr') {
      return '개월'
    }
  }
  return `${unit}${(timeSpan || 0) > 1 ? 's' : ''}`
}

function useCourseCenterContext(isPlatform: boolean) {
  const router = useRouter()
  const {
    gameCategoryState: { gameCategories },
    productState: { products: allProducts },
  } = useStore()

  const products = useMemo(
    () =>
      (allProducts || []).filter(p =>
        isPlatform
          ? p.product_type === 'Platform'
          : p.product_type !== 'Platform'
      ),
    [allProducts, isPlatform]
  )

  const [selectedGame, setSelectedGame] = useState<number | null>(null)
  const [selectedProgram, setSelectedProgram] = useState<number | null>(null)
  const [selectedTimeSpan, setSelectedTimeSpan] = useState<string | null>(null)

  const groupingProducts = useMemo(() => {
    // 根据class的program subtype id group起来，如果是大类是
    const productsWithGroupName = products.map(p => {
      const programTypeName = p.class?.program_type.name
      const programSubTypeName = p.class?.program_sub_type.name

      const subTypeNameInKorean =
        programTypeTranslationMap[programSubTypeName || ''] ||
        programSubTypeName

      const renderSubTypeName =
        APP_LOCALE === 'kr' ? subTypeNameInKorean : programSubTypeName

      return {
        ...p,
        groupTitle:
          programTypeName === 'Play'
            ? `${renderSubTypeName} ${p.time_span} ${getUnitTrans(
                p.time_span,
                p.time_span_unit
              )}`
            : renderSubTypeName,
      }
    })

    return groupBy(
      productsWithGroupName
        .filter(p =>
          selectedGame ? p.game_category_id === selectedGame : true
        )
        .filter(p =>
          selectedProgram
            ? p.class?.program_sub_type?.id === selectedProgram
            : true
        )
        .filter(p => {
          if (selectedTimeSpan) {
            const [timeSpan, unit] = selectedTimeSpan.split(' ')

            return p.time_span === Number(timeSpan) && p.time_span_unit == unit
          }
          return true
        }),
      'groupTitle'
    )
  }, [products, selectedGame, selectedProgram, selectedTimeSpan])

  const subscriptionProducts = useMemo(() => {
    return products.filter(p => p.type !== 'Normal')
  }, [products])

  const gameCategoryIdMap = useMemo(() => {
    return groupBy(
      products.filter(p => p.game_category_id),
      'game_category_id'
    )
  }, [products])

  const existGames = useMemo(() => {
    return (gameCategories || []).filter(g => {
      return !!gameCategoryIdMap[g.id]
    })
  }, [gameCategories, gameCategoryIdMap])

  const existedTimeSpan = useMemo(() => {
    const timeSpanMap = new Map<string, string>()
    const timeSpanArr: { label: string; value: string }[] = []

    products.forEach(p => {
      if (p.time_span) {
        timeSpanMap.set(
          `${p.time_span} ${getUnitTrans(p.time_span, p.time_span_unit)}`,
          `${p.time_span} ${p.time_span_unit}`
        )
      }
    })

    timeSpanMap.forEach((value, key) => {
      timeSpanArr.push({
        label: key,
        value,
      })
    })

    return timeSpanArr.sort((a, b) => {
      const [aSpan, aUnit] = a.value.split(' ')
      const [bSpan, bUnit] = b.value.split(' ')

      return (
        Number(aSpan) +
        (aUnit === 'Month' ? 1000 : 0) -
        (Number(bSpan) + (bUnit === 'Month' ? 1000 : 0))
      )
    })
  }, [products])

  const existProgramSubTypes = useMemo(() => {
    return (products || []).reduce((prev, product) => {
      const currentSubTypeId = get(product, 'class.program_sub_type.id')

      const existed = prev.find(p => p.id === currentSubTypeId)
      if (!existed && !!currentSubTypeId) {
        prev.push(product.class!.program_sub_type)
      }

      return prev
    }, [] as NSClass.ClassProgramType[])
  }, [products])

  const selectedProducts = useMemo(() => {
    if (selectedGame === null) {
      return products
    }
    return products.filter(g => g.game_category_id === selectedGame)
  }, [products, selectedGame])

  useEffect(() => {
    if (existGames.length && existProgramSubTypes.length) {
      const game = existGames.find(g => g.code === router.query.game)
      const program = existProgramSubTypes.find(
        g => g.name === router.query.program
      )

      if (game) {
        setSelectedGame(game.id)
      }
      if (program) {
        setSelectedProgram(program.id)
      }
    }

    if (!router.query.game) {
      setSelectedGame(null)
    }
    if (!router.query.program) {
      setSelectedProgram(null)
    }

    setSelectedTimeSpan((router.query.timeSpan as string | null) || null)
  }, [
    existGames,
    existProgramSubTypes,
    router.query.game,
    router.query.program,
    router.query.timeSpan,
    selectedGame,
  ])

  const productsGroupByGameCategory = useMemo(() => {
    const groupByGameCategoryId = groupBy(products, p => {
      return p.game_category_id
    })
    const items: {
      gameCategory: IGameCategory
      allProducts: NSProduct.Product[]
      subscriptionProducts: NSProduct.Product[]
      normalProducts: NSProduct.Product[]
    }[] = []

    Object.keys(groupByGameCategoryId).map(id => {
      const list = groupByGameCategoryId[id]
      if (list && list.length) {
        const gc = (gameCategories || []).find(
          cate => Number(cate.id) === Number(id)
        )
        if (gc) {
          items.push({
            gameCategory: gc,
            allProducts: list,
            subscriptionProducts: list.filter(p => p.type !== 'Normal'),
            normalProducts: list.filter(p => p.type === 'Normal'),
          })
        }
      }
    })
    return items.sort((i, j) => {
      return i.gameCategory.sequence - j.gameCategory.sequence
    })
  }, [gameCategories, products])

  return {
    existGames,
    existPrograms: existProgramSubTypes,
    selectedGame,
    setSelectedGame,
    selectedProgram,
    setSelectedProgram,
    selectedProducts,
    subscriptionProducts,
    groupingProducts,
    productsGroupByGameCategory,
    existedTimeSpan,
    selectedTimeSpan,
  }
}

export function ProvideCourseCenterContext({ children, isPlatform = false }) {
  const state = useCourseCenterContext(isPlatform)
  return (
    <CourseCenterContext.Provider value={state}>
      {children}
    </CourseCenterContext.Provider>
  )
}

export function useCourseCenter() {
  return useContext(CourseCenterContext)
}
