import React, { useCallback, useEffect, useMemo, useState } from 'react'
import InstallmentOption from './Option'

interface InstallmentGroupProps {
  options: {
    label: string
    value: string
  }[]
  onChange?: (value: string | undefined) => void
  cols?: 1 | 2 | 3 | 4
}

const InstallmentGroup = (props: InstallmentGroupProps) => {
  const { options, onChange, cols = 2 } = props
  const [selected, setSelected] = useState<string>()

  const clickHandler = useCallback(value => {
    setSelected(value)
  }, [])

  useEffect(() => {
    if (onChange) {
      onChange(selected)
    }
  }, [onChange, selected])

  const colClassName = useMemo(() => {
    switch (cols) {
      case 1:
        return 'grid-cols-1'
      case 2:
        return 'grid-cols-2'
      case 3:
        return 'grid-cols-3'
      case 4:
        return 'grid-cols-4'
    }
  }, [cols])

  return (
    <div
      className={`grid items-stretch justify-center max-w-lg mx-auto gap-8 ${colClassName}`}
    >
      {options.map((option, index) => {
        return (
          <InstallmentOption
            label={option.label}
            value={option.value}
            onClick={clickHandler}
            selected={selected === option.value}
            key={index}
          />
        )
      })}
    </div>
  )
}

export default InstallmentGroup
