import React, {
  ReactNode,
  forwardRef,
  useCallback,
  useMemo,
  useState,
} from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { UseFormReturn } from 'react-hook-form'
import { toast } from 'react-toastify'
import { Button, Flex, Input, TextInputProps } from '@mantine/core'
import classNames from 'classnames'
import { notifiySMSWithCaptchaApi, SmsTypeEnum } from '@/services'
import { replacePhoneNumberByPhoneCode } from '@/services/Utils'
import useSmsButton from '@/hooks/useSmsButton'
import GoogleRecaptchaComponent from '@/components/FormComponents/SmsVerification/GoogleRecaptchaComponent'
import FormImageCaptcha from './FormImageCaptcha'

interface SmsVerificationProps {
  phoneNumber: string
  phoneCode: string
  disabled?: boolean
  id?: string
  maxLength?: number
  registerFn?: () => ReturnType<UseFormReturn['register']>
  type?: SmsTypeEnum
  mode?: 'recaptcha' | 'imageCaptcha'
  invalid?: boolean
  label?: string
  classes?: any
}

export const SmsVerification = forwardRef<
  HTMLInputElement,
  SmsVerificationProps & TextInputProps
>((props, ref) => {
  const {
    id,
    maxLength = 6,
    registerFn,
    phoneNumber,
    phoneCode,
    disabled = false,
    type = SmsTypeEnum.Register,
    mode = 'imageCaptcha',
    invalid,
    label,
    classes,
    ...restProps
  } = props
  const { t } = useTranslation()
  const [showReCapcha, setShowRecapcha] = useState(false)
  const [verified, setVerified] = useState(false)

  const disableVerify = useMemo(() => {
    return !phoneNumber || !phoneCode || disabled
  }, [disabled, phoneCode, phoneNumber])

  const { countdown, handleClick } = useSmsButton({
    maxTimer: 60,
    recaptchaVerified: verified,
    isDisabled: disabled,
    // onVerify: () => setShowRecapcha(true),
  })

  const handleSendSMS = useCallback(
    async (code, id) => {
      const result = await notifiySMSWithCaptchaApi({
        captcha_style: 'Image',
        captcha: {
          code,
          id,
        },
        receiver: {
          phone_number: replacePhoneNumberByPhoneCode(phoneCode, phoneNumber),
          phone_code: phoneCode,
        },
        type: type,
      })

      if (result.success) {
        setVerified(true)
        setShowRecapcha(false)
        handleClick()
      } else {
        toast.error(result.message)
      }
      return result.success
    },
    [handleClick, phoneCode, phoneNumber, type]
  )

  const recaptchaContainer = useCallback(
    (
      children: (
        handleReCaptchaVerify: (() => void) | undefined,
        isVerifying: boolean
      ) => ReactNode
    ) => {
      if (mode === 'recaptcha') {
        return (
          <GoogleRecaptchaComponent
            onVerified={async (verified, token) => {
              const result = await notifiySMSWithCaptchaApi({
                captcha_style: 'Recaptcha',
                receiver: {
                  phone_number: replacePhoneNumberByPhoneCode(
                    phoneCode,
                    phoneNumber
                  ),
                  phone_code: phoneCode,
                },
                type: type,
                google_recaptcha: {
                  response: token,
                },
              })
              if (result.success) {
                setVerified(true)
                setShowRecapcha(false)
                handleClick()
              } else {
                toast.error(result.message)
              }
            }}
          >
            {(handleReCaptchaVerify, isVerifying) => {
              return children(handleReCaptchaVerify, isVerifying)
            }}
          </GoogleRecaptchaComponent>
        )
      }
      const noop = undefined
      return children(noop, false)
    },
    [handleClick, mode, phoneCode, phoneNumber, type]
  )

  const renderCaptcha = useMemo(() => {
    if (mode === 'imageCaptcha') {
      return (
        <Input.Wrapper
          label={label}
          classNames={{ ...classes }}
          error={invalid}
        >
          <FormImageCaptcha onSend={handleSendSMS} />
        </Input.Wrapper>
      )
    }
    return null
  }, [classes, handleSendSMS, invalid, label, mode])

  const sendAndInput = useMemo(() => {
    return (
      <Input.Wrapper label={label} classNames={{ ...classes }} error={invalid}>
        <Flex gap="sm">
          <Input
            {...restProps}
            ref={ref}
            className={classNames('flex-1', restProps.className)}
            id={id}
            autoComplete="off"
            maxLength={maxLength}
            placeholder={t('verification_code')}
            disabled={disableVerify}
            {...(registerFn && registerFn())}
            styles={{
              rightSection: {
                paddingTop: '0.25rem',
                paddingBottom: '0.25rem',
              },
            }}
            rightSectionWidth="auto"
          />
          {recaptchaContainer((handleReCaptchaVerify, isVerifying) => {
            return (
              <Button
                type="button"
                onClick={() => {
                  if (countdown > 0 || isVerifying) {
                    return
                  }
                  handleReCaptchaVerify
                    ? handleReCaptchaVerify()
                    : setShowRecapcha(true)
                }}
                disabled={disableVerify || isVerifying || countdown > 0}
              >
                {countdown ? (
                  `${countdown}s`
                ) : (
                  <Trans i18nKey="send_verification_code" />
                )}
              </Button>
            )
          })}
        </Flex>
      </Input.Wrapper>
    )
  }, [
    classes,
    countdown,
    disableVerify,
    id,
    invalid,
    label,
    maxLength,
    recaptchaContainer,
    ref,
    registerFn,
    restProps,
    t,
  ])

  return !showReCapcha ? sendAndInput : renderCaptcha
})

SmsVerification.displayName = 'SmsVerification'
