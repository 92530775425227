import React from 'react'
import DiscordServers from '@/components/DiscordServers'
import ImageComponent from '@/components/ImageComponent'

const Discord = () => {
  return (
    <div className="fixed bottom-[6rem] z-100 right-6">
      <div className="relative flex flex-nowrap items-center justify-center font-black group">
        <a
          href="https://discord.gg/invite/hEC2r3uBUP"
          title="Join Our Discord"
          target="_blank"
          className="block w-12"
          rel="noreferrer"
        >
          <ImageComponent
            src="https://www.ggacademy.gg/wp-content/uploads/2022/10/discord.png"
            width="48"
            height="48"
            className="cursor-pointer"
          />
        </a>
      </div>
    </div>
  )
}

export default Discord
