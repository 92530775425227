import { APP_LOCALE } from '@/constants/define'
import { ILocalizationCode } from '../gga-types/src/types/localization'

export class Currency {
  private name: string
  private _locale: string
  private maximumFractionDigits: number

  constructor(
    private amount: number,
    private localizationCode: ILocalizationCode
  ) {
    switch (this.localizationCode) {
      case 'kr':
        this.name = 'KRW'
        this._locale = 'ko-KR'
        this.maximumFractionDigits = 0
        break

      case 'en_US':
        this.name = 'USD'
        this._locale = 'en-US'
        this.maximumFractionDigits = 2
        break

      case 'zh_CN':
        this.name = 'CNY'
        this._locale = 'zh-CN'
        this.maximumFractionDigits = 2
        break

      case 'ar_AE':
        this.name = 'USD'
        this._locale = 'en-US'
        this.maximumFractionDigits = 2
        break
      default:
        throw new Error('Unsupported currency')
    }
  }

  renderWithSymbol() {
    return Number(this.amount).toLocaleString(this._locale, {
      style: 'currency',
      currency: this.name,
      maximumFractionDigits: this.maximumFractionDigits,
    })
  }

  renderWithName() {
    return `${this.amount.toLocaleString(this._locale)} ${this.name}`
  }

  getCurrencyName() {
    return this.name
  }

  getAmountString() {
    return this.amount.toLocaleString(this._locale)
  }

  getAmount() {
    return this.amount
  }
}

export const currencySymbol = new Currency(0, APP_LOCALE)
  .renderWithSymbol()
  .substring(0, 1)
