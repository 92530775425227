import Head from 'next/head'
import { APP_LOCALE } from '@/constants/define'

type TOgOptions = {
  image: string
  type: string
}

type TPageMeta = {
  pageTitle: string
  pageDescription: string
  pageKeyword: string
  og?: Partial<TOgOptions>
}

const getDefault: Record<typeof APP_LOCALE, TPageMeta> = {
  zh_CN: {
    pageTitle: 'GGA电竞训练营',
    pageDescription:
      'Gen.G Global Academy是一个国际电竞人才培养平台，通过Gen.G电竞俱乐部提供的资源培养全球电子竞技专业人士',
    pageKeyword:
      '精竞, 国际电竞, 人才培养, 平台, 精竞, 电竞, 电子竞技, esports, ESports, Gen.G, geng, 人才培养, academy, 教育, 培训, 俱乐部, 英雄联盟, LOL, League of Legends, 守望先锋, overwatch, valorant, 无畏契约, val, 游戏, 手游, 端游',
  },
  en_US: {
    pageTitle: 'GGA Online',
    pageDescription:
      'Online game coaching program built for gamers. We are the official esports academy of Gen.G Esports.',
    pageKeyword: '',
  },
  kr: {
    pageTitle: 'GGA Online',
    pageDescription:
      '젠지가 함께하는 글로벌 Esports 전문인력 양성 교육기관 Gen.G Global Academy',
    pageKeyword: '',
  },
}
export default function PageMeta({
  pageTitle,
  pageDescription,
  pageKeyword,
  og = {},
}: Partial<TPageMeta>) {
  const defaultItem = getDefault[APP_LOCALE]
  return (
    <Head>
      <title key={'page-title'}>
        {pageTitle
          ? `${pageTitle} - ${defaultItem.pageTitle}`
          : defaultItem.pageTitle}
      </title>
      <meta
        property={'og:title'}
        content={
          pageTitle
            ? `${pageTitle} - ${defaultItem.pageTitle}`
            : defaultItem.pageTitle
        }
      />
      <meta
        name={'description'}
        content={pageDescription || defaultItem.pageDescription}
      />
      <meta
        property={'og:description'}
        content={pageDescription || defaultItem.pageDescription}
      />
      <meta name={'keyword'} content={pageKeyword || defaultItem.pageKeyword} />
      {og?.image && <meta property={'og:image'} content={og.image} />}
      {og?.type && <meta property={'og:type'} content={og.type} />}
    </Head>
  )
}
