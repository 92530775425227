import { useRequest } from 'ahooks'
import { ReactNode, useCallback, useEffect, useMemo, useState } from 'react'
import { LoadingOutlined } from '@ant-design/icons'
import { get } from 'lodash'
import { Button } from 'stripe-ui'
import classNames from 'classnames'
import { useTranslation } from 'next-i18next'
import { getCaptcha, verifyCaptcha } from '@/services/captcha'
import ImageComponent from '@/components/ImageComponent'

type IChildrenCallbackProps = {
  onChange: (text: string) => void
  value: string
}

type IImageCaptchaProps = {
  onChange?: (id: string, code: string) => void
  selfVerify?: boolean
  onSend?: (code: string, id: string) => Promise<boolean>
  onVerify?: (success: boolean, err?: Error) => void
  children?: (props: IChildrenCallbackProps) => ReactNode
  className?: string
  imgClass?: string
  verifyText?: string
}

export default function ImageCaptcha({
  onChange,
  selfVerify,
  onSend,
  onVerify,
  children,
  className,
  imgClass,
  verifyText,
}: IImageCaptchaProps) {
  const { run, loading, data } = useRequest(getCaptcha, { manual: true })
  const [code, setCode] = useState('')

  const id = useMemo(() => get(data, 'data.data.id'), [data])

  const { t } = useTranslation('common')

  useEffect(() => {
    run()
  }, [run])

  useEffect(() => {
    const id = get(data, 'data.data.id')
    if (id) {
      onChange && onChange(id, code)
    }
  }, [code, data, onChange])

  const handleSelfVerify = useCallback(async () => {
    if (onSend) {
      const success = await onSend(code, id)
      if (!success) {
        setCode('')
        run()
      }
    } else {
      const res = await verifyCaptcha({
        id,
        code,
      })
      if (res && res.data && res.data.data) {
        onVerify && onVerify(true)
      } else {
        onVerify && onVerify(false, new Error(t('image_captcha_wrong')))
        setCode('')
        run()
      }
    }
  }, [code, id, onSend, onVerify, run, t])

  return (
    <div className={classNames('px-2 flex flex-col space-y-1', className)}>
      <div className="flex items-center space-x-1.5">
        {children ? (
          children({
            onChange: text => {
              setCode(text)
            },
            value: code,
          })
        ) : (
          <input
            className="flex-1"
            defaultValue={code}
            onChange={e => {
              setCode(e.target.value)
            }}
          />
        )}

        <div
          className="rounded-md border border-gold-500 self-stretch"
          onClick={() => {
            run()
          }}
        >
          {loading ? (
            <div className="px-4 py-1">
              <LoadingOutlined />
            </div>
          ) : (
            <ImageComponent
              className={classNames(
                'rounded-md w-32 h-full cursor-pointer',
                imgClass
              )}
              alt="click to reload"
              src={get(data, 'data.data.image_base64')}
            />
          )}
        </div>
      </div>
      {selfVerify && (
        <Button type="button" onClick={handleSelfVerify}>
          {verifyText || t('verify_image_captcha')}
        </Button>
      )}
    </div>
  )
}
