import { NSClass, NSLiveSession } from 'gga-types'
import { Api } from '@/api'
import { APP_LOCALE } from '@/constants/define'

// TODO: move to gga-types package
export type TCoachInfoTitle = {
  avatar_url: string
  id: number
  name: string
  tags: string
  signed_avatar_url: string
  game_category_id: number
  coach_event_statistic: {
    min_cost: number | null
  } | null
  game_category: any
}

export type TCoachDescription = {
  id: number
  coach_info_id: number
  sequence: number
  title: string
  description: string
}

export type TCoachInfo = TCoachInfoTitle & {
  introduction: string
  achievement: string
  coach_descriptions: TCoachDescription[]
  user_id: number
}

export type TCoachInfoAvatar = {
  game_category_id: number
  game_category: NSClass.GameCategory
  coach_info_avatar_list: (Omit<TCoachInfoTitle, 'avatar_url'> & {
    coach_feedback_statistic: CoachFeedbackStatistic
  })[]
}

export type TagStatistic = {
  count: number
  tag: NSLiveSession.TagItem
}

export type CoachFeedbackStatistic = {
  coach_info_title: TCoachInfoTitle
  coach_rating: number
  total_feedback_count: number
  tag_statistic_list: TagStatistic[]
}

export type FeaturedCoachFeedback = NSLiveSession.CoachFeedbackItem & {
  coach_feedback_statistic: CoachFeedbackStatistic
}

export class CoachApi extends Api {
  constructor(...args) {
    super(...args)
  }

  getCoachInfo(id: string | number) {
    return this.createApi<void, TCoachInfo>(`/v2/coachinfo/${id}`, 'get')
  }

  fetchCoachAvatarApi(locale: string) {
    return this.createApi<void, TCoachInfoAvatar[]>(
      `/v2/coachinfo/avatar/list/${locale}`,
      'get'
    )
  }

  fetchFeaturedCoachFeedbacks() {
    return this.createApi<void, FeaturedCoachFeedback[]>(
      `/v2/CoachFeedback/list/${APP_LOCALE}/featured`,
      'get'
    )
  }

  fetchCoachFeedbackStatistic(coachId: number) {
    return this.createApi<void, CoachFeedbackStatistic>(
      `/v2/CoachFeedback/statistic/${coachId}`,
      'get'
    )
  }

  getUserCoachFeedbackStatus() {
    return this.createApi<void, number>(`/v2/CoachFeedback/uncommented`, 'get')
  }
}
