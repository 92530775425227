import { useCallback, useEffect } from 'react'
import FormForTrialClass from '@/components/CoursewareModal/TrialFeedback/FormForTrialClass'
import FormForStandardClass from '@/components/CoursewareModal/TrialFeedback/FormForStandardClass'
import useTrialFeedback from '@/hooks/useTrialFeedback'
import { postTrialFeedback } from '@/services/trial-feedback'

type ITrialFeedbackProps = {
  isTrial: boolean
  trialId: number
  onSuccess: () => void
}

export default function TrialFeedback({
  isTrial,
  trialId,

  onSuccess,
}: ITrialFeedbackProps) {
  const { fetchHasTrialFeedback, trialFeedbackData, loading } =
    useTrialFeedback()

  useEffect(() => {
    fetchHasTrialFeedback(trialId)
  }, [fetchHasTrialFeedback, trialId])

  const onSubmit = useCallback(
    async (values: any) => {
      await postTrialFeedback(trialId, {
        ...values,
        wanna_try_more:
          typeof values.wanna_try_more === 'number'
            ? Boolean(values.wanna_try_more)
            : undefined,
      })
      onSuccess()
    },
    [onSuccess, trialId]
  )

  if (loading) {
    return <div />
  }

  if (trialFeedbackData?.has_feedback) {
    return (
      <div className={'bg-white pt-8 pb-4 rounded-md'}>
        {isTrial ? (
          <FormForTrialClass
            viewData={trialFeedbackData?.trial_feedback}
            onSubmit={onSubmit}
          />
        ) : (
          <FormForStandardClass
            viewData={trialFeedbackData?.trial_feedback}
            onSubmit={onSubmit}
          />
        )}
      </div>
    )
  }

  return (
    <div className={'bg-white pt-8 pb-4 rounded-md'}>
      {isTrial ? (
        <FormForTrialClass onSubmit={onSubmit} />
      ) : (
        <FormForStandardClass onSubmit={onSubmit} />
      )}
    </div>
  )
}
