import { useCallback, useState } from 'react'
import { useRequest, useSetState } from 'ahooks'
import { IGameInfo } from 'gga-types'
import { getUserGameInfo, putUserGameInfo } from '@/services/user'

export type IGameState = {
  gameInfo: IGameInfo
  fetchUserGameInfo()
  updateUserGameInfo(data: Partial<IGameInfo>)
  updating: boolean
  loading: boolean
}

export default function useGameState(): IGameState {
  const [gameInfo, setGameInfo] = useSetState<IGameInfo>({} as IGameInfo)
  const { runAsync: updateInfo, loading: updating } = useRequest(
    putUserGameInfo,
    {
      manual: true,
    }
  )
  const [loading, setLoading] = useState(false)

  const fetchUserGameInfo = useCallback(async () => {
    setLoading(true)
    try {
      const resp = await getUserGameInfo()
      if (resp.data && resp.data.data) {
        setGameInfo(resp.data.data)
      }
      setLoading(false)
    } catch (e) {
      setLoading(false)
      throw e
    }
  }, [setGameInfo])

  const updateUserGameInfo = useCallback(
    async (data: Partial<IGameInfo>) => {
      await updateInfo(data)
      await fetchUserGameInfo()
    },
    [fetchUserGameInfo, updateInfo]
  )

  return {
    gameInfo,
    fetchUserGameInfo,
    updateUserGameInfo,
    updating,
    loading,
  }
}
