import classNames from 'classnames'
import React, { useMemo } from 'react'
import { Icon } from 'stripe-ui'

interface PendingProps {
  loading: boolean
  fallback?: React.ReactNode | null
  children: React.ReactNode
  className?: string
}

export const Pending = (props: PendingProps) => {
  const { loading, fallback, children, className } = props

  const Loading = useMemo(() => {
    if (fallback) {
      return fallback
    }
    return (
      <div className={classNames('p-4', className)}>
        <Icon name="spin" size="large" className="m-auto" />
      </div>
    )
  }, [className, fallback])

  return <>{loading ? Loading : children}</>
}
