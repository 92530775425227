import { GetServerSidePropsContext } from 'next'
import { IResData, IResDataPaged } from '@/@types/global'
import { RequestOptions, request } from '@/utils/request'

// TODO: 完善API接口(maybe?)
/**
 * higher order api class, can take a server side context to do server side requests
 */
export abstract class Api {
  protected constructor(private context?: GetServerSidePropsContext) {}

  protected createApi<
    T,
    R,
    P extends IResData<R> | IResDataPaged<R> = IResData<R>
  >(
    url: string,
    method: 'get' | 'post' | 'put' | 'patch' | 'delete',
    data?: T,
    options?: Partial<RequestOptions>
  ) {
    return request<P>({
      url,
      method,
      data,
      context: this.context,
      ...(options || {}),
    })
  }
}
