import React, { ReactNode, useCallback, useEffect, useRef } from 'react'
import classNames from 'classnames'
import { useTranslation } from 'next-i18next'
import { IGameCategory } from 'gga-types'

function GameTag({ id, text }) {
  const ref = useRef<HTMLDivElement>(null)

  const scrollIfNeeded = useCallback(() => {
    const hash = window.location.hash.substring(1)
    const gameCode = hash.split('=')[1]
    if (id === gameCode && ref.current) {
      const y =
        ref.current.getBoundingClientRect().top + window.pageYOffset - 80

      window.scrollTo({ top: y, behavior: 'smooth' })
    }
  }, [id])

  useEffect(() => {
    scrollIfNeeded()
    window.addEventListener('hashchange', () => {
      scrollIfNeeded()
    })

    return () => {
      window.removeEventListener('hashchange', scrollIfNeeded)
    }
  }, [id, scrollIfNeeded])

  return (
    <div
      ref={ref}
      id={id}
      className={classNames('text-xl flex-shrink-0 text-brand')}
    >
      {text}
    </div>
  )
}

type TGameCategoryHeaderProps = {
  title: string | ReactNode
}
export default function ProductCategoryHeader({
  title,
}: TGameCategoryHeaderProps) {
  const { t } = useTranslation('common')
  return (
    <div className="flex overflow-auto hide-scroll justify-between items-center pb-2 border-b border-[#8F8F8F] mb-4">
      {title}
    </div>
  )
}
