import router from 'next/router'

export function navigatToProtocalPage(
  classId: number,
  gameCategoryCode: string
) {
  const url = getProtocalPageUrl(classId, gameCategoryCode)
  return router.push(url)
}

export function getProtocalPageUrl(classId: number, gameCategoryCode: string) {
  return `/protocal?cid=${classId || ''}&game=${gameCategoryCode || ''}`
}
