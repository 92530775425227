import { yupResolver } from '@hookform/resolvers/yup'
import { Anchor, Button, Flex, PasswordInput, Stack } from '@mantine/core'
import React, { useCallback } from 'react'
import { get } from 'lodash'
import { useTranslation } from 'next-i18next'
import { Controller, useForm } from 'react-hook-form'
import PhoneNumberInput from '@/components/inputs/PhoneNumberInput'
import { login } from '@/services/user'
import Yup from '@/utils/yup'
import { useStyles } from './utils'

type LoginFormValue = {
  phone: {
    phone_code: string
    phone_number: string
  }
  password: string
}

const schema = Yup.object().shape({
  password: Yup.string().required().min(6),
  phone: Yup.object({
    phone_code: Yup.string().required(),
    phone_number: Yup.string()
      .required()
      .omitStartZero()
      .phoneNumber('phone_code'),
  }),
})

interface LoginFormProps {
  onLogged?: () => void
  onRegister: () => void
  onReset: () => void
  onSmsLogin: () => void
}

export const LoginForm: React.FC<LoginFormProps> = ({
  onLogged,
  onRegister,
  onReset,
  onSmsLogin,
}) => {
  const { t } = useTranslation()
  const { classes } = useStyles()
  const { control, formState, handleSubmit } = useForm<LoginFormValue>({
    mode: 'all',
    defaultValues: {
      phone: {
        phone_code: '86',
        phone_number: '',
      },
      password: '',
    },
    resolver: yupResolver(schema),
  })
  const { errors, isSubmitting, isValid, isDirty } = formState

  const onSubmit = useCallback(
    async (values: LoginFormValue) => {
      const { phone, ...rest } = values
      const postData = {
        ...rest,
        ...phone,
      }
      const response = await login(postData)
      if (response) {
        onLogged?.()
      }
    },
    [onLogged]
  )

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="text-center text-[22px] text-[#FAFAFA] font-bold mb-5">
        Gen.G电竞人才培养平台
      </div>

      <Stack>
        <Controller
          name="phone"
          control={control}
          render={({ field }) => {
            return (
              <PhoneNumberInput
                classes={{ label: classes.label }}
                label={t('phone_number')}
                placeholder={t('phone_number')}
                inputProps={{
                  maxLength: 36,
                  error: get(errors, 'phone.phone_number.message'),
                  description:
                    '使用电话号码登录时，请选择正确的国家/地区代码。',
                  descriptionProps: { sx: { color: '#FAFAFA', opacity: 0.5 } },
                  inputWrapperOrder: ['label', 'input', 'description', 'error'],
                }}
                {...field}
              />
            )
          }}
        />

        <Controller
          name="password"
          control={control}
          render={({ field }) => {
            return (
              <PasswordInput
                classNames={{ label: classes.label }}
                placeholder={t('password')}
                label={t('password')}
                {...field}
                error={get(errors, 'password.message')}
                inputContainer={children => {
                  return (
                    <>
                      {children}
                      <Flex
                        justify="space-between"
                        className="mt-2 text-[#FAFAFA] opacity-70"
                      >
                        <Anchor
                          sx={{ color: '#FAFAFA', opacity: 0.7 }}
                          onClick={onSmsLogin}
                          underline
                          className="underline-offset-4"
                        >
                          验证码登陆
                        </Anchor>
                        <Anchor
                          sx={{ color: '#FAFAFA', opacity: 0.7 }}
                          onClick={onReset}
                          underline
                          className="underline-offset-4"
                        >
                          忘记密码
                        </Anchor>
                      </Flex>
                    </>
                  )
                }}
              />
            )
          }}
        />

        <div className="text-center mt-2">
          <Button
            type="submit"
            color="brand"
            disabled={!isDirty || !isValid}
            loading={isSubmitting}
          >
            登录
          </Button>
        </div>
        <div className="text-center">
          <Anchor
            color="brand"
            underline={false}
            className="flex-1"
            onClick={onRegister}
          >
            创建账号
          </Anchor>
        </div>
      </Stack>
    </form>
  )
}
