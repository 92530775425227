import React, { useMemo } from 'react'
import { Trans } from 'next-i18next'
import ReactModal from 'react-modal'
import classNames from 'classnames'
import { Button } from '@mantine/core'
import { NSClass } from 'gga-types'
import {
  ICoursewareModalContexts,
  useCoursewareModal,
} from '@/hooks/myPages/CoursewareModalProvider'
import SeminarCoachUpload from '@/components/CoursewareModal/SeminarCoachUpload'
import { TeamScrimUpload } from '@/components/CoursewareModal/TeamScrimUpload'
import { useStore } from '@/context/store'
import IconPark from '@/components/IconPark'
import { SoloAndTeamTaskReview } from './SoloAndTeamTaskReview'
import { PageTask } from './PageTask'
import QuizModalContent from './QuizModalContent'
import { SoloTaskUpload } from './SoloTaskUpload'

type ICoursewareContentFactoryProps = {
  ctx?: ICoursewareModalContexts
}

const CoursewareContentFactory = (props: ICoursewareContentFactoryProps) => {
  const { ctx = 'CLASS_MODAL_CONTEXT' } = props
  const {
    classId,
    isSolo,
    viewType,
    setViewType,
    isInit,
    syllabusId,
    info,
    refreshModalData,
    setFullHeight,
    courseware,
  } = useCoursewareModal(ctx)

  const {
    soloLessonState: { submissionStatus, isGGAPro },
  } = useStore()

  const { setGGAProRecord } = useCoursewareModal()

  const backBtn = useMemo(
    () => (
      <div className="text-right">
        <Button
          onClick={() => {
            setViewType('review')
          }}
        >
          <Trans i18nKey="course_form.back" />
        </Button>
      </div>
    ),
    [setViewType]
  )

  const render = useMemo(() => {
    if (viewType === 'review') {
      setFullHeight(true)
    } else {
      setFullHeight(false)
    }
    switch (viewType) {
      case 'quiz':
        return <QuizModalContent />
      case 'solo_upload':
        return (
          <SoloTaskUpload
            ctx={ctx}
            header={isInit || isGGAPro ? null : backBtn}
            submissionStatus={isSolo ? submissionStatus : undefined}
            classId={classId!}
            nodeId={syllabusId!}
            onSuccess={data => {
              if (isGGAPro) {
                setGGAProRecord(data as NSClass.TrialType)
              }
              refreshModalData().then(() => {
                setViewType('review')
              })
            }}
          />
        )
      case 'team_upload':
        return (
          <TeamScrimUpload
            ctx={ctx}
            header={isInit ? null : backBtn}
            classId={classId!}
            nodeId={syllabusId!}
            onSuccess={() => {
              refreshModalData().then(() => {
                setViewType('review')
              })
            }}
          />
        )
      case 'seminar_upload':
        return (
          <SeminarCoachUpload
            onSuccess={() => {
              refreshModalData().then(() => {
                setViewType('review')
              })
            }}
          />
        )
      case 'review':
        return <SoloAndTeamTaskReview ctx={ctx} />
      case 'page':
        return <PageTask ctx={ctx} />
      default:
        return null
    }
  }, [
    backBtn,
    classId,
    ctx,
    isGGAPro,
    isInit,
    isSolo,
    refreshModalData,
    setFullHeight,
    setGGAProRecord,
    setViewType,
    submissionStatus,
    syllabusId,
    viewType,
  ])

  // 目前只在review的组件中去拉取courseware数据
  if ((courseware && info) || viewType === 'review') {
    return render
  } else {
    return null
  }
}

export const CoursewareModal = ({
  ctx = 'CLASS_MODAL_CONTEXT',
}: {
  ctx?: ICoursewareModalContexts
}) => {
  const { isOpen, info, closeTaskModal, onAfterClose, isGGAPro, fullHeight } =
    useCoursewareModal(ctx)

  const titleMapping = useMemo(
    () =>
      ({
        page_std: 'Courseware',
        solo_task: isGGAPro ? 'Personal Training' : 'Solo Task',
        team_scrim: 'Team Scrim',
        seminar: 'Seminar',
        quiz: 'Quiz',
      } as Record<NSClass.TemplateTypes, string>),
    [isGGAPro]
  )
  return (
    <ReactModal
      overlayClassName={'fixed inset-0 rounded-sm z-200 bg-black bg-opacity-10'}
      className={'absolute inset-0 md:inset-4 flex flex-col'}
      isOpen={isOpen}
      onAfterClose={onAfterClose}
    >
      <div
        className={classNames(
          'relative top-1/2 transform -translate-y-1/2 border border-gray-50 bg-white overflow-auto md:rounded-sm outline-none p-1',
          fullHeight && 'h-full'
        )}
      >
        <div className="absolute bg-white top-0 left-0 right-0 z-200 rounded-t flex">
          <div className="flex-1 text-center">
            <div className={'font-bold tracking-wide text-xl py-1'}>
              {(info && titleMapping[info.template]) || ''}
            </div>
          </div>

          <div
            onClick={closeTaskModal}
            className={
              'cursor-pointer inline-block bg-brand p-1 m-1 w-6 h-6 flex items-center justify-center'
            }
          >
            <IconPark
              name={'close'}
              className={classNames('text-white text-lg')}
            />
          </div>
        </div>
        <div
          className={
            'relative pt-8 p-1 flex flex-col h-full overflow-auto md:overflow-hidden'
          }
        >
          <CoursewareContentFactory ctx={ctx} />
        </div>
      </div>
    </ReactModal>
  )
}
