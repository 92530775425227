import { useCallback, useMemo } from 'react'
import { useForm, Controller } from 'react-hook-form'
import { Trans, useTranslation } from 'next-i18next'
import { toast } from 'react-toastify'
import { Field, Password } from 'stripe-ui'
import { Button, Stack } from '@mantine/core'
import { get } from 'lodash'
import { yupResolver } from '@hookform/resolvers/yup'
import { useClicked } from '@/hooks/useClicked'
import { SmsTypeEnum } from '@/services'
import { resetPasswordApi } from '@/services/user'
import { replacePhoneNumberByPhoneCode } from '@/services/Utils'
import { SmsVerification } from '@/components/FormComponents/SmsVerification'
import { APP_LOCALE } from '@/constants/define'
import { Jwt } from '@/services/jwt'
import PhoneNumberInput from '@/components/inputs/PhoneNumberInput'
import Yup from '@/utils/yup'

const schema = Yup.object().shape({
  password: Yup.string().required().min(6),
  sms_verify_code: Yup.string().required(),
  phone: Yup.object({
    phone_code: Yup.string().required(),
    phone_number: Yup.string()
      .required()
      .omitStartZero()
      .phoneNumber('phone_code'),
  }),
})

export const ForgetPasswordForm = ({
  onLogin,
  onSuccess,
}: {
  onLogin: () => void
  onSuccess: () => void
}) => {
  const { t } = useTranslation()

  const {
    register,
    handleSubmit,
    watch,
    control,
    formState: { isValid, errors },
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      phone: {
        phone_code:
          APP_LOCALE === 'en_US' ? '1' : APP_LOCALE === 'kr' ? '82' : '86',
        phone_number: '',
      },
      password: '',
      sms_verify_code: '',
    },
    resolver: yupResolver(schema),
  })

  const { clicked, setClicked } = useClicked()

  const goToLogin = useCallback(
    e => {
      e.preventDefault()
      onLogin()
    },
    [onLogin]
  )

  const currentPhoneCode = watch('phone.phone_code')
  const currentPhoneNumber = watch('phone.phone_number')

  const changePassword = useCallback(
    data => {
      if (clicked) {
        return
      }
      setClicked(true)
      const { phone, ...rest } = data
      resetPasswordApi({
        ...rest,
        phone_number: replacePhoneNumberByPhoneCode(
          phone.phone_code,
          phone.phone_number
        ),
        phone_code: phone.phone_code,
      })
        .then(res => {
          if (!res.success) {
            toast.error(res.message)
          }
          const accessToken = res.data.data?.access_token ?? null
          if (accessToken) {
            Jwt.setToken(accessToken)
            onSuccess()
          }
          setClicked(false)
        })
        .catch(e => {
          setClicked(false)
          console.error(e)
        })
    },
    [clicked, onSuccess, setClicked]
  )

  const isValidPhoneNumber = useMemo(() => {
    return currentPhoneNumber && !errors?.phone?.phone_number?.message
  }, [errors?.phone?.phone_number?.message, currentPhoneNumber])

  return (
    <div className="mx-auto">
      <h4 className="text-xl font-bold text-center">
        {t('forget_password_form.heading')}
      </h4>

      <form onSubmit={handleSubmit(changePassword)} className="pt-2">
        <Field htmlFor="phone_number">
          <div className="flex flex-row space-x-2">
            <Controller
              name="phone"
              control={control}
              render={({ field }) => {
                const { value, onChange } = field
                return (
                  <PhoneNumberInput
                    label={t('phone_number')}
                    placeholder={t('phone_number')}
                    inputProps={{
                      maxLength: 36,
                      withAsterisk: true,
                      error: get(errors, 'phone.phone_number.message'),
                    }}
                    value={value}
                    onChange={onChange}
                  />
                )
              }}
            />
          </div>
        </Field>

        <Field
          label={<Trans i18nKey="forget_password_form.new_password" />}
          htmlFor="password"
          error={get(errors, 'password.message')}
        >
          <Password
            id="password"
            autoComplete="new-password"
            {...register('password', { required: true })}
            placeholder={t('forget_password_form.new_password')}
          />
        </Field>

        <Field
          label={<Trans i18nKey="send_verification_code_via_text" />}
          htmlFor="sms_verify_code"
        >
          <SmsVerification
            mode="recaptcha"
            phoneCode={currentPhoneCode}
            phoneNumber={currentPhoneNumber}
            id="sms_verify_code"
            maxLength={6}
            registerFn={() => register('sms_verify_code', { required: true })}
            type={SmsTypeEnum.ResetPassword}
            invalid={get(errors, 'sms_verify_code.message')}
            disabled={!isValidPhoneNumber}
          />
        </Field>

        <Stack className={'mt-6'} spacing={'xs'}>
          <Button
            variant={'filled'}
            fullWidth
            type="submit"
            disabled={!isValid}
          >
            <span className={'uppercase'}>
              <Trans i18nKey="forget_password_form.button" />
            </span>
          </Button>
          <Button variant="subtle" fullWidth onClick={goToLogin}>
            <span className="uppercase">{t('back-to-login')}</span>
          </Button>
        </Stack>
      </form>
    </div>
  )
}
