import { NSProduct } from 'gga-types'
import { request } from '@/utils/request'
import { IResData } from '@/@types/global'
import { APP_LOCALE } from '@/constants/define'

export function getAllProductsByLocale() {
  return () =>
    request<IResData<NSProduct.Product[]>>({
      url: `/v2/product/${APP_LOCALE}/all`,
      method: 'get',
    })
}

export function checkPromoDup2021Oct(pui: string) {
  return request<IResData<boolean>>({
    url: `/v2/product/h5/personal-promo/2021-oct/dup/${pui}`,
    method: 'get',
  })
}

export function getProductById(productId: number) {
  return request<IResData<NSProduct.Product>>({
    url: `/v2/Product/${productId}`,
    method: 'get',
  })
}

export function getProductByIds(ids: number[]) {
  return request<IResData<NSProduct.Product[]>>({
    url: `/v2/product/by_ids`,
    method: 'post',
    data: {
      ids,
    },
  })
}
