// eslint-disable-next-line import/no-unresolved
import 'swiper/css'
// eslint-disable-next-line import/no-unresolved
import 'swiper/css/pagination'

import { Swiper } from 'swiper/react'
import classNames from 'classnames'
import { Pagination } from 'swiper'
import { renderToString } from 'react-dom/server'
import { PropsWithChildren, useMemo, useState } from 'react'
import styles from './styles.module.scss'

type TResponsiveSwiperProps = {
  slidesPerView?: {
    sm?: number
    md?: number
    lg?: number
  }
  spaceBetween?: number
}

const defaultSlidesPerView = {
  sm: 1,
  md: 2,
  lg: 3,
}

export default function ResponsiveSwiper({
  children,
  slidesPerView = defaultSlidesPerView,
  spaceBetween = 28,
}: PropsWithChildren<TResponsiveSwiperProps>) {
  const [paginateEl, setPaginateEl] = useState<HTMLElement | null>(null)
  const _slidesPerView = useMemo(() => {
    return { ...defaultSlidesPerView, ...slidesPerView }
  }, [slidesPerView])
  return (
    <div className={'w-full'}>
      {paginateEl && (
        <Swiper
          modules={[Pagination]}
          className={classNames('w-full', styles.responsiveSwiper)}
          slidesPerView={1}
          breakpoints={{
            640: {
              slidesPerView: _slidesPerView.sm,
            },
            768: {
              slidesPerView: _slidesPerView.md,
            },
            1024: {
              slidesPerView: _slidesPerView.lg,
            },
          }}
          spaceBetween={spaceBetween}
          pagination={{
            el: paginateEl,
            clickable: true,
            renderBullet: (_, className) => {
              return renderToString(
                <span
                  className={classNames(className, 'border border-gold')}
                ></span>
              )
            },
          }}
        >
          {children}
        </Swiper>
      )}
      <div
        ref={el => {
          setPaginateEl(el)
        }}
        className={classNames(styles.responsiveSwiperNav, 'text-center mt-2')}
      ></div>
    </div>
  )
}
