import 'intersection-observer'
import 'styles/index.global.scss'
import 'react-toastify/dist/ReactToastify.css'
import { useEffect } from 'react'
import { appWithTranslation } from 'next-i18next'
import { useRouter } from 'next/router'
import { AppComponent, AppShell } from '@/components/App'
import nextI18NextConfig from '@/next-i18next.config'
import '@/utils/prototype'
import ContactWrapper from '@/components/kr/ContacWrapper'
import { ChannelIOService } from '@/services/channel-io-service'

function App({ Component, pageProps }) {
  const router = useRouter()
  const countryCode = router.query.country
  useEffect(() => {
    if (countryCode !== 'UAE') {
      ChannelIOService.bootstrap()
    }
  }, [countryCode])

  return (
    <AppShell {...pageProps}>
      <ContactWrapper hide={pageProps.hideContact}>
        <AppComponent Component={Component} pageProps={pageProps} />
      </ContactWrapper>
    </AppShell>
  )
}

export default appWithTranslation(App, nextI18NextConfig as any)
