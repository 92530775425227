import { CloseOutlined } from '@ant-design/icons'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import ReactModal from 'react-modal'
import { useTranslation } from 'next-i18next'
import { format } from 'date-fns'
import classNames from 'classnames'
import { toast } from 'react-toastify'
import { EnvelopeIcon } from '@heroicons/react/24/outline'
import { NSClass, NSNotification } from 'gga-types'
import { useStore } from '@/context/store'
import Pagination from '@/components/Pagination'
import {
  CoursewareModalProvider,
  useCoursewareModal,
} from '@/hooks/myPages/CoursewareModalProvider'
import { CoursewareModal } from '@/components/CoursewareModal'
import {
  checkIsGGAPro,
  getUserClassByIdV2,
  getVideoRecordById,
} from '@/services/class'
import IconPark from '@/components/IconPark'
import { notificationHandler } from '@/components/NotificationBell/util'
import { APP_LOCALE } from '@/constants/define'
import { useAuth } from '@/containers/auth'

export default function NotificationBell() {
  const {
    notificationState: {
      startPolling,
      hasNew,
      initialOpen,
      fetcher,
      resetList,
    },
  } = useStore()
  const { isAuthenticated } = useAuth()
  const pollRef = useRef<any>()
  const [open, setOpen] = useState(false)

  const handleClick = useCallback(() => {
    setOpen(true)
  }, [])

  useEffect(() => {
    if (initialOpen) {
      setOpen(true)
    }
  }, [initialOpen])

  useEffect(() => {
    if (open) {
      fetcher()
    } else {
      resetList()
    }
  }, [fetcher, open, resetList])

  useEffect(() => {
    const fn = () => {
      if (isAuthenticated && APP_LOCALE !== 'en_US') {
        pollRef.current = startPolling(30000)
      }
    }

    fn()

    if (!isAuthenticated && pollRef.current) {
      pollRef.current()
    }

    return () => {
      pollRef.current?.()
    }
  }, [isAuthenticated, startPolling])

  return (
    <CoursewareModalProvider ctx="NOTIFICATION_MODAL_CONTEXT">
      <div>
        <div
          className="relative text-white cursor-pointer"
          onClick={e => {
            e.stopPropagation()
            handleClick()
          }}
        >
          {hasNew && (
            <span
              className="absolute -right-0.5 rounded-full bg-[#FB4D3D]"
              style={{
                width: 8,
                height: 8,
              }}
            />
          )}
          <EnvelopeIcon className="h-6" />
        </div>

        <ReactModal
          isOpen={open}
          overlayClassName="fixed w-full h-screen bg-white bg-opacity-50 top-0 left-0 right-0 z-100"
          className="fixed overflow-hidden h-screen container max-w-screen-sm md:max-w-screen-sm top-0 right-0 bottom-0 mr-0 ml-auto z-100 outline-none focus:outline-none"
        >
          <NotificationPanel
            visible={open}
            onClose={() => {
              setOpen(false)
            }}
          />
        </ReactModal>
      </div>
      <CoursewareModal ctx="NOTIFICATION_MODAL_CONTEXT" />
    </CoursewareModalProvider>
  )
}

type INotificationPanelProps = {
  onClose: () => void
  visible: boolean
}

function NotificationPanel({ onClose, visible }: INotificationPanelProps) {
  const {
    notificationState: {
      total,
      page,
      pageSize,
      setPage,
      notification,
      urlNotification,
      clearUrlNotification,
    },
  } = useStore()

  const urlNotificationComponent = useMemo(() => {
    if (urlNotification) {
      return (
        <div className={'hidden'}>
          <NotificationCard
            {...urlNotification}
            defaultOpen
            onOpened={() => {
              clearUrlNotification()
            }}
          />
        </div>
      )
    }
    return null
  }, [clearUrlNotification, urlNotification])

  useEffect(() => {
    const fn = e => {
      e.preventDefault()
    }
    let dom
    if (visible) {
      dom = document.getElementById('n-panel')
      if (dom) {
        dom.addEventListener('touchmove', fn)
        dom.addEventListener('scroll', fn)
      }
    }
    return () => {
      dom = document.getElementById('n-panel')
      if (dom) {
        dom.removeEventListener('touchmove', fn)
        dom.removeEventListener('scroll', fn)
      }
    }
  }, [visible])

  const { t } = useTranslation('notification')

  return (
    <div
      id="n-panel"
      className="bg-white overflow-hidden h-full container max-w-screen-sm md:max-w-screen-sm rounded-sm flex flex-col"
    >
      {urlNotificationComponent}
      <div className="pb-1 flex container">
        <div className="px-2 text-xl cursor-pointer" onClick={onClose}>
          <CloseOutlined />
        </div>
      </div>
      <div className="p-4 flex-grow flex flex-col">
        <h1 className="font-bold flex-shrink-0 text-codgray-500 text-3xl">
          {t('my_message')}
        </h1>
        <div className="overflow-hidden h-1 flex-grow py-2 my-3 border-b border-t border-gray-100">
          <div className="h-full w-full overflow-auto">
            {notification.length ? (
              notification.map(n => (
                <div key={n.id} className={'mb-3'}>
                  <NotificationCard {...n} />
                </div>
              ))
            ) : (
              <div className="h-full flex flex-col items-center justify-center text-codgray">
                <IconPark name={'dengdaiwenjian'} className="text-6xl" />
                <div className="text-xl px-2 text-center">
                  {t('no_notifications')}
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="flex flex-shrink-0 justify-center items-center container">
          {total && (
            <Pagination
              total={total}
              page={page}
              pageSize={pageSize}
              onChange={p => setPage(p)}
            />
          )}
        </div>
      </div>
    </div>
  )
}

function NotificationCard({
  defaultOpen,
  onOpened,
  ...item
}: NSNotification.Item & { defaultOpen?: boolean; onOpened?: () => void }) {
  const { biz_type, biz_data, ...props } = item
  const { t } = useTranslation('notification')
  const {
    notificationState: { readNotification },
  } = useStore()
  const { initModal } = useCoursewareModal('NOTIFICATION_MODAL_CONTEXT')

  const [status, setStatus] = useState(props.status)

  const onHomeworkMarked = useCallback(async () => {
    const payload = biz_data as NSNotification.HomeworkMarkedBizData
    let ggaProRecord: NSClass.TrialType
    if (biz_data.class_id) {
      const classRes = await getUserClassByIdV2(biz_data.class_id)
      if (classRes.data && classRes.data.data) {
        if (checkIsGGAPro(classRes.data.data)) {
          const res = await getVideoRecordById(
            biz_data.record_id,
            biz_data.trial_sequence
          )
          if (res && res.data && res.data.data) {
            ggaProRecord = res.data.data
            await initModal(
              ggaProRecord.record.node_id,
              ggaProRecord.record.class.id,
              'review',
              { gga_pro_record: ggaProRecord }
            )
          } else {
            toast.error('Homework is no longer available')
          }
        } else {
          await initModal(payload.node_id, payload.class_id, 'review')
        }
      } else {
        toast.error('Homework is no longer available')
      }
    }
  }, [biz_data, initModal])

  const handleClick = useCallback(async () => {
    switch (biz_type) {
      case 'comment_created':
        await onHomeworkMarked()
        break
      case 'comment_replied':
        break
      case 'homework_marked':
        await onHomeworkMarked()
        break
      case 'public_course_comment_created': {
        try {
          const data = await notificationHandler.public_course_comment_created(
            props.id
          )
          if (data) {
            await initModal(
              data.content.courseware_id,
              data.content.class_id,
              'page',
              {
                notification: data,
              }
            )
          }
        } catch (e) {
          if (e && (e as Error).message === 'not_in_class') {
            toast.error(t('user_not_in_class'))
          }
        }
        break
      }
      default:
        break
    }
    if (props.status === 'unread') {
      await readNotification(props.id)
      setStatus('read')
    }
  }, [
    biz_type,
    initModal,
    onHomeworkMarked,
    props.id,
    props.status,
    readNotification,
    t,
  ])

  const messageBody = useMemo(() => {
    switch (biz_type) {
      case 'comment_created':
        return t('body.comment_created')
      case 'comment_replied':
        return t('body.comment_replied')
      case 'homework_marked':
        return t('body.homework_marked')
      default:
        return t('body.general')
    }
  }, [biz_type, t])

  const messageDetail = useMemo(() => {
    switch (biz_type) {
      default:
        return props.message
    }
  }, [biz_type, props.message])

  useEffect(() => {
    if (defaultOpen) {
      handleClick().then(() => {
        onOpened && onOpened()
      })
    }
  }, [defaultOpen, handleClick, onOpened])

  return (
    <div
      className={classNames(
        'relative rounded-md container my-1 cursor-pointer py-1 px-4 bg-transparent hover:bg-gradient-to-tr hover:from-gold-200 hover:via-gold-50 hover:to-white transition-all duration-200 ease-in-out',
        status === 'unread' &&
          "before:content-[''] before:inline-block before:bg-red-600 before:absolute before:w-2 before:h-2 before:rounded-full before:top-2 before:left-1"
      )}
      onClick={handleClick}
    >
      <div className={'tracking-wide'}>
        <div
          className={classNames(
            'relative font-bold',
            status === 'read' && 'text-gray-400'
          )}
        >
          {messageBody}
        </div>
        {messageDetail && (
          <div className="text-gray-400 text-sm line-clamp-1">
            {messageDetail}
          </div>
        )}
        <div className={status === 'read' ? 'text-gray-400' : ''}>
          {format(new Date(item.created_at), 'yyyy/MM/dd HH:mm:ss')}
        </div>
      </div>
    </div>
  )
}
