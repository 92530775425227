import { useState, useCallback, useRef, useMemo } from "react";

export type VoiceRecorderHandle = {
  init: () => Promise<void>;
  startRecording: () => void;
  stopRecording: () => void;
  play: () => void;
  stopPlaying: () => void;
  getData: () => Blob | undefined;
};

export type IVoiceRecorderProps = {
  onRecorderSaved?: (blob: Blob) => void;
};

export default function useVoiceRecorder(props: IVoiceRecorderProps) {
  const { onRecorderSaved } = props;
  const recorderRef = useRef<MediaRecorder>();
  const [player, setPlayer] = useState<HTMLAudioElement>();
  // const [recorder, setRecorder] = useState<MediaRecorder>();

  const [blob, setBlob] = useState<Blob>();
  const [userMediaStream, setUserMediaStream] = useState<MediaStream>();

  const clearRecordingState = useCallback(() => {
    // https://stackoverflow.com/questions/44274410/mediarecorder-stop-doesnt-clear-the-recording-icon-in-the-tab
    if (userMediaStream) {
      userMediaStream.getTracks().forEach(track => track.stop()); // 清除tab的recording状态
    }
  }, [userMediaStream]);

  const debugStream = useCallback((stream: MediaStream) => {
    console.log("stream:", stream);
    console.log("getVideoTracks:", stream.getVideoTracks());
    for (const track of stream.getAudioTracks()) {
      console.log("Track Information:");
      for (const key in track) {
        if (typeof track[key] !== "function") {
          console.log(`\t${key}: ${track[key]}`);
        }
      }
      console.log("Track Settings:");
      const settings = track.getSettings();
      for (const key in settings) {
        if (typeof settings[key] !== "function") {
          console.log(`\t${key}: ${settings[key]}`);
        }
      }
    }
  }, []);

  const VoiceRecorder: VoiceRecorderHandle = useMemo<VoiceRecorderHandle>(
    () => ({
      init: async () => {
        console.log("voiceRecorder.init()");
        if (navigator.mediaDevices) {
          try {
            const stream = await navigator.mediaDevices.getUserMedia({
              audio: true,
              video: false,
            });
            setUserMediaStream(stream);
            debugStream(stream);
          } catch (e) {
            console.error(e);
            throw new Error("media not found");
          }
        } else {
          throw new Error("media not found");
        }
      },
      startRecording: () => {
        if (!userMediaStream) {
          console.log("userMediaStream is null. not starting audio recording");
          return;
        }
        const chunks: Blob[] = [];
        const mediaRecorder = new MediaRecorder(
          userMediaStream
          // options
        );

        mediaRecorder.ondataavailable = (e: BlobEvent) => {
          console.log("dataavailable", e.data.size);
          if (e.data.size > 0) {
            chunks.push(e.data);
          }
        };

        mediaRecorder.onstop = () => {
          console.log("VoiceRecorder -> mediaRecorder.onstop");
          const newBlob = new Blob(chunks, {
            type: "audio/ogg",
          });
          setBlob(newBlob);
          const url = URL.createObjectURL(newBlob);
          setPlayer(new Audio(url));
          clearRecordingState();
          if (onRecorderSaved) {
            onRecorderSaved(newBlob);
          }
        };

        mediaRecorder.onstart = function () {
          console.log("mediaRecorder on start");
        };

        mediaRecorder.onerror = function () {
          console.log("mediaRecorder onerror");
        };

        mediaRecorder.onresume = function () {
          console.log("mediaRecorder onresume");
        };

        mediaRecorder.onpause = function () {
          console.log("mediaRecorder onpause");
        };

        recorderRef.current = mediaRecorder;
        // setRecorder(mediaRecorder);

        mediaRecorder.start(1000);
      },
      stopRecording: () => {
        console.log("VoiceRecorder.stopRecording");

        recorderRef.current && recorderRef.current.stop();
      },
      play() {
        if (!player) return;
        player.currentTime = 0;
        player.play();
      },
      stopPlaying() {
        if (!player) return;
        player.pause();
      },
      getData() {
        return blob;
      },
    }),
    [
      blob,
      clearRecordingState,
      debugStream,
      onRecorderSaved,
      player,
      userMediaStream,
    ]
  );

  return {
    VoiceRecorder,
  };
}
