import {
  DefaultMantineColor,
  MantineTheme,
  MantineThemeOverride,
  Tuple,
} from '@mantine/core'

const KR_Config: MantineThemeOverride = {
  fontFamily: `"Pretendard", -apple-system, BlinkMacSystemFont,
      "Malgun Gothic", "맑은 고딕", helvetica, "Apple SD Gothic Neo", sans-serif`,
}

const CN_Config: MantineThemeOverride = {}

const overRideByCountry = {
  kr: KR_Config,
  zh_CN: CN_Config,
}

const mantineTheme = (lang: string): MantineThemeOverride => {
  return {
    colors: {
      brand: [
        '#F2E9CE',
        '#ECE0BA',
        '#E1CD92',
        '#D6BB6A',
        '#CBA942',
        '#AA8A00',
        '#8F7527',
        '#735E20',
        '#574718',
        '#3B3010',
      ],
      dark: [
        '#B5B5B5',
        '#ABABAB',
        '#969696',
        '#828282',
        '#6E6E6E',
        '#595959',
        '#3D3D3D',
        '#212121',
        '#050505',
        '#000000',
      ],
    },
    primaryColor: 'brand',
    primaryShade: {
      light: 5,
      dark: 5,
    },
    components: {
      Anchor: {
        defaultProps: { color: 'brand' },
      },
      Button: {
        defaultProps: {
          styles: (theme, props) => {
            return {
              root: {
                height: props.size === 'xs' ? '34px' : undefined,
                '&[data-disabled]': {
                  backgroundColor: '#A5A5A5',
                  color: '#FAFAFA',
                },
                ...(props.size === 'xs'
                  ? {
                      '.mantine-Button-label': {
                        fontSize: '14px',
                      },
                    }
                  : {}),
              },
            }
          },
        },
      },
      Input: {
        defaultProps: {
          styles: {
            input: {
              lineHeight: '36px',
            },
          },
        },
      },
      Checkbox: {
        defaultProps: {
          styles: (theme: MantineTheme) => {
            const color = theme.fn.primaryColor()
            return {
              input: {
                outline: '0 !important',
                ':focus': {
                  boxShadow: 'none',
                },
                ':checked': {
                  backgroundImage: 'none',
                },
                ':checked:hover, :checked:focus': {
                  borderColor: `${color} !important`,
                  backgroundColor: `${color} !important`,
                  boxShadow: `0px 0px 0px 1px ${color}`,
                },
              },
            }
          },
        },
      },
      Textarea: {
        defaultProps: { styles: () => ({ input: { lineHeight: 1.25 } }) },
      },
      Radio: {
        defaultProps: {
          styles: (theme: MantineTheme) => {
            const color = theme.fn.primaryColor()
            return {
              radio: {
                outline: '0 !important',
                ':focus': {
                  boxShadow: 'none',
                },
                ':checked': {
                  backgroundImage: 'none',
                },
                ':checked:hover, :checked:focus': {
                  borderColor: `${color} !important`,
                  backgroundColor: `${color} !important`,
                  boxShadow: `0px 0px 0px 1px ${color}`,
                },
              },
            }
          },
        },
      },
      PasswordInput: {
        defaultProps: {
          styles: () => {
            // fix tailwindcss conflict
            return {
              innerInput: {
                ':focus': {
                  border: 'none',
                },
              },
            }
          },
        },
      },
    },
    ...(overRideByCountry[lang] || {}),
  }
}

export default mantineTheme

type ExtendedCustomColors = 'brand' | DefaultMantineColor

declare module '@mantine/core' {
  export interface MantineThemeColorsOverride {
    colors: Record<ExtendedCustomColors, Tuple<string, 10>>
  }
}
