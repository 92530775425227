import React, { ReactNode, useCallback, useEffect, useMemo } from 'react'
import { useRequest, useThrottleFn } from 'ahooks'
import { useTranslation } from 'next-i18next'
import {
  ICoursewareModalContexts,
  useCoursewareModal,
} from '@/hooks/myPages/CoursewareModalProvider'
import { VideoUploadForm } from '@/components/Form/Courseware/VideoUploadForm'
import { submitClassWorkApi, submitWorkApiV2 } from '@/services/class'
import { PDFUploadForm } from '@/components/Form/Courseware/PDFUploadForm'
import useRawContent from '@/hooks/useRawContent'
import ReplayCodeUploadForm from '@/components/Form/Courseware/ReplayCodeUploadForm'
import ReplayFileUploadForm from '@/components/Form/Courseware/ReplayFileUploadForm'

type ITeamScrimUploadProps = {
  header?: ReactNode
  classId: number
  nodeId: number
  onSuccess: () => void
  ctx?: ICoursewareModalContexts
}

export const TeamScrimUpload = ({
  header,
  classId,
  onSuccess,
  nodeId,
  ctx = 'CLASS_MODAL_CONTEXT',
}: ITeamScrimUploadProps) => {
  const { courseware, requestClose, setBeforeClose } = useCoursewareModal(ctx)

  const { parsed, renderReplacedVideo } = useRawContent({
    content: courseware.content || '',
  })
  const { i18n } = useTranslation()

  // 通过i18n.language记录file_upload_platform值
  // 韩国使用aws而zh_CN使用aliyun
  const platform = useMemo(() => {
    switch (i18n.language) {
      case 'kr':
        return 'aws'
      default:
        return 'aliyun'
    }
  }, [i18n.language])

  const _onSubmit = useCallback(
    async data => {
      // Build POST Body Data
      const postData = {
        description: data.description,
        content: data.url,
        file_path: data.file_name,
        replay_code: data.replay_code,
        replay_file_path: data.replay_file_path,
        file_upload_platform: data.file_upload_platform || platform,
        file_size: data.file_size,
        player_identity: data.player_identity,
      }

      await submitClassWorkApi(classId, nodeId, postData)
      await submitWorkApiV2(classId, nodeId)
      onSuccess()
    },
    [classId, nodeId, onSuccess, platform]
  )

  const { runAsync: onSubmit, loading: submitting } = useRequest(_onSubmit, {
    manual: true,
  })

  const { run: onSubmitThrottle } = useThrottleFn(onSubmit, { wait: 1000 })

  const submissionType = useMemo(() => {
    return courseware.syllabus.submit_type
  }, [courseware])

  useEffect(() => {
    renderReplacedVideo()
  }, [renderReplacedVideo])

  return (
    <div
      className={
        "overflow-auto bg-repeat bg-[url('https://res.gengesports.cn/user-web/images/bg-faq.png')]"
      }
    >
      <div className={'max-w-screen-xl mx-auto bg-white bg-opacity-60 p-2'}>
        {header}
        <div
          className="w-full gga-page-view"
          dangerouslySetInnerHTML={{ __html: parsed }}
        />
        {submissionType === 'video' && (
          <VideoUploadForm
            submitting={submitting}
            requestClose={requestClose}
            onSubmit={onSubmitThrottle}
            onModalClose={setBeforeClose}
          />
        )}

        {submissionType === 'replay_code' && (
          <ReplayCodeUploadForm
            onSubmit={onSubmitThrottle}
            onModalClose={setBeforeClose}
          />
        )}
        {submissionType === 'replay_file' && (
          <ReplayFileUploadForm
            onSubmit={onSubmitThrottle}
            onModalClose={setBeforeClose}
          />
        )}

        {submissionType === 'pdf' && (
          <PDFUploadForm
            submitting={submitting}
            requestClose={requestClose}
            onSubmit={onSubmitThrottle}
            onModalClose={setBeforeClose}
          />
        )}
      </div>
    </div>
  )
}
