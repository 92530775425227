import React, { useCallback, useEffect, useState } from 'react'
import { Button } from 'stripe-ui'
import { Trans, useTranslation } from 'next-i18next'
import { Controller, useForm } from 'react-hook-form'
import get from 'lodash/get'
import { v4 as uuidv4 } from 'uuid'
import classNames from 'classnames'
import { useOSSPut } from '@/hooks/useOSSUploader'
import { FileUploader } from '@/components/uploader/FileUploader'
import styles from '@/styles/myPage.module.scss'
import type { ICourseVideoUploadForm, ReturnType } from './types'

export const PDFUploadForm = ({
  onSubmit,
  submitting = false,
  onModalClose,
  className,
}: ICourseVideoUploadForm) => {
  const { t, i18n } = useTranslation()

  const {
    uploadStatus,
    uploadProgress,
    reupload,
    handleUploadKr,
    handleUploadCn,
  } = useOSSPut()

  const { register, handleSubmit, setError, setValue, control } = useForm({
    mode: 'all',
  })

  const [submitDisabled, setSubmitDisabled] = useState(true)

  useEffect(() => {
    if (uploadProgress === 100) {
      setSubmitDisabled(false)
    } else {
      setSubmitDisabled(true)
    }
  }, [uploadProgress])

  const submitForm = useCallback(
    data => {
      if (submitting) {
        return
      } else {
        onSubmit(data)
        onModalClose && onModalClose(undefined)
      }
    },
    [onModalClose, onSubmit, submitting]
  )

  // 上传时间处理
  const fileChangedHandler = useCallback(
    async file => {
      // 有空用户点击没有选择文件
      if (file) {
        const filename = uuidv4() + '.pdf'

        let fileUrl: string | undefined
        if (i18n.language === 'kr') {
          fileUrl = await handleUploadKr(file, filename)
        } else if (i18n.language === 'zh_CN') {
          fileUrl = await handleUploadCn(file, filename)
        }

        if (fileUrl) {
          setValue('url', fileUrl, { shouldValidate: true })
          setValue('file_name', `courseware/${filename}`, {
            shouldValidate: true,
          })
        } else {
          setError('url', {
            type: 'manual',
            message: t('course_form.upload_failed'),
          })
        }
      }
    },
    [handleUploadCn, handleUploadKr, i18n.language, setError, setValue, t]
  )

  const handleCancelUpload = useCallback(() => {
    reupload()
  }, [reupload])

  useEffect(() => {
    if (uploadStatus.status !== 'init') {
      onModalClose &&
        onModalClose(() => {
          return () =>
            Promise.resolve(
              confirm(
                t(
                  'components.courseware_modal.upload_form_close_confirm.confirm_tip'
                )
              )
            )
        })
    }
  }, [onModalClose, t, uploadStatus.status])

  return (
    <form
      className={classNames(className)}
      onSubmit={handleSubmit<ReturnType>(submitForm)}
    >
      <Controller
        {...register('file')}
        control={control}
        render={({ formState: { errors }, field: { ref } }) => (
          <FileUploader
            onError={message => {
              setError('file', {
                type: 'manual',
                message,
              })
            }}
            type="pdf"
            onChange={fileChangedHandler}
            progress={uploadProgress}
            message={uploadStatus.message}
            status={uploadStatus.status}
            error={get(errors, 'file.message')}
            onCancel={handleCancelUpload}
            ref={ref}
          >
            <div className={styles.uploadTip}>
              *{' '}
              <Trans i18nKey="components.courseware_modal.solo_task_modal_content.pdf_upload_content.file_type_tip" />
            </div>
          </FileUploader>
        )}
      />
      <div className="text-center mt-4">
        <Button type="submit" variant="brand" disabled={submitDisabled}>
          <Trans i18nKey="course_form.submit" />
        </Button>
      </div>
    </form>
  )
}
