import { useRouter } from 'next/router'
import { useReducer } from 'react'
import { getPaymentStatus } from '@/services/payment'
import { useModalStore } from '@/store/use-modal-store'

interface IState {
  checkout: boolean
  qrcode: string | undefined
  bizId: string | undefined
}

interface IAction {
  type: 'back' | 'reset' | 'set_checkout'
  payload?: any
}

const initState: IState = {
  bizId: undefined,
  checkout: false,
  qrcode: undefined,
}

const checkoutReducer = (state: IState, action: IAction): IState => {
  switch (action.type) {
    case 'set_checkout':
      return {
        ...state,
        qrcode: action.payload.qrcode,
        bizId: action.payload.bizId,
        checkout: true,
      }

    case 'back':
      return { ...state, checkout: false, qrcode: undefined }
    case 'reset':
      return initState
    default:
      return { ...state }
  }
}

export const useQrcodeCheckout = () => {
  const router = useRouter()
  const [state, dispatch] = useReducer(checkoutReducer, initState)
  const closeModal = useModalStore(state => state.closeModal)

  const checkPaymentStatus = async () => {
    if (state.bizId) {
      try {
        const result = await getPaymentStatus(state.bizId)
        if (result.success && result.data?.data.status !== 'Success') {
          alert('您的订单还未完成支付，请稍后再尝试！')
          return
        } else {
          closeModal('AddCreditModal')
          router.push(`/payment/success/${state.bizId}`)
        }
      } catch (error) {
        alert('订单不存在')
      }
    }
  }

  return { state, dispatch, checkPaymentStatus }
}
