import React, { useMemo } from 'react'
import Link from 'next/link'
import classNames from 'classnames'
import { Trans, useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'
import { Button } from '@mantine/core'
import { useMenu } from '@/components/HeaderBar/data'
import ActiveLink from '@/components/HeaderBar/desktop/ActiveLink'
import { APP_LOCALE, HOMEPAGE_DYNAMIC } from '@/constants/define'
import ImageComponent from '@/components/ImageComponent'
import { useAuth } from '@/containers/auth'
import Profile from '@/components/HeaderBar/profile'
import MenuLink from './MenuLink'

type INavLinksProp = {
  isAuthenticated: boolean
  className?: string
}

function NavLinks({ className, isAuthenticated }: INavLinksProp) {
  const { menu } = useMenu()
  return (
    <div className={classNames(className, 'h-full flex flex-1')}>
      {menu
        .filter(r => (r.auth && isAuthenticated) || !r.auth)
        .map((it, key) => {
          if (it.children?.length) {
            return <MenuLink key={key} menu={it} />
          }
          return (
            <ActiveLink
              key={key}
              href={it.href!}
              text={it.text || ''}
              isBlank={it.isBlank}
              parent={it.parent}
            />
          )
        })}
    </div>
  )
}

export default function DesktopHeader({ isAuthenticated }) {
  const { t, i18n } = useTranslation()
  const router = useRouter()
  const { setOpenAuthModal } = useAuth()

  const hasLogin = useMemo(() => {
    return !(
      router.asPath.startsWith('/login') ||
      router.asPath.startsWith('/register')
    )
  }, [router.asPath])

  const { query } = router

  const countryCode = useMemo(() => {
    return (query as any).country
  }, [query])

  return (
    <header
      className={classNames(
        'fixed top-0 left-0 right-0 w-full flex flex-nowrap items-center bg-black justify-start z-10 h-navPc px-6'
      )}
    >
      {countryCode !== 'UAE' ? (
        <Link href={HOMEPAGE_DYNAMIC(i18n)}>
          <ImageComponent
            className="object-contain w-auto h-full p-2 cursor-pointer"
            src="/images/logo-gga.png"
          />
        </Link>
      ) : (
        <Link href="https://gengesports2024.my.canva.site/aradaworkshop">
          <ImageComponent
            className="object-contain w-auto h-full p-2 cursor-pointer"
            src="/images/logo-gga.png"
          />
        </Link>
      )}

      {countryCode !== 'UAE' ? (
        <NavLinks isAuthenticated={isAuthenticated} />
      ) : (
        <div className={classNames('flex-1')}></div>
      )}

      {isAuthenticated ? (
        <div className="flex items-center justify-end">
          <Profile className="z-10" />
        </div>
      ) : APP_LOCALE !== 'zh_CN' ? (
        <Button
          onClick={async () => {
            await router.push({
              pathname: '/login',
              query: {
                redirect_url: encodeURIComponent(router.asPath),
              },
            })
          }}
          color="brand"
        >
          <span className={'uppercase'}>
            <Trans t={t} i18nKey="login" />
          </span>
        </Button>
      ) : (
        hasLogin && (
          <Button onClick={() => setOpenAuthModal(true)} color="brand">
            登录/注册
          </Button>
        )
      )}
    </header>
  )
}
