import React, {
  useState,
  forwardRef,
  Fragment,
  useMemo,
  useEffect,
  useCallback,
  CSSProperties,
} from "react";

import { CompactPicker } from "react-color";
import classNames from "classnames";
import { Dropdown } from "stripe-ui";
import {
  TrashIcon,
  BrushIcon,
  CloseIcon,
  DrawIcon,
  RecordIcon,
  RedoIcon,
  UndoIcon,
} from "../components/icons";

export type RecordingControlsHandle = {
  /**
   *
   * @param btnNames
   * @param disabled
   * @param isExclude 是否是排除列表，if true, 从按钮列表中排除btnNames里的选项
   */
  disabledToggle: (btnNames: ButtonList[], isExclude?: boolean) => void;
};

export type RecordingControlsProps = {
  init: (handle: RecordingControlsHandle) => void;
  onStartRecording: () => void;
  onStartDrawing: () => void;
  onStopRecording: () => void;
  onStartPlaying: () => void;
  onUndo: () => void;
  onRedo: () => void;
  onClear: () => void;
  onDiscard: () => void;
  onExitPlayback: () => void;
  onColorChanged: (color: string) => void;
  canRecord: boolean;
  strokeStyle?: string;
  btnProps?: Partial<Record<ButtonList, ConfigurableBtnState>>;
  strokeWidth?: number;
  onStrokeWidthChange?: (width: number) => void;
};

const CONTROL_BUTTONS = [
  "startRecording",
  "startDrawing",
  "stop",
  "preview",
  "colorPicker",
  "strokeWidth",
  "undo",
  "redo",
  "clear",
  "discard",
  "exitPlayback",
] as const;

export type ButtonList = typeof CONTROL_BUTTONS[number];

type BtnState = {
  disabled?: boolean;
  hidden?: boolean;
  className?: string;
  style?: CSSProperties;
  text?: string;
};

type ButtonsState = Record<ButtonList, BtnState>;

export type ConfigurableBtnState = Pick<
  BtnState,
  "className" | "style" | "hidden"
>;

const INIT_STATE = CONTROL_BUTTONS.reduce(
  (state: ButtonsState, name: ButtonList) => {
    if (name === "startRecording" || name === "startDrawing") {
      state[name] = {
        disabled: false,
        className: "gvp-controls-control-button",
      };
    } else {
      state[name] = {
        disabled: true,
        className: "gvp-controls-control-button",
      };
    }
    return state;
  },
  {} as ButtonsState
);

type RenderChildren<T> = (props: T) => React.ReactNode;

const Field = ({
  children,
  ...rest
}: { children: RenderChildren<BtnState> } & BtnState) => {
  if (rest.hidden) {
    return null;
  } else {
    return <Fragment>{children(rest)}</Fragment>;
  }
};

const RecordingControls = forwardRef<
  RecordingControlsHandle,
  RecordingControlsProps
>((prop, ref) => {
  const {
    init,
    onStartRecording,
    onStartDrawing,
    onStopRecording,
    onStartPlaying,
    onUndo,
    onRedo,
    onClear,
    onDiscard,
    onExitPlayback,
    canRecord = true,
    onColorChanged,
    strokeStyle,
    btnProps,
    strokeWidth = 1,
    onStrokeWidthChange,
  } = prop;

  const [buttonsState, setButtonsState] = useState<ButtonsState>(INIT_STATE);
  const color = useMemo(() => {
    const rgbArray = strokeStyle?.match(/\d+/g);
    return rgbArray
      ? { r: rgbArray[0], g: rgbArray[1], b: rgbArray[2], a: "1" }
      : { r: "0", g: "0", b: "0", a: "1" };
  }, [strokeStyle]);
  const [showColorPalette, toggleColorPalette] = useState(false);
  // const [color, setColor] = useState<any>(
  //   rgbArray
  //     ? { r: rgbArray[0], g: rgbArray[1], b: rgbArray[2], a: "1" }
  //     : {
  //         r: "0",
  //         g: "0",
  //         b: "0",
  //         a: "1",
  //       }
  // );

  const handleBtnProps = useCallback(() => {
    if (btnProps) {
      setButtonsState(prev => {
        const data: ButtonsState = (
          Object.keys(btnProps) as ButtonList[]
        ).reduce((result, name) => {
          result[name] = {
            ...prev[name],
            ...btnProps[name],
            className: classNames(
              "gvp-controls-control-button",
              btnProps[name]!.className
            ),
          };
          return { ...prev, ...result };
        }, {} as ButtonsState);
        return data;
      });
    }
  }, [btnProps]);

  useEffect(() => {
    handleBtnProps();
  }, [handleBtnProps]);

  const startHandler = () => {
    // setRecordState(RecordType.START);
    if (onStartRecording) onStartRecording();
  };
  const startDrawingHandler = () => {
    // setRecordState(RecordType.START);
    if (onStartDrawing) onStartDrawing();
  };
  const stopHandler = () => {
    // setRecordState(RecordType.STOP);
    if (onStopRecording) onStopRecording();
  };
  const playHandler = () => {
    if (onStartPlaying) onStartPlaying();
  };
  const undoHandler = () => {
    if (onUndo) onUndo();
  };
  const redoHandler = () => {
    if (onRedo) onRedo();
  };
  const clearHandler = () => {
    if (onClear) onClear();
  };
  // const saveHandler = () => {
  //   if (onSave) onSave();
  // };
  const discardHandler = () => {
    if (onDiscard) onDiscard();
  };
  const exitPlaybackHandler = () => {
    if (onExitPlayback) onExitPlayback();
  };

  const handleColorPickerClick = useCallback(() => {
    if (buttonsState.colorPicker.disabled) {
      return;
    }
    toggleColorPalette(prev => !prev);
  }, [buttonsState]);

  const handleClose = useCallback(() => {
    toggleColorPalette(false);
  }, []);

  const handleChange = useCallback(
    c => {
      // debugger;
      // setColor(c.rgb);
      toggleColorPalette(false);
      if (onColorChanged) {
        const rgbString = `rgb(${c.rgb.r}, ${c.rgb.g}, ${c.rgb.b})`;
        onColorChanged(rgbString);
      }
    },
    [onColorChanged]
  );
  // const handleChangeComplete = c => {
  //   console.log("handleChangeComplete", c);
  // };

  const ControlMethods = useMemo(
    () => ({
      disabledToggle: (bts: ButtonList[], isExclude = false) => {
        const otherBtns = CONTROL_BUTTONS.filter(bt => bts.indexOf(bt) < 0);
        setButtonsState(prev => {
          const declared = bts.reduce((st, bt) => {
            return {
              ...st,
              [bt]: {
                ...prev[bt],
                disabled: !isExclude,
              },
            };
          }, {} as ButtonsState);

          const implicit = otherBtns.reduce((st, bt) => {
            return {
              ...st,
              [bt]: {
                ...prev[bt],
                disabled: isExclude,
              },
            };
          }, {} as ButtonsState);
          return {
            ...prev,
            ...declared,
            ...implicit,
          };
        });
      },
    }),
    []
  );

  useEffect(() => {
    init && init(ControlMethods);
  }, [ControlMethods, init]);

  // const switchDrawable = () => {
  //   if (onSwitchDrawable) onSwitchDrawable();
  // };

  return (
    <div className="gvp-player-controls-wrapper">
      {canRecord && (
        <Field {...buttonsState.startRecording}>
          {props => (
            <button
              onClick={startHandler}
              {...props}
              className={classNames(props.className, "icon-button")}
            >
              <RecordIcon />
            </button>
          )}
        </Field>
      )}
      <Field {...buttonsState.startDrawing}>
        {props => (
          <button onClick={startDrawingHandler} {...props}>
            <span
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <DrawIcon />
              {props.text && (
                <span
                  style={{
                    marginLeft: "0.25rem",
                    color: "#333",
                  }}
                >
                  {props.text}
                </span>
              )}
            </span>
          </button>
        )}
      </Field>

      {canRecord && (
        <Field {...buttonsState.stop}>
          {props => (
            <button
              onClick={stopHandler}
              {...props}
              className={classNames(props.className, "text-button")}
            >
              Stop Recording
            </button>
          )}
        </Field>
      )}
      {canRecord && (
        <Field {...buttonsState.preview}>
          {props => (
            <button
              onClick={playHandler}
              {...props}
              className={classNames(props.className, "text-button")}
            >
              Start Preview
            </button>
          )}
        </Field>
      )}

      <Field {...buttonsState.colorPicker}>
        {({ disabled }) => (
          <div className={"gvp-controls-swatch-wrapper"}>
            <div
              className={classNames(
                "gvp-controls-swatch",
                disabled ? "gvp-controls-swatch-disabled" : null
              )}
              onClick={handleColorPickerClick}
            >
              <div
                className={"gvp-controls-color"}
                style={{
                  background: `rgba(${color.r}, ${color.g}, ${color.b}, ${color.a})`,
                }}
              />
            </div>
            {showColorPalette && (
              <div className={"gvp-controls-popover"}>
                <div className={"gvp-controls-cover"} onClick={handleClose} />
                <CompactPicker color={color} onChange={handleChange} />
              </div>
            )}
          </div>
        )}
      </Field>
      <Field {...buttonsState.strokeWidth}>
        {({ disabled, className }) => {
          const items = [1, 2, 4, 8, 12].map(w => {
            return {
              label: (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    padding: "0 4px",
                  }}
                >
                  <span>{w} px</span>
                  <div
                    style={{
                      width: 76,
                      height: w,
                      marginLeft: 8,
                      background: strokeStyle || "#000",
                      border: 1,
                      borderTopLeftRadius: "25%",
                      borderBottomRightRadius: "25%",
                    }}
                  />
                </div>
              ),
              onSelect: () => {
                onStrokeWidthChange && onStrokeWidthChange(w);
              },
            };
          });
          return (
            <Dropdown items={items} placement="topLeft">
              <button
                disabled={disabled}
                className={classNames(className, "icon-button")}
              >
                <span
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <BrushIcon />{" "}
                  <span
                    style={{
                      marginLeft: "0.4rem",
                      fontSize: "1.05rem",
                    }}
                  >
                    :{strokeWidth}px
                  </span>
                </span>
              </button>
            </Dropdown>
          );
        }}
      </Field>

      <Field {...buttonsState.undo}>
        {props => (
          <button
            onClick={undoHandler}
            {...props}
            className={classNames(props.className, "icon-button")}
          >
            <UndoIcon />
          </button>
        )}
      </Field>

      <Field {...buttonsState.redo}>
        {props => (
          <button
            onClick={redoHandler}
            {...props}
            className={classNames(props.className, "icon-button")}
          >
            <RedoIcon />
          </button>
        )}
      </Field>

      <Field {...buttonsState.clear}>
        {props => (
          <button
            onClick={clearHandler}
            {...props}
            className={classNames(props.className, "icon-button")}
          >
            <TrashIcon />
          </button>
        )}
      </Field>

      <Field {...buttonsState.discard}>
        {props => (
          <button
            onClick={discardHandler}
            {...props}
            className={classNames(props.className, "icon-button")}
          >
            <CloseIcon />
          </button>
        )}
      </Field>
      {/* <button onClick={saveHandler} disabled={saveDisabled}>
        Confirm Recording
      </button> */}
      <Field {...buttonsState.exitPlayback}>
        {props => (
          <button
            onClick={exitPlaybackHandler}
            {...props}
            className={classNames(props.className, "text-button")}
          >
            Exit Playback
          </button>
        )}
      </Field>
    </div>
  );
});

RecordingControls.displayName = "RecordingControls";

export default RecordingControls;
