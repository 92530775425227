import { Icon } from 'stripe-ui'
import classNames from 'classnames'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useRequest } from 'ahooks'
import { LoadingOutlined } from '@ant-design/icons'
import { get } from 'lodash'
import { format } from 'date-fns'
import { Transition } from '@headlessui/react'
import { NSCoupon, NSProduct } from 'gga-types'
import { request } from '@/utils/request'
import { IResDataArray } from '@/@types/global'
import { useAuth } from '@/containers/auth'
import IconPark from '@/components/IconPark'

function CouponList({
  list,
  onSelect,
}: {
  list?: NSCoupon.Item[]
  onSelect: (item?: NSCoupon.Item) => void
}) {
  const { t, i18n } = useTranslation('buynow')
  const [current, setCurrent] = useState<NSCoupon.Item>()
  const unit = useMemo(
    () => (i18n.language === 'kr' ? '₩' : '￥'),
    [i18n.language]
  )
  const couponItem = useCallback(
    (item: NSCoupon.Item) => {
      const { discount, recurring_count, expired_at, code, coupon_type } = item
      return (
        <div
          key={item.code}
          onClick={() => {
            if (current && current.code === code) {
              setCurrent(undefined)
              onSelect()
            } else {
              setCurrent(item)
              onSelect(item)
            }
          }}
          className={classNames(
            'w-full transition-all outline ease-in-out cursor-pointer rounded-md py-1 px-3 border border-gray-400 bg-gray-300 hover:border-gold-400 hover:bg-gold-50 text-white hover:text-gold-500',
            current && current.code === code
              ? 'outline-gold-400 border-gold-400 bg-gold-50 text-gold-600'
              : 'outline-transparent'
          )}
        >
          <div className={'text-xs text-left'}>{code}</div>

          <div className={'flex space-x-3 items-end'}>
            {coupon_type === 'Discount' ? (
              <div className={'flex-1 text-left'}>
                <span className={'text-3xl font-fjalla'}>{discount}</span>
                <span className={'text-sm'}>% Off</span>
              </div>
            ) : (
              <div className={'flex-1 text-left'}>
                <span className={'text-base'}>{unit}</span>
                <span className={'text-3xl font-fjalla'}>{discount}</span>
              </div>
            )}
            <div className={'w-1/2 text-right'}>
              <div>{t('coupon-valid-until')}:</div>
              <div>{format(new Date(expired_at), 'yyyy-MM-dd')}</div>
            </div>
          </div>
        </div>
      )
    },
    [current, onSelect, t, unit]
  )

  return (
    <div className={'overflow-auto max-h-48 px-1 py-1'}>
      <div className={'space-y-2'}>
        {list &&
          list.map(item => {
            return couponItem(item)
          })}
      </div>
    </div>
  )
}

function EmptyCouponList() {
  const { t } = useTranslation('buynow')
  return (
    <div className={'text-center'}>
      <IconPark className={'text-[#333] text-5xl m-3'} name={'noresult'} />
      <div className={'text-[#333]'}>{t('no_coupons_found')}</div>
    </div>
  )
}

export default function CashierCouponModule({
  product,
  className,
  onSelect,
}: {
  className?: string
  product: NSProduct.Product
  onSelect: (item?: NSCoupon.Item) => void
}) {
  const [showCoupons, setShowCoupons] = useState(true)
  const { isAuthenticated } = useAuth()
  const { data, loading, run } = useRequest(
    () =>
      request<IResDataArray<NSCoupon.Item>>({
        url: `/v2/product/getcoupons/${product.id}`,
      }),
    {
      manual: true,
    }
  )
  const { t } = useTranslation('buynow')

  useEffect(() => {
    if (isAuthenticated && showCoupons) {
      run()
    }
  }, [isAuthenticated, run, showCoupons])

  return (
    <section className={classNames(className)}>
      <div className="py-2">
        <a
          href="#"
          onClick={e => {
            e.preventDefault()
            const res = !showCoupons
            if (!res) {
              onSelect(undefined)
            }
            setShowCoupons(res)
          }}
          className="no-underline flex"
        >
          <span>{t('i_have_coupon')}</span>
          {loading ? (
            <LoadingOutlined className={'ml-4 text-xs'} />
          ) : (
            <Icon
              name="chevronRight"
              className={classNames('inline-block ml-0.5', {
                'rotate-90 transform': showCoupons,
              })}
            />
          )}
        </a>
      </div>
      <Transition
        show={showCoupons && !loading}
        enter="transition-opacity ease-linear duration-300"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity ease-linear duration-300"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        {get(data, 'data.data.length') ? (
          <CouponList
            onSelect={coupon => {
              onSelect(coupon)
            }}
            list={get(data, 'data.data')}
          />
        ) : (
          <EmptyCouponList />
        )}
      </Transition>
    </section>
  )
}
