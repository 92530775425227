import {
  ColorScheme,
  MantineProvider,
  Pagination as MantinePagination,
  PaginationProps,
  useMantineTheme,
} from '@mantine/core'
import { useMediaQuery } from '@mantine/hooks'
import myCache from '@/components/App/emotion-cache'

type TPaginationProps = PaginationProps & {
  pageSize?: number
  colorScheme?: ColorScheme
}

export default function Pagination({
  pageSize = 20,
  total,
  colorScheme,
  ...rest
}: TPaginationProps) {
  const theme = useMantineTheme()
  const smallScreen = useMediaQuery(
    theme.fn.smallerThan('sm').replace('@media ', '')
  )

  return (
    <MantineProvider
      inherit
      emotionCache={myCache}
      theme={{
        colorScheme: colorScheme || 'light',
      }}
    >
      <MantinePagination
        size={smallScreen ? 'sm' : 'md'}
        {...rest}
        total={Math.ceil(total / pageSize)}
      ></MantinePagination>
    </MantineProvider>
  )
}
