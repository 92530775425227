import React, { useCallback, useEffect, useMemo, useReducer } from 'react'
import QRCode from 'react-qr-code'
import { Button, Field, Icon, Checkbox } from 'stripe-ui'
import classNames from 'classnames'
import { isMobile } from 'react-device-detect'
import { useRouter } from 'next/router'
import get from 'lodash/get'
import { toast } from 'react-toastify'
import { NSProduct } from 'gga-types'
import Modal from '@/components/modal'
import { request } from '@/utils/request'
import { getPaymentStatus } from '@/services/payment'
import { Pending } from '@/components/Pending'
import NavLink from '@/components/NavLink'
import { useAuth } from '@/containers/auth'
import { formatCurrency } from '@/services/Utils'
import { useCashier } from '@/hooks/useCashier'
import CashierCouponModule from '@/pages/cashier/layouts/CashierCouponModule'
import ImageComponent from '@/components/ImageComponent'
import { getProtocalPageUrl } from '@/utils/protocal-page'
import { appendUtm } from '@/utils/referrerHelper'
import { TEnrollLiveSessionParams } from '@/@types/global'
import { InstallmentGroup } from '../Installment'

interface CashierModalProps {
  product: NSProduct.Product | null | undefined
  additionType?: 'Unknown' | 'EventEnrollment'
  additionValue?: TEnrollLiveSessionParams | any
  isOpen: boolean
  onRequestClose: () => void
}

const paymentVendors = [
  {
    name: 'alipay',
    logo: '/images/payment/alipay.png',
    label: '支付宝',
  },
  {
    name: 'wechatpay',
    logo: '/images/payment/wechatpay.png',
    label: '微信',
  },
]

interface IState {
  vendor: typeof paymentVendors[0] | null
  checkout: boolean
  qrcode: string | null
  phone_code: string
  phone_number: string
  phoneError: boolean
  bizId: string
  aggreedTc: boolean
  couponCode: string
  couponCodeSuccess: boolean
  couponCodeError: string | null | undefined
  showCoupon: boolean
  installmentNumber: string | null
}

interface IAction {
  type:
    | 'set_vendor'
    | 'checkout'
    | 'back'
    | 'reset'
    | 'set_qrcode'
    | 'set_phone'
    | 'set_phone_code'
    | 'set_phone_error'
    | 'set_biz_id'
    | 'aggree_tc'
    | 'set_coupon_code'
    | 'set_coupon_success'
    | 'set_coupon_error'
    | 'toggle_coupon'
    | 'set_installment_number'
  payload?: any
}

const initState: IState = {
  vendor: null,
  checkout: false,
  qrcode: null,
  phone_code: '',
  phone_number: '',
  phoneError: false,
  bizId: '',
  aggreedTc: false,
  couponCode: '',
  couponCodeSuccess: true,
  couponCodeError: null,
  showCoupon: false,
  installmentNumber: null,
}

function checkoutReducer(state: IState, action: IAction): IState {
  switch (action.type) {
    case 'set_vendor':
      return {
        ...state,
        vendor: action.payload,
      }
    case 'checkout':
      return { ...state, checkout: true }
    case 'back':
      return { ...state, checkout: false, qrcode: null }
    case 'set_qrcode':
      return { ...state, qrcode: action.payload }
    case 'reset':
      return initState
    case 'set_phone':
      return { ...state, phone_number: action.payload }
    case 'set_phone_code':
      return { ...state, phone_code: action.payload }
    case 'set_phone_error':
      return { ...state, phoneError: action.payload }
    case 'set_biz_id':
      return { ...state, bizId: action.payload }
    case 'aggree_tc':
      return { ...state, aggreedTc: action.payload }
    case 'set_coupon_code':
      return { ...state, couponCode: action.payload }
    case 'set_coupon_success':
      return { ...state, couponCodeSuccess: action.payload }
    case 'set_coupon_error':
      return { ...state, couponCodeError: action.payload }
    case 'toggle_coupon':
      return { ...state, showCoupon: action.payload }
    case 'set_installment_number':
      return { ...state, installmentNumber: action.payload }
    default:
      return state
  }
}

export const CashierModal = (props: CashierModalProps) => {
  const { product, isOpen, additionType, additionValue } = props
  const [state, dispatch] = useReducer(checkoutReducer, initState)
  const router = useRouter()
  const { user } = useAuth()

  const { couponDiscountAmount, amount, checkCoupon } = useCashier(product!)

  const payable = useMemo(() => amount > 0, [amount])

  const getPaymentQrcode = useCallback(() => {
    dispatch({ type: 'checkout' })
    request({
      url: `/v2/payment/getqrcode/${state.vendor?.name}/${product?.id}`,
      data: {
        phone_code: state.phone_code,
        phone_number: state.phone_number,
        coupon_code: state.couponCodeSuccess ? state.couponCode : undefined,
        cashier_url: appendUtm(window.location.href),
        installment_number: state.installmentNumber,
        addition_type: additionType,
        addition_value: additionValue,
      },
      method: 'post',
    }).then(res => {
      if (res.success) {
        dispatch({ type: 'set_qrcode', payload: res.data.data.url })
        dispatch({ type: 'set_biz_id', payload: res.data.data.biz_id })
      } else {
        toast.error(res.message)
      }
    })
  }, [
    additionType,
    additionValue,
    product?.id,
    state.couponCode,
    state.couponCodeSuccess,
    state.installmentNumber,
    state.phone_code,
    state.phone_number,
    state.vendor?.name,
  ])

  const getH5Pay = useCallback(() => {
    request({
      url: `/v2/payment/h5pay/${state.vendor?.name}/${product?.id}`,
      data: {
        phone_code: '86',
        phone_number: state.phone_number,
        coupon_code: state.couponCodeSuccess ? state.couponCode : undefined,
        installment_number: state.installmentNumber,
        cashier_url: window.location.href,
        addition_type: additionType,
        addition_value: additionValue,
      },
      method: 'post',
    }).then(res => {
      if (res.success) {
        if (state.vendor?.name === 'wechatpay') {
          location.href = res.data.data
        } else if (state.vendor?.name === 'alipay') {
          document.write(res.data.data)
        }
      } else {
        toast.error(res.message)
      }
    })
  }, [
    state.vendor?.name,
    state.phone_number,
    state.couponCodeSuccess,
    state.couponCode,
    state.installmentNumber,
    product?.id,
    additionType,
    additionValue,
  ])

  const readyToPay = useCallback(() => {
    if (!isMobile) {
      getPaymentQrcode()
    } else {
      getH5Pay()
    }
  }, [getH5Pay, getPaymentQrcode])

  const back = useCallback(() => {
    dispatch({ type: 'back' })
  }, [])

  useEffect(() => {
    if (isOpen) {
      dispatch({ type: 'reset' })
    }
  }, [isOpen])

  const submitDisabled = useMemo(() => {
    if (!state.aggreedTc) {
      return true
    }

    return !state.vendor || !state.phone_number
  }, [state.aggreedTc, state.phone_number, state.vendor])

  const checkPaymentStatus = useCallback(() => {
    if (state.bizId) {
      getPaymentStatus(state.bizId)
        .then(
          res => {
            if (res.data.data.status !== 'Success') {
              alert('您的订单还未完成支付，请稍后再尝试！')
              return
            } else {
              router.push(`/payment-status/?id=${state.bizId}`)
            }
          },
          err => {
            alert('订单不存在')
          }
        )
        .catch(exp => {
          alert('订单不存在')
        })
    }
  }, [router, state.bizId])

  useEffect(() => {
    if (user && isOpen) {
      dispatch({
        type: 'set_phone',
        payload: user.phone_number,
      })
      dispatch({
        type: 'set_phone_code',
        payload: user.phone_code,
      })
    }
  }, [isOpen, user])

  useEffect(() => {
    checkCoupon({
      coupon_code: state.couponCode,
    })
  }, [checkCoupon, state.couponCode])

  const installmentNumber = useMemo(() => {
    if (product) {
      // HOK 价格大于等于1500才能分期
      if (product.game_category?.code === 'hok') {
        if (amount < 1500) {
          return []
        } else {
          return [3, 6]
        }
      }
      if (product?.time_span_unit === 'Month' && product?.time_span) {
        if (product?.time_span >= 6) {
          return [3, 6]
        } else if (product?.time_span >= 3) {
          return [3]
        }
      } else if (product?.time_span_unit === 'Day' && product?.time_span) {
        if (product?.time_span >= 180) {
          return [3, 6]
        } else if (product?.time_span >= 90) {
          return [3]
        }
      }
    }

    return []
  }, [product, amount])

  const installmentOptions = useMemo(() => {
    if (state.vendor?.name === 'alipay' && installmentNumber.length) {
      return installmentNumber.map(number => {
        return {
          value: `${number}`,
          label: `${Math.floor((amount * 100) / number) / 100} x ${number}期`,
        }
      })
    }
    return []
  }, [state.vendor?.name, installmentNumber, amount])

  const installmentGroupChangeHandler = useCallback(val => {
    if (val) {
      dispatch({
        type: 'set_installment_number',
        payload: val,
      })
    } else {
      dispatch({
        type: 'set_installment_number',
        payload: null,
      })
    }
  }, [])

  return (
    <Modal size="small" {...props}>
      <div className="pb-4 max-w-lg">
        <Pending loading={false}>
          <>
            {!state.checkout ? (
              <>
                {product && (
                  <div className="mb-4">
                    <div className={'text-xs text-gray-400 mb-2'}>
                      您正在购买
                    </div>
                    <div className="text-xl font-bold">{product.name}</div>
                    <div className={'mt-1'}>{product.sub_name}</div>
                  </div>
                )}
                <dl className="flex flex-col px-3 my-3">
                  <div className="flex justify-between border-b border-gray-200 py-2">
                    <dt>
                      <span className="text-gray-400">价格：</span>
                    </dt>
                    <dd>{formatCurrency(product?.amount)}元</dd>
                  </div>
                  {product?.discount_amount ? (
                    <div className="flex justify-between border-b border-gray-200 py-2">
                      <dt>
                        <span className="text-gray-400">优惠:</span>
                      </dt>

                      <dd>
                        {formatCurrency(
                          product.discount_amount - product.amount
                        )}
                        元
                      </dd>
                    </div>
                  ) : null}

                  {couponDiscountAmount ? (
                    <div className="flex justify-between border-b border-gray-200 py-2">
                      <dt>
                        <span className="text-gray-400">折扣码:</span>
                      </dt>
                      <dd>-{formatCurrency(couponDiscountAmount)}元</dd>
                    </div>
                  ) : null}

                  <div className="flex justify-between py-2 ">
                    <dt>
                      <span className="text-base text-black">合计:</span>
                    </dt>
                    <dd>
                      <span className="text-base text-black">
                        {formatCurrency(amount)}元
                      </span>
                    </dd>
                  </div>
                </dl>
                {product && (
                  <CashierCouponModule
                    onSelect={coupon => {
                      if (coupon) {
                        dispatch({
                          type: 'set_coupon_code',
                          payload: coupon.code,
                        })
                      } else {
                        dispatch({
                          type: 'set_coupon_code',
                          payload: undefined,
                        })
                      }
                    }}
                    className={'mx-2 mb-3'}
                    product={product}
                  />
                )}

                {payable && (
                  <>
                    <div className="text-[#333] mb-2">请选择支付方式</div>
                    <div className="flex items-stretch justify-center max-w-lg mx-auto p-2">
                      {paymentVendors.map(vendor => {
                        return (
                          <div
                            key={vendor.name}
                            className={classNames(
                              'flex-1 mx-4 px-2 py-6 border rounded-lg cursor-pointer hover:border-gold-200',
                              state.vendor?.name !== vendor.name &&
                                'opacity-40',
                              state.vendor?.name === vendor.name &&
                                'border-gold-400 shadow-md shadow-gold-500'
                            )}
                            onClick={() => {
                              dispatch({ type: 'set_vendor', payload: vendor })

                              if (vendor.name !== 'alipay') {
                                dispatch({
                                  type: 'set_installment_number',
                                  payload: null,
                                })
                              }
                            }}
                          >
                            <ImageComponent
                              alt={vendor.name}
                              src={vendor.logo}
                            />
                          </div>
                        )
                      })}
                    </div>

                    <form className="py-4 text-left">
                      {!!installmentOptions?.length && (
                        <Field label={'使用花呗分期'}>
                          <InstallmentGroup
                            options={installmentOptions}
                            onChange={installmentGroupChangeHandler}
                          />
                        </Field>
                      )}

                      <Field>
                        <Checkbox
                          checkboxProps={{
                            id: 'agreement',
                            checked: state.aggreedTc,
                            onChange: e => {
                              dispatch({
                                type: 'aggree_tc',
                                payload: e.target.checked,
                              })
                            },
                          }}
                          label={
                            <div className="text-gray-800">
                              我已阅读并同意
                              <NavLink
                                href={getProtocalPageUrl(
                                  get(product, 'class.id', ''),
                                  get(product, 'game_category.code', '')
                                )}
                                target="_blank"
                                className="text-gold-500"
                              >
                                相关协议
                              </NavLink>
                            </div>
                          }
                        />
                      </Field>
                    </form>
                  </>
                )}
              </>
            ) : (
              <>
                <h3 className="text-lg text-black mb-4 text-center">
                  请使用{state.vendor?.label}扫码支付
                </h3>
                <div className="flex flex-row justify-center">
                  {state.qrcode ? (
                    <div className={'inline-block mx-auto'}>
                      <QRCode value={state.qrcode} size={150} />
                    </div>
                  ) : (
                    <span className="my-6">
                      <Icon name="spin" size="large" />
                    </span>
                  )}
                </div>
              </>
            )}

            <div>
              <div className="text-center">
                {!state.checkout ? (
                  <Button
                    variant="brand"
                    size="large"
                    disabled={submitDisabled}
                    onClick={readyToPay}
                  >
                    立即支付
                  </Button>
                ) : (
                  <div className={'mt-10'}>
                    <Button variant="base" size="large" onClick={back}>
                      重新选择
                    </Button>
                    <Button
                      variant="brand"
                      size="large"
                      onClick={checkPaymentStatus}
                      className="ml-2"
                    >
                      我已完成支付
                    </Button>
                  </div>
                )}
              </div>
            </div>
          </>
        </Pending>
      </div>
    </Modal>
  )
}
