import { useCallback, useEffect, useState } from 'react'
import { get } from 'lodash'
import { useTranslation } from 'next-i18next'
import { NSClass, NSCourse } from 'gga-types'
import {
  getClassSyllabuses,
  getCourseNodeStatusTags,
  getNodeIdsOrderedByHotness,
} from '@/services/class'

export type IVoidLibraryState = ReturnType<typeof useVodLibraryState>

const getNodesByDepth = (
  tree: NSClass.SyllabusItemStruct[],
  depth: number,
  result: NSClass.SyllabusItemStruct[]
) => {
  for (let i = 0; i < tree.length; i++) {
    const cur = tree[i]
    if (cur.depth < depth) {
      getNodesByDepth(cur.children, depth, result)
    } else if (cur.depth === depth) {
      result.push(cur)
    }
  }
}

export type INodeOrderingType = 'created_at' | 'hottest'

async function orderByHottest(
  nodes: NSClass.SyllabusItemStruct[],
  parentNodeId: number
) {
  // get hottest order id list
  const resp = await getNodeIdsOrderedByHotness(parentNodeId)
  if (resp && resp.data && resp.data.data) {
    const orderIdsByHottest = resp.data.data
    const childrenIdMap = new Map()
    nodes.forEach(c => {
      childrenIdMap.set(c.id, c)
    })
    const orderedChildren = orderIdsByHottest.reduce((r: any, id) => {
      if (childrenIdMap.has(id)) {
        r.push(childrenIdMap.get(id))
      }
      return r
    }, [])
    return orderedChildren
  } else {
    throw new Error('Cannot Get Ordered by Hotness List')
  }
}

async function orderByCreatedAt(
  nodes: NSClass.SyllabusItemStruct[],
  parentNodeId: number
) {
  const orderedChildren = nodes.sort((a, b) => {
    return +new Date(b.created_at) - +new Date(a.created_at)
  })
  return orderedChildren
}

const orderFunctions = {
  created_at: orderByCreatedAt,
  hottest: orderByHottest,
}

export default function useVodLibraryState() {
  const [classId, setClassId] = useState<number>()
  const [tree, setTree] = useState<NSClass.SyllabusItemStruct[]>()
  const [pageNodes, setPageNodes] = useState<NSClass.SyllabusItemStruct[]>()
  const [vodTags, setVodTags] = useState<NSClass.SyllabusItemStruct[]>()
  const [currentVodTagId, setCurrentVodTagId] = useState<number | null>(null)
  const [currentOrderType, setCurrentOrderType] =
    useState<INodeOrderingType>('created_at')
  const [coursewareTags, setCoursewareTags] =
    useState<NSCourse.ICoursewareStatusTag[]>()

  const [topCourseNodes, setTopCourseNodes] =
    useState<NSClass.SyllabusItemStruct[]>()

  const { t } = useTranslation('my-class')
  const orderTypes: { label: string; value: INodeOrderingType }[] = [
    {
      label: t('order_type.created_at'),
      value: 'created_at',
    },
    {
      label: t('order_type.hottest'),
      value: 'hottest',
    },
  ]

  const fetchTree = useCallback(async (classId: number) => {
    const res = await getClassSyllabuses(classId, {
      depth: 4,
      expand: ['record'],
    })
    if (res && res.data && res.data.data) {
      setClassId(classId)
      setTree(res.data.data)
      const rootId = get(res, 'data.data.0.root_id')
      const tagRes = await getCourseNodeStatusTags(rootId)
      if (tagRes && tagRes.data && tagRes.data.data) {
        setCoursewareTags(tagRes.data.data)
      }
    }
  }, [])

  const refresh = useCallback(() => {
    if (classId) {
      fetchTree(classId)
    }
  }, [classId, fetchTree])

  const fetchVodTypeTags = useCallback(() => {
    if (tree) {
      const nodes: NSClass.SyllabusItemStruct[] = []
      getNodesByDepth(tree, 3, nodes)
      const syllabuses = nodes.filter(n => n && n.node_type === 'syllabus')
      // 拿出top的文件夹
      const topIndex = syllabuses.findIndex(n => n.sequence === 1)
      let filteredSyllabus = [...syllabuses]
      if (topIndex > -1) {
        setTopCourseNodes(
          syllabuses[topIndex].children.filter(c => c.template === 'page_std')
        )
        filteredSyllabus = [
          ...syllabuses.slice(0, topIndex),
          ...syllabuses.slice(topIndex + 1),
        ]
      }
      setVodTags(filteredSyllabus)
      // setCurrentVodTagId(get(filteredSyllabus, "0.id"));
    }
  }, [tree])

  const getPageNodes = useCallback(async () => {
    if (vodTags && (currentVodTagId || currentVodTagId === null)) {
      if (currentVodTagId === null) {
        // 全部
        const children = vodTags.reduce((arr, t) => {
          return [...arr, ...t.children]
        }, [] as NSClass.SyllabusItemStruct[])
        const orderedChildren = await orderFunctions[currentOrderType](
          children.filter(n => n.template === 'page_std'),
          get(vodTags, '0.root_id')
        )
        setPageNodes(orderedChildren)
      } else {
        const nodes = vodTags.find(v => v.id === currentVodTagId)
        if (nodes) {
          // 根据筛选条件排序
          const orderedChildren = await orderFunctions[currentOrderType](
            nodes.children.filter(n => n.template === 'page_std'),
            nodes.id
          )
          setPageNodes(orderedChildren)
        }
      }
    }
  }, [currentOrderType, currentVodTagId, vodTags])

  // Everything happens after fetching *Tree*
  useEffect(() => {
    fetchVodTypeTags()
  }, [fetchVodTypeTags])
  useEffect(() => {
    getPageNodes()
  }, [getPageNodes])

  return {
    fetchTree,
    orderTypes,
    vodTags,
    pageNodes,
    currentOrderType,
    currentVodTagId,
    setCurrentVodTagId,
    setCurrentOrderType,
    coursewareTags,
    topCourseNodes,
    refresh,
  }
}
