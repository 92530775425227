import React from 'react'
import ReactModal from 'react-modal'
import classNames from 'classnames'
import IconPark from '@/components/IconPark'
import style from './styles.module.scss'

const modalSizeMapping = {
  large: style.large,
  small: style.small,
  medium: style.medium,
}

interface ModalProps extends ReactModal.Props {
  size?: 'large' | 'medium' | 'small'
  title?: string
  showCloseBtn?: boolean
  footer?: React.ReactNode
  children?: React.ReactNode
}

const Modal = (props: ModalProps) => {
  const {
    children,
    size = 'large',
    footer,
    title,
    onRequestClose,
    shouldCloseOnEsc = false,
    ...rest
  } = props
  return (
    <ReactModal
      {...rest}
      ariaHideApp={false}
      overlayClassName={classNames(style.modalOverlay)}
      className={classNames(style.modalContent)}
      shouldCloseOnEsc={shouldCloseOnEsc}
      closeTimeoutMS={200}
    >
      <div className={classNames(style.modal)}>
        <div
          className={classNames(
            'relative h-screen-safe mx-auto md:h-4/5 w-full md:w-auto md:min-w-120 md:max-w-7xl flex flex-col justify-center',
            modalSizeMapping[size]
          )}
        >
          <div className="bg-white relative h-8 flex-shrink-0 rounded-t">
            {title && <div className="font-bold py-2 text-center">{title}</div>}
            <div
              onClick={onRequestClose}
              className={
                'absolute top-1 right-1 cursor-pointer bg-brand p-1 w-6 h-6 flex items-center justify-center'
              }
            >
              <IconPark
                name={'close'}
                className={classNames('text-white text-lg')}
              />
            </div>
          </div>

          <div
            className={classNames(
              'bg-white p-4 relative flex justify-center overflow-auto max-h-full w-full shadow-inner rounded-b'
            )}
          >
            <div className="flex-1">{children}</div>
          </div>
          {footer && <div className={style.footer}>{footer}</div>}
        </div>
      </div>
    </ReactModal>
  )
}

export default Modal
