import ReactModal from 'react-modal'
import { ReactNode } from 'react'
import { CloseCircleOutlined } from '@ant-design/icons'
import classNames from 'classnames'
import TransitionScale from '@/components/Transitions/TransitionScale'

export type IPlainModalWrapperProps = {
  children: ReactNode
  isOpen: boolean
  onClose: () => void
  overlayClassName?: string
  parent?: HTMLElement
  className?: string
  /**
   * scrollOverlay: @param{boolean}
   * true：modal内容超长滚动overlay
   * false：modal内容超长滚动modal content
   */
  scrollOverlay?: boolean
}
export default function PlainModalWrapper({
  children,
  isOpen,
  onClose,
  overlayClassName,
  parent,
  className,
  scrollOverlay,
}: IPlainModalWrapperProps) {
  return (
    <ReactModal
      parentSelector={() => parent || document.body}
      ariaHideApp={false}
      isOpen={isOpen}
      shouldCloseOnEsc
      onRequestClose={onClose}
      className={classNames(
        'absolute transform lg:left-1/2 lg:-translate-x-1/2 bg-transparent mx-auto border-none focus:outline-none',
        {
          'md:-translate-y-1/2 md:top-1/2': !scrollOverlay,
          'md:py-8': scrollOverlay,
        },
        className
      )}
      shouldCloseOnOverlayClick
      overlayClassName={classNames(
        'fixed inset-0 bg-black bg-opacity-50 z-200',
        {
          'overflow-auto': scrollOverlay,
        },
        overlayClassName
      )}
      closeTimeoutMS={200}
    >
      <div className="relative px-4 lg:px-0 mx-auto">
        {children}
        <div className="absolute right-4 top-0 p-2" onClick={onClose}>
          <TransitionScale>
            <CloseCircleOutlined className="text-3xl text-gold cursor-pointer hover:text-gold-300" />
          </TransitionScale>
        </div>
      </div>
    </ReactModal>
  )
}
