import classNames from "classnames";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import { Icon } from "stripe-ui";
import { Controls } from "./Controls";

interface PDFSubmissionProps {
  filePath: string;
  className?: string;
  workerSrc?: string;
  pageValue?: number;
  onPageChange?: (page: number) => void;
  onRendered?: () => void;
}

export const PDFViewer = (props: PDFSubmissionProps) => {
  const {
    onPageChange,
    pageValue,
    className,
    filePath,
    workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`,
    onRendered,
  } = props;

  useEffect(() => {
    pdfjs.GlobalWorkerOptions.workerSrc = workerSrc;
  }, [workerSrc]);

  const [currentPage, setCurrentPage] = useState(pageValue || 1);
  const [numPages, setNumPages] = useState<number>(0);

  const onDocumentLoadSuccess = useCallback(({ numPages: nextNumPages }) => {
    setNumPages(nextNumPages);
  }, []);

  const prevHandler = useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault();
      onPageChange && onPageChange(currentPage - 1);

      setCurrentPage(prev => {
        if (1 < currentPage) {
          return prev - 1;
        }
        return prev;
      });
    },
    [currentPage, onPageChange]
  );

  const nextHandler = useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault();
      onPageChange && onPageChange(currentPage + 1);

      setCurrentPage(prev => {
        if (prev < numPages!) {
          return prev + 1;
        }
        return prev;
      });
    },
    [currentPage, numPages, onPageChange]
  );

  useEffect(() => {
    if (pageValue) {
      setCurrentPage(pageValue);
    }
  }, [pageValue]);

  const wrapperRef = useRef<HTMLDivElement>(null);
  return (
    <div
      className={classNames(
        "rounded-md shadow-lg border-t border-gray-100 border-opacity-70 flex flex-col",
        className
      )}
      ref={wrapperRef}
    >
      <Controls
        filePath={filePath}
        numPages={numPages}
        currentPage={currentPage}
        onPrev={prevHandler}
        onNext={nextHandler}
      />
      <Document
        file={filePath}
        onLoadSuccess={onDocumentLoadSuccess}
        loading={
          <div className="flex flex-col items-center my-6">
            <div className="mb-1">PDF is loading</div>
            <div>
              <Icon name="spin" />
            </div>
          </div>
        }
      >
        <Page
          onRenderSuccess={() => {
            onRendered && onRendered();
          }}
          pageNumber={currentPage}
          scale={1}
          width={wrapperRef?.current?.offsetWidth}
          canvasRef={ref => {
            if (ref) {
              ref.classList.add("mx-auto");
            }
          }}
          loading={
            <div className="flex flex-col items-center my-6">
              <div className="mb-1">Please wait a second</div>
              <div>
                <Icon name="spin" />
              </div>
            </div>
          }
        />
      </Document>

      <Controls
        filePath={filePath}
        numPages={numPages}
        currentPage={currentPage}
        onPrev={prevHandler}
        onNext={nextHandler}
      />
    </div>
  );
};
