import React, { useEffect, useState } from 'react'
import { useRequest } from 'ahooks'
import { Controller, useForm, useWatch } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { Button } from '@mantine/core'
import { NSProduct } from 'gga-types'
import { isMobile } from 'react-device-detect'
import { toast } from 'react-toastify'
import { ChevronUpIcon } from '@heroicons/react/20/solid'
import { useRouter } from 'next/router'
import {
  ChinaPaymentVendorSelector,
  TVendors,
} from '@/lib/ui/china-payment-vendor-selector'
import { ProductApi } from '@/api/product/index.api'
import Yup from '@/utils/yup'
import { currencySymbol } from '@/utils/currency'
import { useCheckoutStore } from '@/store/checkout-store'
import { h5pay, getPaymentQrcode } from '@/services/payment'
import { useAuth } from '@/containers/auth'
import { useModalStore } from '@/store/use-modal-store'
import { useCouponCode } from '@/hooks/use-coupon-code'
import { ModalFooter } from '@/lib/ui/modals/modal-footer'
import { usePromoCodeStore } from '@/store/use-promo-code-store'
import { PackageGroup } from './package-group'

const productApi = new ProductApi()

const formSchema = Yup.object({
  productId: Yup.number().required(),
  vendor: Yup.mixed<TVendors>().oneOf(['wechatpay', 'alipay']).required(),
})

type FormSchema = Yup.InferType<typeof formSchema>

interface AddCreditFormProps {
  onSubmitted?: (data: { url: string; biz_id: string }) => void
}

export const AddCreditForm = ({ onSubmitted }: AddCreditFormProps) => {
  const { query } = useRouter()
  const { user } = useAuth()
  const { data: tokenProductRes } = useRequest(() =>
    productApi.getTokenProductsAPI()
  )
  const [tokenProducts, setTokenProducts] = useState<NSProduct.Product[]>([])
  const { fetchCoupon, rawCoupons } = useCouponCode()
  const promoCodes = usePromoCodeStore(state => state.promoCodes)
  const getPromoCodeFromQuery = usePromoCodeStore(
    state => state.getPromoCodeFromQuery
  )

  const openSelectCouponModal = useModalStore(
    state => () => state.openModal('SelectCouponModal', undefined)
  )

  const {
    setCoupons,
    setOriginalAmount,
    offAmount,
    finalAmount,
    originalAmount,
    selectedCoupon,
  } = useCheckoutStore()

  const form = useForm<FormSchema>({
    defaultValues: {
      vendor: 'wechatpay',
      productId: undefined,
    },
    resolver: yupResolver(formSchema),
  })

  const selectedProductId = useWatch({
    control: form.control,
    name: 'productId',
  })

  const onSubmit = async (values: FormSchema) => {
    const payload = {
      name: user?.name,
      phoneCode: user?.phone_code,
      phoneNumber: user?.phone_number,
      productId: values.productId,
      vendor: values.vendor,
      couponCode:
        selectedCoupon?._type === 'coupon' ? selectedCoupon?.code : undefined,
      promoCode:
        selectedCoupon?._type === 'promo' ? selectedCoupon?.code : undefined,
    }

    if (isMobile) {
      const response = await h5pay(payload)
      if (response.success) {
        if (values.vendor === 'wechatpay') {
          location.href = response.data!.data
        } else if (values.vendor === 'alipay') {
          document.write(response.data!.data)
        }
      } else {
        toast.error(response.message)
      }
    } else {
      const response = await getPaymentQrcode(payload)

      if (response.success) {
        onSubmitted?.(response.data!.data)
      } else {
        toast.error(response.message)
      }
    }
  }

  useEffect(() => {
    if (tokenProductRes?.data?.data) {
      const products = tokenProductRes?.data?.data.sort(
        (a, b) => a.token_amount - b.token_amount
      )
      setTokenProducts(products)
      form.setValue('productId', products?.[0].id)
    }
  }, [form, tokenProductRes?.data?.data])

  useEffect(() => {
    if (selectedProductId) {
      const selectedProduct = tokenProducts.find(
        p => p.id === selectedProductId
      )!
      setOriginalAmount(
        selectedProduct.discount_amount || selectedProduct.amount
      )
      fetchCoupon(selectedProductId)
    }
  }, [fetchCoupon, selectedProductId, setOriginalAmount, tokenProducts])

  useEffect(() => {
    setCoupons(rawCoupons, promoCodes)
  }, [promoCodes, rawCoupons, setCoupons])

  useEffect(() => {
    getPromoCodeFromQuery(query)
  }, [getPromoCodeFromQuery, query])

  return (
    <form
      noValidate
      className="flex flex-col space-y-5"
      onSubmit={form.handleSubmit(onSubmit)}
    >
      <Controller
        control={form.control}
        name="productId"
        render={({ field: { value, onChange } }) => (
          <div className="flex flex-col space-y-2.5">
            <label className="text-[#333] font-medium">选择点数包</label>

            <PackageGroup
              value={value}
              onChange={onChange}
              data={tokenProducts}
            />
          </div>
        )}
      />

      <Controller
        control={form.control}
        name="vendor"
        render={({ field: { value, onChange } }) => (
          <div className="flex flex-col space-y-2.5">
            <label className="text-[#333] font-medium">选择支付方式</label>
            <ChinaPaymentVendorSelector value={value} onChange={onChange} />
          </div>
        )}
      />
      <ModalFooter>
        <div className="text-[#333]">
          <span>{currencySymbol}</span>
          <span className="text-xl font-fjalla">{finalAmount}</span>

          {Boolean(offAmount) && (
            <span className="ml-2 line-through text-[#666]">
              {currencySymbol}
              {originalAmount}
            </span>
          )}
        </div>
        <div className="flex flex-1 justify-end items-stretch space-x-2.5">
          <div className="flex flex-col items-end justify-around">
            <span className="text-[#333] text-xs">
              共减 {currencySymbol}
              {offAmount}
            </span>
            <a
              onClick={openSelectCouponModal}
              className="no-underline bg-[#AB8C30]/10 text-[#AB8C30] text-xs inline-flex px-1.5 py-0.5 rounded-full cursor-pointer"
            >
              <span>查看优惠券</span>

              <ChevronUpIcon className="w-4 h-4" />
            </a>
          </div>
          <Button size="md" type="submit">
            立即支付
          </Button>
        </div>
      </ModalFooter>
    </form>
  )
}
