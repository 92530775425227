import classNames from 'classnames'
import React, { useMemo } from 'react'
import { NSProduct } from 'gga-types'
import { ScrollArea } from '@mantine/core'
import { isMobile } from 'react-device-detect'
import IconPark from '@/components/IconPark'
import { APP_LOCALE } from '@/constants/define'
import { Currency } from '@/utils/currency'
import { formatNumberToLessThanTwoDecimals } from '@/utils/number'

const currencySymbol = new Currency(1, APP_LOCALE)
  .renderWithSymbol()
  .substring(0, 1)

interface PackageItemProps {
  data: NSProduct.Product
  selected?: boolean
  onSelect?: () => void
}

const PackageItem = ({ data, selected, onSelect }: PackageItemProps) => {
  const validDurationLabel = useMemo(() => {
    return `有效期${data.time_span}${
      data.time_span_unit === 'Day' ? '天' : '月'
    }`
  }, [data])

  const discount = useMemo(() => {
    if (data.token_amount === 0) {
      return null
    }
    const d = data.amount / data.token_amount
    if (d === 1) {
      return null
    }
    return formatNumberToLessThanTwoDecimals(
      (data.amount / data.token_amount) * 10
    )
  }, [data])

  return (
    <div
      role="button"
      className={classNames(
        'w-[100px] shrink-0 p-3 rounded-lg flex flex-col space-y-1',
        {
          'bg-[#AB8C30]/10 ring-2 ring-[#AB8C30] ring-inset': selected,
          'bg-[#FAFAFA]': !selected,
        }
      )}
      onClick={onSelect}
    >
      <div className="flex items-center justify-center space-x-0.5">
        {APP_LOCALE !== 'zh_CN' ? (
          <>
            <IconPark name="coin" />

            <span className="font-fjalla text-[#666]">{data.token_amount}</span>
          </>
        ) : (
          <>
            <span className="font-fjalla text-[#666]">{data.token_amount}</span>
            <span className="text-[#666] text-xs">点数</span>
          </>
        )}
      </div>
      <div className="flex items-center justify-center space-x-0.5">
        <span className="align-baseline">{currencySymbol}</span>
        <span className="text-xl font-fjalla text-[#333]">{data.amount}</span>
      </div>
      <div className="text-center">
        <span className="inline-block text-[10px] text-white bg-[#AB8C30] rounded-full py-0.5 px-2">
          {discount}折
        </span>
      </div>
      <div className="text-[10px] text-[#999] text-center">
        {validDurationLabel}
      </div>
    </div>
  )
}

interface PackageGroupProps {
  data?: NSProduct.Product[]
  value?: number
  onChange?: (value?: number) => void
}

export const PackageGroup = ({ data, value, onChange }: PackageGroupProps) => {
  return (
    <ScrollArea type="hover" offsetScrollbars={true}>
      <div
        className={classNames('flex flex-nowrap space-x-2', {
          'w-[300px]': isMobile,
        })}
      >
        {data?.map(product => (
          <PackageItem
            key={product.id}
            data={product}
            selected={value === product.id}
            onSelect={() => onChange?.(product.id)}
          />
        ))}
      </div>
    </ScrollArea>
  )
}
