import { NSCoupon, NSPayment, NSPromoCode } from 'gga-types'
import {
  IResData,
  IResDataArray,
  TEnrollLiveSessionParams,
} from '@/@types/global'
import { request } from '@/utils/request'
import { appendUtm } from '@/utils/referrerHelper'

export const getPaymentStatus = (bizId: string) => {
  return request<IResData<NSPayment.Order>>({
    url: `/v2/Payment/status/check/${bizId}`,
    method: 'get',
  })
}

export const checkCouponValid = (couponCode: string, product_id: number) => {
  return request<IResData<NSPayment.Coupon>>({
    url: `/v2/Payment/coupon/check`,
    data: {
      product_id: product_id,
      code: couponCode,
    },
    method: 'post',
  })
}

export const registerSubscription = (
  authKey: string,
  productId: number,
  couponCode?: string,
  promoCode?: string,
  cashierUrl?: string
) => {
  return request<IResData<NSPayment.PaymentSubscription>>({
    url: `/v2/Payment/register/subscription`,
    data: {
      auth_key: authKey,
      product_id: productId,
      coupon_code: couponCode,
      promo_code: promoCode,
      cashier_url: cashierUrl,
    },
    method: 'post',
  })
}

export const retryBiling = (uuid: string) => {
  return request<IResData<boolean>>({
    url: `/v2/Payment/retry/billing/${uuid}`,
    method: 'post',
  })
}

export const modifySubscription = (authKey: string, uuid: string) => {
  return request<IResData<NSPayment.PaymentSubscription>>({
    url: `/v2/Payment/modify/subscription/${uuid}`,
    data: {
      auth_key: authKey,
    },
    method: 'put',
  })
}

type IPaymentRequest = {
  productId: number
  vendor: string
  name?: string
  phoneNumber?: string | undefined
  phoneCode?: string | undefined
  couponCode?: string | undefined
  promoCode?: string | undefined
  installmentNumber?: number | undefined
  additionType?: 'Unknown' | 'EventEnrollment'
  additionValue?: TEnrollLiveSessionParams | any
}

export const h5pay = ({
  productId,
  vendor,
  phoneNumber,
  couponCode,
  promoCode,
  installmentNumber,
  additionType,
  additionValue,
  name,
}: IPaymentRequest) => {
  return request<IResData<string>>({
    url: `/v2/payment/h5pay/${vendor}/${productId}`,
    data: {
      phone_code: '86',
      phone_number: phoneNumber,
      coupon_code: couponCode,
      promo_code: promoCode,
      installment_number: installmentNumber,
      cashier_url: appendUtm(window.location.href),
      addition_type: additionType,
      addition_value: additionValue,
      name,
    },
    method: 'post',
  })
}

export const getPaymentQrcode = ({
  productId,
  vendor,
  phoneNumber,
  couponCode,
  promoCode,
  installmentNumber,
  additionType,
  additionValue,
  name,
}: IPaymentRequest) => {
  return request<
    IResData<{
      url: string
      biz_id: string
    }>
  >({
    url: `/v2/payment/getqrcode/${vendor}/${productId}`,
    data: {
      phone_code: '86',
      phone_number: phoneNumber,
      coupon_code: couponCode,
      promo_code: promoCode,
      cashier_url: appendUtm(window.location.href),
      installment_number: installmentNumber,
      addition_type: additionType,
      addition_value: additionValue,
      name,
    },
    method: 'post',
  })
}

type TCheckPromoRet = {
  original_amount: number
  result_amount: number
  coupon?: {
    discount: number
    coupon_type: 'Discount' | 'Cash'
    recurring_count: number
  }
  coupon_discount: number
  promo_code?: {
    code: string
    description: string
    discount: number
    discount_type: 'Discount' | 'Cash'
    expired_at: string
  }
  promo_discount: number
}
export const calculateProductAmountAPI = (
  productId: number,
  context: {
    coupon_code?: string
    promo_code?: string
  }
) => {
  return request<IResData<TCheckPromoRet>>({
    url: `/v2/payment/calculate/${productId}`,
    data: {
      ...context,
    },
    method: 'post',
  })
}

export const checkPromoCodeApi = (productId: number, promoCode: string) => {
  return request<IResData<TCheckPromoRet>>({
    url: `/v2/payment/calculate/${productId}/promoCode?promoCode=${promoCode}`,
    method: 'get',
  })
}

export const fetchCouponApi = (productId: number) => {
  return request<IResDataArray<NSCoupon.Item>>({
    url: `/v2/product/getcoupons/${productId}`,
  })
}

export const getPromoCodeInfoApi = (code: string) => {
  return request<IResData<NSPromoCode.ItemDto>>({
    url: `/v2/Payment/promo/${encodeURIComponent(code)}`,
    method: 'get',
  })
}

export const generateOrderApi = ({
  productId,
  vendor,
  name,
  phoneCode,
  phoneNumber,
  couponCode,
  promoCode,
  installmentNumber,
  additionType,
  additionValue,
}: IPaymentRequest) => {
  return request<
    IResData<{
      biz_id: string
      amount: number
      product_name: string
    }>
  >({
    url: `/v2/Payment/generate/order/${vendor}/${productId}`,
    method: 'post',
    data: {
      name: name,
      phone_code: phoneCode,
      phone_number: phoneNumber,
      coupon_code: couponCode,
      promo_code: promoCode,
      installment_number: installmentNumber,
      cashier_url: appendUtm(window.location.href),
      addition_type: additionType,
      addition_value: additionValue,
    },
  })
}
