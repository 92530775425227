import { format } from 'date-fns'

export function getRootDomain(fromHost) {
  const hostname = fromHost || location.hostname
  const hostnameStr = hostname.split('.')
  const rootDomain = `.${hostnameStr
    .slice(hostnameStr.length - 2, hostnameStr.length)
    .join('.')}`
  const isIP = isNaN(parseInt(hostnameStr[0]))

  if (isIP) {
    return rootDomain == '.localhost' ? '' : rootDomain
  } else {
    return hostname
  }
}

//手机号码的正则验证
export function validatePhone(phone) {
  const reg =
    /^(13[0-9]|14[5-9]|15[012356789]|166|17[0-8]|18[0-9]|19[8-9])[0-9]{8}$/
  return reg.test(phone)
}

export function trim(str) {
  return str.replace(/^(\s|\xA0)+|(\s|\xA0)+$/g, '')
}

//密码验证
export function isNumberOr_Letter(s) {
  const regu = '^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,20}$'
  const re = new RegExp(regu)
  if (re.test(s)) {
    return true
  } else {
    return false
  }
}

//是否是空对象
export function isEmptyObject(obj) {
  for (const n in obj) {
    return false
  }
  return true
}

//删除数组指定的参数
export function contains(a, obj) {
  let i = a.length
  while (i--) {
    if (a[i].name === obj) {
      return i
    }
  }
  return false
}

//得到地址栏参数
export function parseQueryString(url) {
  const urlObject = {}
  if (/\?/.test(url)) {
    const urlString = url.substring(url.indexOf('?') + 1)
    const urlArray = urlString.split('&')
    for (let i = 0, len = urlArray.length; i < len; i++) {
      const urlItem = urlArray[i]
      const item = urlItem.split('=')
      urlObject[item[0]] = item[1]
    }
  }
  return urlObject
}

//手机号中间四位星号隐藏
export function mobileXing(str) {
  const reg = /^(\d{3})\d*(\d{4})$/
  return str.replace(reg, '$1****$2')
}

//获取年份范围
export function ranges(start, end) {
  return [...Array(end - start).keys()].map(item => start + item)
}

// 格式化时间
export const formatTime = currentTime => {
  return format(currentTime * 1000, currentTime >= 3600 ? 'HH:mm:ss' : 'mm:ss')
}
