import { useCallback, useState } from 'react'
import { NSFeedback } from 'gga-types'
import { getHasTrialFeedback } from '@/services/trial-feedback'

export default function useTrialFeedback() {
  const [loading, setLoading] = useState(false)
  const [trialFeedbackData, setTrialFeedbackData] =
    useState<NSFeedback.IHasTrialFeedbackResponse>()

  const fetchHasTrialFeedback = useCallback(async (trialId: number) => {
    setLoading(true)
    const res = await getHasTrialFeedback(trialId)
    if (res && res.data && res.data.data) {
      setTrialFeedbackData(res.data.data)
    }
    setLoading(false)
  }, [])

  const reset = useCallback(() => {
    setTrialFeedbackData(undefined)
  }, [])

  return {
    fetchHasTrialFeedback,
    trialFeedbackData,
    reset,
    loading,
  }
}
