import OSS from 'ali-oss'
import { useEffect, useMemo, useReducer, useRef } from 'react'
import axios, { CancelTokenSource } from 'axios'
import { getSTSAuth } from '@/services'
import { OSS_CONSTANT } from '@/config/config'
import { multipartUpload } from '@/services/s3'

export const setupOssClient = async () => {
  const config = await getSTSAuth()

  return new OSS({
    accessKeyId: config.access_key_id,
    accessKeySecret: config.access_key_secret,
    stsToken: config.security_token,
    region: OSS_CONSTANT.Region,
    bucket: OSS_CONSTANT.Bucket,
  })
}

interface OSSUploadProps {
  file: File
  filename: string
  onSuccess?: (result: { OSSClient: any; result: any }) => void
  onError?: () => void
}

export type IUploadStatus =
  | 'init'
  | 'pending'
  | 'uploading'
  | 'completed'
  | 'error'
export type IUploadPlatform = 'aliyun' | 'aws'

const INIT_STATE: IUploadState = {
  status: 'init',
}

type IAction = {
  type: IUploadStatus
  data?:
    | {
        uploadProgress: number
        target?: IUploadPlatform
      }
    | any
}

export type IUploadState = {
  status: IUploadStatus
  uploadProgress?: number
  target?: IUploadPlatform
  payload?: any
}

function reducer(st: IUploadState, action: IAction): IUploadState {
  switch (action.type) {
    case 'init':
      return {
        ...st,
        status: action.type,
        uploadProgress: 0,
      }
    case 'completed':
      return {
        ...st,
        status: action.type,
        uploadProgress: 0,
        payload: action.data.payload,
      }
    case 'pending':
    case 'error':
      return {
        ...st,
        status: action.type,
      }
    case 'uploading':
      if (!action.data) {
        throw new Error('Payload required')
      }
      return {
        ...st,
        status: action.type,
        uploadProgress: action.data.uploadProgress,
        target: action.data.target,
      }
    default:
      throw new Error('Unknown action')
  }
}

export type IUseUploader = ReturnType<typeof useUpload>

export default function useUpload() {
  const [currentState, dispatch] = useReducer(reducer, INIT_STATE)

  const cancelTokenRef = useRef<CancelTokenSource>()
  const uploadId = useRef('')
  const filenameRef = useRef('')

  const clientRef = useRef<OSS>()

  useEffect(() => {
    if (currentState.status === 'init' || currentState.status === 'completed') {
      uploadId.current = ''
      filenameRef.current = ''
      clientRef.current = undefined
      cancelTokenRef.current = undefined
    }
  }, [currentState.status])

  const OSSUpload = useMemo(() => {
    /**
     * @param {File} file 文件对像
     * @param {String} filename  文件名称/路径名称
     */
    const multipart = async ({ file, filename }: OSSUploadProps) => {
      filenameRef.current = filename

      let currentCheckpoint
      const OSSClient = await setupOssClient()
      clientRef.current = OSSClient

      try {
        const ossRes = await OSSClient.multipartUpload(filename, file, {
          checkpoint: currentCheckpoint,
          progress: (p, checkpoint) => {
            currentCheckpoint = checkpoint
            uploadId.current = checkpoint.uploadId
            dispatch({
              type: 'uploading',
              data: {
                uploadProgress: p * 100,
                target: 'aliyun',
              },
            })
            return done => {
              done()
            }
          },
        })
        if (ossRes && ossRes.name) {
          dispatch({
            type: 'completed',
            data: {
              payload: {
                OSSClient: clientRef.current,
                result: ossRes,
              },
            },
          })
        } else {
          dispatch({ type: 'error' })
        }
      } catch (err) {
        console.error('Upload error', err)
        if (err.name === 'cancel') {
          dispatch({ type: 'init' })
        } else {
          dispatch({ type: 'error' })
        }
        // if (err.name === "abort") {
        //   // handle abort
        // } else if (err.code === "ConnectionTimeoutError") {
        //   uploadErrorHandling();/**/
        // }
      }
    }
    const upload = async (fileName: string, file: File | Buffer) => {
      dispatch({
        type: 'uploading',
        data: { target: 'aliyun', uploadProgress: 10 },
      })
      const OSSClient = await setupOssClient()
      const result = await OSSClient.put(fileName, file)

      if (result.res.status === 200) {
        dispatch({
          type: 'completed',
          data: {
            payload: {
              OSSClient,
              result,
            },
          },
        })
      } else {
        dispatch({
          type: 'error',
        })
      }
      return result
    }

    const cancelUpload = () => {
      if (filenameRef.current && uploadId.current && clientRef.current) {
        clientRef.current.abortMultipartUpload(
          filenameRef.current,
          uploadId.current
        )
      }
    }

    return {
      upload,
      multipart,
      cancelUpload,
    }
  }, [])

  const S3Upload = useMemo(() => {
    const multipart = async (signedUrl: string, file: Blob) => {
      try {
        const res = await multipartUpload(signedUrl, file, {
          onInit: tokenSrc => {
            cancelTokenRef.current = tokenSrc
          },
          onProgress: (progress: number) => {
            dispatch({
              type: 'uploading',
              data: {
                target: 'aws',
                uploadProgress: progress * 100,
              },
            })
          },
        })
        dispatch({
          type: 'completed',
          data: { payload: signedUrl },
        })
        return res
      } catch (e) {
        if (axios.isCancel(e)) {
          dispatch({ type: 'init' })
        } else {
          console.error(e)
          dispatch({ type: 'error' })
        }
      }
    }

    const cancelUpload = () => {
      if (cancelTokenRef.current) {
        cancelTokenRef.current.cancel()
        dispatch({ type: 'init' })
      }
    }

    return {
      multipart,
      cancelUpload,
    }
  }, [])

  const reupload = () => {
    dispatch({
      type: 'init',
    })
  }

  const goPending = () => {
    dispatch({
      type: 'pending',
    })
  }

  return {
    OSSUpload,
    S3Upload,
    currentState,
    reupload,
    goPending,
  }
}
