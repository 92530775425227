import classNames from 'classnames'
import { useCallback, useState } from 'react'

interface InstallmentOptionProps {
  label: string
  value: string
  selected?: boolean
  onClick?: (value: string | undefined) => void
}

const InstallmentOption = (props: InstallmentOptionProps) => {
  const { label, value, selected, onClick } = props

  const clickHandler = useCallback(() => {
    if (onClick) {
      onClick(selected ? undefined : value)
    }
  }, [onClick, selected, value])

  return (
    <div
      className={classNames(
        'px-2 py-2 border-2 rounded-lg cursor-pointer text-center',
        {
          'opacity-50': !selected,
          'border-brand font-bold': selected,
        }
      )}
      onClick={clickHandler}
    >
      <p>{label}</p>
      <p>(免手续费)</p>
    </div>
  )
}

export default InstallmentOption
