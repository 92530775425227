import { NSNotification } from 'gga-types'
import { request } from '@/utils/request'
import { IResData } from '@/@types/global'

export const getNotificationStatus = async () => {
  const res = await request<IResData<{ has_new: boolean }>>({
    url: '/v2/notification/check',
    method: 'get',
  })
  return res
}

export const expandNotificationContent = (nid: number) => {
  return request<IResData<NSNotification.Item>>({
    url: `/v2/notification/${nid}/content`,
    method: 'get',
  })
}
