import { GetServerSidePropsContext } from 'next'
import {
  destroyCookie,
  parseCookies,
  setCookie,
  default as Nookies,
} from 'nookies'

export class Jwt {
  static TOKEN_KEY = '_gga_auth'

  private static _setLoginTokenInCookie(
    tk: string,
    ctx?: GetServerSidePropsContext,
    maxAge = 60 * 60 * 24 * 30
  ) {
    setCookie(ctx || null, Jwt.TOKEN_KEY, tk, {
      maxAge,
      httpOnly: false,
      path: '/',
      secure: true,
    })
  }

  static getToken(ctx?: GetServerSidePropsContext) {
    let cookies
    if (ctx) {
      cookies = Nookies.get(ctx)
    } else {
      cookies = parseCookies()
    }

    const token = cookies[Jwt.TOKEN_KEY] || ''
    return token
  }

  static setToken(tk: string, ctx?: GetServerSidePropsContext) {
    Jwt._setLoginTokenInCookie(tk, ctx)
  }

  static clear(ctx?: GetServerSidePropsContext) {
    Jwt._setLoginTokenInCookie('', ctx, 0)
  }
}
