/**
 * @param srcSize 文件size in bytes
 * @param gigs GB limit
 */
export function isFileGreaterThan(srcSize: number, gigs: number): boolean {
  return srcSize > gigs * 1024 * 1024 * 1024
}

export function checkExtension(filename: string, extension: string) {
  const fileExt =
    filename.substring(filename.lastIndexOf('.') + 1, filename.length) ||
    filename

  return fileExt === extension
}
