import React, { useCallback, useRef } from 'react'
import { LoadingOutlined } from '@ant-design/icons'
import { createRoot } from 'react-dom/client'

function PageLoadingComponent() {
  return (
    <LoadingOutlined
      className="text-gold-400"
      style={{
        fontSize: 64,
      }}
    />
  )
}

export function usePageLoading(mount?: string) {
  const ref = useRef(`Page_loading_${new Date().getTime()}`)
  const trigger = useCallback(
    (loading: boolean) => {
      if (document) {
        if (loading) {
          const container = document.createElement('div')
          container.id = ref.current
          container.className =
            'fixed top-0 left-0 right-0 bg-black opacity-40 w-screen h-screen z-50 flex flex-col justify-center z-200'
          let parent

          if (mount) {
            parent = document.getElementById(mount)
          } else {
            parent = document.body
          }
          if (parent) {
            const mountPoint = createRoot(container)
            mountPoint.render(<PageLoadingComponent />)
            parent.appendChild(container)
          }
        } else {
          const ele = document.getElementById(ref.current)
          if (ele) {
            ele.remove()
          }
        }
      }
    },
    [mount]
  )

  return {
    trigger,
  }
}
