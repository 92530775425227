import { ILocalizationCode, NSProduct } from 'gga-types'
import { Api } from '@/api'
import { APP_LOCALE } from '@/constants/define'

export class ProductApi extends Api {
  constructor(...args) {
    super(...args)
  }

  getAllProducts(locale: ILocalizationCode) {
    return this.createApi<void, NSProduct.Product[]>(
      `/v2/Product/${locale}/all`,
      'get'
    )
  }

  getProductById(productId: number) {
    return this.createApi<void, NSProduct.Product>(
      `/v2/Product/${productId}`,
      'get'
    )
  }

  getTokenProductsAPI() {
    return this.createApi<void, NSProduct.Product[]>(
      `/v2/product/${APP_LOCALE}/token`,
      'get'
    )
  }
}
