import browser from './browser'

export const isDev =
  !process.env.APP_ENV || process.env.APP_ENV === 'development'
export const isProduction = process.env.APP_ENV === 'production'

export function isFunction(obj: any) {
  return obj && {}.toString.call(obj) === '[object Function]'
}

export function isEmail(str: string) {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(str)
}

export function isMicroMessenger() {
  return (
    browser.versions.mobile &&
    navigator.userAgent.toLowerCase().indexOf('micromessenger') !== -1
  )
}

export const isMiniapp = process.env.APP_TYPE === 'miniapp'

export function isIE(userAgent: string) {
  return (
    /MSIE (\d+\.\d+);/.test(userAgent) || userAgent.indexOf('Trident/') > -1
  )
}
