import React, { useEffect } from 'react'
import { isBrowser } from 'react-device-detect'
import { useRouter } from 'next/router'
import { ChannelIOService } from '@/services/channel-io-service'
import Discord from './Discord'
import Kakao from './Kakao'

const ContactWrapper: React.FC<
  React.PropsWithChildren<{
    hide?: boolean
  }>
> = ({ hide = false, children }) => {
  useEffect(() => {
    if (isBrowser) {
      if (hide) {
        ChannelIOService.instance.hideChannelButton()
      } else {
        ChannelIOService.instance.showChannelButton()
      }
    }
  })

  const router = useRouter()
  const countryCode = router.query.country

  return (
    <>
      {children}
      {hide ? null : (
        <>
          {countryCode !== 'UAE' && <Discord />}
          {countryCode !== 'UAE' && <Kakao />}
        </>
      )}
    </>
  )
}

export default ContactWrapper
