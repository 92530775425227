import { IResData } from '@/@types/global'
import { request } from '@/utils/request'

type TSTSAuthConfig = {
  access_key_id: string
  access_key_secret: string
  security_token: string
  expiration: string
}

export function getSTSAuth() {
  return request<IResData<TSTSAuthConfig>>({
    url: '/v2/User/oss/credentials',
    method: 'get',
  }).then(res => {
    if (res.data?.data) {
      return res.data.data
    } else {
      throw new Error('Error get sts token')
    }
  })
}

// 通知短信服务发送验证码
export const notifiySMSApiV2 = (phoneCode: string, phoneNumber: string) => {
  return request({
    url: '/v2/user/sms/code',
    method: 'post',
    data: {
      phone_code: phoneCode,
      phone_number: phoneNumber,
    },
  })
}

export type IReceiver = {
  phone_code: string
  phone_number: string
}

export type ICaptcha = {
  code: string
  id: string
}

export type IGoogleRecaptcha = {
  response: string
  remote_ip?: string
}

export enum SmsTypeEnum {
  Login = 'login',
  Register = 'register',
  ResetPassword = 'reset_password',
}

export type TNotifySmsWithCaptchaApiParams = {
  captcha_style: 'Image' | 'Recaptcha'
  receiver: IReceiver
  google_recaptcha?: IGoogleRecaptcha
  captcha?: ICaptcha
  type?: SmsTypeEnum
}

export const notifiySMSWithCaptchaApi = (
  data: TNotifySmsWithCaptchaApiParams
) => {
  return request({
    url: '/v2/util/sms/code',
    method: 'post',
    data,
  })
}
