import { useCallback, useEffect, useMemo, useState } from 'react'

type IUseSmsButtonParams = {
  /**
   * @description 需要Recaptcha验证
   */
  onVerify?: () => void

  recaptchaVerified: boolean
  isDisabled?: boolean
  /**
   * @description 秒
   */
  maxTimer?: number

  onCountDown?: (time: number) => void

  onSubmit?: () => void
}

export default function useSmsButton({
  maxTimer = 60,
  onCountDown,
  onSubmit,
  recaptchaVerified,
  onVerify,
  isDisabled,
}: IUseSmsButtonParams) {
  const [countdown, setCountdown] = useState<number>(0)

  const startCountdown = useCallback(() => {
    let second = maxTimer

    setCountdown(second)
    const exec = () => {
      if (second > 0) {
        second = second - 1
        setCountdown(second)
        onCountDown && onCountDown(second)
      } else {
        setCountdown(0)
        onCountDown && onCountDown(second)
        clearInterval(intervalID)
      }
    }

    const intervalID = setInterval(exec, 1000)

    return () => {
      clearInterval(intervalID)
    }
  }, [maxTimer, onCountDown])

  // useEffect(() => {
  //   if (recaptchaVerified) {
  //     startCountdown();
  //     onSubmit();
  //   }
  // }, [onSubmit, recaptchaVerified, startCountdown]);

  const handleClick = useCallback(() => {
    if (!recaptchaVerified && onVerify) {
      onVerify()
    } else {
      startCountdown()
      onSubmit && onSubmit()
    }
  }, [onSubmit, onVerify, recaptchaVerified, startCountdown])

  const disabled = useMemo(() => {
    return !recaptchaVerified || isDisabled || (!!countdown && countdown > 0)
  }, [countdown, isDisabled, recaptchaVerified])

  return {
    handleClick,
    disabled,
    countdown,
  }
}
