import { useState, useCallback, useMemo } from 'react'
import { toast } from 'react-toastify'
import { NSProduct } from 'gga-types'
import {
  calculateProductAmountAPI,
  checkPromoCodeApi,
} from '@/services/payment'
import { get } from 'lodash'

export type ICalculateDiscountContext = {
  coupon_code?: string
  promo_code?: string
}

export type ICouponValidStatus = 'valid' | 'invalid' | undefined

export const useCashier = (product: NSProduct.Product) => {
  const initialAmount = useMemo(() => {
    return product.discount_amount || product.amount
  }, [product.amount, product.discount_amount])

  const [couponDiscountAmount, setCouponDiscountAmount] = useState<number>(0)
  const [couponRecurringCount, setCouponRecurringCount] = useState<number>(0)

  const [couponChecking, setCouponChecking] = useState<boolean>(false)
  const [couponValid, setCouponVaild] = useState<ICouponValidStatus>(undefined)
  const [discountCode, setDiscountCode] = useState<{
    coupon_code?: string
    promo_code?: string
  }>({})

  const removeCouponDiscount = useCallback(() => {
    setCouponDiscountAmount(0)
  }, [])

  const productLocale = useMemo(() => {
    return get(product, 'localization.code')
  }, [product])

  const amount: number = useMemo(() => {
    if (!couponDiscountAmount) {
      return initialAmount
    } else {
      return initialAmount - couponDiscountAmount < 0
        ? 0
        : initialAmount - couponDiscountAmount
    }
  }, [couponDiscountAmount, initialAmount])

  // 如果taxable是false，就是免税，否则需要税
  // 那么免税就是taxFreeAmount就是amount，反之taxAmount就是0
  const taxFreeAmount = useMemo(() => {
    if (product.taxable) {
      return 0
    } else {
      return amount
    }
  }, [product.taxable, amount])

  const vat = useMemo(() => {
    if (product.taxable) {
      const grossAmount = Math.round((amount - taxFreeAmount) / 1.1)
      const vat = amount - taxFreeAmount - grossAmount
      return vat
    } else {
      return 0
    }
  }, [amount, product.taxable, taxFreeAmount])

  /**
   * 如果是false则为通过，否则返回错误信息
   */
  const checkPromoCode = useCallback(
    async (promoCode: string) => {
      const res = await checkPromoCodeApi(product.id, promoCode)
      if (res.success) {
        setCouponDiscountAmount(
          res.data!.data!.coupon_discount || res.data!.data!.promo_discount
        )
        setCouponRecurringCount(res.data!.data!.coupon?.recurring_count || 1)
        setCouponVaild(promoCode ? 'valid' : undefined)
        setDiscountCode({ promo_code: promoCode })
        return false
      } else {
        return res.message
      }
    },
    [product.id]
  )

  const checkCoupon = useCallback(
    async (context?: ICalculateDiscountContext) => {
      if (!context?.coupon_code && !context?.promo_code) {
        removeCouponDiscount()
        setCouponVaild(undefined)
        setDiscountCode({})
        return true
      }

      try {
        setCouponVaild(undefined)
        setDiscountCode({})
        setCouponChecking(true)

        const res = await calculateProductAmountAPI(product.id, context)

        if (res.success) {
          setCouponDiscountAmount(
            res.data!.data!.coupon_discount || res.data!.data!.promo_discount
          )
          setCouponRecurringCount(res.data!.data!.coupon?.recurring_count || 1)
          setCouponVaild('valid')
          setDiscountCode({ ...context })

          return true
        } else {
          setCouponVaild('invalid')
          setDiscountCode({})
          removeCouponDiscount()
          toast.error(res.message)
        }
      } catch (e) {
        removeCouponDiscount()
        setCouponVaild('invalid')
        setDiscountCode({})
        return 'coupon code error'
      } finally {
        setCouponChecking(false)
      }
    },
    [product.id, removeCouponDiscount]
  )

  return {
    initialAmount,
    removeCoupontDiscount: removeCouponDiscount,
    amount,
    couponDiscountAmount,
    checkCoupon,
    discountCode,
    couponValid,
    couponRecurringCount,
    taxFreeAmount,
    vat,
    couponChecking,
    checkPromoCode,
    productLocale,
  }
}
