import React, { useMemo } from 'react'
import dynamic from 'next/dynamic'
import { LocalizationEnum } from 'gga-types'
import { useCountry } from '@/hooks/useCountry'
import { ReplayFileUploadInputProps } from './types'

const KrInputs = dynamic(() => import('./Inputs.kr'), { ssr: false })

const ZhCNInputs = dynamic(() => import('./Inputs.zh_CN'), { ssr: false })

const ReplayFileUploadInput: React.FC<ReplayFileUploadInputProps> = props => {
  const country = useCountry()

  const Component = useMemo(() => {
    switch (country) {
      case LocalizationEnum.kr:
        return <KrInputs {...props} />
      case LocalizationEnum.zh_CN:
        return <ZhCNInputs {...props} />
    }
  }, [country, props])

  return <>{Component}</>
}

export default ReplayFileUploadInput
