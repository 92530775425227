import classNames from 'classnames'
import React from 'react'
import style from './styles.module.scss'

interface ProgressBarProps {
  process: number
  showProcess?: boolean
}

export const ProgressBar = (props: ProgressBarProps) => {
  const { process, showProcess } = props
  return (
    <div className={style.progress}>
      <div
        className={classNames(style.progressOuter, {
          [style.showInfo]: showProcess,
        })}
      >
        <div className={classNames(style.meter, style.animate)}>
          <span style={{ width: process + '%' }}>
            <span></span>
          </span>
        </div>
      </div>
      <div className={style.infoText}>{process}%</div>
    </div>
  )
}
