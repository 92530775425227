import React from 'react'
import { Button } from '@mantine/core'
import { Modal } from '@/lib/ui/modals/modal'
import { useModalStore } from '@/store/use-modal-store'
import { useCheckoutStore } from '@/store/checkout-store'
import { ModalFooter } from '@/lib/ui/modals/modal-footer'
import { usePromoCodeStore } from '@/store/use-promo-code-store'
import CouponSelector from '../cashier/coupon-selector'

export const SelectCouponModal = () => {
  const { isOpen } = useModalStore(state =>
    state.getModalState('SelectCouponModal')
  )
  const closeModal = useModalStore(
    state => () => state.closeModal('SelectCouponModal')
  )

  const { redeemPromoCode } = usePromoCodeStore()
  const { coupons, selectedCoupon, setSelectedCoupon } = useCheckoutStore()

  return (
    <Modal
      size="sm"
      centered={true}
      opened={isOpen}
      onClose={closeModal}
      title={'选择优惠券'}
      closeOnClickOutside={false}
      zIndex={999}
    >
      <div className="mb-4">
        <CouponSelector
          coupons={coupons}
          onSelect={setSelectedCoupon}
          onRedeemPromoCode={redeemPromoCode}
          selected={selectedCoupon}
        />
      </div>
      <ModalFooter className="space-x-4">
        <Button
          variant="light"
          color="gray"
          fullWidth
          onClick={() => {
            setSelectedCoupon(undefined)
            closeModal()
          }}
          styles={{ label: { color: '#333' } }}
        >
          暂不使用
        </Button>
        <Button fullWidth onClick={closeModal}>
          确定
        </Button>
      </ModalFooter>
    </Modal>
  )
}
