import React, {
  ForwardedRef,
  forwardRef,
  ReactNode,
  useCallback,
  useMemo,
  useState,
} from 'react'
import classNames from 'classnames'
import { Button } from 'stripe-ui'
import { Trans, useTranslation } from 'next-i18next'
import {
  CheckCircleOutlined,
  CloseOutlined,
  LoadingOutlined,
} from '@ant-design/icons'
import styles from '@/styles/myPage.module.scss'
import { UploadIcon } from '@/components/icon'
import { checkExtension } from '@/utils/file'

type ICoursewarePDFUploaderProps = {
  type: 'pdf' | 'rofl'
  onChange?(file: File): void
  onCancel?(): void
  progress?: number
  status?: string
  message?: string
  error?: string
  disabled?: boolean
  children?: ReactNode
  onError?(message: string): void
  clearError?: () => void
}

export const FileUploader = forwardRef(
  (props: ICoursewarePDFUploaderProps, ref: ForwardedRef<any>) => {
    const {
      type,
      error,
      status,
      progress,
      onCancel,
      onChange,
      message,
      disabled = false,
      children,
      onError,
      clearError,
    } = props

    const { t } = useTranslation()

    const [fileName, setFileName] = useState('')

    const isRightType = useCallback(
      (file: File) => {
        if (type === 'pdf') {
          return file.type === 'application/pdf'
        } else if (type === 'rofl') {
          return checkExtension(file.name, 'rofl')
        }
        return true
      },
      [type]
    )

    const handleFileChange = useCallback(
      (e: React.FormEvent<HTMLInputElement>) => {
        if (disabled) {
          return
        }
        const files = (e.target as HTMLInputElement).files as FileList
        if (files[0]) {
          if (!isRightType(files[0])) {
            onError && onError(t('course_form.unsupported_file_msg'))
            return
          }
          clearError && clearError()
          setFileName(files[0].name)
          onChange && onChange(files[0])
        }
      },
      [clearError, disabled, isRightType, onChange, onError, t]
    )

    const accept = useMemo(() => {
      if (type === 'pdf') {
        return 'application/pdf'
      }
      return `.${type}`
    }, [type])

    const inputRef = React.createRef<HTMLInputElement>()

    return (
      <div className={classNames(styles.uploadWrap)}>
        <label
          className={classNames(styles.box, {
            [styles.hide]: status !== 'init',
          })}
        >
          <UploadIcon />
          <Button variant="brand" className="mt-8" disabled={disabled}>
            {t('course_form.upload')}
          </Button>
          <input
            disabled={disabled}
            ref={inputRef}
            className={styles.upload}
            type="file"
            accept={accept}
            onInput={handleFileChange}
            onClick={() => {
              // 点击时重置input value可触发对相同文件的onInput事件
              if (inputRef.current) {
                inputRef.current.value = ''
              }
            }}
          />
        </label>

        <div
          className={classNames(styles.uploadProgressWrap, {
            [styles.hide]: status === 'init',
          })}
        >
          {status === 'uploading' && (
            <div className="text-xl mb-10 text-center">
              <div className="dotdotdot-loading mb-2">Uploading</div>
              <div className={'text-center my-3'}>{progress?.toFixed(2)}%</div>
              <LoadingOutlined />
            </div>
          )}

          {status === 'completed' && (
            <div className="text-xl mb-10 text-green-600 text-center">
              <div className="mb-2">Completed</div>
              <CheckCircleOutlined />
            </div>
          )}

          {status === 'error' && (
            <div className="text-xl mb-10 text-red-600 text-center">
              <div className="mb-2">Failed</div>
              <CloseOutlined />
            </div>
          )}

          <div
            style={{
              textAlign: 'center',
            }}
          >
            <span>
              {t('course_form.file_name')}: {fileName}
            </span>

            {(status === 'completed' || status === 'error') && (
              <span
                style={{
                  marginLeft: '5px',
                  textDecoration: 'underline',
                }}
              >
                <a
                  href="#"
                  onClick={e => {
                    // re-upload
                    e.preventDefault()
                    onCancel && onCancel()
                  }}
                >
                  {t('course_form.reupload')}
                </a>
              </span>
            )}
          </div>
          <div
            className={classNames({
              [styles.done]: status !== 'error',
              [styles.error]: status === 'error',
            })}
          >
            {status === 'completed' && (
              <Trans t={t} i18nKey="course_form.upload_completed" />
            )}
          </div>
        </div>

        {/* <div className={styles.uploadTip}>
          *{" "}
          <Trans
            t={t}
            i18nKey="components.courseware_modal.solo_task_modal_content.rofl_upload_content.file_type_tip"
          />
        </div> */}
        {children}
        {!!error && <span className={styles.error}>{error}</span>}
      </div>
    )
  }
)

FileUploader.displayName = 'FileUploader'
