import { ReactNode, useEffect, useRef, useState } from 'react'
import Confetti from 'react-confetti'
import classNames from 'classnames'
import ImageComponent from '@/components/ImageComponent'

interface SuccessWrapperProps {
  children: ReactNode
  header?: string
  className?: string
}

export const SuccessWrapper = ({
  children,
  header,
  className,
}: SuccessWrapperProps) => {
  const wrapperRef = useRef<HTMLDivElement>(null)
  const [dimension, setDimension] = useState<{ width: number; height: number }>(
    { width: 0, height: 0 }
  )

  useEffect(() => {
    setDimension({
      width: wrapperRef.current!.clientWidth,
      height: wrapperRef.current!.clientHeight,
    })
  }, [])

  return (
    <div
      ref={wrapperRef}
      className={classNames('text-[#333] relative flex flex-col', className)}
    >
      <Confetti
        width={dimension?.width}
        height={dimension?.height}
        recycle={false}
        className="inset-0"
      ></Confetti>

      <div className="flex flex-col items-center mb-4">
        <ImageComponent
          alt="Success"
          src="https://res.gengesports.cn/user-web/images/payment/purchase-success.png"
          className="w-full object-cover max-w-[424px]"
        />

        {header && (
          <div className="text-base font-semibold text-[#333] text-center">
            {header}
          </div>
        )}
      </div>

      {children}
    </div>
  )
}
