import { LinkProps } from 'next/link'

export const isExternalLink = (href?: LinkProps['href']): boolean =>
  !href || (typeof href === 'string' && /^(https?:)?\/\//.test(href))

export function utf8_to_b64(str) {
  return btoa(unescape(encodeURIComponent(str)))
}

export function b64_to_utf8(str) {
  return decodeURIComponent(escape(atob(str)))
}
