import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useInView } from 'react-intersection-observer'
import { LoadingOutlined } from '@ant-design/icons'
import { uniqBy } from 'lodash'
import { NSNotification } from 'gga-types'
import usePublicCourseComment from '@/hooks/usePublicCourseComment'
import PublicCourseCommentItem from '@/components/CoursewareModal/PageTask/PublicCourseComment/PublicCourseCommentItem'
import { useAuth } from '@/containers/auth'
import ReplyInput from '@/components/CoursewareModal/PageTask/PublicCourseComment/ReplyInput'
import { postCommentsV2 } from '@/services/comment'

type IPublicCourseCommentProps = {
  courseId: number
  classUuid: string
  notification?: NSNotification.Item
  trialId: number
}

export default function PublicCourseComment({
  courseId,
  classUuid,
  notification,
  trialId,
}: IPublicCourseCommentProps) {
  const [obs, inView] = useInView()
  const {
    fetcher,
    data,
    setPage,
    hasNextPage,
    loading,
    unShiftNew,
    replaceItemAt,
  } = usePublicCourseComment(courseId)
  const { user } = useAuth()
  const { t } = useTranslation()

  useEffect(() => {
    if (notification) {
      unShiftNew(notification.content.root_comment)
    }
  }, [notification, unShiftNew])

  useEffect(() => {
    fetcher()
  }, [fetcher])

  useEffect(() => {
    if (inView && hasNextPage && !loading) {
      setPage(prev => prev + 1)
    }
  }, [hasNextPage, inView, loading, setPage])

  return (
    <div>
      <ReplyInput
        onSubmit={async (data, next) => {
          const res = await postCommentsV2(data, classUuid, courseId, trialId)
          if (res && res.data && res.data.data) {
            unShiftNew(res.data.data)
          }
          next()
        }}
        placeholder={t('placeholder.interact_with_everyone')}
        maxCount={200}
      />
      {uniqBy(data, 'id').map((comment, idx) => {
        return (
          <PublicCourseCommentItem
            trialId={trialId}
            onDelete={() => {
              replaceItemAt({ ...comment, deleted_at: new Date() }, idx)
            }}
            classUuid={classUuid}
            syllabusId={courseId}
            key={comment.id}
            comment={comment}
            depth={comment.depth}
            isMine={Boolean(user && user.id === comment.creator.id)}
          />
        )
      })}
      <div ref={obs} />
      <div className={'w-full mx-auto text-center text-gray-400'}>
        {loading ? (
          <div>
            <LoadingOutlined />
          </div>
        ) : (
          <div>{t('has_reached_bottom')}</div>
        )}
      </div>
    </div>
  )
}
