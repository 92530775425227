import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import { ReactNode, useCallback, useEffect, useState } from 'react'
import classNames from 'classnames'

export type TGoogleRecaptchaComponentProps = {
  onVerified?: (verified: boolean, token: string) => void
  children?: (
    handleReCaptchaVerify: () => void,
    isVerifying: boolean
  ) => ReactNode
  action?: string
  /**
   * 如果需要组件内部调用后端接口验证
   */
  needsVerify?: boolean
  className?: string
}

export default function GoogleRecaptchaComponent({
  onVerified,
  children,
  action,
  needsVerify,
  className,
}: TGoogleRecaptchaComponentProps) {
  const { executeRecaptcha } = useGoogleReCaptcha()
  const [isVerifying, setIsVerifying] = useState(false)

  useEffect(() => {
    const regx = /^[A-Za-z/_]+$/
    if (action && !regx.test(action)) {
      throw new Error('Action can only contains "A-Za-z/_", please change')
    }
  }, [action])

  // Create an event handler so you can call the verification on button click event or form submit
  const handleReCaptchaVerify = useCallback(async () => {
    if (!executeRecaptcha) {
      console.log('Execute recaptcha not yet available')
      return
    }
    setIsVerifying(true)

    const token = await executeRecaptcha(action || 'VERIFY_RECAPTCHA')

    let verified = true
    if (needsVerify) {
      // TODO: 调用纯验证接口
      verified = true
    }

    setIsVerifying(false)
    onVerified && onVerified(verified, token)
    // Do whatever you want with the token
  }, [action, executeRecaptcha, needsVerify, onVerified])

  return (
    <div className={classNames(className)}>
      {children ? (
        children(handleReCaptchaVerify, isVerifying)
      ) : (
        <button onClick={handleReCaptchaVerify}>Verify recaptcha</button>
      )}
    </div>
  )
}
