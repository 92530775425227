import classNames from "classnames";
import React, { useMemo } from "react";
import { Icon } from "stripe-ui";

interface ControlsProps {
  filePath: string;
  numPages: number;
  currentPage: number;
  onPrev: (e: React.MouseEvent) => void;
  onNext: (e: React.MouseEvent) => void;
}

export const Controls = (props: ControlsProps) => {
  const { numPages, currentPage, onPrev, onNext, filePath } = props;
  const canPrev = useMemo(() => {
    return currentPage > 1;
  }, [currentPage]);

  const canNext = useMemo(() => {
    return currentPage < numPages;
  }, [currentPage, numPages]);
  return (
    <div
      className={classNames("flex flex-row justify-between px-2", {
        hidden: !numPages,
      })}
    >
      <div></div>
      <div className={classNames("bg-white flex flex-row my-2 shadow-md")}>
        <button
          className={classNames(
            "h-10 w-10 text-center no-underline bg-white cursor-pointer	relative",
            {
              "hover:bg-gray-100": canPrev,
            }
          )}
          onClick={onPrev}
          title="Previous Page"
          disabled={!canPrev}
        >
          <Icon
            name="chevronLeft"
            className={classNames("absolute h-5 w-5 inset-1/4", {
              "text-gray-200": !canPrev,
            })}
          />
        </button>
        <div className="pl-1 pr-1 flex items-center">
          {currentPage} of {numPages}
        </div>
        <button
          title="Next Page"
          className={classNames(
            "h-10 w-10 text-center no-underline bg-white cursor-pointer	relative",
            {
              "hover:bg-gray-100": canNext,
            }
          )}
          onClick={onNext}
          disabled={!canNext}
        >
          <Icon
            name="chevronRight"
            className={classNames("absolute h-5 w-5 inset-1/4", {
              "text-gray-200": !canNext,
            })}
          />
        </button>
      </div>

      <div className={classNames("bg-white flex flex-row my-2 shadow-md")}>
        <a
          href={filePath}
          target="_blank"
          className={classNames(
            "flex h-10 no-underline text-center w-10 hover:bg-gray-100"
          )}
          title={"Open in the window"}
          rel="noreferrer noopener"
        >
          <Icon name="externalLink" className="m-auto" />
        </a>
      </div>
    </div>
  );
};
