import { Modal, ModalProps } from '@mantine/core'
import { createStyles } from '@mantine/styles'
import { APP_LOCALE } from '@/constants/define'

const useStyles = createStyles(() => ({
  close: { display: 'none' },
  overlay: {
    backgroundColor: 'rgba(0,0,0,0.4) !important',
  },
  header: {
    display: 'none',
  },
  body: {
    backgroundColor: 'transparent',
  },
  inner: {
    color: '#FAFAFA',
    alignItems: 'center',
  },
  modal: {
    backgroundColor: APP_LOCALE === 'zh_CN' ? 'rgba(51, 51, 51, 0.5)' : '#fff',
    boxShadow: '-6px 6px 6px 0px rgba(0, 0, 0, 0.16)',
    backdropFilter: 'blur(32px)',
    borderRadius: '8px',
    paddingTop: '24px !important',
    paddingLeft: '16px !important',
    paddingRight: '16px !important',
    width: '336px !important',
  },
}))

type StyledModalProps = Omit<ModalProps, 'classnames'>

export const StyledModal = (props: StyledModalProps) => {
  const { children, ...restProps } = props
  const { classes } = useStyles()
  return (
    <Modal
      {...restProps}
      classNames={{
        ...classes,
      }}
    >
      {children}
    </Modal>
  )
}
