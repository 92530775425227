import { NSClass } from 'gga-types'

const getNodeById = (nodes, targetID) => {
  for (let i = 0; i < nodes.length; i++) {
    if (nodes[i].id == targetID) return nodes[i]

    const n = getNodeById(nodes[i].children, targetID)
    if (n != null) return n
  }
  return null
}

const getParentPreviousSibling = (node, allNodes) => {
  const parent = getNodeById(allNodes, node.parent_id)
  if (parent == null) {
    return true
  }

  if (parent._sequence > 1) {
    const grandPa = getNodeById(allNodes, parent.parent_id)
    if (grandPa == null) {
      return null
    }
    return grandPa.children[parent._sequence - 1 - 1]
  } //parent is also the first node of its siblings
  else {
    return getParentPreviousSibling(parent, allNodes)
  }
}

const getParentPreviousSiblingFinished = (node, allNodes) => {
  const parent = getNodeById(allNodes, node.parent_id)
  if (parent == null) return true

  if (parent._sequence > 1) {
    const grandPa = getNodeById(allNodes, parent.parent_id)
    if (grandPa == null) return true
    return grandPa.children[parent._sequence - 1 - 1].is_finished
  } //parent is also the first node of its siblings
  else return getParentPreviousSiblingFinished(parent, allNodes)
}

const getParentPreviousSiblingFinishedOmitPDF = (node, allNodes) => {
  const parent = getNodeById(allNodes, node.parent_id)
  if (parent == null) return true

  if (parent._sequence > 1) {
    const grandPa = getNodeById(allNodes, parent.parent_id)
    if (grandPa == null) return true
    const parentPreviousSibling = grandPa.children[parent._sequence - 1 - 1]
    if (parentPreviousSibling.depth === 3) {
      return parentPreviousSibling.children.every(child => {
        return child.is_finished || child.submit_type === 'pdf'
      })
    }
    return parentPreviousSibling.is_finished
  } //parent is also the first node of its siblings
  else return getParentPreviousSiblingFinishedOmitPDF(parent, allNodes)
}

export type Strategy = (nodes: any, allNodes: any) => void

export const noLimitStrategy: Strategy = (nodes, allNodes) => {
  if (nodes.length == 0) return false
  const currentDepth = nodes[0].depth
  if (currentDepth >= 5) return false
  //depth 1~4
  for (let i = 0; i < nodes.length; i++) {
    nodes[i].is_locked = false
    noLimitStrategy(nodes[i].children, allNodes)
  }
}

const lockLevelStrategy: Strategy = (nodes: any, allNodes: any) => {
  if (nodes.length == 0) return false

  const currentDepth = nodes[0].depth

  if (currentDepth >= 5) return false

  //depth 1~4
  for (let i = 0; i < nodes.length; i++) {
    if (i > 0) {
      if (!nodes[i - 1].is_finished || nodes[i - 1].is_locked)
        //previous sibling not finished
        nodes[i].is_locked = true
    } else if (i == 0) {
      //first node in siblings
      if (currentDepth > 1) {
        //check parent's previous sibling
        //depth1 node 1 is always unlocked
        if (!getParentPreviousSiblingFinished(nodes[i], allNodes)) {
          nodes[i].is_locked = true
        }
      }
    }

    lockLevelStrategy(nodes[i].children, allNodes)
  }
}

const lockObjectiveStrategy: Strategy = (nodes: any, allNodes: any) => {
  if (nodes.length == 0) return false

  const currentDepth = nodes[0].depth

  if (currentDepth >= 5) return false

  //depth 1~4
  for (let i = 0; i < nodes.length; i++) {
    if (currentDepth > 2) {
      // 如果当前node是siblings的第一个，那么就不要锁了
      if (nodes[i]._sequence === 1) {
        nodes[i].is_locked = false
      } else {
        if (i > 0) {
          if (!nodes[i - 1].is_finished || nodes[i - 1].is_locked) {
            //previous sibling not finished
            nodes[i].is_locked = true
          }
        } else if (i == 0) {
          //check parent's previous sibling
          if (!getParentPreviousSiblingFinished(nodes[i], allNodes)) {
            nodes[i].is_locked = true
          }
        }
      }
    }

    lockObjectiveStrategy(nodes[i].children, allNodes)
  }
}

export const emtPostStrategy: Strategy = (nodes: any, allNodes: any) => {
  if (nodes.length == 0) return false

  const currentDepth = nodes[0].depth

  if (currentDepth >= 5) return false

  for (let i = 0; i < nodes.length; i++) {
    const currNode = nodes[i]
    const prevNode = nodes[i - 1]
    if (currentDepth > 2) {
      // 如果当前node是siblings的第一个，那么就不要锁了

      if (currNode._sequence === 1) {
        currNode.is_locked = false
      } else {
        if (i > 0) {
          if (
            prevNode.template === 'solo_task' &&
            prevNode.submit_type === 'pdf' &&
            !prevNode.is_locked
          ) {
            currNode.is_locked = false
          } else if (!prevNode.is_locked && prevNode.is_finished) {
            currNode.is_locked = false
          }
        } else if (i == 0) {
          //check parent's previous sibling
          if (!getParentPreviousSiblingFinishedOmitPDF(nodes[i], allNodes)) {
            nodes[i].is_locked = true
          }
        }
      }
    }

    if (currentDepth === 3 && i > 0) {
      const innerLocked = !prevNode.children.every(child => {
        return child.is_finished || child.submit_type === 'pdf'
      })
      currNode.is_locked = innerLocked || prevNode.is_locked
    }

    emtPostStrategy(nodes[i].children, allNodes)
  }
}

export type IStrategies = NSClass.ClassProcessType
export const classLockStrategyMapping: {
  [P in IStrategies]: Strategy
} = {
  'No Limit': noLimitStrategy,
  'Lock Level': lockLevelStrategy,
  'Lock Objective': lockObjectiveStrategy,
  // EMT: emtPostStrategy,
}

export class ClassLockContext {
  private strategy: Strategy
  private postStrategy?: Strategy

  constructor(strategy: Strategy, postStrategy?: Strategy) {
    this.strategy = strategy
    this.postStrategy = postStrategy
  }

  private checkIsFinished(nodes: NSClass.SyllabusItemStruct[]) {
    if (nodes.length == 0) return true

    const currentDepth = nodes[0].depth

    if (currentDepth >= 4)
      return nodes.reduce((prevBool, node, index) => {
        // 给nodes 赋值 正确的sequence， 后端的记录的sequence只能表示顺序前后，目前不能代表顺序的index，待日后更正
        node._sequence = index + 1
        const isFinished =
          (node.template !== 'solo_task' &&
            node.record?.last_trial.status === 'finished') || //finished page/seminar/team_scrim
          (node.template === 'solo_task' &&
            (node.record?.last_trial.status === 'finished' ||
              node.record?.trials.some(t => t.status === 'finished'))) //finished solo (at least in one trial)

        node.is_finished = !!isFinished

        return prevBool && isFinished
      }, true)

    //depth 1~3
    for (let i = 0; i < nodes.length; i++) {
      nodes[i].is_finished = this.checkIsFinished(nodes[i].children)
    }
    return nodes.every(n => n.is_finished)
  }

  public doLock(nodes): void {
    this.checkIsFinished(nodes)

    this.strategy(nodes, nodes)

    if (this.postStrategy) {
      this.postStrategy(nodes, nodes)
    }
  }
}
