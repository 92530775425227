import classNames from 'classnames'
import React, { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { get } from 'lodash'
import { format } from 'date-fns'
import { ScrollArea, TextInput } from '@mantine/core'
import IconPark from '@/components/IconPark'
import { TCouponWithStatus, TPromoWithStatus } from '@/store/checkout-store'
import { currencySymbol } from '@/utils/currency'
import { APP_LOCALE } from '@/constants/define'

interface CouponItemProps extends Pick<CouponSelectorProps, 'onSelect'> {
  selected: boolean
  coupon: TCouponWithStatus | TPromoWithStatus
}

const CouponItem = ({ coupon, onSelect, selected }: CouponItemProps) => {
  const { t, i18n } = useTranslation('cashier')
  const couponTypeKey = useMemo(
    () => (coupon._type === 'coupon' ? 'coupon_type' : 'discount_type'),
    [coupon._type]
  )

  const discountText = useMemo(() => {
    switch (coupon[couponTypeKey]) {
      case 'Cash':
        return coupon.discount
      case 'Discount':
        return APP_LOCALE === 'zh_CN'
          ? ((100 - coupon.discount) / 10).toFixed(1)
          : `${coupon.discount}%`
    }
  }, [coupon, couponTypeKey])

  const discountNode = useMemo(() => {
    if (coupon[couponTypeKey] === 'Cash') {
      return (
        <>
          <span className="text-xs">{currencySymbol}</span>
          <span
            className={classNames(
              'font-fjalla ',
              APP_LOCALE === 'kr' ? 'text-xl' : 'text-3xl'
            )}
          >
            {discountText}
          </span>
        </>
      )
    } else {
      return (
        <>
          <span
            className={classNames(
              'font-fjalla ',
              APP_LOCALE === 'zh_CN' ? 'text-3xl' : 'text-xl'
            )}
          >
            {discountText}
          </span>
          <span className="text-xs">
            {APP_LOCALE === 'zh_CN' ? '折' : 'Off'}
          </span>
        </>
      )
    }
  }, [coupon, couponTypeKey, discountText])

  return (
    <div
      role="button"
      className={classNames(
        'relative flex bg-gradient-to-r py-2.5 text-[#1A100A] to-[#FAEFC8] from-[#DEBE8E] rounded-lg',
        { 'opacity-50': coupon._disabled }
      )}
      onClick={() => {
        if (!coupon._disabled) {
          onSelect(coupon)
        }
      }}
    >
      <div className="shrink-0 px-3 flex items-center justifiy-center border-dashed border-l-0 border-t-0 border-b-0 border-r border-black w-[105px]">
        <div className="flex w-full justify-center items-baseline space-x-0.5">
          {discountNode}
        </div>
      </div>
      <div className="flex flex-col justify-center space-y-0.5 pl-3">
        <span className="text-semibold uppercase">
          {t('coupon_code')}: {coupon.code}
        </span>
        <span className="text-xs flex items-end justify-start space-x-0.5">
          <span>{t('coupon-valid-until')}:</span>
          <span>{format(new Date(coupon.expired_at), 'yyyy-MM-dd')}</span>
        </span>
      </div>
      <div className="shrink-0 flex items-center ml-auto px-3">
        <div className="flex justify-center items-center rounded-full w-4 h-4 border border-black border-solid ring-black">
          {selected && (
            <div className="bg-black rounded-full w-2.5 h-2.5"></div>
          )}
        </div>
      </div>

      <div className="absolute w-3 h-3 rounded-full -top-1.5 left-[99px] bg-white" />
      <div className="absolute w-3 h-3 rounded-full -bottom-1.5 left-[99px] bg-white" />
    </div>
  )
}

function CouponList({
  coupons,
  onSelect,
  selected,
}: Pick<CouponSelectorProps, 'coupons' | 'onSelect' | 'selected'>) {
  return (
    <div className={'space-y-2'}>
      {coupons &&
        coupons.map(coupon => (
          <CouponItem
            coupon={coupon}
            key={coupon.code}
            onSelect={onSelect}
            selected={selected?.code === coupon.code}
          />
        ))}
    </div>
  )
}

function EmptyCouponList() {
  const { t } = useTranslation('buynow')
  return (
    <div className={'text-center'}>
      <IconPark className={'text-[#333] text-5xl m-3'} name={'noresult'} />
      <div className={'text-[#333]'}>{t('no_coupons_found')}</div>
    </div>
  )
}

interface CouponSelectorProps {
  coupons: (TCouponWithStatus | TPromoWithStatus)[] | undefined
  className?: string
  onSelect: (item?: TCouponWithStatus | TPromoWithStatus | undefined) => void
  selected?: TCouponWithStatus | TPromoWithStatus | undefined
  onRedeemPromoCode: (code: string) => Promise<boolean>
}

export default function CouponSelector({
  className,
  coupons,
  selected,
  onSelect,
  onRedeemPromoCode,
}: CouponSelectorProps) {
  const { t } = useTranslation('cashier')
  const [code, setCode] = useState<string>()
  const [error, setError] = useState<string>('')

  const handleReedem = useCallback(async () => {
    if (code) {
      const result = await onRedeemPromoCode(code)
      if (result) {
        setError('')
      } else {
        setError(t('coupon_error_try_again'))
      }
      return
    }
    setError('')
  }, [code, onRedeemPromoCode, t])

  return (
    <section className={classNames('flex flex-col space-y-3', className)}>
      <p className="text-[#333] text-xs flex justify-start items-center space-x-1">
        <IconPark name="exclamation-circle" className="w-5 h-5" />
        <span>已自动为您选择最大优惠折扣券</span>
      </p>

      <ScrollArea type="hover" className="h-60">
        {get(coupons, 'length') ? (
          <CouponList
            selected={selected}
            onSelect={coupon => {
              onSelect(coupon)
            }}
            coupons={coupons}
          />
        ) : (
          <EmptyCouponList />
        )}
      </ScrollArea>

      <div>
        <TextInput
          placeholder="请输入优惠码兑换优惠券"
          rightSection={
            <span
              role="type"
              className="cursor-pointer flex justify-start items-center text-brand-500"
              onClick={handleReedem}
            >
              {t('apply')}
            </span>
          }
          value={code}
          onChange={e => {
            setCode(e.target.value)
          }}
          error={error}
        />
      </div>
    </section>
  )
}
