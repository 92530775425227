import { useCallback, useMemo, useState } from 'react'
import { IResDataPaged } from '@/@types/global'
import { IResponse } from '@/utils/request'

type IUsePaginationOptions = {
  initialPage: number
  initialPageSize: number
  appending?: boolean
}

const DEFAULT_OPTIONS = {
  initialPage: 1,
  initialPageSize: 20,
  appending: false,
}

export default function usePagination<T>(
  action: (
    page: number,
    pageSize: number
  ) => Promise<IResponse<IResDataPaged<T>>>,
  opt?: Partial<IUsePaginationOptions>
) {
  const options = {
    ...DEFAULT_OPTIONS,
    ...opt,
  }

  const [page, setPage] = useState(options.initialPage)
  const [pageSize, setPageSize] = useState(options.initialPageSize)
  const [total, setTotal] = useState(0)
  const [data, setData] = useState<T[]>([])
  const [loading, setLoading] = useState(false)

  const [nextPage, setNextPage] = useState<number>()

  const hasNextPage = useMemo(() => {
    return nextPage && nextPage > page
  }, [nextPage, page])

  const fetcher = useCallback(async () => {
    setLoading(true)
    const res = await action(page, pageSize)
    if (res && res.data && res.data.data.items) {
      if (options.appending) {
        setData(prev => {
          return [...prev, ...res.data!.data.items]
        })
      } else {
        setData(res.data.data.items)
      }
      setNextPage(res.data.data.next_page)
      setTotal(res.data.data.total)
    }
    setLoading(false)
  }, [action, options.appending, page, pageSize])

  const clearList = useCallback(() => {
    setData([])
  }, [])

  const unShiftNew = useCallback(
    (newRec: T) => {
      if (options.appending) {
        setData(prev => {
          return [newRec, ...prev]
        })
      }
    },
    [options.appending]
  )

  const insertItemAt = useCallback(
    (item: T, index: number) => {
      if (options.appending) {
        setData(prev => {
          return [...prev.slice(0, index), item, ...prev.slice(index)]
        })
      }
    },
    [options.appending]
  )

  const replaceItemAt = useCallback(
    (item: T, index: number) => {
      if (options.appending) {
        setData(prev => {
          return [...prev.slice(0, index), item, ...prev.slice(index + 1)]
        })
      }
    },
    [options.appending]
  )

  return {
    fetcher,
    setPage,
    setPageSize,
    data,
    total,
    page,
    pageSize,
    clearList,
    loading,
    hasNextPage,
    unShiftNew,
    insertItemAt,
    replaceItemAt,
  }
}
