import React from 'react'

import IconPark from '@/components/IconPark'
import { useAuth } from '@/containers/auth'
import CustomerServiceQR from '../CustomerServiceQR'
import { StyledModal } from '../../../lib/ui/modals/styled-modal'

interface ConstultantWechatModalProps {
  open: boolean
  setOpen: (open: boolean) => void
}

export const ConsultantWechatModal: React.FC<ConstultantWechatModalProps> = ({
  open,
  setOpen,
}) => {
  const { isAuthenticated, setOpenAuthModal } = useAuth()

  const handleClick = () => {
    setOpen(false)
    setOpenAuthModal(true)
  }
  return (
    <StyledModal
      opened={open}
      onClose={() => setOpen(false)}
      lockScroll={false}
    >
      <div className="text-center">
        <div className="text-white text-2xl font-medium">添加顾问微信</div>
        <div className="text-[#FAFAFA] mt-6">
          请使用<span className="text-brand">微信</span>联系您的专属顾问
        </div>
        <div className="mt-4 flex items-center justify-center">
          <CustomerServiceQR dimension={196} />
        </div>

        {!isAuthenticated && (
          <div
            onClick={handleClick}
            className="mt-[30px] flex flex-row text-brand gap-2 items-center justify-center cursor-pointer"
          >
            <IconPark
              name={'customer-service'}
              className="text-lg fill-brand"
            />
            <span className="text-lg underline underline-offset-4">
              客服主动联系我
            </span>
          </div>
        )}
      </div>
    </StyledModal>
  )
}
