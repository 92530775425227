import React, {
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useState,
} from 'react'
import { useCountDown } from 'ahooks'
import { isBefore, addMinutes } from 'date-fns'
import { NSClass } from 'gga-types'
import { useCoursewareModal } from '@/hooks/myPages/CoursewareModalProvider'
import { IPostCheckQuizResultRet, postCheckQuizResult } from '@/services/quiz'
import {
  QuizAnswerView,
  QuizPendingView,
  QuizQustionView,
  QuizResultView,
} from '@/components/CoursewareModal/QuizModalContent/scatter'
import { submitWorkApiV2 } from '@/services/class'

type IQuizState = {
  viewState:
    | 'pending'
    | 'progress'
    | 'checking'
    | 'result_success'
    | 'result_fail'
    | 'view_answer'
  total: number
  current: number
  currentA: number
}

type IQuizAction = {
  type:
    | 'start_quiz'
    | 'prev_q'
    | 'next_q'
    | 'check_result'
    | 'failed'
    | 'success'
    | 'restart'
    | 'review'
    | 'prev_a'
    | 'next_a'
    | 'set_answer'
  payload?: any
}

const genInitState = (initState: Partial<IQuizState>): IQuizState => {
  return {
    viewState: 'pending',
    total: 0,
    current: 0,
    currentA: 0,
    ...initState,
  }
}

function quizReducer(state: IQuizState, action: IQuizAction): IQuizState {
  const next = state.current + 1
  const prev = state.current - 1

  const nextA = state.currentA + 1
  const prevA = state.currentA - 1
  switch (action.type) {
    case 'start_quiz': {
      return {
        ...state,
        viewState: 'progress',
        current: 0,
      }
    }
    case 'next_q': {
      return {
        ...state,
        viewState: 'progress',
        current: next > state.total ? state.total : next,
      }
    }
    case 'prev_q': {
      return {
        ...state,
        viewState: 'progress',
        current: prev < 0 ? 0 : prev,
      }
    }
    case 'check_result': {
      return {
        ...state,
        viewState: 'checking',
      }
    }
    case 'failed': {
      return {
        ...state,
        viewState: 'result_fail',
      }
    }
    case 'success': {
      return {
        ...state,
        viewState: 'result_success',
      }
    }
    case 'restart': {
      return {
        ...state,
        viewState: 'pending',
        current: 0,
      }
    }
    case 'review': {
      return {
        ...state,
        viewState: 'view_answer',
        currentA: 0,
      }
    }
    case 'prev_a': {
      return {
        ...state,
        viewState: 'view_answer',
        currentA: prevA < 0 ? 0 : prevA,
      }
    }
    case 'next_a': {
      return {
        ...state,
        viewState: 'view_answer',
        currentA: nextA > state.total ? state.total : nextA,
      }
    }
    default:
      throw new Error('No such action')
  }
}

export default function QuizModalContent() {
  const { courseware, info, classId, refreshModalData, closeTaskModal } =
    useCoursewareModal()

  const quiz = useMemo(() => courseware as NSClass.QuizItem, [courseware])

  const [targetDate, setTargetDate] = useState<number>()
  const [countdown] = useCountDown({ targetDate })
  const [myAnswer, setMyAnswer] = useState<NSClass.QuizAnswer>({})

  const [rankResult, setRankResult] = useState<IPostCheckQuizResultRet>()
  const [state, dispatch] = useReducer(
    quizReducer,
    { total: quiz.exam.length, current: 0 },
    genInitState
  )

  const previousPassed = useMemo(() => {
    return quiz.record && quiz.record.trials.some(t => t.status === 'finished')
  }, [quiz.record])

  const lastSubmitTime = useMemo(() => {
    if (quiz.record && quiz.record.trials) {
      const maxSubmitTime = Math.max.apply(
        null,
        quiz.record.trials.map(t => +new Date(t.submitted_at))
      )
      return maxSubmitTime
    }
    return undefined
  }, [quiz.record])

  const canStartQuiz = useMemo(() => {
    return (
      Boolean(!quiz.record) || // 没做过quiz
      Boolean(
        quiz.record &&
          quiz.record.trials &&
          quiz.record.trials.every(t => t.trial_sequence < 2)
      ) || // 做过但是只做了一次
      Boolean(
        quiz.record.trials &&
          quiz.record.trials.some(t => t.status === 'finished')
      ) || // 做过切pass过了
      Boolean(
        lastSubmitTime &&
          isBefore(addMinutes(new Date(lastSubmitTime), 10), new Date())
      ) // 做过,最后一次是10分钟前
    )
  }, [lastSubmitTime, quiz.record])

  const checkResult = useCallback(async () => {
    const res = await postCheckQuizResult(courseware.id, myAnswer)
    if (res && res.data?.data) {
      const data = res.data.data
      setRankResult(data)
      const isPassed = data.passed
      if (isPassed) {
        dispatch({
          type: 'success',
        })
      } else {
        dispatch({
          type: 'failed',
        })
      }

      if (!previousPassed) {
        if (isPassed) {
          await submitWorkApiV2(classId, info.id, {
            status: 'finished',
            score: Math.round(data.rank),
          })
        } else {
          await submitWorkApiV2(classId, info.id, {
            status: 'failed',
            score: Math.round(data.rank),
          })
        }
      }
    }
  }, [classId, courseware.id, info.id, myAnswer, previousPassed])

  const handleRetry = useCallback(() => {
    setMyAnswer({})
    dispatch({
      type: 'restart',
    })
  }, [setMyAnswer])

  const handleClose = useCallback(() => {
    closeTaskModal()
  }, [closeTaskModal])

  const stateEffect = useCallback(async () => {
    switch (state.viewState) {
      case 'pending': {
        await refreshModalData()
        break
      }
      case 'checking': {
        checkResult()
        break
      }
      case 'result_fail':
      case 'result_success': {
        await refreshModalData()
        break
      }
    }
  }, [checkResult, refreshModalData, state.viewState])

  useEffect(() => {
    if (countdown === 0 && state.viewState === 'progress') {
      // send check result request
      dispatch({
        type: 'check_result',
      })
    }
  }, [checkResult, countdown, state.viewState])

  useEffect(() => {
    stateEffect()
  }, [stateEffect])

  const renderContent = useMemo(() => {
    switch (state.viewState) {
      case 'pending':
        return (
          <QuizPendingView
            canStart={canStartQuiz}
            lastTrialTime={
              lastSubmitTime
                ? new Date(lastSubmitTime).toISOString()
                : undefined
            }
            quiz={quiz}
            onStart={() => {
              if (canStartQuiz) {
                setTargetDate(Date.now() + quiz.time_limit * 1000)
                setTimeout(() => {
                  dispatch({
                    type: 'start_quiz',
                  })
                })
              }
            }}
          />
        )
      case 'progress':
        return (
          <QuizQustionView
            quiz={quiz}
            userAnswer={myAnswer}
            timeLeft={countdown}
            onPrev={() => {
              dispatch({
                type: 'prev_q',
              })
            }}
            onNext={() => {
              dispatch({
                type: 'next_q',
              })
            }}
            onFinish={() => {
              dispatch({
                type: 'check_result',
              })
            }}
            current={state.current}
            onClick={(qid, val) => {
              setMyAnswer(prev => {
                return {
                  ...prev,
                  [qid]: val,
                }
              })
            }}
          />
        )
      case 'view_answer': {
        return (
          <QuizAnswerView
            quiz={quiz}
            current={state.currentA}
            userAnswer={myAnswer}
            officialAnswer={rankResult?.answer || {}}
            onPrev={() => {
              dispatch({
                type: 'prev_a',
              })
            }}
            onNext={() => {
              dispatch({
                type: 'next_a',
              })
            }}
            onBack={() => {
              dispatch({
                type: 'success',
              })
            }}
          />
        )
      }
      case 'result_success':
        return (
          <div className="relative overflow-hidden flex-1">
            <QuizResultView
              className="relative bg-white bg-opacity-90 backdrop-filter z-10 w-full h-full"
              rank={rankResult!.rank}
              isSuccess
              onRetry={handleRetry}
              onClose={handleClose}
              onViewAnswer={() => {
                dispatch({
                  type: 'review',
                })
              }}
            />
            <QuizQustionView
              className="absolute top-0 left-0 w-full h-full"
              quiz={quiz}
              userAnswer={myAnswer}
              timeLeft={0}
              current={state.current}
            />
          </div>
        )
      case 'result_fail':
        return (
          <div className="relative overflow-hidden">
            <QuizResultView
              className="relative bg-white bg-opacity-90 backdrop-filter z-10"
              rank={rankResult!.rank}
              isSuccess={false}
              onRetry={handleRetry}
              onClose={handleClose}
            />
            <QuizQustionView
              className="absolute top-0 left-0 w-full h-full"
              quiz={quiz}
              userAnswer={myAnswer}
              timeLeft={0}
              current={state.current}
            />
          </div>
        )
    }
  }, [
    canStartQuiz,
    countdown,
    handleClose,
    handleRetry,
    lastSubmitTime,
    myAnswer,
    quiz,
    rankResult,
    state,
  ])

  return (
    <div
      className={
        "bg-repeat overflow-auto bg-[url('https://res.gengesports.cn/user-web/images/bg-faq.png')]"
      }
    >
      <div className={'max-w-screen-xl mx-auto bg-white bg-opacity-60 p-2'}>
        <div className={'h-full w-full flex items-center justify-center'}>
          {renderContent}
        </div>
      </div>
    </div>
  )
}
