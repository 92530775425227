import React from 'react'
import { withServerSideProps } from '@/containers/withServerSideProps'
import { ProvideCourseCenterContext } from '@/pages/course-center/container/CourseCenterContext'
import { APP_LOCALE } from '@/constants/define'
import { courseCenterPage } from '@/services/get-serverside-props/course-center'
import CourseCenterRoot from './components/CourseCenterRoot'

export default function CourseCenterPage() {
  return (
    <ProvideCourseCenterContext>
      <CourseCenterRoot />
    </ProvideCourseCenterContext>
  )
}

const getServerSideProps = withServerSideProps(
  courseCenterPage,
  APP_LOCALE === 'zh_CN' ? ['footerLink'] : undefined
)

export { getServerSideProps }
