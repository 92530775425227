import { yupResolver } from '@hookform/resolvers/yup'
import {
  Anchor,
  Button,
  Checkbox,
  PasswordInput,
  Select,
  Stack,
  TextInput,
} from '@mantine/core'
import React, { useCallback, useMemo, useState } from 'react'
import { get } from 'lodash'
import { useForm, Controller } from 'react-hook-form'
import { useTranslation } from 'next-i18next'
import Yup from '@/utils/yup'
import PhoneNumberInput from '@/components/inputs/PhoneNumberInput'
import { SmsVerification } from '@/components/FormComponents/SmsVerification'
import { SmsTypeEnum } from '@/services'
import { useStore } from '@/context/store'
import { useAuth } from '@/containers/auth'
import { useStyles } from './utils'

type RegisterFormValue = {
  phone: {
    phone_code: string
    phone_number: string
  }
  name: string
  sms_verify_code: string
  password: string
  interested_program: string
}

const schema = Yup.object().shape({
  name: Yup.string().required(),
  password: Yup.string().required().min(6),
  sms_verify_code: Yup.string().required(),
  phone: Yup.object({
    phone_code: Yup.string().required(),
    phone_number: Yup.string()
      .required()
      .omitStartZero()
      .phoneNumber('phone_code')
      .availablePhone('phone_code'),
  }),
  interested_program: Yup.string().required(),
})

interface RegisterFormProps {
  onLogged?: () => void
  onLogin: () => void
}

export const RegisterForm: React.FC<RegisterFormProps> = ({
  onLogin,
  onLogged,
}) => {
  const [aggreed, setAggreed] = useState(false)
  const { t } = useTranslation()
  const { classes } = useStyles()
  const {
    gameCategoryState: { gameCategories },
  } = useStore()
  const { registerUser } = useAuth()
  const { register, handleSubmit, formState, watch, control } =
    useForm<RegisterFormValue>({
      mode: 'all',
      reValidateMode: 'onChange',
      defaultValues: {
        phone: {
          phone_code: '86',
          phone_number: '',
        },
        password: '',
        sms_verify_code: '',
        name: '',
        interested_program: '',
      },
      resolver: yupResolver(schema),
    })
  const { errors, isSubmitting } = formState

  const { phone_code: watchPhoneCode, phone_number: watchPhoneNumber } =
    watch('phone')

  const isValidPhoneNumber = useMemo(() => {
    return watchPhoneNumber && !errors?.phone?.phone_number?.message
  }, [errors?.phone?.phone_number?.message, watchPhoneNumber])

  const onSubmit = useCallback(
    async (value: RegisterFormValue) => {
      const { phone, ...restData } = value
      const response = await registerUser({
        ...restData,
        ...phone,
      })

      if (response) {
        onLogged?.()
      }
    },
    [onLogged, registerUser]
  )

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="text-center text-[#FAFAFA] text-lg mb-4">
        Gen.G电竞人才培养平台-注册
      </div>

      <Stack spacing="lg">
        <Controller
          name="name"
          control={control}
          render={({ field }) => {
            return (
              <TextInput
                autoComplete="off"
                classNames={{ label: classes.label }}
                label="姓名"
                placeholder="姓名"
                {...field}
                error={get(errors, 'name.message')}
                maxLength={32}
              />
            )
          }}
        />

        <Controller
          name="phone"
          control={control}
          render={({ field }) => {
            return (
              <PhoneNumberInput
                classes={{ label: classes.label }}
                label={t('phone_number')}
                placeholder={t('phone_number')}
                inputProps={{
                  maxLength: 36,
                  error: get(errors, 'phone.phone_number.message'),
                }}
                {...field}
              />
            )
          }}
        />

        <Controller
          name="sms_verify_code"
          control={control}
          render={() => {
            return (
              <SmsVerification
                classes={{ label: classes.label }}
                mode={'imageCaptcha'}
                phoneCode={watchPhoneCode}
                phoneNumber={watchPhoneNumber}
                id="sms_verify_code"
                maxLength={6}
                registerFn={() => register('sms_verify_code')}
                type={SmsTypeEnum.Register}
                disabled={!isValidPhoneNumber}
                invalid={get(errors, 'sms_verify_code.message')}
                label={t('send_verification_code_via_text')}
              />
            )
          }}
        />

        <Controller
          name="password"
          control={control}
          render={({ field }) => {
            return (
              <PasswordInput
                classNames={{ label: classes.label }}
                placeholder={t('password')}
                label={t('password')}
                {...field}
                error={get(errors, 'password.message')}
              />
            )
          }}
        />

        <Controller
          name="interested_program"
          control={control}
          render={({ field }) => {
            return (
              <Select
                classNames={{ label: classes.label }}
                label="感兴趣的游戏"
                placeholder="感兴趣的游戏"
                data={(gameCategories || [])
                  ?.filter(g => !g.hidden)
                  .map(g => ({
                    label: t(g.display_code ?? g.code),
                    value: g.code,
                  }))}
                {...field}
                error={get(errors, 'interested_program.message')}
              />
            )
          }}
        />

        <Checkbox
          sx={{
            label: {
              color: '#FAFAFA',
              opacity: 0.7,
              fontSize: '14px',
              lineHeight: '18px',
            },
          }}
          size="xs"
          label={t('register_form.tips')}
          checked={aggreed}
          onChange={() => setAggreed(!aggreed)}
        />

        <p className="text-center">
          <Button
            type="submit"
            color="brand"
            disabled={!aggreed}
            loading={isSubmitting}
          >
            注册并登录
          </Button>
        </p>

        <p className="text-center">
          <Anchor onClick={onLogin} underline className="underline-offset-8">
            已有账号
          </Anchor>
        </p>
      </Stack>
    </form>
  )
}
