import { useCallback, useState } from 'react'
import { isEmpty, get } from 'lodash'
import { request } from '@/utils/request'
import { ICmsConfig, ICmsConfigKeyEnum } from '@/@types/global'

export type IHomepageState = ReturnType<typeof useHomepageState>

export default function useHomepageState() {
  const [config, setConfig] = useState<ICmsConfig>()

  const fetchConfig = useCallback(async () => {
    const ret = await request({
      url: `/v2/config`,
    })

    if (ret && ret.data && ret.data.data) {
      const parseToObj = ret.data.data.reduce((res, cur) => {
        try {
          res[cur.name] = JSON.parse(cur.value)
          return res
        } catch (e) {
          res[cur.name] = cur.value
          return res
        }
      }, {})
      // setConfigCache(JSON.stringify(parseToObj));
      setConfig(parseToObj)
    }
  }, [])

  const getConfig = useCallback(
    (key: ICmsConfigKeyEnum) => {
      const conf = get(config, key, null)
      return isEmpty(conf) ? null : conf
    },
    [config]
  )

  return {
    config,
    fetchConfig,
    getConfig,
  }
}
