import classNames from 'classnames'
import { isFunction } from '@/utils/is'

type IFilterSelectorOptionProps<T, P> = {
  label: string
  value: T | undefined
  origin?: P
}

type ActiveFunction<T, P> = (opt: IFilterSelectorOptionProps<T, P>) => boolean

type IFilterSelectorProps<T, P> = {
  options: IFilterSelectorOptionProps<T, P>[]
  category: string
  onChange: (value: T | undefined) => void
  active: T | ActiveFunction<T, P>
  size?: 'normal' | 'small'
}

export default function FilterSelector<T, P = any>({
  options,
  category,
  onChange,
  active,
  size = 'normal',
}: IFilterSelectorProps<T, P>) {
  return (
    <div
      className={classNames('overflow-hidden text-white flex hide-scroll', {
        'text-base': size === 'normal',
        'text-small': size === 'small',
      })}
    >
      <div className={classNames('hidden md:flex shrink-0 mr-2 w-20')}>
        <span className={'py-1.5 cursor-default'}>{category}:</span>
      </div>
      <ul className={'overflow-auto flex md:flex-wrap items-start hide-scroll'}>
        {options.map(opt => {
          return (
            <li
              className={classNames(
                'cursor-pointer shrink-0 px-2.5 py-1 mr-3 mb-1 rounded hover:text-gray-300 hover:bg-gray-700/30',
                (isFunction(active)
                  ? (active as ActiveFunction<T, P>)(opt)
                  : active === opt.value) && '!text-gold bg-gold/10'
              )}
              key={opt.label}
              onClick={() => {
                onChange(opt.value)
              }}
            >
              {opt.label}
            </li>
          )
        })}
      </ul>
    </div>
  )
}
