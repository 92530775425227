import { ApiDomain, DotNetDomain } from '@/config/config'
import { isNoAuthRoute } from '@/constants/define'
import { isBrowser } from '@/services/Utils'
import { Jwt } from '@/services/jwt'
import { isDev, isMiniapp } from '@/utils/is'
import axios, { AxiosRequestConfig } from 'axios'
import * as https from 'https'
import cloneDeep from 'lodash.clonedeep'
import get from 'lodash/get'
import { GetServerSidePropsContext } from 'next'
import { i18n } from 'next-i18next'
import router from 'next/router'
import { toast } from 'react-toastify'
import { v4 as uuidv4 } from 'uuid'
import { getErrorMsgByErrorCode } from './errorCodes'

export interface RequestOptions extends AxiosRequestConfig {
  url: string
  context?: GetServerSidePropsContext
  skipError?: boolean
}

const V1_REGEX = /^\/(api|uv1|pv1)/
const V2_REGEX = /^\/(v2)/

function pathInterceptor(config) {
  //正则表达式含义： 排除后缀为.html | 排除前缀为http: https:
  if (
    !/.+(?=\.html$)/.test(config.url) &&
    !/^(?=(http:|https:)).*/.test(config.url)
  ) {
    if (V1_REGEX.test(config.url)) {
      config.url = ApiDomain + config.url.replace(/^\/(api)/, '/uv1')
    }
    if (V2_REGEX.test(config.url)) {
      config.url = DotNetDomain + config.url
    }
  }

  return config
}

const instance = axios.create({
  httpsAgent: new https.Agent({
    rejectUnauthorized: !isDev,
  }),
})

instance.interceptors.request.use(
  config => {
    return pathInterceptor(config)
  },
  function (error) {
    return Promise.reject(error)
  }
)

function fetcher(options: RequestOptions) {
  const { method = 'get', data, url, headers, context } = options
  const requestId = uuidv4().split('-')
  const assignHeaders = {
    Authorization: `Bearer ${Jwt.getToken(context)}`,
    'X-Request-Id': requestId[requestId.length - 1],
    ...headers,
  }

  const cloneData = cloneDeep(data)

  switch (method.toLowerCase()) {
    case 'get':
      return instance.get(url, {
        params: cloneData,
        headers: assignHeaders,
      })
    case 'blob':
      return instance.get(url, {
        params: cloneData,
        responseType: 'blob',
        headers: assignHeaders,
      })
    case 'delete':
      return instance.delete(url, {
        data: cloneData,
        headers: assignHeaders,
      })
    case 'post':
      return instance.post(url, cloneData, { headers: assignHeaders })
    case 'put':
      return instance.put(url, cloneData, { headers: assignHeaders })
    case 'upload':
      return instance.post(url, data, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
    case 'patch':
      return instance.patch(url, cloneData, { headers: assignHeaders })
    case 'head':
      return instance.head(url, cloneData)
    default:
      return instance(options)
  }
}

export type IResponse<T> = {
  success: boolean
  message: string
  statusCode: number
  data?: T
}

export async function request<R = any>(
  _options: RequestOptions
): Promise<IResponse<R>> {
  const options = { ..._options, skipError: false }
  try {
    const response = await fetcher(options)
    const { statusText, status } = response
    let data = response.data
    if (options.method === 'blob') {
      return Promise.resolve({
        ...response,
        success: true,
        message: statusText,
        statusCode: status,
        data,
      })
    }

    if (Array.isArray(data)) {
      data = {
        list: data,
      }
    }
    return {
      success: true,
      message: statusText,
      statusCode: status,
      data,
    }
  } catch (error: any) {
    const errorResponse = error.response ?? null
    let statusCode = 600
    let msg = getErrorMsgByErrorCode(statusCode)

    // 未登录，重定向
    if (errorResponse && errorResponse.status == 401) {
      Jwt.clear()

      if (isBrowser && !isNoAuthRoute() && !isMiniapp && !options.skipError) {
        toast.error(i18n?.t('server_response.session_expired') as string)
        await router.replace({
          pathname: '/login',
          query: {
            redirect_url: encodeURIComponent(router.asPath),
          },
        })
      }
      return Promise.resolve({ success: false, statusCode, message: msg })
    }

    if (errorResponse && errorResponse.status === 429) {
      return Promise.resolve({
        success: false,
        statusCode: 429,
        message: get(errorResponse, ['data', 'message']),
      })
    }
    if (errorResponse && errorResponse instanceof Object) {
      const { data: data_1 = {}, statusText: statusText_1 } = errorResponse
      statusCode = errorResponse.status

      // 兼容.net的api
      const code = data_1?.data?.code || data_1?.code
      const message = data_1?.data?.message || data_1?.message
      msg = getErrorMsgByErrorCode(code ?? -1) || message || statusText_1
    } else {
      statusCode = 600
      msg = getErrorMsgByErrorCode(statusCode)
    }

    if (
      (errorResponse && errorResponse.status === 400) ||
      (isBrowser && router && router.pathname === '/')
    ) {
      return Promise.resolve({
        success: false,
        statusCode: get(errorResponse, ['data', 'code'], 400),
        message: msg,
      })
    }
    if (!options.skipError) {
      // handle unknown error in one place
      toast.error(msg)
    }

    console.error('[Request] Request Error', msg, error)
    throw new Error(
      JSON.stringify({ success: false, statusCode, message: msg })
    )
  }
}

export const GGAAxios = instance
