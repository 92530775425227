import React, { memo, useCallback, useMemo, useState } from 'react'
import { Trans, useTranslation } from 'next-i18next'
import { get } from 'lodash'

import {
  Button,
  Checkbox,
  Input,
  PasswordInput,
  Select,
  Stack,
  TextInput,
  Textarea,
  MultiSelect,
} from '@mantine/core'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { SmsTypeEnum } from '@/services'
import { useAuth } from '@/containers/auth'
import { SmsVerification } from '@/components/FormComponents/SmsVerification'
import PhoneNumberInput from '@/components/inputs/PhoneNumberInput'
import Yup from '@/utils/yup'
import { APP_LOCALE } from '@/constants/define'
import { getLearningPurposeOptions } from '@/utils/user'

const schema = Yup.object().shape({
  learning_purpose: Yup.array().min(1),
  interested_program: Yup.string().required(),
  name: Yup.string().required(),
  password: Yup.string().required().min(6),
  sms_verify_code: Yup.string().required(),
  phone: Yup.object({
    phone_code: Yup.string().required(),
    phone_number: Yup.string()
      .required()
      .phoneNumber('phone_code')
      .availablePhone('phone_code'),
  }),
  inquiry: Yup.string(),
  ...(APP_LOCALE === 'en_US'
    ? {
        discord_id: Yup.string().required(),
      }
    : {}),
})

export const RegisterFormKR = memo(
  ({ onLogin, onSuccess }: { onLogin: () => void; onSuccess: () => void }) => {
    const { t } = useTranslation()
    const GAME_CATEGORIES = useMemo(
      () => [
        {
          value: 'lol',
          label: t('lol'),
        },
        {
          value: 'ow',
          label: t('ow'),
        },
        {
          value: 'valorant',
          label: t('valorant'),
        },
        {
          value: 'pubg',
          label: t('pubg'),
        },
        {
          value: 'other',
          label: t('other'),
        },
      ],
      [t]
    )

    const {
      formState: { errors, isSubmitting, isValid },
      register,
      handleSubmit,
      control,
      watch,
    } = useForm({
      mode: 'all',
      reValidateMode: 'onChange',
      defaultValues: {
        inquiry: '',
        name: '',
        interested_program: '',
        learning_purpose: [],
        phone: {
          phone_code:
            APP_LOCALE === 'en_US' ? '1' : APP_LOCALE === 'kr' ? '82' : '86',
          phone_number: '',
        },
        password: '',
        sms_verify_code: '',
        ...(APP_LOCALE === 'en_US'
          ? {
              discord_id: '',
            }
          : {}),
      },
      resolver: yupResolver(schema),
    })

    const { registerUser } = useAuth()

    const { phone_code: watchPhoneCode, phone_number: watchPhoneNumber } =
      watch('phone')

    const [agreed, setAgreed] = useState(false)
    const [termsAgreed, setTermsAgreed] = useState(false)
    const [smsAgreed, setSmsAgreed] = useState(false)

    const goToLogin = useCallback(
      e => {
        e.preventDefault()
        onLogin()
      },
      [onLogin]
    )

    const batchRequest = useCallback(
      async data => {
        const { phone, ...restData } = data

        const res = await registerUser({
          ...restData,
          phone_code: phone.phone_code,
          phone_number: phone.phone_number,
          learning_purpose: restData.learning_purpose.join(','),
        })
        if (res) {
          onSuccess()
        }
      },
      [onSuccess, registerUser]
    )

    return (
      <div className="mx-auto">
        <h3 className="text-lg text-center mb-2">
          {t('register_form.heading')}
        </h3>
        <form onSubmit={handleSubmit(batchRequest)}>
          <Stack spacing="lg" className="mb-4">
            <Controller
              name="phone"
              control={control}
              render={({ field }) => {
                const { value, onChange } = field
                return (
                  <PhoneNumberInput
                    label={t('phone_number')}
                    placeholder={t('phone_number')}
                    inputProps={{
                      maxLength: 36,
                      withAsterisk: true,
                      error: get(errors, 'phone.phone_number.message'),
                    }}
                    value={value}
                    onChange={onChange}
                  />
                )
              }}
            />

            <Controller
              name="name"
              control={control}
              render={({ field }) => {
                return (
                  <TextInput
                    withAsterisk
                    label={t('name')}
                    autoComplete="off"
                    placeholder={t('name')}
                    error={get(errors, 'name.message')}
                    maxLength={32}
                    {...field}
                  />
                )
              }}
            />

            <Controller
              name="learning_purpose"
              control={control}
              render={({ field }) => {
                return (
                  <MultiSelect
                    withAsterisk
                    label={t('learning_purpose')}
                    data={[...getLearningPurposeOptions(t)]}
                    {...field}
                    error={get(errors, 'learning_purpose.message')}
                  />
                )
              }}
            />

            <Controller
              name="interested_program"
              control={control}
              render={({ field }) => {
                return (
                  <Select
                    withAsterisk
                    label={t('interested_program')}
                    data={[...GAME_CATEGORIES]}
                    {...field}
                    error={get(errors, 'interested_program.message')}
                  />
                )
              }}
            />

            {APP_LOCALE === 'en_US' && (
              <Controller
                control={control}
                render={({ field }) => {
                  return (
                    <TextInput
                      {...field}
                      label={'Discord ID'}
                      maxLength={64}
                      withAsterisk
                      error={get(errors, 'discord_id.message')}
                    />
                  )
                }}
                name={'discord_id'}
              />
            )}

            <Controller
              name="password"
              control={control}
              render={({ field }) => {
                return (
                  <PasswordInput
                    placeholder={t('password')}
                    label={<Trans i18nKey="password" />}
                    withAsterisk
                    {...field}
                    error={get(errors, 'password.message')}
                  />
                )
              }}
            />

            <Input.Wrapper
              withAsterisk
              label={t('send_verification_code_via_text')}
              error={get(errors, 'sms_verify_code.message')}
            >
              <SmsVerification
                mode={'recaptcha'}
                phoneCode={watchPhoneCode}
                phoneNumber={watchPhoneNumber}
                id="sms_verify_code"
                maxLength={6}
                registerFn={() => register('sms_verify_code')}
                type={SmsTypeEnum.Register}
                disabled={
                  !watchPhoneNumber || get(errors, 'phone.phone_number.message')
                }
              />
            </Input.Wrapper>

            <Controller
              name="inquiry"
              control={control}
              render={({ field }) => {
                return (
                  <Textarea
                    label={t('inquiry')}
                    placeholder={t('inquiry.placeholder')}
                    error={get(errors, 'inquiry.message')}
                    {...field}
                  />
                )
              }}
            />

            <Checkbox
              size="xs"
              label={
                <div className="text-gray-400 text-xs">
                  <Trans i18nKey="register_form.tips" />
                </div>
              }
              checked={agreed}
              onChange={() => setAgreed(!agreed)}
            />

            {APP_LOCALE === 'en_US' && (
              <>
                <Checkbox
                  size="xs"
                  label={
                    <div className="text-gray-400 text-xs">
                      * I have read and agreed to the terms of service and
                      privacy agreement.
                    </div>
                  }
                  checked={termsAgreed}
                  onChange={() => setTermsAgreed(!termsAgreed)}
                />
                <Checkbox
                  size="xs"
                  label={
                    <div className="text-gray-400 text-xs">
                      * I agree to receive text messages.
                    </div>
                  }
                  checked={smsAgreed}
                  onChange={() => setSmsAgreed(!smsAgreed)}
                />
              </>
            )}
          </Stack>
          <Stack spacing={'xs'}>
            <Button
              type="submit"
              disabled={
                !agreed ||
                (APP_LOCALE === 'en_US' && (!smsAgreed || !termsAgreed))
              }
              loading={isSubmitting}
            >
              <span className={'uppercase'}>{t('register')}</span>
            </Button>
            <Button variant="subtle" fullWidth onClick={goToLogin}>
              <span className="uppercase">{t('back-to-login')}</span>
            </Button>
          </Stack>
        </form>
      </div>
    )
  }
)

RegisterFormKR.displayName = 'RegisterFormKR'
