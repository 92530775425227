import { useRequest } from 'ahooks'
import React, { memo, useCallback } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { Trans, useTranslation } from 'next-i18next'
import { Button, Space, Stack } from '@mantine/core'
import { Field, Input } from 'stripe-ui'
import { get } from 'lodash'
import { replacePhoneNumberByPhoneCode } from '@/services/Utils'
import { login } from '@/services/user'
import { removeAllWS } from '@/utils/string'
import PhoneNumberInput from '@/components/inputs/PhoneNumberInput'
import { APP_LOCALE } from '@/constants/define'
import { TLoginFormProps } from './type'

export const LoginFormOld = memo(
  ({ onForget, onRegister, onSuccess, onLoginBySms }: TLoginFormProps) => {
    const { t, i18n } = useTranslation()
    const {
      register,
      handleSubmit,
      control,
      formState: { errors },
    } = useForm({
      mode: 'all',
      defaultValues: {
        phone: {
          phone_code:
            APP_LOCALE === 'en_US' ? '1' : APP_LOCALE === 'kr' ? '82' : '86',
          phone_number: '',
        },
        password: '',
      },
    })
    const { runAsync, loading } = useRequest(login, { manual: true })
    const goToForget = useCallback(
      e => {
        e.preventDefault()
        onForget && onForget()
      },
      [onForget]
    )

    const goToRegister = useCallback(
      e => {
        e.preventDefault()
        onRegister && onRegister()
      },
      [onRegister]
    )

    const loginHandler = useCallback(
      values => {
        const postData = {
          ...values,
          ...{
            phone_number: replacePhoneNumberByPhoneCode(
              values.phone.phone_code,
              values.phone.phone_number
            ),
            phone_code: values.phone.phone_code,
          },
        }

        runAsync(postData).then(success => {
          if (success) {
            onSuccess && onSuccess()
          }
        })
      },
      [onSuccess, runAsync]
    )

    return (
      <>
        <h4 className="text-xl font-bold text-center">
          {t('login_by_password')}
        </h4>
        <form onSubmit={handleSubmit(loginHandler)} className="pt-2">
          <Field
            label={<Trans i18nKey="phone_number" />}
            htmlFor="phone_code"
            className="px-0"
            helpText={t('login_form.tips')}
            error={get(errors, ['phone', 'message'])}
          >
            <Controller
              rules={{
                validate: value => {
                  if (!value || !value.phone_number) {
                    return t('required_field') as string
                  }
                  return true
                },
              }}
              control={control}
              render={({ field }) => {
                return (
                  <PhoneNumberInput
                    value={field.value}
                    placeholder={t('phone_number')}
                    onChange={field.onChange}
                    inputProps={{
                      autoComplete: 'off',
                      maxLength: 36,
                      onInput: e => {
                        e.currentTarget.value = removeAllWS(
                          e.currentTarget.value
                        )
                      },
                    }}
                  />
                )
              }}
              name={'phone'}
            />
          </Field>
          <Field
            className="px-0"
            label={<Trans i18nKey="password" />}
            htmlFor="password"
          >
            <Input
              id="password"
              type="password"
              placeholder={t('password')}
              autoComplete="off"
              {...register('password', { required: true })}
            />
          </Field>
          <div className="mt-2 text-right w-full flex mb-4">
            <div>
              <a
                href="#"
                className="no-underline text-gold"
                onClick={() => {
                  onLoginBySms && onLoginBySms()
                }}
              >
                {t('login_by_sms')}
              </a>
            </div>
            <div className="flex-1 flex space-x-2 justify-end">
              <a
                href="#"
                className="no-underline text-gold"
                onClick={goToForget}
              >
                {t('forget_password')}
              </a>
            </div>
          </div>
          <Stack spacing={'xs'}>
            <Button variant="filled" type="submit" fullWidth loading={loading}>
              <span className={'uppercase'}>{t('login')}</span>
            </Button>
            <Button variant="subtle" fullWidth onClick={goToRegister}>
              <span className="uppercase"> {t('login_form.register')}</span>
            </Button>
          </Stack>
        </form>
      </>
    )
  }
)

LoginFormOld.displayName = 'LoginFormOld'
