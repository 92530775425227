import { loadScript } from '@/services/script-loader'

type WxLoginScope = 'snsapi_login'
type WxLoginParams = {
  /**
   * true：手机点击确认登录后可以在 iframe 内跳转到 redirect_uri，false：手机点击确认登录后可以在 top window 跳转到 redirect_uri。默认为 false。
   */
  self_redirect?: boolean
  /**
   * 第三方页面显示二维码的容器id
   */
  id: string
  /**
   * 应用唯一标识，在微信开放平台提交应用审核通过后获得
   */
  appid: string
  /**
   * 应用授权作用域，拥有多个作用域用逗号（,）分隔，网页应用目前仅填写snsapi_login即可
   */
  scope: WxLoginScope[]
  /**
   * 重定向地址，需要进行UrlEncode
   */
  redirect_uri: string
  /**
   * 用于保持请求和回调的状态，授权请求后原样带回给第三方。该参数可用于防止csrf攻击（跨站请求伪造攻击），建议第三方带上该参数，可设置为简单的随机数加session进行校验
   */
  state?: string
  /**
   * 提供"black"、"white"可选，默认为黑色文字描述。详见文档底部FAQ
   */
  style?: string
  /**
   * 自定义样式链接，第三方可根据实际需求覆盖默认样式。详见文档底部FAQ
   */
  href?: string
}

export class WechatSdkService {
  /**
   * 官方文档：https://developers.weixin.qq.com/doc/oplatform/Website_App/WeChat_Login/Wechat_Login.html
   */
  async wxLogin(args: WxLoginParams) {
    const WxLogin = (await loadScript(
      'https://res.wx.qq.com/connect/zh_CN/htmledition/js/wxLogin.js',
      'WxLogin'
    )) as any
    const loginObj = new WxLogin({
      ...args,
      scope: args.scope.join(','),
    })
    return loginObj
  }
}
