import { RecordType } from "../model/recordType";
import { ActionsInterval } from "../model/actionsInterval";
import type { PlayState } from "../model/playState";
import type { Action } from "../model/action";
import DrawableService from "./drawableService";

export type IRecordablePlaybackSetupProps = {
  scale: number;
  actionList: ActionsInterval[];
};

export type IRecordableSetStatePayload = {
  play?: IRecordablePlaybackSetupProps;
};

class RecordableService {
  actionsIntervalList: ActionsInterval[];
  actionsIntervalPlayPosition: number;
  drawableService: DrawableService;
  timeInterval: number;
  intervalId: ReturnType<typeof setInterval> | null;
  timeoutId: ReturnType<typeof setTimeout> | null;
  state: RecordType;
  startTime: number;
  stopTime: number;
  isPlay: boolean;
  playInfo: PlayState | null;
  prevActionsIntervalId: number;
  replayScale: number;

  playStart: number; // 这一次播放的开始时间
  played: number; // 已播放的时间

  constructor(timeInterval = 100) {
    this.actionsIntervalList = [];
    this.drawableService = new DrawableService();
    this.timeInterval = timeInterval;
    this.intervalId = null;
    this.timeoutId = null;
    this.state = RecordType.READY;
    this.startTime = 0;
    this.stopTime = 0;
    this.actionsIntervalPlayPosition = 0;
    this.prevActionsIntervalId = 0;
    this.playInfo = null;
    this.isPlay = false;
    this.replayScale = 1;

    this.playStart = 0;
    this.played = 0;
  }

  setDrawableService = (drawableService: DrawableService) => {
    this.drawableService = drawableService;
  };

  setState = (state: RecordType) => {
    // debugger;
    console.log("recordableService.setState: from ", this.state, "to", state);
    if (this.state === state) {
      return;
    }
    this.state = state;
    switch (this.state) {
      case RecordType.READY:
        this.reset();
        break;
      case RecordType.START:
        this.start();
        break;
      case RecordType.STOP:
        this.stop();
        break;
      case RecordType.PLAY:
        this.play();
        break;
      case RecordType.PAUSE:
        this.pause();
        break;
      case RecordType.RESUME:
        this.resume();
        break;
      default:
        this.stop();
        break;
    }
  };

  recordingInterval = () => {
    const actionList = this.drawableService.getActionList();
    if (actionList.length > 0) {
      const nextTimeInterval = new Date().getTime() - this.startTime;
      this.actionsIntervalList.push(
        new ActionsInterval(
          this.drawableService.getActionList(),
          nextTimeInterval
        )
      );
      this.drawableService.clearActionList();
    }
  };

  /**
   * 开始录制
   */
  start = () => {
    // debugger;
    const stopTimeInterval = new Date().getTime() - this.stopTime;
    this.startTime += stopTimeInterval;
    console.log("recordableService - start - setting recording interval");
    this.intervalId = setInterval(this.recordingInterval, this.timeInterval);
    console.log("recording interval started", this.intervalId);
  };

  /**
   * 停止录制
   */
  stop = () => {
    // debugger;
    if (this.intervalId !== null) {
      this.stopTime = new Date().getTime();
      this.recordingInterval();
      console.log(
        "recordableService - stop - clearing interval",
        this.intervalId
      );
      clearInterval(this.intervalId);
      this.intervalId = null;

      console.log(
        "recordableService.actionsIntervalList:",
        this.actionsIntervalList
      );
    }
  };

  playbackSetup = ({ scale, actionList }: IRecordablePlaybackSetupProps) => {
    this.replayScale = scale;
    this.drawableService.setReplayScale(scale);
    this.actionsIntervalList = actionList;
  };

  /**
   * 开始重新播放
   */
  play = () => {
    // debugger;
    console.log("start play");
    this.isPlay = true;
    this.drawableService.clearCanvas();
    this.onPlay();
    this.drawingInterval();
  };

  /**
   * 播放暂停
   */
  pause = () => {
    this.isPlay = false;
    this.onPause();
  };

  /**
   * 继续播放
   */
  resume = () => {
    // console.log("resuming ===>");
    this.isPlay = true;
    const pos = this.preDraw(this.played, "milsec");
    // console.log("new position ==>", pos);
    this.actionsIntervalPlayPosition = pos;
    this.onPlay();
    this.drawingInterval();
  };

  /**
   * @description 跳至某个时间点，将播放过的时间记录成这个跳转的时间
   */
  seek = async (time: number) => {
    this.preDraw(time);
    this.played = time * 1000;
  };

  reset = () => {
    console.log("recordableService - reset ");
    this.drawableService.clearCanvas();
    this.drawableService.clearActionList();
    if (this.intervalId !== null) {
      console.log(
        "recordableService - reset - clearing interval",
        this.intervalId
      );
      clearInterval(this.intervalId);
    }
    if (this.timeoutId) {
      console.log(
        "recordableService - reset - clearing timeout",
        this.timeoutId
      );
      clearTimeout(this.timeoutId);
    }
    this.actionsIntervalList = [];
    this.intervalId = null;
    this.timeoutId = null;
    this.state = RecordType.STOP;
    this.startTime = 0;
    this.stopTime = 0;
    this.actionsIntervalPlayPosition = 0;
    this.prevActionsIntervalId = 0;
    this.playInfo = null;
    this.isPlay = false;

    this.playStart = 0;
    this.played = 0;
  };

  /**
   * @description 记录暂停时间与开始时间的差，加上played的时间就是播放过的时间
   */
  private onPause = () => {
    const now = new Date().getTime();
    this.played = now - this.playStart + this.played;
    if (this.timeoutId) {
      clearInterval(this.timeoutId);
    }

    // console.log(
    //   "on pause ===>",
    //   this.played,
    //   now,
    //   this.playStart,
    //   this.actionsIntervalPlayPosition
    // );
  };

  /**
   * @description 记录开始播放的时间点
   */
  private onPlay = () => {
    this.playStart = new Date().getTime();
  };

  private drawingInterval = (startTime?: number, isNew: boolean = true) => {
    // 记录开始绘画的绝对时间
    const st = startTime || new Date().getTime();

    if (this.actionsIntervalPlayPosition < this.actionsIntervalList.length) {
      const currentActionsInterval = this.actionsIntervalList[
        this.actionsIntervalPlayPosition
      ];
      // console.log(
      //   "drawing interval  ===>",
      //   this.actionsIntervalPlayPosition,
      //   this.actionsIntervalList[this.actionsIntervalPlayPosition].interval,
      //   this.played
      // );

      /**
       * @description
       * 开始时间与当前时间的差，为真正的interval
       * 下一次的绘画timeout时间需与差值做比较并减去多出来的误差来达到与初始同步的状态
       */
      const actualDiff = new Date().getTime() - st;

      // 需减去played的时间，interval记录的是从播放初开始的时间，下一次播放时间应减去播放过的时间
      let timeSlot = currentActionsInterval.interval - actualDiff - this.played;

      // 不是recursive call的情况下
      if (isNew) {
        const diff = currentActionsInterval.interval - this.played;
        timeSlot = diff > 0 ? diff : 0;
      }

      // console.log(
      //   "get time slot  ===>",
      //   st,
      //   this.actionsIntervalPlayPosition,
      //   currentActionsInterval.interval,
      //   this.played,
      //   timeSlot
      // );

      this.timeoutId = setTimeout(() => {
        if (!this.isPlay) {
          return;
        }
        this.drawableService.setActionList(currentActionsInterval.actions);
        this.drawableService.drawActions();
        this.actionsIntervalPlayPosition += 1;
        this.prevActionsIntervalId = currentActionsInterval.interval;
        this.drawingInterval(st, false);
      }, timeSlot);
    } else {
      // playback ended
      console.log(
        "playback reached end. setting actionsIntervalPlayPosition to 0"
      );
      this.actionsIntervalPlayPosition = 0;
      // todo:should raise event here, notifying outside caller that the playback is ended, so that the caller doesn't need to reset() for the next playback
    }
  };

  private preDraw = (time: number, unit: "second" | "milsec" = "second") => {
    const rounded = Math.round(unit === "second" ? time * 1000 : time);
    let i = 0;

    let actions: Action[] = [];
    let pos = 0;

    while (i < this.actionsIntervalList.length) {
      if (this.actionsIntervalList[i].interval <= rounded) {
        actions = actions.concat(this.actionsIntervalList[i].actions);
        pos = i;
      }
      i += 1;
    }

    // console.log(
    //   "predrawed",
    //   pos,
    //   this.actionsIntervalList[pos].interval,
    //   rounded,
    //   this.actionsIntervalList
    // );
    this.drawableService.clearCanvas();
    if (actions && actions.length) {
      this.drawableService.setActionList(actions);
      this.drawableService.drawActions();
    }
    return pos + 1;
  };
}

export default RecordableService;
