import { LocalizationEnum } from 'gga-types'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

export const useCountry = () => {
  const { i18n } = useTranslation()
  const country: LocalizationEnum = useMemo(() => {
    return i18n.language === 'zh_CN'
      ? LocalizationEnum.zh_CN
      : LocalizationEnum.kr
  }, [i18n.language])

  return country
}
