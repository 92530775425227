import { loadScript } from '@/services/script-loader'

export type TWxSDK = {
  miniProgram: any
}

export const loadWxSdk = async () => {
  const src = '//res.wx.qq.com/open/js/jweixin-1.6.0.js'
  const sdk = await loadScript<TWxSDK>(src, 'wx')
  return sdk
}
