import { useRequest, useSetState } from 'ahooks'
import { omit } from 'lodash'
import Router, { useRouter } from 'next/router'
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react'
import { toast } from 'react-toastify'
import { IResData, IUserProfile, TUserTokenBalance } from '@/@types/global'
import {
  AuthFormProps,
  ILoginMethod,
} from '@/components/zh_CN/auth-components/AuthForm'
import { APP_LOCALE, APP_TYPE, isNoAuthRoute } from '@/constants/define'
import { isBrowser, replacePhoneNumberByPhoneCode } from '@/services/Utils'
import { AuthService } from '@/services/auth'
import { Jwt } from '@/services/jwt'
import {
  IRegisterApiParams,
  TCreateLeadResponse,
  createLead,
  getUserTokenBalance,
  registerApi,
} from '@/services/user'
import { isMicroMessenger } from '@/utils/is'
import { IResponse } from '@/utils/request'

export type IAuthContext = ReturnType<typeof useAuthProvider>

const AuthContext = createContext<IAuthContext>({} as IAuthContext)

const authService = new AuthService()

type TUseAuthOptions = {
  onAuthModalClose?: (isLogged?: boolean) => void
}

function useAuthProvider() {
  const [user, setUser] = useState<IUserProfile>()
  const [loading, setLoading] = useState(true)
  const { query, pathname } = useRouter()
  const [openAuthModal, setOpenAuthModal] = useState(false)
  const [initialLoginMethod, setInitialLoginMethod] = useState<ILoginMethod>()
  const [onAuthModalClose, setOnAuthModalClose] =
    useState<TUseAuthOptions['onAuthModalClose']>()
  const [authModalState, setAuthModalState] = useSetState<
    Partial<AuthFormProps>
  >({
    initialStage: 'login',
  })

  const {
    data: tokenBalance,
    runAsync: fetchUserTokenBalance,
    refreshAsync: refreshTokenBalance,
  } = useRequest<TUserTokenBalance | undefined, any>(
    async () => {
      const res = await getUserTokenBalance()
      if (res.data && res.data.data) {
        return res.data.data
      }
      return
    },
    {
      manual: true,
    }
  )

  useEffect(() => {
    if (user && user.id) {
      refreshTokenBalance()
    }
  }, [refreshTokenBalance, user])

  const refreshUser = useCallback(async () => {
    setLoading(true)
    const tokenUser = await authService.refreshUserByToken()
    setLoading(false)
    setUser(tokenUser)
    return tokenUser
  }, [])

  /**
   * 中文的网站碰到微信小程序不做读取，有界面提示用浏览器打开
   */
  const readUser = useCallback(async () => {
    if (
      APP_TYPE === 'miniapp' ||
      (!isMicroMessenger() && APP_LOCALE === 'zh_CN') ||
      APP_LOCALE !== 'zh_CN'
    ) {
      const isRead = authService.readTokenFromUrl()
      if (isRead) {
        return await Router.replace({
          pathname: pathname,
          query: omit(query, ['token']),
        })
      }
    }
  }, [pathname, query])

  const registerUser = useCallback(
    async (
      data: IRegisterApiParams & {
        email?: string
      }
    ) => {
      const phoneNumber = replacePhoneNumberByPhoneCode(
        data.phone_code,
        data.phone_number
      )

      const res = await registerApi({
        ...data,
        ...{ phone_number: phoneNumber },
      })

      if (res && res.data?.data && res.data.data.access_token) {
        let result: true | IResponse<IResData<TCreateLeadResponse>> = true
        try {
          result = await createLead({
            email: data.email,
            first_name: data.name,
            phone: data.phone_number,
            country_call_code: data.phone_code,
            interested_program: data.interested_program,
            learning_purpose: data.learning_purpose,
            inquiry: data.inquiry,
            discord_id: data.discord_id,
          })
        } catch (e) {
          console.error('Initialize Deal Error', e)
        }

        Jwt.setToken(res.data.data.access_token)
        return result
      } else {
        toast.error(res.message)
        return
      }
    },
    []
  )

  const setOpenAuthModalMemo = useCallback(
    (open: boolean, loginMethod?: ILoginMethod) => {
      setOpenAuthModal(open)
      if (open) {
        setInitialLoginMethod(loginMethod)
      }
    },
    []
  )

  const _setOnAuthModalClose = useCallback(
    (fn: ((isLoggedIn?: boolean) => void) | undefined) => {
      setOnAuthModalClose(() => {
        return fn
      })
    },
    []
  )

  return {
    registerUser,
    refreshUser,
    setUser,
    isAuthenticated: !!user,
    user,
    loading,
    readUser,
    tokenBalance,
    fetchUserTokenBalance,
    refreshTokenBalance,
    openAuthModal,
    setOpenAuthModal: setOpenAuthModalMemo,
    initialLoginMethod,
    onAuthModalClose,
    setOnAuthModalClose: _setOnAuthModalClose,
    authModalState,
    setAuthModalState,
  }
}

export const AuthProvider = ({ children }) => {
  const value = useAuthProvider()
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}

export function useAuth() {
  return useContext(AuthContext)
}

export const ProtectRoute = ({ children }) => {
  const { isAuthenticated, loading } = useAuth()
  const router = useRouter()
  if (isBrowser && !loading && !isAuthenticated && !isNoAuthRoute()) {
    Router.replace({
      pathname: '/login',
      query: {
        redirect_url: encodeURIComponent(router.asPath),
      },
    }).then(() => {
      return null
    })
  }
  return children
}
