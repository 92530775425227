import { Api } from '@/api'

export class ConfigValueApi extends Api {
  constructor(...args) {
    super(...args)
  }

  getConfigValues() {
    return this.createApi<any, { name: string; value: string }[]>(
      '/v2/configvalue',
      'get'
    )
  }
}
