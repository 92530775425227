import React, { useCallback } from "react";
import { IBoundData } from "../GreatVideoPlayer/type";
import {
  useRecordableContainerContext,
  RecordableEventEmitter,
} from "../container/RecordableContainer";
import PlaybackOverlay from "../recordableDrawing/PlaybackOverlay";
import RecordingOverlay from "../recordableDrawing/RecordingOverlay";
import { ActionsInterval } from "../recordableDrawing/model/actionsInterval";

type IStatefulRecordingOverlayProps = {
  bound: IBoundData;
};

export function StatefulRecordingOverlay({
  bound,
}: IStatefulRecordingOverlayProps) {
  const { overlayRef, recorderState } = useRecordableContainerContext();
  const handleAudioRecorderSaved = useCallback(
    (blob: Blob) => {
      // get audio context data and pass it out on callback
      const drawingData = overlayRef!.current!.getDrawingData();
      RecordableEventEmitter.emit("onAudioRecorderSaved", blob, drawingData);
    },
    [overlayRef]
  );

  return (
    <RecordingOverlay
      init={handle => {
        overlayRef.current = handle;
      }}
      className="gvp-player-overlay-wrapper i-am-recording"
      strokeStyle={recorderState.strokeStyle}
      lineWidth={recorderState.strokeWidth}
      state={recorderState.recordState!}
      onAudioRecorderSaved={handleAudioRecorderSaved}
      bound={bound}
      drawable={recorderState.drawable}
    />
  );
}

export type IStatefulPlaybackOverlayProps = {
  id: string;
  audioSrc: string;
  drawingData: ActionsInterval[];
  recordingPlaybackWidth: number;
  bound: IBoundData;
};

export function StatefulPlaybackOverlay({
  id,
  bound,
  audioSrc,
  drawingData,
  recordingPlaybackWidth,
}: IStatefulPlaybackOverlayProps) {
  const { playbackRef, setPlaybackState } = useRecordableContainerContext();

  const handleInit = useCallback(
    methods => {
      playbackRef.current = methods;
    },
    [playbackRef]
  );

  const handleChange = useCallback(
    (state, data) => {
      setPlaybackState({
        isPlaying: state.status === "playing",
        current: (data.audioState.currentTime / data.audioState.duration) * 100,
        currentTime: data.audioState.currentTime,
        duration: data.audioState.duration,
        overlayStatus: state.status,
      });
    },
    [setPlaybackState]
  );

  return (
    <PlaybackOverlay
      id={id}
      className={"gvp-player-overlay-wrapper i-am-playback"}
      bound={bound}
      init={handleInit}
      audioPlayback={audioSrc}
      drawingDataPlayback={drawingData}
      recordingPlaybackWidth={recordingPlaybackWidth}
      onChange={handleChange}
    />
  );
}
