import { Trans, useTranslation } from 'next-i18next'
import { Button, Textarea } from 'stripe-ui'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { get } from 'lodash'
import Yup from '@/utils/yup'

type IReplyInputProps = {
  placeholder: string
  maxCount: number
  onSubmit: (data: { content: string }, next: () => void) => void
}

export default function ReplyInput({
  placeholder,
  maxCount,
  onSubmit,
}: IReplyInputProps) {
  const { t } = useTranslation()
  const {
    register,
    watch,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    mode: 'all',
    defaultValues: {
      content: '',
    },
    resolver: yupResolver(
      Yup.object().shape({
        content: Yup.string()
          .required('content is required')
          .max(200, 'must not exceed 200 characters'),
      })
    ),
  })

  const watchContent = watch('content')

  return (
    <form
      onSubmit={handleSubmit(data => {
        onSubmit({ ...data, content: data.content.trimRight() }, () => {
          reset()
        })
      })}
    >
      <Textarea
        {...register('content')}
        maxLength={maxCount}
        className={
          'text-codgray bg-[#f0f0f0] border border-[#979797] rounded py-2 px-3'
        }
        placeholder={placeholder}
        rows={4}
        style={{
          resize: 'none',
        }}
      />
      <div className={'flex justify-end space-x-3 items-center py-2'}>
        <span>
          <Trans
            t={t}
            i18nKey={'textarea.count_limit'}
            values={{
              count: maxCount,
            }}
          />
        </span>
        <Button
          type={'submit'}
          variant={'brand'}
          size={'small'}
          disabled={!watchContent || get(errors, 'content.message')}
        >
          {t('course_form.send_btn')}
        </Button>
      </div>
    </form>
  )
}
