import React from "react";
import {
  useRecordableContainerContext,
  RecordableEventEmitter,
} from "../container/RecordableContainer";
import RecordingControls, {
  RecordingControlsProps,
} from "../recordableDrawing/RecordingControls";
import RecordingPlaybackControls from "../recordableDrawing/RecordingPlaybackControls";

type IStatefulRecordingControlsProps = {
  recordable: boolean;
  btnProps?: RecordingControlsProps["btnProps"];
  width: number;
  height: number;
};

export function StatefulRecordingControls({
  recordable,
  btnProps,
  width,
  height,
}: IStatefulRecordingControlsProps) {
  const {
    controlsRef,
    recorderDispatcher,
    recorderState,
    controlActions,
    overlayRef,
    playbackRef,
  } = useRecordableContainerContext();

  return (
    <RecordingControls
      init={handler => {
        controlsRef.current = handler;
      }}
      btnProps={btnProps}
      onStartRecording={async () => {
        controlActions.startRecording();

        try {
          await overlayRef.current?.initVoiceRecorder();
          overlayRef.current?.startAudioRecording();

          recorderDispatcher({
            type: "start:rec",
            payload: { canvasWidth: width, canvasHeight: height },
          });
          RecordableEventEmitter.emit("onStartRecording");
        } catch (e) {
          console.error(e);
          RecordableEventEmitter.emit("onStartRecording", e);
        }
      }}
      onStartDrawing={() => {
        controlActions.startDrawing();
        recorderDispatcher({
          type: "start:draw",
          payload: { canvasWidth: width, canvasHeight: height },
        });
        RecordableEventEmitter.emit("onStartDrawing");
      }}
      onStopRecording={() => {
        controlActions.stopRecording();
        overlayRef.current?.stopAudioRecording();
        recorderDispatcher({ type: "stop:rec" });
        const drawingData = overlayRef.current?.getDrawingData();
        RecordableEventEmitter.emit("onStopRecording", drawingData || []);
      }}
      onStartPlaying={() => {
        recorderDispatcher({ type: "start:preview" });
        overlayRef.current?.startAudioPlaying();
        RecordableEventEmitter.emit("onStartPlaying");
      }}
      onColorChanged={st => {
        recorderDispatcher({
          type: "set:color",
          payload: {
            strokeStyle: st,
          },
        });
        RecordableEventEmitter.emit("onColorChanged", st);
      }}
      onUndo={() => {
        overlayRef!.current!.undo();
        RecordableEventEmitter.emit("onUndo");
      }}
      onRedo={() => {
        overlayRef!.current!.redo();
        RecordableEventEmitter.emit("onRedo");
      }}
      onClear={() => {
        overlayRef!.current!.clear();
        RecordableEventEmitter.emit("onClear");
      }}
      // onSave={handleSave}
      onDiscard={() => {
        controlActions.reset();
        overlayRef.current?.reset();
        overlayRef.current?.stopAudioPlaying();
        recorderDispatcher({ type: "stop:draw" });
        RecordableEventEmitter.emit("onDiscard");
      }}
      onExitPlayback={() => {
        controlActions.reset();
        recorderDispatcher({
          type: "stop:play",
        });
        overlayRef.current?.reset();

        playbackRef.current?.pause();
        RecordableEventEmitter.emit("onExitPlayback");
      }}
      canRecord={recordable}
      strokeStyle={recorderState.strokeStyle}
      strokeWidth={recorderState.strokeWidth}
      onStrokeWidthChange={w => {
        recorderDispatcher({
          type: "set:stroke-width",
          payload: { strokeWidth: w },
        });
      }}
    />
  );
}

export function StatefulPlaybackControls() {
  const {
    playbackRef,
    setPlaybackVolume,
    playbackVolume,
    playbackState,
    overlayRef,
    recorderDispatcher,
    controlActions,
  } = useRecordableContainerContext();
  return (
    <RecordingPlaybackControls
      onVolumeChange={v => {
        playbackRef.current?.setAudioVolume(v);
        setPlaybackVolume(v);
      }}
      volume={playbackVolume}
      total={playbackState.duration}
      current={playbackState.current || 0}
      onPlayButtonClick={play => {
        if (play) {
          if (!playbackState.overlayStatus) {
            playbackRef.current?.reset();
            playbackRef.current?.start();
          } else if (playbackState.overlayStatus === "stopped") {
            playbackRef.current?.reset();
            playbackRef.current?.restart();
          } else {
            playbackRef.current?.resume();
          }
        } else {
          playbackRef.current?.pause();
        }
      }}
      onExit={() => {
        controlActions.reset();
        recorderDispatcher({
          type: "stop:play",
        });
        overlayRef.current?.reset();
        playbackRef.current?.pause();
        RecordableEventEmitter.emit("onExitPlayback");
      }}
      playing={playbackState.isPlaying}
      onSeekEnd={percentage => {
        playbackRef.current?.seek((playbackState.duration * percentage) / 100);
      }}
    />
  );
}
