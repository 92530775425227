import React, {
  ReactNode,
  useRef,
  useState,
  createContext,
  useContext,
  useMemo,
} from "react";
import EventEmitter from "eventemitter3";
import useRecorderOverlay from "../hooks/useRecorderOverlay";
import {
  IPlaybackOverlayMethods,
  PlaybackState,
} from "../recordableDrawing/PlaybackOverlay";
import { RecordingControlsHandle } from "../recordableDrawing/RecordingControls";
import { RecordingOverlayHandle } from "../recordableDrawing/RecordingOverlay";
import { IStatefulPlaybackOverlayProps } from "../components/StatefulOverlays";

const eventEmitter = new EventEmitter();

const RecordableEventEmitter = {
  on: (event: IEvents, fn) => eventEmitter.on(event, fn),
  once: (event: IEvents, fn) => eventEmitter.once(event, fn),
  off: (event: IEvents, fn) => eventEmitter.off(event, fn),
  emit: (event: IEvents, ...args: any) => eventEmitter.emit(event, ...args),
};

Object.freeze(RecordableEventEmitter);
export { RecordableEventEmitter };

type IRecordableContainerProps = {
  recorderSettings?: {
    strokeStyle?: string;
  };
};

type IRecordableContainerProviderProps = IRecordableContainerProps & {
  children: ReactNode;
};

type IRecordableContainerContext = ReturnType<typeof useRecordableContainer>;

type IEvents =
  | "onAudioRecorderSaved"
  | "onStartRecording"
  | "onStartDrawing"
  | "onStopRecording"
  | "onStartPlaying"
  | "onColorChanged"
  | "onUndo"
  | "onRedo"
  | "onClear"
  | "onDiscard"
  | "onExitPlayback"
  | "onPlaybackReady";

const RecordableContainerContext = createContext<IRecordableContainerContext>(
  {} as IRecordableContainerContext
);

function useRecordableContainer({
  recorderSettings,
}: IRecordableContainerProps) {
  const controlsRef = useRef<RecordingControlsHandle>();
  const overlayRef = useRef<RecordingOverlayHandle>();
  const playbackRef = useRef<IPlaybackOverlayMethods>();

  const [componentState, setComponentState] = useState<"play" | "rec" | null>(
    null
  );

  const [playbackProps, setPlaybackProps] =
    useState<
      Pick<
        IStatefulPlaybackOverlayProps,
        "audioSrc" | "drawingData" | "recordingPlaybackWidth"
      >
    >();

  const { state: recorderState, dispatcher: recorderDispatcher } =
    useRecorderOverlay({
      strokeStyle: recorderSettings?.strokeStyle,
      strokeWidth: 1,
    });

  const [playbackState, setPlaybackState] = useState<{
    duration: number;
    currentTime: number;
    /**
     * @description percentage
     */
    current: number;
    isPlaying: boolean;
    overlayStatus?: PlaybackState["status"];
  }>({
    duration: 0,
    currentTime: 0,
    current: 0,
    isPlaying: false,
  });

  const [playbackVolume, setPlaybackVolume] = useState(100);

  const controlActions = useMemo(
    () => ({
      startRecording: () => {
        controlsRef.current!.disabledToggle([
          "startRecording",
          "startDrawing",
          "discard",
          "preview",
          "exitPlayback",
        ]);
      },
      startDrawing: () => {
        controlsRef.current!.disabledToggle([
          "startRecording",
          "startDrawing",
          "exitPlayback",
          "preview",
          "stop",
        ]);
      },
      stopRecording: () => {
        controlsRef.current!.disabledToggle(["preview", "discard"], true);
      },
      reset: () => {
        controlsRef.current!.disabledToggle(
          ["startDrawing", "startRecording"],
          true
        );
      },
      playing: () => {
        controlsRef.current!.disabledToggle(["exitPlayback"], true);
      },
    }),
    []
  );

  return {
    controlActions,
    playbackState,
    setPlaybackState,
    playbackVolume,
    setPlaybackVolume,
    overlayRef,
    playbackRef,
    recorderState,
    recorderDispatcher,
    controlsRef,
    componentState,
    setComponentState,
    playbackProps,
    setPlaybackProps,
  };
}

export function useRecordableContainerContext() {
  return useContext(RecordableContainerContext);
}

export default function ProvideRecordableContainer({
  children,
  recorderSettings,
}: IRecordableContainerProviderProps) {
  const value = useRecordableContainer({ recorderSettings });

  return (
    <RecordableContainerContext.Provider value={value}>
      {children}
    </RecordableContainerContext.Provider>
  );
}
