import { useAsyncEffect } from 'ahooks'
import { useId } from '@mantine/hooks'
import { WechatSdkService } from '@/services/wechat-sdk-service'
import { WX_WEB_APP_ID } from '@/config/config'

export default function WechatLogin({ className }: { className?: string }) {
  const id = useId()

  const genQr = async () => {
    const originUrl = encodeURIComponent(window.location.href)
    const verifyUrl =
      window.location.origin + `/_internal/wx-code?originUrl=${originUrl}`
    await new WechatSdkService().wxLogin({
      id: id,
      redirect_uri: encodeURIComponent(verifyUrl),
      scope: ['snsapi_login'],
      appid: WX_WEB_APP_ID,
      self_redirect: false,
      style: 'white',
      href: window.location.origin + '/wechat_qrcode.css',
    })
  }

  useAsyncEffect(async () => {
    if (window) {
      genQr()
    }
  }, [])

  return <div id={id} className={className} />
}
