import React from 'react'
import { Modal } from '@/lib/ui/modals/modal'
import { useQrcodeCheckout } from '@/hooks/use-qrcode-checkout'
import { useModalStore } from '@/store/use-modal-store'
import { AddCreditForm } from '../Form/add-credit-form'
import { QrcodeCounter } from '../cashier/zh_CN/qrcode-counter'

export const AddCreditModal = () => {
  const { isOpen } = useModalStore(state =>
    state.getModalState('AddCreditModal')
  )
  const closeModal = useModalStore(
    state => () => state.closeModal('AddCreditModal')
  )

  const { state, dispatch, checkPaymentStatus } = useQrcodeCheckout()

  return (
    <Modal
      size="sm"
      centered={true}
      opened={isOpen}
      onClose={() => {
        dispatch({ type: 'reset' })
        closeModal()
      }}
      title={'充值中心'}
      closeOnClickOutside={false}
    >
      {!state.checkout ? (
        <AddCreditForm
          onSubmitted={result => {
            dispatch({
              type: 'set_checkout',
              payload: { qrcode: result.url, bizId: result.biz_id },
            })
          }}
        />
      ) : (
        <QrcodeCounter
          qrcode={state.qrcode}
          onBack={() => dispatch({ type: 'back' })}
          onPurchased={checkPaymentStatus}
        />
      )}
    </Modal>
  )
}
