import { i18n } from 'next-i18next'

export const errorCodes = () => ({
  52001: i18n?.t('server_response.phone_or_email_incorrect'), //user not exist
  52002: i18n?.t('server_response.password_incorrect'),
  52000: i18n?.t('server_response.phone_or_email_incorrect'), //invalid username or password
  54001: i18n?.t('server_response.verificaton_code_incorrect'),
  54002: i18n?.t('server_response.verificaton_code_incorrect'),
  54003: i18n?.t('server_response.verificaton_code_incorrect'),
  54005: i18n?.t('server_response.recaptcha_failed'),
  50101: i18n?.t('server_response.user_exists'),
  600: i18n?.t('server_response.internal_error'),
  55001: i18n?.t('coupon_not_exists', { ns: 'buynow' }),
  55002: i18n?.t('coupon_has_been_expired', { ns: 'buynow' }),
  55003: i18n?.t('coupon_has_been_used', { ns: 'buynow' }),
  59031: i18n?.t('cancel_failed_reason_cancel_failed', { ns: 'live-session' }),
  59032: i18n?.t('cancel_failed_reason_cannot_be_cancelled', {
    ns: 'live-session',
  }),
  59033: i18n?.t('cancel_failed_reason_starting_soon', { ns: 'live-session' }),
  57011: i18n?.t('server_response.cannot_purchase_repeatedly'),
  55005: i18n?.t('promo_code_does_not_exist', { ns: 'buynow' }),
  55006: i18n?.t('promo_code_expired', { ns: 'buynow' }),
  55007: i18n?.t('already_used_this_promotion_code', { ns: 'buynow' }),
  // key-claim errors
  67000: i18n?.t('server_response.product_key.invalid_serial_code'),
  67001: i18n?.t('server_response.product_key.expired_serial_code'),
  67002: i18n?.t('server_response.product_key.serial_code_used'),
})

export const getErrorMsgByErrorCode = errorCode => {
  return errorCodes()[errorCode]
}
