import { ActionType } from "../model/actionType";
import { Action } from "../model/action";

class DrawableService {
  readonly tempImage: HTMLImageElement;
  context: CanvasRenderingContext2D;
  canvas: HTMLCanvasElement;
  private strokeStyle = "rgb(0, 0, 0)";
  private lineWidth = 1;
  /**
   * @description 控制画板是否可画
   */
  private drawable = false;
  /**
   * @description 判断画板是否正在被作画
   */
  private isDrawing = false;
  /**
   * @description 缓存画作的每一次有效canvas image, 方便undo
   */
  private snapshotImageList: ImageData[] = [];
  private snapshotPosition = -1;
  /**
   * @description 鼠标事件等将会触发向list中推送actions
   */
  private actionList: Action[] = [];
  /**
   * @description 为恢复原来canvas的尺寸并且清除
   */
  private replayScale: number;

  constructor() {
    this.canvas = document.createElement("canvas");
    this.tempImage = document.createElement("img");
    this.replayScale = 1;
    const tempCtx = this.canvas.getContext("2d");
    if (tempCtx instanceof CanvasRenderingContext2D) {
      this.context = tempCtx;
    } else {
      throw new Error("can not find canvas context");
    }
  }

  init = (canvas: HTMLCanvasElement, drawable: boolean) => {
    this.canvas = canvas;
    this.drawable = drawable;
    this.isDrawing = false;

    const tempCtx = canvas.getContext("2d");
    if (tempCtx instanceof CanvasRenderingContext2D) {
      this.context = tempCtx;
    } else {
      throw new Error("can not find canvas context");
    }
    this.resetSnapshot();
    // this.snapshotImageList.push(this.context.getImageData(0, 0, this.canvas.width, this.canvas.height));
    // this.snapshotPosition++;
  };

  setIsDrawable = (drawable: boolean) => {
    this.drawable = drawable;
  };

  setStrokeStyle = (strokeStyle: string) => {
    this.strokeStyle = strokeStyle;
  };

  setLineWidth = (lineWidth: number) => {
    this.lineWidth = lineWidth;
  };

  setReplayScale = (scale: number) => {
    if (scale <= 0) {
      throw new Error("Cannot set scale as a non-positive number!");
    }
    this.replayScale = scale;
    this.context.setTransform(1, 0, 0, 1, 0, 0); // reset first
    this.context.scale(scale, scale);
  };

  mouseDownHandler = (e: any) => {
    if (!this.drawable) {
      return;
    }
    this.isDrawing = true;
    const newAction = this.createAction(e.pageX, e.pageY, ActionType.MOVETO);
    this.drawAction(newAction);
  };

  mouseUpHandler = (e: any) => {
    if (!this.drawable) {
      return;
    }
    const newAction = this.createAction(e.pageX, e.pageY, ActionType.MOVEEND);
    this.drawAction(newAction);
    this.isDrawing = false;
  };

  mouseMoveHandler = (e: any) => {
    if (!this.drawable) {
      return;
    }
    if (this.isDrawing) {
      const newAction = this.createAction(e.pageX, e.pageY, ActionType.LINETO);
      this.drawAction(newAction);
    }
  };

  undoHandler = () => {
    const newAction = this.createAction(0, 0, ActionType.UNDO);
    this.drawAction(newAction);
  };

  redoHandler = () => {
    const newAction = this.createAction(0, 0, ActionType.REDO);
    this.drawAction(newAction);
  };

  clearHandler = () => {
    const newAction = this.createAction(0, 0, ActionType.CLEAR);
    this.drawAction(newAction);
  };

  createAction = (pageX: number, pageY: number, actionType: ActionType) => {
    const { offsetLeft, offsetTop } = this.getCanvasOffset();
    const { scrollX, scrollY } = window;
    const canvasRatio = this.getCanvasRatio();

    const x = Math.floor((pageX - offsetLeft - scrollX) * canvasRatio);
    const y = Math.floor((pageY - offsetTop - scrollY) * canvasRatio);
    const newAction = new Action(
      x,
      y,
      actionType,
      this.lineWidth,
      this.strokeStyle
    );
    this.actionList.push(newAction);
    return newAction;
  };

  getCanvasOffset = () => {
    const domRect = this.canvas.getBoundingClientRect();
    return { offsetLeft: domRect.left, offsetTop: domRect.top };
  };

  getCanvasRatio = () => {
    return this.canvas.width / this.canvas.clientWidth;
  };

  drawAction = (action: Action) => {
    switch (action.actionType) {
      case ActionType.MOVETO:
        this.context.beginPath();
        this.context.lineCap = "round";
        this.context.moveTo(action.x, action.y);
        this.context.strokeStyle = action.strokeStyle;
        this.context.lineWidth = action.lineWidth;
        break;
      case ActionType.MOVEEND:
        this.pushSnapshot();
        break;
      case ActionType.LINETO:
        this.context.lineTo(action.x, action.y);
        this.context.stroke();
        break;
      case ActionType.REDO:
        // debugger;
        if (this.snapshotPosition < this.snapshotImageList.length - 1) {
          this.context.putImageData(
            this.snapshotImageList[this.snapshotPosition + 1],
            0,
            0
          );
          this.context.drawImage(this.tempImage, 0, 0);
          this.snapshotPosition++;
        }
        break;
      case ActionType.UNDO:
        // debugger;
        if (this.snapshotPosition > 0) {
          this.context.putImageData(
            this.snapshotImageList[this.snapshotPosition - 1],
            0,
            0
          );
          this.snapshotPosition--;
        } else if (this.snapshotPosition === 0) {
          this.clearCanvas();
          this.snapshotPosition--;
        }
        break;
      case ActionType.CLEAR:
        this.clearCanvas();
        this.pushSnapshot();
        break;
      default:
        throw new Error("Unknow Action");
    }
  };

  pushSnapshot = () => {
    // debugger;
    if (this.snapshotImageList.length - 1 !== this.snapshotPosition) {
      this.snapshotImageList = this.snapshotImageList.slice(
        0,
        this.snapshotPosition + 1
      );
    }
    this.snapshotPosition++;
    this.snapshotImageList.push(
      this.context.getImageData(0, 0, this.canvas.width, this.canvas.height)
    );
  };

  drawActions = () => {
    this.actionList.forEach((action, index) => {
      this.drawAction(action);
    });
  };

  getActionList = () => {
    return this.actionList;
  };

  setActionList = (actionList: Action[]) => {
    this.actionList = actionList;
  };

  clearActionList = () => {
    this.actionList = [];
  };

  clearCanvas = () => {
    this.context.clearRect(
      0,
      0,
      this.canvas.width / this.replayScale,
      this.canvas.height / this.replayScale
    );
  };

  resetSnapshot = () => {
    // debugger;
    this.snapshotImageList = [];
    this.snapshotPosition = -1;
    // this.snapshotImageList.push(
    //   this.context.getImageData(0, 0, this.canvas.width, this.canvas.height),
    // );
    // this.snapshotPosition++;
  };
}

export default DrawableService;
