import classNames from 'classnames'
import React from 'react'
import { NSComment, NSClass } from 'gga-types'
import HomeworkCommentItem from '@/components/CoursewareModal/SoloAndTeamTaskReview/components/HomeworkComments/HomeworkCommentItem'
import HomeworkCommentInput from '@/components/CoursewareModal/SoloAndTeamTaskReview/components/HomeworkComments/HomeworkCommentInput'
import NoComment from '@/components/CoursewareModal/SoloAndTeamTaskReview/components/HomeworkComments/NoComment'
import { ICommentMode } from '@/components/CoursewareModal/SoloAndTeamTaskReview/components/HomeworkComments/type'

type IHomeworkCommentsProps = {
  className?: string
  comments: NSComment.CommentItem[]
  classUUID: string
  info: NSClass.SyllabusItemStruct
  onLabelClick: (item: NSComment.CommentItem) => void
  canSubmit: boolean
  onSubmit: (data) => void
  onFocus: () => void
  currentTime?: number
  currentPlayingCommentId?: number
  mode?: ICommentMode
  trialId: number
}

export default function HomeworkComments({
  className,
  comments,
  info,
  classUUID,
  onLabelClick,
  canSubmit,
  onSubmit,
  onFocus,
  currentTime,
  currentPlayingCommentId,
  mode = 'normal',
  trialId,
}: IHomeworkCommentsProps) {
  return (
    <div
      className={classNames(
        'relative flex flex-col h-[40rem] md:h-full flex-shrink-0 ml-0 w-auto md:w-96 md:ml-4',
        className
      )}
    >
      <div className={'overflow-auto h-full'}>
        {!comments.length ? (
          <div
            className={
              'flex-1 flex flex-col justify-center items-center h-full'
            }
          >
            <NoComment />
          </div>
        ) : (
          comments.map((comment, idx) => {
            return (
              <div key={comment.id} className={'mb-3'}>
                <HomeworkCommentItem
                  mode={mode}
                  currentPlayingCommentId={currentPlayingCommentId}
                  canSubmit={canSubmit}
                  trialId={trialId}
                  index={idx}
                  classUUID={classUUID}
                  item={comment}
                  info={info}
                  onLabelClick={onLabelClick}
                />
              </div>
            )
          })
        )}
      </div>
      <HomeworkCommentInput
        mode={mode}
        currentTime={currentTime}
        onSubmit={onSubmit}
        onFocus={onFocus}
      />
    </div>
  )
}
