import { useLayoutEffect, useMemo, useRef } from "react";
import { useSetState } from "ahooks";
import { getDuration } from "../utils/file";

export type IUseAudioHandler = {
  pause: () => IUseAudioHandler;
  play: () => IUseAudioHandler;
  seek: (time: number) => IUseAudioHandler;
  setAudioSrc: (src: string) => Promise<IUseAudioHandler>;
  setVolume: (v: number) => IUseAudioHandler;
  restart: () => void;
};

export type IUseAudioState = {
  currentTime: number;
  duration: number;
};

export type IUseAudioProps = {
  id: string;
};

export default function useAudio({
  id,
}: IUseAudioProps): [IUseAudioHandler, IUseAudioState] {
  const audioPlayer = useRef<HTMLAudioElement>(new Audio());
  const [state, setState] = useSetState<IUseAudioState>({
    currentTime: 0,
    duration: 0,
  });

  useLayoutEffect(() => {
    return () => {
      if (audioPlayer.current) {
        audioPlayer.current.pause();
      }
    };
  }, []);

  const audioMethods: IUseAudioHandler = useMemo<IUseAudioHandler>(
    () => ({
      pause: () => {
        audioPlayer?.current?.pause();
        return audioMethods;
      },
      play: () => {
        audioPlayer?.current?.play();
        return audioMethods;
      },
      seek: (time: number) => {
        audioPlayer.current!.currentTime = time;
        return audioMethods;
      },
      restart: () => {
        audioPlayer.current.currentTime = 0;
        audioPlayer.current.play();
        setState({
          currentTime: 0,
        });
      },
      setAudioSrc: src => {
        return new Promise((resolve, reject) => {
          if (src && audioPlayer.current) {
            audioPlayer.current.onloadeddata = () => {
              getDuration(src, d => {
                setState({ duration: d });
                return resolve(audioMethods);
              });
            };

            audioPlayer.current.onerror = e => {
              reject(e);
            };
            audioPlayer.current.ontimeupdate = () => {
              setState({
                currentTime: audioPlayer.current
                  ? audioPlayer.current?.currentTime
                  : 0,
              });
            };
            audioPlayer.current.onended = () => {
              console.log("playback ended");
            };
            audioPlayer.current.setAttribute("src", src);
            audioPlayer.current.load();
          } else {
            reject(new Error("No src provide"));
          }
        });
      },
      setVolume: (v: number) => {
        audioPlayer.current!.volume = v / 100;
        return audioMethods;
      },
    }),
    [setState]
  );

  return [audioMethods, state];
}
