import { zhCN, ko } from 'date-fns/locale'
import format from 'date-fns/format'
import subMonths from 'date-fns/subMonths'
import compareAsc from 'date-fns/compareAsc'
import formatDistance from 'date-fns/formatDistance'
import subYears from 'date-fns/subYears'
import { i18n } from 'next-i18next'
import { ILocalizationCode } from 'gga-types'

export type IDuration = {
  hours: number
  seconds: number
  minutes: number
}

export function getDuration(str: string): IDuration {
  const sec_num = parseInt(str, 10)
  const hours = Math.floor(sec_num / 60 / 60)
  const minutes = Math.floor((sec_num - hours * 60 * 60) / 60)
  const seconds = sec_num - minutes * 60 - hours * 60 * 60

  return {
    hours,
    seconds,
    minutes,
  }
}

export function formatDeclarativeTime(time: Date, locale: ILocalizationCode) {
  const now = new Date()
  const oneMonthAgo = subMonths(now, 1)
  const oneYearAgo = subYears(now, 1)
  if (compareAsc(oneMonthAgo, time) < 1 && compareAsc(oneYearAgo, time) >= 0) {
    // 大于一个月且小于一年的用format
    return format(time, 'HH:mm MM/dd ')
  } else {
    const lo = (() => {
      switch (locale) {
        case 'zh_CN':
          return zhCN
        case 'kr':
          return ko
        default:
          return undefined
      }
    })()
    return formatDistance(time, now, {
      addSuffix: true,
      locale: lo,
    })
  }
}

export function formatTime(
  time: Date | number,
  style: string,
  options?: Parameters<typeof format>[2]
) {
  const lo = (() => {
    switch (i18n?.language) {
      case 'zh_CN':
        return zhCN
      case 'kr':
        return ko
      default:
        return undefined
    }
  })()
  return format(time, style, { locale: lo, ...(options || {}) })
}
