import { TFunction } from 'next-i18next'

export const getLearningPurposeOptions = (t: TFunction) => {
  return [
    {
      value: '1',
      label: t('learning-purpose.hobby'),
    },
    {
      value: '2',
      label: t('learning-purpose.pro'),
    },
    {
      value: '3',
      label: t('learning-purpose.school'),
    },
    {
      value: '4',
      label: t('learning-purpose.job'),
    },
    {
      value: '6',
      label: t('learning-purpose.video-creation'),
    },
    {
      value: '7',
      label: t('learning-purpose.esports-camp'),
    },
    {
      value: '5',
      label: t('other'),
    },
  ]
}
