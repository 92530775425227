import { useCallback, useState } from 'react'
import { NSClass } from 'gga-types'
import { getClassSyllabuses } from '@/services/class'

export type ITeamEventsState = ReturnType<typeof useTeamEventsState>

export function useTeamEventsState<
  T extends NSClass.TeamEvent = NSClass.TeamEvent
>() {
  const [teamEvents, setTeamEvents] = useState<T[]>([])
  const [courseId, setCourseId] = useState<number>()
  const [loading, setLoading] = useState(false)

  // Team list 算法
  const generateTeamList = useCallback(list => {
    list = list.filter(item => item.node_type == 'syllabus')

    const pastItems: T[] = []
    const currentItems: T[] = []
    const featureItems: T[] = []

    const now = new Date().valueOf()

    for (const item of list) {
      // 【未来的】如果结束时间小于当前时间
      if (item.schedule && new Date(item.schedule.start_at).valueOf() > now) {
        // 显示时间
        item.schedule.time = new Date(item.schedule.end_at).valueOf()
        item.schedule.lable = 'upcoming'

        featureItems.push({
          ...item,
          end: new Date(item.schedule.end_at).valueOf(),
          start: new Date(item.schedule.start_at).valueOf(),
        })
      }

      //  如果任务开始时间大于当前时间 已开始的
      if (item.schedule && new Date(item.schedule.start_at).valueOf() <= now) {
        // 【当下】
        if (new Date(item.schedule.end_at).valueOf() > now) {
          // 显示时间
          item.schedule.time = new Date(item.schedule.end_at).valueOf()
          item.schedule.lable = 'ongoing'

          currentItems.push({
            ...item,
            end: new Date(item.schedule.end_at).valueOf(),
            start: new Date(item.schedule.start_at).valueOf(),
          })
        } else {
          //【过去】
          // 显示时间
          item.schedule.time = new Date(item.schedule.end_at).valueOf()
          item.schedule.lable = 'done'

          currentItems.push({
            ...item,
            end: new Date(item.schedule.end_at).valueOf(),
            start: new Date(item.schedule.start_at).valueOf(),
          })
        }
      }
    }

    const predicate = (a: T, b: T) => {
      return b.end - a.end
    }

    // 两个数组分别 desc 降序
    pastItems.sort(predicate)
    currentItems.sort(predicate)
    featureItems.sort(predicate)

    // 过去的放前面
    const selectedItems: T[] = [
      ...pastItems.reverse(),
      ...currentItems.reverse(),
      ...featureItems.reverse(),
    ]

    return selectedItems
  }, [])

  const fetchTeamEvents = useCallback(
    (cid: number) => {
      setLoading(true)
      getClassSyllabuses(cid, { expand: ['record', 'schedule'], depth: 2 })
        .then(({ data }) => {
          if (data && data.data) {
            const list = generateTeamList(data.data)
            setTeamEvents(list)
            setLoading(false)
            setCourseId(cid)
          }
        })
        .catch(() => {
          setLoading(false)
        })
    },
    [generateTeamList]
  )

  const refreshTeamEvents = useCallback(() => {
    if (courseId) {
      fetchTeamEvents(courseId)
    } else {
      setTeamEvents([])
    }
  }, [courseId, fetchTeamEvents])

  return {
    teamEvents,
    courseId,
    refreshTeamEvents,
    loading,
    fetchTeamEvents,
  }
}
