import React from 'react'
import { useTranslation } from 'next-i18next'
import { Modal } from '@/lib/ui/modals/modal'
import { SuccessWrapper } from '@/lib/ui/success-wrapper'
import {
  Block,
  Row,
} from '@/pages/payment/success/[bizId]/components/payment-detail'
import { CoachPlatformNotice } from '@/pages/payment/success/[bizId]/components/coach-platform-notice'
import { useModalStore } from '@/store/use-modal-store'
import { formatDateTime } from '@/pages/live-session/components/helpers'

export const BookingSuccessModal = () => {
  const { t } = useTranslation('payment-status')
  const { isOpen, params } = useModalStore(state =>
    state.getModalState('BookingSuccessModal')
  )
  const closeModal = useModalStore(
    state => () => state.closeModal('BookingSuccessModal')
  )

  return (
    <Modal
      size="sm"
      centered={true}
      opened={isOpen}
      onClose={closeModal}
      closeOnClickOutside={false}
    >
      {params?.product && params.session && (
        <SuccessWrapper
          header={t('book_successful', { ns: 'coach-platform' })}
          className="-mt-4"
        >
          <div className="divide-y divider-[#666] divide-dashed border-t border-dashed">
            <Block>
              <Row label={'课程名称'} text={params?.session?.name} />
              <Row
                label={'上课时间'}
                text={formatDateTime(
                  new Date(params.session!.event_start_date_time),
                  new Date(params.session!.event_end_date_time),
                  false,
                  t
                )}
              />
            </Block>

            <Block>
              <Row label={'结算方式'} text={'点数支付'} />
              <Row
                label={'本次课程消耗点数'}
                text={`${params?.product?.token_amount}点数`}
              />
            </Block>

            <Block>
              <CoachPlatformNotice hideButton={true} />
            </Block>
          </div>
        </SuccessWrapper>
      )}
    </Modal>
  )
}
