import { Global } from '@mantine/core'

export function CustomFonts() {
  return (
    <Global
      styles={[
        {
          '@font-face': {
            fontFamily: 'Pretendard',
            src: `url('https://res.gengesports.cn/user-web/fonts/pretendard/Pretendard-Light.otf') format("OpenType")`,
            fontWeight: 300,
            fontStyle: 'normal',
          },
        },
        {
          '@font-face': {
            fontFamily: 'Pretendard',
            src: `url('https://res.gengesports.cn/user-web/fonts/pretendard/Pretendard-Regular.otf') format("OpenType")`,
            fontWeight: 'normal',
            fontStyle: 'normal',
          },
        },
        {
          '@font-face': {
            fontFamily: 'Pretendard',
            src: `url('https://res.gengesports.cn/user-web/fonts/pretendard/Pretendard-Medium.otf') format("OpenType")`,
            fontWeight: 500,
            fontStyle: 'normal',
          },
        },
        {
          '@font-face': {
            fontFamily: 'Pretendard',
            src: `url('https://res.gengesports.cn/user-web/fonts/pretendard/Pretendard-SemiBold.otf') format("OpenType")`,
            fontWeight: 600,
            fontStyle: 'normal',
          },
        },
        {
          '@font-face': {
            fontFamily: 'Pretendard',
            src: `url('https://res.gengesports.cn/user-web/fonts/pretendard/Pretendard-Bold.otf') format("OpenType")`,
            fontWeight: 'bold',
            fontStyle: 'normal',
          },
        },
      ]}
    />
  )
}
