import React from 'react'
import classNames from 'classnames'
import { Trans } from 'next-i18next'
import { CommentIcon } from '@/components/icon'

export default function NoComment() {
  return (
    <div className={classNames('text-center flex-1 flex flex-col')}>
      <div className={'flex-1 flex flex-col items-center justify-center'}>
        <div className="m-auto mt-4 mb-2">
          <CommentIcon className="inline-block w-14 h-14" />
        </div>
        <p className="text-gray-500">
          <Trans i18nKey="course_form.send_feedback" />
        </p>
      </div>
    </div>
  )
}
