export function formatDuration(str: string) {
  const sec_num = parseInt(str, 10);
  const minutes = Math.floor(sec_num / 60);
  const seconds = sec_num - minutes * 60;

  let mm = `${minutes}`;
  let ss = `${seconds}`;
  if (minutes < 10) {
    mm = `0${minutes}`;
  }
  if (seconds < 10) {
    ss = `0${seconds}`;
  }
  return `${mm}:${ss}`;
}
