import React, { Fragment, useCallback } from 'react'
import { useRouter } from 'next/router'
import classNames from 'classnames'
import { Trans, useTranslation } from 'next-i18next'
import { Menu, Transition } from '@headlessui/react'
import { Burger } from '@mantine/core'
import { useMenu } from '@/components/HeaderBar/data'
import NavLink from '@/components/NavLink'
import { useAuth } from '@/containers/auth'

export default function MobileNavLink() {
  const { i18n } = useTranslation()
  const { asPath } = useRouter()
  const { menu } = useMenu()

  const { isAuthenticated } = useAuth()

  const isActive = useCallback(
    href => {
      return asPath === href
    },
    [asPath]
  )

  return (
    <Menu as="div" className={'flex items-center'}>
      {({ open }) => {
        return (
          <>
            <Menu.Button>
              <Burger className={'px-1'} opened={open} color="#fff" />
            </Menu.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items className="absolute top-full p-4 bg-black backdrop-filter bg-opacity-90 mb-4 text-white font-bold text-2xl w-full">
                {!isAuthenticated && i18n.language !== 'zh_CN' && (
                  <Menu.Item>
                    <NavLink
                      data-gga
                      data-event-category="login_attempt"
                      href={{
                        pathname: '/login',
                        query: {
                          redirect_url: encodeURIComponent(asPath),
                        },
                      }}
                      className={classNames(
                        'no-underline block p-1 mb-2 border-b-2 border-transparent',
                        isActive('/login') && 'text-gold-400 border-gold-400'
                      )}
                    >
                      <Trans i18nKey="login" />
                    </NavLink>
                  </Menu.Item>
                )}
                {menu
                  .filter(r => (r.auth && isAuthenticated) || !r.auth)
                  .map(menu => {
                    if (menu.children?.length) {
                      return menu.children.map(m => {
                        return (
                          <Menu.Item key={m.text}>
                            {({ active }) => {
                              return (
                                <NavLink
                                  href={m.href}
                                  className={classNames(
                                    'no-underline block p-1 mb-2 border-b-2 border-transparent',
                                    isActive(m.href) &&
                                      'text-gold-400 border-gold-400'
                                  )}
                                >
                                  {m.text}
                                </NavLink>
                              )
                            }}
                          </Menu.Item>
                        )
                      })
                    }
                    return (
                      <Menu.Item key={menu.text}>
                        {({ active }) => {
                          return (
                            <NavLink
                              href={menu.href}
                              className={classNames(
                                'no-underline block p-1 mb-2 border-b-2 border-transparent',
                                isActive(menu.href) &&
                                  'text-gold-400 border-gold-400'
                              )}
                              target={menu.isBlank ? '_blank' : '_self'}
                            >
                              {menu.text}
                            </NavLink>
                          )
                        }}
                      </Menu.Item>
                    )
                  })}
              </Menu.Items>
            </Transition>
          </>
        )
      }}
    </Menu>
  )
}
