import React, {
  PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react'
import { ILocalizationCode } from 'gga-types'
import useGameState, { IGameState } from '@/context/store/useGameState'
import {
  ITeamEventsState,
  useTeamEventsState,
} from '@/context/store/useTeamEventsState'
import useCourseState, { ICourseState } from '@/context/store/useCourseState'
import {
  ISoloLessonState,
  useSoloLessonState,
} from '@/context/store/useSoloLessonState'
import { usePageLoading } from '@/components/PageLoading'
import useGameCategoryState, {
  IGameCategoryState,
} from '@/context/store/useGameCategoryState'
import useHomepageState, {
  IHomepageState,
} from '@/context/store/useHomepageState'
import useProductState, {
  IProductState,
  IProductStateProps,
} from '@/context/store/useProductState'
import useUserSettingsState, {
  IUserSettingsState,
} from '@/context/store/useUserSettingsState'
import { TWxSdkState } from '@/context/store/useWxSdkState'
import useWxSdk from '@/context/store/useWxSdk'
import useNotificationState, {
  INotificationState,
} from './useNotificationState'
import useVodLibraryState, { IVoidLibraryState } from './useVodLibraryState'
import useRandomConsultantState, {
  IRandomConsultantState,
} from './useRandomConsultantState'
import useLangState, { IUseLangState } from './useLangState'

type IStore = {
  loading: boolean
  toggleLoading: (l: boolean) => void
  game: IGameState
  teamEvents: ITeamEventsState
  course: ICourseState
  soloLessonState: ISoloLessonState
  gameCategoryState: IGameCategoryState
  homepageState: IHomepageState
  productState: IProductState
  userSettingsState: IUserSettingsState
  notificationState: INotificationState
  vodLibraryState: IVoidLibraryState
  wxSdkState: TWxSdkState
  randomConsultantState: IRandomConsultantState
  langState: IUseLangState
}

const StoreContext = createContext<IStore | any>({})

function useProvideStoreContext(serverStoreProps: TServerStoreProps = {}) {
  const { productStateProps } = serverStoreProps

  const [loading, setLoading] = useState(false)
  const { trigger } = usePageLoading()
  const toggleLoading = useCallback((l?: boolean) => {
    if (typeof l === 'undefined') {
      setLoading(prev => !prev)
    } else {
      setLoading(l)
    }
    return () => {
      setLoading(false)
    }
  }, [])

  useEffect(() => {
    trigger(loading)
  }, [loading, trigger])

  return {
    loading,
    toggleLoading,
    teamEvents: useTeamEventsState(),
    game: useGameState(),
    course: useCourseState(),
    soloLessonState: useSoloLessonState(),
    gameCategoryState: useGameCategoryState(),
    homepageState: useHomepageState(),
    productState: useProductState(productStateProps),
    userSettingsState: useUserSettingsState(),
    notificationState: useNotificationState(),
    vodLibraryState: useVodLibraryState(),
    wxSdkState: useWxSdk(),
    randomConsultantState: useRandomConsultantState(),
    langState: useLangState(),
  } as IStore
}

export type TServerStoreProps = {
  productStateProps?: IProductStateProps
}

type TProvideStoreProps = PropsWithChildren<{
  lang?: ILocalizationCode
  serverStoreProps?: TServerStoreProps
}>

export function ProvideStore({
  children,
  serverStoreProps,
}: TProvideStoreProps) {
  const value = useProvideStoreContext(serverStoreProps)
  return <StoreContext.Provider value={value}>{children}</StoreContext.Provider>
}

export function ConsumeStore({ children }) {
  return <StoreContext.Consumer>{children}</StoreContext.Consumer>
}

export function useStore(): IStore {
  return useContext(StoreContext)
}
