import { Api } from '@/api'
import { IUserProfile } from '@/@types/global'

type LoginByWechatWebParams = {
  login_code: string
}

export class UserApi extends Api {
  constructor(...args) {
    super(...args)
  }

  getUserInfo() {
    return this.createApi<void, IUserProfile>('/v2/user/info', 'get')
  }

  loginByWechatWeb(data: LoginByWechatWebParams) {
    return this.createApi<LoginByWechatWebParams, { access_token: string }>(
      `/v2/User/login/wechat/web`,
      'post',
      data
    )
  }
}
