import { ParsedUrlQuery } from 'querystring'
import { create } from 'zustand'
import { NSPromoCode } from 'gga-types'
import { getPromoCodeInfoApi } from '@/services/payment'

type PromoCodeStore = {
  promoCodes: NSPromoCode.ItemDto[]
  redeemPromoCode: (code: string) => Promise<boolean>
  getPromoCodeFromQuery: (query: ParsedUrlQuery) => void
}

export const usePromoCodeStore = create<PromoCodeStore>((set, get) => {
  const fetchPromoCode = async (code: string): Promise<boolean> => {
    const existed = get().promoCodes.find(p => p.code === code)

    if (existed) {
      return true
    }
    const response = await getPromoCodeInfoApi(code)

    if (response.success && response.data?.data) {
      const newPromo = response.data.data

      const promoCodeset = new Set(get().promoCodes)
      promoCodeset.add(newPromo)

      set(state => ({ ...state, promoCodes: Array.from(promoCodeset) }))

      return true
    }
    return false
  }

  const getPromoCodeFromQuery = async (query: ParsedUrlQuery) => {
    const code = query.promoCode as string | undefined
    if (code) {
      await fetchPromoCode(code)
    }
  }

  return {
    promoCodes: [],
    redeemPromoCode: async (code: string) => {
      return await fetchPromoCode(code)
    },
    getPromoCodeFromQuery,
  }
})
