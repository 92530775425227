import React, {
  forwardRef,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import classNames from 'classnames'
import { Button } from 'stripe-ui'
import { Trans, useTranslation } from 'next-i18next'
import styles from '@/styles/myPage.module.scss'
import { UploadIcon } from '@/components/icon'
import { ProgressBar } from '@/components/ProgressBar'
import { PendingUploadView } from '@/components/Form/Courseware/scatters'
import { IUseUploader } from '@/hooks/useUpload'
import { isFileGreaterThan } from '@/utils/file'

type ICoursewareModalUploaderProps = {
  onChange?(file: File): void
  onCancel?(): void
  onError?(err: string): void
  value?: File | undefined
  disabled?: boolean
  className?: string
  uploadState: IUseUploader
}

const CoursewareModalUploader = forwardRef(
  ({
    onChange,
    onCancel,
    value,
    disabled = false,
    className,
    onError,
    uploadState,
  }: ICoursewareModalUploaderProps) => {
    const [fileName, setFileName] = useState('')
    const [showWarning, setShowWarning] = useState(false)
    const fileValue = useRef<File>()
    const { t } = useTranslation()
    const {
      currentState: { status, uploadProgress },
      reupload,
      goPending,
    } = uploadState

    const handleFileChange = useCallback(
      (e: React.FormEvent<HTMLInputElement>) => {
        if (disabled) {
          return
        }
        const files = (e.target as HTMLInputElement).files as FileList
        if (files[0]) {
          const watchFile = files[0]
          if (watchFile.type !== 'video/mp4') {
            onError && onError(t('course_form.unsupported_file_msg'))
            return false
          }

          // File Size is greater then 10GB
          if (isFileGreaterThan(watchFile.size, 5)) {
            onError && onError(t('course_form.over_size_msg'))
            return false
          }
          if (isFileGreaterThan(watchFile.size, 2)) {
            setShowWarning(true)
          }
          setFileName(watchFile.name)
          fileValue.current = watchFile
          goPending()
        }
      },
      [disabled, goPending, onError, t]
    )

    const inputRef = React.createRef<HTMLInputElement>()

    useEffect(() => {
      fileValue.current = value
    }, [value])

    return (
      <div className={classNames(styles.uploadWrap, className)}>
        {status === 'pending' && fileValue.current ? (
          <PendingUploadView
            filename={fileValue.current.name}
            onOk={() => {
              onChange && onChange(fileValue.current!)
            }}
            onCancel={() => {
              reupload()
            }}
          />
        ) : null}
        <label
          className={classNames(styles.box, {
            [styles.hide]: status !== 'init',
          })}
        >
          <UploadIcon />
          <Button
            type="button"
            variant="brand"
            className="mt-8"
            disabled={disabled}
          >
            {t('course_form.upload')}
          </Button>
          <input
            disabled={disabled}
            ref={inputRef}
            className={styles.upload}
            type="file"
            accept="video/mp4"
            onInput={handleFileChange}
            onClick={() => {
              // 点击时重置input value可触发对相同文件的onInput事件
              if (inputRef.current) {
                inputRef.current.value = ''
              }
            }}
          />
        </label>

        {status === 'init' || status === 'pending' ? null : (
          <div className={classNames(styles.uploadProgressWrap)}>
            {uploadProgress && (
              <ProgressBar
                process={Math.floor(uploadProgress)}
                showProcess={true}
              />
            )}
            <div className="text-center">
              <span>
                {t('course_form.file_name')}: {fileName}
              </span>
              <span
                className={classNames('ml-2 underline', {
                  [styles.hide]: status !== 'uploading',
                })}
              >
                <a
                  href="#"
                  onClick={e => {
                    // cancel upload
                    e.preventDefault()
                    inputRef.current!.value = ''
                    onCancel && onCancel()
                  }}
                >
                  {t('course_form.cancel')}
                </a>
              </span>

              <span
                className={classNames('ml-2 underline', {
                  [styles.hide]: status !== 'completed' && status !== 'error',
                })}
              >
                <a
                  href="#"
                  onClick={e => {
                    // re-upload
                    e.preventDefault()
                    onCancel && onCancel()
                  }}
                >
                  {t('course_form.reupload')}
                </a>
              </span>
            </div>
            <div
              className={classNames({
                [styles.done]: status !== 'error',
                [styles.error]: status === 'error',
              })}
            >
              {status === 'completed' && (
                <Trans t={t} i18nKey="course_form.upload_completed" />
              )}
            </div>
          </div>
        )}
        <div
          className={classNames(styles.fileSizeWarning, {
            [styles.shown]: showWarning,
          })}
        >
          <Trans t={t} i18nKey="course_form.file_size_warning" />
        </div>
        <div className={styles.uploadTip}>
          <Trans t={t} i18nKey="course_form.uploading_tips" />
        </div>
      </div>
    )
  }
)

CoursewareModalUploader.displayName = 'CoursewareModalUploader'

export default CoursewareModalUploader
