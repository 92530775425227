import { useRequest } from 'ahooks'
import { IGameCategory } from 'gga-types'
import { getGameCategories } from '@/services/gameCategories'

export type IGameCategoryState = ReturnType<typeof useGameCategoryState>

export default function useGameCategoryState() {
  const { data: gameCategories, runAsync: fetchGameCategories } = useRequest<
    IGameCategory[],
    any
  >(
    async () => {
      const ret = await getGameCategories()
      if (ret && ret.data) {
        return ret.data.data
      }
      return []
    },
    {
      cacheKey: 'game-categories',
    }
  )

  return {
    fetchGameCategories,
    gameCategories,
  }
}
