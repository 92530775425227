import Link from 'next/link'
import { Button } from '@mantine/core'
import ImageComponent from '@/components/ImageComponent'

export const TrialNotice = () => {
  return (
    <div className="space-y-8">
      <div className="rounded-lg bg-gradient-to-r from-[#F6F6F6]/100 to-[#F6F6F6]/0 p-3 flex justify-start items-center space-x-3">
        <div>
          <p className="font-semibold">注意事项:</p>
          <div className="mt-2.5 space-y-2.5">
            <p>
              1、购买成功后，可直接进入
              <span className="font-semibold">GGA</span>
              小程序选择训练时间（选择价格为69点数的训练即可直接预约，点数余额可在个人中心查看）；
            </p>
            <div className="flex space-x-2">
              <div className="space-y-2.5">
                <p>
                  2、需使用<span className="font-semibold">购买手机号</span>
                  登录小程序；
                </p>
                <p>3、购买后有效期为一个月（一个月内预约训练时间即可）；</p>
                <p>4、适用于LOL和Valorant两种游戏；</p>
              </div>
              <div className="flex-none">
                <ImageComponent
                  src="https://res.gengesports.cn/user-web/images/mini-app-code%403x.png"
                  className="w-[100px] h-[100px]"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="flex flex-col items-center space-y-4">
        <Link href="https://www.gengacademy.com/webview/_miniapp/wechat/miniapp-proxy?_scheme_uid=38d7dec91b46466f98e08942a799a086">
          <Button className="!w-[288px]">前往小程序立即预约</Button>
        </Link>
      </div>
    </div>
  )
}
