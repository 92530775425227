import React, { useCallback, useMemo, useState } from 'react'
import { v4 as uuid } from 'uuid'
import ReactDOM from 'react-dom'
import dynamic from 'next/dynamic'
import { useTranslation } from 'next-i18next'
import { isMobile } from 'react-device-detect'
import { getVideoFileSrc } from '@/utils/video'
import { OSSCdnUrl, OSS_CONSTANT } from '@/config/config'
import { OssResourceUtils } from '@/utils/oss-resource.utils'

function _escapeSpecialCharacter(str: string) {
  return str.replace(/[-/\\^$*+?.()|[\]{}]/g, '\\$&')
}

function replaceVideo(content: string) {
  const videoRegex = /<video\s[^>]*?src\s*=\s*['"]([^'"]*?)['"][^>]*?>/
  const regexGlobal = new RegExp(videoRegex, 'g')
  let data = content || ''
  const videos = [] as { id: string; exec: RegExpExecArray }[]
  let result
  // eslint-disable-next-line no-cond-assign
  while ((result = regexGlobal.exec(data))) {
    videos.push({ id: uuid(), exec: result })
  }

  data = data.replace(/<\/video>/g, '')
  videos.forEach(re => {
    data = data.replace(
      new RegExp(re.exec[0]),
      `<div id="${re.id}" class="relative"/>`
    )
  })
  return { data, videos }
}

function replaceImage(content: string) {
  const url1 = _escapeSpecialCharacter(OssResourceUtils.getDownloadRootUrl())
  const url2 = _escapeSpecialCharacter(OssResourceUtils.getAccelerateUrl())
  const regex1 = new RegExp('<img [^>]*src="' + url1 + '([^"]*)"', 'g')
  const regex2 = new RegExp('<img [^>]*src="' + url2 + '([^"]*)"', 'g')
  return content
    .replace(regex1, '<img src="' + OssResourceUtils.getCdnUrl() + '$1"')
    .replace(regex2, '<img src="' + OssResourceUtils.getCdnUrl() + '$1"')
}

const GreatVideoPlayerDynamic = dynamic(
  () => import('@/UI-React/src/GreatVideoPlayer'),
  { ssr: false }
)
type IUseRawContent = {
  content: string
  accelerate?: boolean
}
export default function useRawContent({
  content = '',
  accelerate = true,
}: IUseRawContent) {
  const [videoArr, setVideoArr] = useState<
    { id: string; exec: RegExpExecArray }[]
  >([])

  const { t } = useTranslation()

  const parsed = useMemo(() => {
    const _content = replaceImage(content)

    const { videos, data } = replaceVideo(_content)

    setVideoArr(videos)
    return data
  }, [content])

  const replaceHost = useCallback((url: string, host: string) => {
    try {
      const link = new URL(url)
      const { origin } = link
      return url.replace(new RegExp(origin), host)
    } catch (e) {
      console.error(`Cannot parse url ${url}`, e)
      return url
    }
  }, [])

  const renderReplacedVideo = useCallback(async () => {
    for (let i = 0; i < videoArr.length; i++) {
      const exec = videoArr[i]
      let url = exec.exec[1]
      url = new OssResourceUtils(url).getNormalizedUrl()
      // eslint-disable-next-line no-await-in-loop
      const res = await getVideoFileSrc(url)

      if (document.getElementById(exec.id)) {
        ReactDOM.render(
          <div className={'relative'}>
            <GreatVideoPlayerDynamic
              style={{
                height: isMobile ? undefined : 640,
              }}
              init={() => {}}
              config={{
                id: exec.id,
                videoSrc: res.src,
                controllable: false,
                recordable: false,
                type: res.type,
                sources: res && res.hls ? res.hls : [],
                definitionTipsConfig: {
                  label: t('components.greatvideo.switchdefinitiontips'),
                },
              }}
            />
          </div>,
          document.getElementById(exec.id)
        )
      }
    }
  }, [t, videoArr])

  return {
    videoArr,
    parsed,
    replaceHost,
    renderReplacedVideo,
  }
}
