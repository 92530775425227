import { MantineProvider } from '@mantine/core'
import { ModalsProvider } from '@mantine/modals'
import { NextComponentType } from 'next'
import { useTranslation } from 'next-i18next'
import Head from 'next/head'
import { useRouter } from 'next/router'
import React, {
  FC,
  PropsWithChildren,
  ReactNode,
  useEffect,
  useMemo,
} from 'react'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'
import { Slide, ToastContainer } from 'react-toastify'
import { IPageProps } from '@/@types/global'
import { IconParkScript } from '@/components/IconPark'
import KRLayout from '@/components/kr/Layout'
import ZhCNLayout from '@/components/zh_CN/Layout'
import { APP_LOCALE, GOOGLE_RECAPTCHA_KEY } from '@/constants/define'
import { AuthProvider, useAuth } from '@/containers/auth'
import { ProvideStore, useStore } from '@/context/store'
import { isBrowser } from '@/services/Utils'
import {
  ChannelIOService,
  channelIOLanguage,
} from '@/services/channel-io-service'
import { setReferrer } from '@/utils/referrerHelper'
import { utmHelper } from '@/utils/utmHelper'
import { ZhCNAuthModal } from '../zh_CN/modals/ZhCNAuthModal'
import { AddCreditModal } from '../Modals/add-credit-modal'
import { SelectCouponModal } from '../Modals/select-coupon-modal'
import { BookingSuccessModal } from '../Modals/booking-success-modal'
import { CustomFonts } from './CustomFonts'
import myCache from './emotion-cache'
import MetaTagsEnUS from './meta-tags/en_US'
import MetaTagsKr from './meta-tags/kr'
import MetaTagsZhCN from './meta-tags/zh_CN'
import mantineTheme from './theme'

const MetaComponent = (() => {
  switch (APP_LOCALE) {
    case 'kr':
      return MetaTagsKr
    case 'zh_CN':
      return MetaTagsZhCN
    case 'en_US':
      return MetaTagsEnUS
    default:
      return null
  }
})()

const DefaultLayout = (() => {
  switch (APP_LOCALE) {
    case 'zh_CN':
      return ZhCNLayout
    default:
      return KRLayout
  }
})()

export const AppShell: FC<PropsWithChildren<IPageProps>> = ({
  children,
  ...restProps
}) => {
  const { serverStoreProps } = restProps
  return (
    <GoogleReCaptchaProvider
      useRecaptchaNet={true}
      reCaptchaKey={GOOGLE_RECAPTCHA_KEY}
    >
      <AuthProvider>
        <ProvideStore serverStoreProps={serverStoreProps}>
          {children}
        </ProvideStore>
      </AuthProvider>
    </GoogleReCaptchaProvider>
  )
}

type IAppComponentProps = {
  Component: NextComponentType & {
    getLayout: ReactNode
  }
  pageProps: IPageProps
}

const DefaultPageProps: Partial<IPageProps> = {
  isOfficial: true,
}

export function AppComponent({
  Component,
  pageProps: _pageProps,
}: IAppComponentProps) {
  const pageProps = useMemo(
    () => ({ ...DefaultPageProps, ..._pageProps }),
    [_pageProps]
  )
  const { refreshUser, readUser, user } = useAuth()
  const { i18n } = useTranslation()
  const {
    homepageState: { fetchConfig },
  } = useStore()

  const { asPath } = useRouter()

  useEffect(() => {
    // update user info to channel.io
    if (user && APP_LOCALE !== 'zh_CN') {
      ChannelIOService.instance.updateUser({
        language: channelIOLanguage(APP_LOCALE),
        profile: {
          name: user.name,
          email: user.email,
          mobileNumber: `+${user.phone_code}${user.phone_number}`,
          avatarUrl: user.avatar_url,
        },
      })
    }
  }, [user])

  useEffect(() => {
    // set external site referrer
    if (isBrowser) {
      setReferrer()
      utmHelper.init()
    }
  }, [])

  const MemoLayout = useMemo(() => {
    return (Component.getLayout || DefaultLayout) as any
  }, [Component.getLayout])

  useEffect(() => {
    readUser()
      .then(() => {
        return refreshUser()
      })
      .then(() => {
        fetchConfig()
      })
  }, [fetchConfig, readUser, refreshUser])

  return (
    <main>
      <Head>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, minimum-scale=1.0, maximum-scale=1.0, user-scalable=no"
        />
        {MetaComponent && (
          <MetaComponent asPath={asPath} pageProps={pageProps} />
        )}
        <IconParkScript />
      </Head>

      <MantineProvider
        withNormalizeCSS
        emotionCache={myCache}
        theme={mantineTheme(i18n.language)}
      >
        <CustomFonts />
        <ModalsProvider
          modalProps={{
            radius: 'md',
            padding: 'md',
            sx: theme => {
              return {
                '.mantine-Modal-close': {
                  color: 'white',
                  background: theme.colors.brand[5],
                  borderRadius: '50%',
                  ':hover': {
                    background: theme.colors.brand[3],
                  },
                },
              }
            },
          }}
        >
          <MemoLayout {...pageProps}>
            {/* nextJS component */}
            <React.Fragment>
              <Component {...pageProps} />
            </React.Fragment>
          </MemoLayout>

          <ToastContainer
            theme="dark"
            position="top-right"
            autoClose={3000}
            hideProgressBar
            newestOnTop={false}
            transition={Slide}
            closeOnClick
            draggable={false}
            pauseOnHover
          />
          <ZhCNAuthModal />

          <AddCreditModal />
          <SelectCouponModal />
          <BookingSuccessModal />
        </ModalsProvider>
      </MantineProvider>
    </main>
  )
}
