import { isSameDay } from 'date-fns'
import { formatTime } from '@/utils/time'

export const formatDateTime = (
  start: Date,
  end: Date,
  isAllDay: boolean,
  t
) => {
  const isEndedSameDay = isSameDay(start, end)

  if (isAllDay) {
    const endRender = isEndedSameDay ? '' : ` ~ ${formatTime(end, 'M/d iii')}`
    return `${formatTime(start, 'M/d iii')}${endRender} ${t('all_day')}`
  } else {
    if (isEndedSameDay) {
      return `${formatTime(start, 'M/d iii H:mm ~')} ${formatTime(end, 'H:mm')}`
    } else {
      return `${formatTime(start, 'M/d iii H:mm ~')} ${formatTime(
        end,
        'M/d iii H:mm'
      )}`
    }
  }
}

export const formatDateTimeFull = (
  start: Date,
  end: Date,
  isAllDay: boolean,
  t
) => {
  const isEndedSameDay = isSameDay(start, end)

  if (isAllDay) {
    const endRender = isEndedSameDay ? '' : ` ~ ${formatTime(end, 'M/d iii')}`
    return `${formatTime(start, 'M/d iii')}${endRender} ${t('all_day')}`
  } else {
    if (isEndedSameDay) {
      return `${formatTime(start, 'M/dd iii H:mm - ')} ${formatTime(
        end,
        'H:mm'
      )}`
    } else {
      return `${formatTime(start, 'M/dd iii H:mm - ')} ${formatTime(
        end,
        'M/dd iii H:mm'
      )}`
    }
  }
}
