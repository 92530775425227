import { Button } from 'stripe-ui'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import classNames from 'classnames'
import { Circle } from 'rc-progress'
import { useCountDown } from 'ahooks'
import { addMinutes } from 'date-fns'
import { Trans, useTranslation } from 'next-i18next'
import { NSClass } from 'gga-types'
import { getDuration, IDuration } from '@/utils/time'

function formatQuizTimer(d: IDuration) {
  const mm = d.minutes < 10 ? `${0}${d.minutes}` : d.minutes
  const ss = d.seconds < 10 ? `${0}${d.seconds}` : d.seconds

  return [d.hours ? d.hours : '', `${mm} min`, `${ss} sec`].join(' ').trim()
}

type ISubviewProps = {
  quiz: NSClass.QuizItem
}

const transScope = str => {
  return 'components.courseware_modal.quiz_modal_content.' + str
}

export function QuizPendingView({
  quiz,
  onStart,
  canStart,
  lastTrialTime,
}: ISubviewProps & {
  onStart: () => void
  canStart: boolean
  lastTrialTime?: string
}) {
  const { t } = useTranslation()
  return (
    <div>
      <h1 className="text-center text-2xl font-bold mb-2">Quiz</h1>
      <div className="flex text-lg">
        <span className="flex-1 text-right mr-16">
          <Trans
            i18nKey={transScope('quiz_pending_view.label.question_count')}
          />
          : {quiz.exam.length}
        </span>
        <span className="flex-1">
          <Trans i18nKey={transScope('quiz_pending_view.label.time_limit')} />:{' '}
          {formatQuizTimer(getDuration(String(quiz.time_limit)))}
        </span>
      </div>
      <p className="px-8 py-4 mb-10 text-lg">
        <p
          dangerouslySetInnerHTML={{
            __html: t(transScope('quiz_pending_view.disclaimer'), {
              pass_line: `<b>${quiz.pass_line}</b>`,
              interpolation: { escapeValue: false },
            }),
          }}
        />
        <br />
        <b>
          <Trans
            i18nKey={transScope('quiz_pending_view.important')}
            values={{
              pass_line: quiz.pass_line,
            }}
          />
        </b>
      </p>
      <div className="flex items-center justify-center">
        <Button
          variant="brand"
          type="button"
          size="large"
          disabled={!canStart}
          onClick={onStart}
        >
          <Trans i18nKey={transScope('quiz_pending_view.label.start')} />
        </Button>
      </div>
      {!canStart && lastTrialTime && (
        <div className="my-2">
          <TryLaterView lastTrialTime={lastTrialTime} />
        </div>
      )}
    </div>
  )
}

type IQuizQuestionViewProps = ISubviewProps & {
  userAnswer: NSClass.QuizAnswer
  officialAnswer?: NSClass.QuizAnswer
  timeLeft: number
  current: number
  onNext?: () => void
  onPrev?: () => void
  onFinish?: () => void
  onClick?: (qid: string, val: string[]) => void
  className?: string
}

export function QuizQustionView({
  current,
  quiz,
  timeLeft,
  onClick,
  userAnswer,
  onNext,
  onPrev,
  onFinish,
  className,
}: IQuizQuestionViewProps) {
  const currentQ = useMemo(() => {
    return quiz.exam[current]
  }, [current, quiz.exam])

  const answerSet = useMemo(
    () => new Set(userAnswer[currentQ.id]),
    [currentQ.id, userAnswer]
  )

  const isSelected = useCallback(
    (optVal: string) => {
      return answerSet.has(optVal)
    },
    [answerSet]
  )

  const handleFinish = useCallback(() => {
    // TODO: add confirm
    onFinish && onFinish()
  }, [onFinish])

  return (
    <div className={classNames(className)}>
      <div className="text-center">
        {formatQuizTimer(getDuration(String(timeLeft / 1000)))}
      </div>
      <div className="text-center text-2xl font-bold">{currentQ.question}</div>
      <div className="text-center text-lg font-semibold text-gray-500">
        <div>{`${current + 1} / ${quiz.exam.length}`}</div>
      </div>
      <div className="p-8">
        {currentQ.type === 'checkbox' && (
          <div className="mb-2">Choose more than one below: </div>
        )}
        {currentQ.options.map(opt => {
          const checked = isSelected(opt.value)
          return (
            <div
              className={classNames(
                'border border-gray-300 rounded-full cursor-pointer max-w-1/3 text-lg mb-4 px-4 py-1',
                checked && 'text-white border-gold-200 bg-gold-300'
              )}
              key={opt.value}
              onClick={() => {
                const curAnswer = new Set(userAnswer[currentQ.id])
                let newAnswer
                if (currentQ.type === 'checkbox') {
                  if (checked) {
                    curAnswer.delete(opt.value)
                  } else {
                    curAnswer.add(opt.value)
                  }
                  newAnswer = Array.from(curAnswer)
                } else {
                  if (checked) {
                    newAnswer = []
                  } else {
                    newAnswer = [opt.value]
                  }
                }
                onClick && onClick(currentQ.id, newAnswer)
              }}
            >
              <p className="px-4">{opt.label}</p>
            </div>
          )
        })}
      </div>
      <div className="flex justify-between">
        <div>
          <Button
            variant="base"
            onClick={() => onPrev && onPrev()}
            disabled={current === 0}
          >
            <Trans i18nKey={transScope('quiz_question_view.label.prev')} />
          </Button>
        </div>
        <div>
          {current < quiz.exam.length - 1 ? (
            <Button
              disabled={!answerSet.size}
              onClick={() => onNext && onNext()}
            >
              <Trans i18nKey={transScope('quiz_question_view.label.next')} />
            </Button>
          ) : (
            <Button
              variant="brand"
              onClick={handleFinish}
              disabled={!answerSet.size}
            >
              <Trans i18nKey={transScope('quiz_question_view.label.finish')} />
            </Button>
          )}
        </div>
      </div>
    </div>
  )
}

type IQuizAnswerViewProps = ISubviewProps & {
  userAnswer: NSClass.QuizAnswer
  officialAnswer: NSClass.QuizAnswer
  current: number
  onNext: () => void
  onPrev: () => void
  onBack: () => void
}

export function QuizAnswerView({
  quiz,
  userAnswer,
  officialAnswer,
  current,
  onNext,
  onPrev,
  onBack,
}: IQuizAnswerViewProps) {
  const currentQ = useMemo(() => {
    return quiz.exam[current]
  }, [current, quiz.exam])

  const answerSet = useMemo(
    () => new Set(userAnswer[currentQ.id]),
    [currentQ.id, userAnswer]
  )

  const correctAnswerSet = useMemo(
    () => new Set(officialAnswer[currentQ.id]),
    [currentQ.id, officialAnswer]
  )

  const isCorrect = useMemo(() => {
    return (
      correctAnswerSet.size === answerSet.size &&
      [...Array.from(correctAnswerSet)].every(value => answerSet.has(value))
    )
  }, [answerSet, correctAnswerSet])

  const isSelected = useCallback(
    (optVal: string) => {
      return answerSet.has(optVal)
    },
    [answerSet]
  )

  const isCorrectOption = useCallback(
    (optVal: string) => {
      return correctAnswerSet.has(optVal)
    },
    [correctAnswerSet]
  )

  return (
    <div>
      <div className="mb-4">
        <Button onClick={onBack}>
          <Trans i18nKey={transScope('quiz_question_view.label.back')} />
        </Button>
      </div>
      <div className="text-center text-2xl font-bold">{currentQ.question}</div>
      <div className="text-center text-lg font-semibold text-gray-500">
        <div>{`${current + 1} / ${quiz.exam.length}`}</div>
      </div>
      <div className="p-8">
        {currentQ.options.map(opt => {
          const checked = isSelected(opt.value)
          const correct = isCorrectOption(opt.value)
          return (
            <div
              className={classNames(
                'border border-gray-300 rounded-full max-w-1/3 text-lg mb-4 px-4 py-1',
                correct && checked && 'text-white border-gold-200 bg-gold-300',
                correct &&
                  !checked &&
                  'text-white border-green-500 bg-green-500',
                !correct && checked && 'text-white border-red-500 bg-red-500'
              )}
              key={opt.value}
            >
              <p className="px-4">{opt.label}</p>
            </div>
          )
        })}
      </div>
      <div className="flex justify-between items-end">
        <Button variant="base" onClick={onPrev} disabled={current === 0}>
          <Trans i18nKey={transScope('quiz_question_view.label.prev')} />
        </Button>

        {isCorrect ? (
          <div className="text-2xl text-green-500">
            <Trans i18nKey={transScope('quiz_question_view.correct')} />
          </div>
        ) : (
          <div className="text-2xl text-red-500">
            <Trans i18nKey={transScope('quiz_question_view.wrong')} />
          </div>
        )}

        <Button disabled={current === quiz.exam.length - 1} onClick={onNext}>
          <Trans i18nKey={transScope('quiz_question_view.label.next')} />
        </Button>
      </div>
    </div>
  )
}

type IQuizResultViewProps = {
  rank: number
  isSuccess: boolean
  onClose: () => void
  onRetry: () => void
  onViewAnswer?: () => void
  className?: string
}

export function QuizResultView({
  rank,
  isSuccess,
  onClose,
  onRetry,
  onViewAnswer,
  className,
}: IQuizResultViewProps) {
  return (
    <div className={classNames(className)}>
      <div className="text-center text-lg font-semibold mb-8">
        {isSuccess ? (
          <Trans i18nKey={transScope('quiz_result_view.passed')} />
        ) : (
          <Trans i18nKey={transScope('quiz_result_view.not_pass')} />
        )}
      </div>
      <div className="relative w-52 mx-auto text-center mb-8">
        <div
          className={classNames(
            'absolute top-1/2 left-1/2 transform -translate-y-1/2 -translate-x-1/2 text-lg',
            isSuccess ? 'text-green-500' : 'text-red-500'
          )}
        >
          {Math.floor(rank)}%
        </div>
        <Circle
          percent={rank}
          strokeColor="#3AB981"
          strokeWidth={10}
          trailWidth={10}
          trailColor="#EF4444"
        />
      </div>
      <div className="text-center">
        <div className="inline-block w-1/2 mx-auto">
          <div className="flex justify-around items-center">
            <Button onClick={onRetry}>
              <Trans i18nKey={transScope('quiz_result_view.label.retry')} />
            </Button>
            {isSuccess && (
              <Button
                onClick={() => {
                  onViewAnswer && onViewAnswer()
                }}
              >
                <Trans
                  i18nKey={transScope('quiz_result_view.label.view_answer')}
                />
              </Button>
            )}
            <Button onClick={onClose}>
              <Trans i18nKey={transScope('quiz_result_view.label.close')} />
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}

type ITryLaterView = {
  lastTrialTime: string
}

export function TryLaterView({ lastTrialTime }: ITryLaterView) {
  const [targetDate, setTargetDate] = useState<number>()
  const [_, formattedResult] = useCountDown({ targetDate })

  useEffect(() => {
    setTargetDate(addMinutes(new Date(lastTrialTime), 10).getTime())
  }, [lastTrialTime, setTargetDate])
  return (
    <div className="text-center mx-auto text-red-500">
      <div className="text-lg">
        <Trans
          i18nKey={transScope('try_later_view.try_later')}
          values={{
            minutes: formattedResult.minutes,
          }}
        />
      </div>
    </div>
  )
}
