import classNames from 'classnames'
import React from 'react'

export const LogoBg = ({
  children,
  className,
}: {
  className?: string | null
  children: React.ReactNode
}) => {
  return (
    <div
      className={classNames(
        'bg-darkjungle bg-fixed	bg-no-repeat bg-center bg-contain md:bg-auto h-content',
        className
      )}
      style={{ backgroundImage: 'url(/images/homepage/bg-logo.png)' }}
    >
      {children}
    </div>
  )
}
