import { useCallback, useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import { loadWxSdk, TWxSDK } from '@/services/wechat-miniapp'

type TNavigationSuccessRes = {
  eventChannel: any
}

// https://developers.weixin.qq.com/miniprogram/dev/api/route/wx.navigateTo.html
type TNavigationOptions = {
  events: object
  success: (res?: TNavigationSuccessRes) => void
  fail: () => void
  complete: () => void
}

export const MiniprogramKit = (SDK: TWxSDK) => {
  return {
    postMessage: (message: object) => {
      SDK.miniProgram.postMessage(message)
    },
    navigateTo: (path: string, options: Partial<TNavigationOptions> = {}) => {
      SDK.miniProgram.navigateTo({
        url: path,
        ...options,
      })
    },
    redirectTo: (path: string, options: Partial<TNavigationOptions> = {}) => {
      SDK.miniProgram.redirectTo({
        url: path,
        ...options,
      })
    },
  }
}

export default function useWxSdk() {
  const [sdk, setSdk] = useState<TWxSDK>()
  const [isLoaded, setIsLoaded] = useState<boolean>(false)
  const router = useRouter()

  useEffect(() => {
    loadWxSdk().then(res => {
      setSdk(res)
    })
  }, [])

  useEffect(() => {
    if (sdk) {
      sdk.miniProgram.getEnv(res => {
        setIsLoaded(res.miniprogram)
      })
    }
  }, [sdk])

  const navigateToLogin = useCallback(() => {
    sdk?.miniProgram.navigateTo({
      url: `/pages/login/index?redirect_url=${encodeURIComponent(
        router.asPath
      )}`,
    })
  }, [router.asPath, sdk?.miniProgram])

  const navigateToShop = useCallback(() => {
    sdk?.miniProgram.switchTab({
      url: '/pages/shop/index',
    })
  }, [sdk?.miniProgram])

  return {
    isLoaded,
    miniProgram: sdk && isLoaded ? MiniprogramKit(sdk) : null,
    navigateToLogin,
    navigateToShop,
  }
}
