import * as Yup from 'yup'
import { i18n } from 'next-i18next'
import { get } from 'lodash'
import { phoneNumberUtil } from 'gga-fe-lib'
import { getCheckDupPhone } from '@/services/user'

Yup.addMethod(Yup.string, 'phoneNumber', function (phoneCode: string) {
  return this.test(
    'phoneNumber',
    () => {
      return i18n?.t('phone_number_invalid', { ns: 'validation' })
    },
    (value, testContext) => {
      if (!value) {
        return true
      }
      // isMobilePhone 如果填写+86这样的是合法的。。。。
      if (/^\+/.test(value)) {
        return false
      }

      const validator = phoneNumberUtil.getValidatorByCode(
        testContext.parent[phoneCode]
      )

      try {
        const bool = validator?.(value) || false
        return bool
      } catch (error) {
        return false
      }
    }
  )
})

Yup.addMethod(Yup.string, 'chinaPhoneNumber', function () {
  return this.test(
    'chinaPhoneNumber',
    () => {
      return i18n?.t('phone_number_invalid', { ns: 'validation' })
    },
    value => {
      if (!value) {
        return true
      }

      const validator = phoneNumberUtil.getValidatorByCode('86')

      try {
        const bool = !!validator && validator(`+86${value}`)
        return bool
      } catch (error) {
        return false
      }
    }
  )
})

Yup.addMethod(Yup.string, 'omitStartZero', function () {
  return this.test(
    'omitStartZero',
    () => {
      return i18n?.t('register.phone_nubmer_cannot_start_with_zero')
    },
    value => {
      if (!value) {
        return true
      }
      return !/^0/.test(value)
    }
  )
})

Yup.addMethod(Yup.string, 'availablePhone', function (phoneCode: string) {
  return this.test(
    'availablePhone',
    () => {
      return i18n?.t('register.phone_number_exists')
    },
    async (value, testContext) => {
      if (!value) {
        return true
      } else {
        let normalizedPhoneNumber = value
        const code = testContext.parent[phoneCode] || '86'
        if (code === '82' && value?.startsWith('0')) {
          normalizedPhoneNumber = value.slice(1)
        }

        if (
          (this as any).lastValidation?.phoneNumber === normalizedPhoneNumber
        ) {
          return (this as any).lastValidation.valid
        }

        const res = await getCheckDupPhone({
          phone_code: code,
          phone_number: normalizedPhoneNumber,
        })
        const valid = get(res, 'data.data')

        ;(this as any).lastValidation = {
          valid: valid,
          phoneNumber: normalizedPhoneNumber,
        }

        return valid
      }
    }
  )
})

Yup.setLocale({
  string: {
    min: _ref => {
      return i18n?.t('string_min_err', {
        ns: 'validation',
        key: {
          min: _ref.min,
        },
        min: _ref.min,
      })
    },
  },
  mixed: {
    required: () => {
      return i18n?.t('string_required_err', {
        ns: 'validation',
      })
    },
  },
})

export default Yup
