const HLS_PREFIX = '/Act-m3u8-segment/'
const QUALITIES = ['720', '480', '360'] as const

/**
 * @description Check video src whether it has a HLS version
 */
const checkVideoSrc = async (src: string) => {
  try {
    const res = await fetch(src, {
      method: 'HEAD',
      referrer: 'no-referrer',
    })
    if (res && res.status === 200) {
      return true
    }
  } catch (e) {
    if (e.message !== 'Source not valid') {
      console.log(e)
    }
    return false
  }
}

const getVideoFilename = (src: string) => {
  const seg = src.split('/')
  const candidate = seg[seg.length - 1]
  return candidate.replace(/\.mp4$/, '')
}

export type IGetVideoFileSrcRet = {
  type: 'hls' | 'mp4'
  src: string
  hls?: {
    name: typeof QUALITIES[number]
    url: string
  }[]
}

function genURL(filename: string, q: string, o: string) {
  return `${o}${HLS_PREFIX}${filename}/${filename}.${q}.m3u8`
}

export async function getVideoFileSrc(
  src: string
): Promise<IGetVideoFileSrcRet> {
  const filename = getVideoFilename(src)
  const url = new URL(src)
  const { origin } = url

  const checks = QUALITIES.map(q => {
    const link = genURL(filename, q, origin)
    return checkVideoSrc(link)
  })

  const result = await Promise.all(checks)

  const hls = result
    .map((r, i) => {
      if (r) {
        return {
          name: QUALITIES[i],
          url: genURL(filename, QUALITIES[i], origin),
        }
      }
      return null
    })
    .filter(r => r !== null)
  if (!hls || !hls.length) {
    return {
      type: 'mp4',
      src,
    }
  }

  return {
    type: 'hls',
    src: hls[0]!.url,
    hls,
  }
}
