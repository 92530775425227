import React, { useCallback, useEffect, useMemo, useRef } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { v4 as uuidv4 } from 'uuid'
import { Trans, useTranslation } from 'next-i18next'
import classNames from 'classnames'
import CoursewareModalUploader from '@/components/uploader/CoursewareModalUploader'
import useUpload from '@/hooks/useUpload'
import { getHomeworkAwsUploadUrl } from '@/services/class'
import { ICourseVideoUploadForm, ReturnType } from './types.d'

export const VideoUploadForm = ({
  className,
  onSubmit,
  requestClose,
  onModalClose,
  submissionStatus,
}: ICourseVideoUploadForm) => {
  const { t, i18n } = useTranslation()
  const canSubmit = useMemo(() => {
    return submissionStatus == null || submissionStatus.can_submit
  }, [submissionStatus])
  const fileNameRef = useRef<string>()
  const fileInfo = useRef<{ name: string; size: number }>()

  const uploadState = useUpload()
  const {
    currentState,
    OSSUpload: { multipart: ossUpload, cancelUpload: ossCancelUpload },
    S3Upload,
    reupload,
  } = uploadState

  const {
    handleSubmit,
    setError,
    setValue,
    control,
    watch,
    reset,
    formState: { errors, isSubmitted },
  } = useForm({
    mode: 'all',
  })

  useEffect(() => {
    if (currentState.status === 'completed') {
      if (currentState.target === 'aws') {
        const regex = new RegExp(`(${fileNameRef.current}).*`)
        const url = (currentState.payload as string).replace(regex, '$1')
        setValue('url', url)
        setValue('file_name', 'courseware/' + fileNameRef.current, {
          shouldValidate: true,
        })
      } else {
        const { OSSClient, result } = currentState.payload || {}
        const url = `https://${OSSClient.options.bucket}.${OSSClient.options.endpoint.host}/${result.name}`
        setValue('url', url, { shouldValidate: true })
        setValue('file_name', result.name, { shouldValidate: true })
      }
    }
  }, [currentState.payload, currentState.status, currentState.target, setValue])

  // const uploadStatusMessage = useMemo(() => {
  //   switch (currentState.status) {
  //     case "init":
  //       return "Init";
  //     case "completed":
  //       return "Upload Completed";
  //     case "error":
  //       return "Upload Failed, Please try again";
  //     case "uploading":
  //       return "Uploading...";
  //   }
  // }, [currentState.status]);

  const handleCancelUpload = useCallback(() => {
    if (i18n.language === 'kr') {
      S3Upload.cancelUpload()
    } else {
      ossCancelUpload()
    }
    ossCancelUpload()
    reupload()
    reset()
  }, [S3Upload, i18n.language, ossCancelUpload, reset, reupload])

  // 关闭modal取消上传
  useEffect(() => {
    if (requestClose) {
      handleCancelUpload()
    }
  }, [handleCancelUpload, requestClose])

  const submitForm = useCallback(
    data => {
      onModalClose && onModalClose(undefined)
      onSubmit({
        ...data,
        file_upload_platform: currentState.target,
        file_size: fileInfo.current?.size,
      })
    },
    [currentState.target, onModalClose, onSubmit]
  )

  const watchFile = watch('file')

  const uploadProcess = useCallback(async () => {
    fileNameRef.current = uuidv4() + '.mp4'
    fileInfo.current = {
      name: watchFile.name,
      size: watchFile.size,
    }

    if (i18n.language === 'kr') {
      // get homework presign url
      const { data } = await getHomeworkAwsUploadUrl(fileNameRef.current)
      if (data && data.data) {
        await S3Upload.multipart(data.data, watchFile)
      }
    } else {
      await ossUpload({
        file: watchFile,
        filename: `courseware/${fileNameRef.current}`,
      })
    }
  }, [S3Upload, i18n.language, ossUpload, watchFile])

  useEffect(() => {
    if (currentState.status === 'completed' && canSubmit && !isSubmitted) {
      handleSubmit<ReturnType>(submitForm)()
    }
  }, [canSubmit, currentState.status, handleSubmit, isSubmitted, submitForm])

  useEffect(() => {
    if (watchFile) {
      uploadProcess()
    }
  }, [uploadProcess, watchFile])

  useEffect(() => {
    if (currentState.status === 'uploading' && onModalClose) {
      onModalClose(() => {
        return () =>
          Promise.resolve(
            confirm(
              t(
                'components.courseware_modal.upload_form_close_confirm.confirm_tip'
              )
            )
          )
      })
    }
  }, [currentState.status, onModalClose, t])

  return (
    <form className={classNames(className)}>
      {uploadState.currentState.status === 'error' ? (
        <div className="text-red-600 text-lg text-center text-bold">
          {t(
            'components.courseware_modal.video_upload_error',
            'There was something wrong with the network, please try it again.'
          )}
        </div>
      ) : null}
      <Controller
        name="file"
        control={control}
        render={({ formState, field: { onChange, value } }) => (
          <CoursewareModalUploader
            onChange={onChange}
            value={value}
            onCancel={handleCancelUpload}
            disabled={!canSubmit}
            uploadState={uploadState}
            onError={e => {
              setError('file', {
                type: 'manual',
                message: e,
              })
            }}
          />
        )}
      />

      {errors.file && (
        <div className="text-center text-red-500 mx-auto my-4">
          {errors.file.message}
        </div>
      )}

      {submissionStatus && submissionStatus.submission_limit ? (
        <div className={classNames('text-center font-bold mb-3 text-black')}>
          {submissionStatus.submission_limit_unit === 'week' ? (
            <Trans
              t={t}
              i18nKey="course_form.weekly_submit_limit"
              values={{
                count: submissionStatus.remain_count,
              }}
            />
          ) : (
            <Trans
              t={t}
              i18nKey="course_form.submit_limit"
              values={{
                count: submissionStatus.remain_count,
              }}
            />
          )}
        </div>
      ) : null}
    </form>
  )
}
