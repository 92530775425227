import { Button, Checkbox, Stack } from '@mantine/core'
import { Trans, useTranslation } from 'next-i18next'
import { useCallback, useMemo, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { get } from 'lodash'
import { useId } from '@mantine/hooks'
import { yupResolver } from '@hookform/resolvers/yup'
import PhoneNumberInput from '@/components/inputs/PhoneNumberInput'
import { SmsTypeEnum } from '@/services'
import { SmsVerification } from '@/components/FormComponents/SmsVerification'
import Yup from '@/utils/yup'
import { replacePhoneNumberByPhoneCode } from '@/services/Utils'
import { getWebAnalyticsInfo } from '@/utils/referrerHelper'
import { loginApiByPhoneSms } from '@/services/user'
import { APP_LOCALE } from '@/constants/define'
import { TLoginFormProps } from '../type'

const schema = Yup.object().shape({
  sms_code: Yup.string().required(),
  phone: Yup.object({
    phone_code: Yup.string().required(),
    phone_number: Yup.string().required().phoneNumber('phone_code'),
  }),
})

export default function LoginByPhoneSms({
  onSuccess,
  onLogin,
}: TLoginFormProps) {
  const { t, i18n } = useTranslation()

  const id = useId('sms_verify_code')
  const {
    handleSubmit,
    control,
    watch,
    register,
    formState: { errors, isSubmitting, isValid },
  } = useForm({
    mode: 'onChange',
    reValidateMode: 'onSubmit',
    resolver: yupResolver(schema),
    defaultValues: {
      phone: {
        phone_code:
          APP_LOCALE === 'en_US' ? '1' : APP_LOCALE === 'kr' ? '82' : '86',
        phone_number: '',
      },
      sms_code: '',
    },
  })
  const [aggreed, setAggreed] = useState(false)

  const canSubmit = useMemo(
    () => aggreed && !isSubmitting && isValid,
    [aggreed, isSubmitting, isValid]
  )

  const { phone_code: watchPhoneCode, phone_number: watchPhoneNumber } =
    watch('phone')

  const onSubmit = useCallback(
    async values => {
      const postData = {
        ...values,
        ...{
          phone_number: replacePhoneNumberByPhoneCode(
            values.phone.phone_code,
            values.phone.phone_number
          ),
          phone_code: values.phone.phone_code,
          registration_url: getWebAnalyticsInfo().registration_url,
        },
      }

      const success = await loginApiByPhoneSms(postData)
      if (success) {
        onSuccess && onSuccess()
      }
    },
    [onSuccess]
  )
  return (
    <div className="mx-auto">
      <h4 className="text-xl font-bold text-center">{t('login_by_sms')}</h4>
      <form onSubmit={handleSubmit(onSubmit)} className="pt-2">
        <Stack spacing={'lg'}>
          <div>
            <Controller
              name="phone"
              control={control}
              render={({ field }) => {
                const { value, onChange } = field
                return (
                  <PhoneNumberInput
                    label={t('phone_number')}
                    placeholder={t('phone_number')}
                    inputProps={{
                      maxLength: 36,
                      withAsterisk: true,
                      // error: get(errors, "phone.phone_number.message"),
                    }}
                    value={value}
                    onChange={onChange}
                  />
                )
              }}
            />
            <div className="text-xs text-gray-400 mt-1">
              {t('login_form.tips')}
            </div>
          </div>
          <Stack spacing={'xs'}>
            <SmsVerification
              mode={i18n.language === 'kr' ? 'recaptcha' : 'imageCaptcha'}
              phoneCode={watchPhoneCode}
              phoneNumber={watchPhoneNumber}
              id={id}
              maxLength={6}
              registerFn={() => register('sms_code')}
              type={SmsTypeEnum.Register}
              disabled={
                !watchPhoneNumber || Boolean(errors.phone?.phone_number)
              }
              invalid={get(errors, 'sms_verify_code.message')}
            />
            <div className="text-gold">
              <a href="#" className="no-underline" onClick={onLogin}>
                {t('login_by_password')}
              </a>
            </div>
          </Stack>
          <Checkbox
            size="xs"
            label={
              <div className="text-gray-400 text-xs">
                <Trans i18nKey="register_form.tips" />
              </div>
            }
            checked={aggreed}
            onChange={() => setAggreed(!aggreed)}
          />
        </Stack>
        <div className="mt-6">
          <Button
            disabled={!canSubmit}
            variant="filled"
            fullWidth
            type="submit"
            loading={isSubmitting}
          >
            <span className={'uppercase'}>
              <Trans i18nKey="login" />
            </span>
          </Button>
        </div>
      </form>
    </div>
  )
}
