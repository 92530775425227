import React, { HTMLAttributes } from 'react'

type IconProps = HTMLAttributes<SVGElement>

export const UploadIcon = () => {
  return (
    <svg
      className="icon"
      viewBox="0 0 1024 1024"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
    >
      <path
        d="M768 512l-96 0C654.08 512 640 526.08 640 544 640 561.92 654.08 576 672 576L768 576c35.2 0 64 28.8 64 64l0 192c0 35.2-28.8 64-64 64L192 896c-35.2 0-64-28.8-64-64l0-192c0-35.2 28.8-64 64-64l96 0C305.92 576 320 561.92 320 544 320 526.08 305.92 512 288 512L192 512c-70.4 0-128 57.6-128 128l0 192c0 70.4 57.6 128 128 128l576 0c70.4 0 128-57.6 128-128l0-192C896 569.6 838.4 512 768 512zM186.88 375.04 448 113.28l0 560C448 689.92 462.08 704 478.72 704c17.28 0 30.72-14.08 30.72-30.72L509.44 113.28l261.12 261.76c13.44 14.08 35.84 14.08 49.92 0 13.44-14.08 13.44-35.84 0-49.92l-312.96-313.6C503.04 6.4 497.28 3.84 490.88 2.56 487.68 0.64 483.2 0 478.72 0S470.4 0.64 466.56 2.56C460.8 3.84 454.4 6.4 449.92 11.52l-312.96 313.6c-13.44 14.08-13.44 35.84 0 49.92C151.04 388.48 172.8 388.48 186.88 375.04z"
        fill="#9E9E9E"
      ></path>
    </svg>
  )
}

interface TickIconProps extends IconProps {
  width?: number
  height?: number
}

export const TickIcon = (props: TickIconProps) => {
  const { width = 128, height = 128, ...restProps } = props
  return (
    <svg
      className="icon"
      viewBox="0 0 1024 1024"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      {...restProps}
    >
      <path
        d="M512 0C229.668571 0 0 229.668571 0 512s229.668571 512 512 512 512-229.668571 512-512S794.331429 0 512 0z m307.2 343.771429s-267.702857 295.497143-327.68 365.714285c-59.977143 70.217143-106.788571 0-106.788571 0L210.651429 529.554286s-27.794286-42.422857 21.942857-81.92c46.811429-38.034286 84.845714 0 84.845714 0l122.88 128.731428L746.057143 291.108571s29.257143-20.48 59.977143 5.851429c23.405714 21.942857 13.165714 46.811429 13.165714 46.811429z"
        fill="#00c044"
      ></path>
    </svg>
  )
}

export const CommentIcon = (props: IconProps) => {
  return (
    <svg
      className="icon"
      viewBox="0 0 1024 1024"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      {...props}
    >
      <path
        d="M920.408 0H115.408C57.84 0 0 66.96 0 124.488v613C0 795.016 57.84 832 115.408 832h225.84l152.752 175.448c6.064 6.752 14.752 13.104 23.84 13.104s17.752-7.552 23.84-14.336L694.472 832h225.936C977.936 832 1024 795.016 1024 737.488V124.488C1024 66.96 977.936 0 920.408 0zM960 737.488c0 22.216-17.376 30.512-39.592 30.512H680.184c-9.096 0-17.752 8.776-23.84 15.552L517.84 940.576l-138.44-158.24c-6.056-6.784-14.744-14.336-23.84-14.336h-240.16C93.16 768 64 759.704 64 737.488V124.488C64 102.256 93.16 64 115.408 64h805c22.216 0 39.592 38.256 39.592 60.488v613z"
        fill="#989898"
      ></path>
      <path
        d="M819.784 256h-596c-17.688 0-32 14.328-32 32s14.312 32 32 32h596c17.688 0 32-14.328 32-32s-14.312-32-32-32zM527.84 480H223.784c-17.688 0-32 14.328-32 32s14.312 32 32 32H527.84c17.688 0 32-14.328 32-32s-14.312-32-32-32z"
        fill="#989898"
      ></path>
    </svg>
  )
}

export const CommentQIcon = () => {
  return (
    <svg
      className="text-center align-middle icon"
      viewBox="0 0 1024 1024"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
    >
      <path
        d="M555.437 744.856c9.653-3.219-9.653-24.132-30.567-38.61-27.348-19.305-69.178-22.523-69.178-22.523v-61.134s57.917-16.090 111.006 0c53.090 16.090 74.004 65.962 77.222 61.134 1.609-1.609 6.434-8.043 8.043-9.653 35.394-51.483 53.090-120.659 53.090-209.145 0-85.266-17.696-154.447-53.090-205.926-35.394-51.483-82.049-77.222-141.573-77.222-59.525 0-106.181 25.741-143.184 77.222-35.394 51.483-53.090 120.659-53.090 207.534 0 85.266 17.696 154.447 53.090 205.926 35.394 51.483 82.049 78.831 139.964 78.831 17.696-1.609 33.786-3.219 48.265-6.434zM819.279 939.52s-88.485 22.523-141.573-8.043c-41.828-24.132-75.615-75.615-78.831-77.222-24.132 9.653-80.441 9.653-91.7 9.653-112.617 0-202.708-35.394-270.278-106.181-67.569-70.787-101.356-165.705-101.356-284.757 0-120.659 33.786-215.58 101.356-286.366 67.569-70.787 159.27-106.181 275.104-106.181 115.833 0 207.534 35.394 275.104 106.181s101.356 167.315 101.356 287.973c0 157.662-56.308 270.278-170.532 339.456 0 0 9.653 20.914 30.567 32.174 19.305 11.263 57.917 9.653 57.917 9.653l12.87 83.658z"
        fill="#ffffff"
        p-id="13815"
      ></path>
    </svg>
  )
}

export const CaptialIcon = () => {
  return (
    <svg
      viewBox="0 0 1024 1024"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
    >
      <path
        d="M512 16C238.066 16 16 238.066 16 512s222.066 496 496 496 496-222.066 496-496S785.934 16 512 16z m234.268 693.506c-3.184 3.734-79.552 91.462-219.702 91.462-169.384 0-288.968-126.52-288.968-291.134 0-162.606 124.008-286.802 287.524-286.802 133.914 0 203.93 74.63 206.844 77.808a24 24 0 0 1 2.476 29.246l-44.76 69.31c-8.098 12.534-25.548 14.702-36.468 4.59-0.466-0.428-53.058-47.76-123.76-47.76-92.232 0-147.832 67.15-147.832 152.164 0 79.204 51.028 159.384 148.554 159.384 77.394 0 130.56-56.676 131.088-57.25 10.264-11.13 28.118-10.066 37.016 2.106l49.094 67.144a24.002 24.002 0 0 1-1.106 29.732z"
        fill="#AB8C2F"
      ></path>
    </svg>
  )
}

export const UaeSmileIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Frame">
        <path
          id="Vector"
          d="M18.3333 2.91666H1.66663V15.4167H4.58329V17.5L8.74996 15.4167H18.3333V2.91666Z"
          stroke="#3AA39F"
          strokeWidth="1.66667"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          id="Vector_2"
          d="M12.9166 6.66666V7.08332"
          stroke="#3AA39F"
          strokeWidth="1.66667"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          id="Vector_3"
          d="M7.08337 6.66666V7.08332"
          stroke="#3AA39F"
          strokeWidth="1.66667"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          id="Vector_4"
          d="M12.9167 10.4167C12.9167 10.4167 12.0834 12.0833 10 12.0833C7.91671 12.0833 7.08337 10.4167 7.08337 10.4167"
          stroke="#3AA39F"
          strokeWidth="1.66667"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  )
}

export const GGALogoIcon = () => {
  return (
    <svg
      width="60"
      height="14"
      viewBox="0 0 60 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="LOGO" clipPath="url(#clip0_136_398)">
        <g id="Group">
          <path
            id="Vector"
            d="M27.6222 6.78281H30.2402V11.9995C29.5683 12.5256 28.7893 12.9306 27.9059 13.2117C27.0212 13.4929 26.1313 13.6347 25.235 13.6347C23.9492 13.6347 22.7937 13.3497 21.7684 12.781C20.7431 12.211 19.9384 11.4217 19.3542 10.4106C18.77 9.39952 18.4785 8.25818 18.4785 6.984C18.4785 5.70982 18.77 4.56848 19.3542 3.55739C19.9384 2.54759 20.7483 1.75703 21.7865 1.187C22.8234 0.616976 23.9918 0.333252 25.2879 0.333252C26.3725 0.333252 27.3578 0.522831 28.2412 0.903279C29.1259 1.28244 29.8687 1.83441 30.4697 2.55662L28.6307 4.31957C27.746 3.35233 26.6794 2.86871 25.4298 2.86871C24.6392 2.86871 23.9376 3.04024 23.325 3.38328C22.7125 3.72633 22.234 4.20995 21.8922 4.83415C21.5505 5.45834 21.3789 6.17539 21.3789 6.984C21.3789 7.79261 21.5492 8.49032 21.8922 9.11451C22.234 9.7387 22.7086 10.2262 23.316 10.5744C23.9234 10.9239 24.616 11.098 25.3936 11.098C26.219 11.098 26.9619 10.9149 27.6222 10.5473V6.78152V6.78281Z"
            fill="black"
          />
          <path
            id="Vector_2"
            d="M41.2591 6.78281H43.8771V11.9995C43.2052 12.5256 42.4263 12.9306 41.5429 13.2117C40.6581 13.4929 39.7683 13.6347 38.872 13.6347C37.5862 13.6347 36.4307 13.3497 35.4054 12.781C34.3801 12.211 33.5754 11.4217 32.9912 10.4106C32.4069 9.40081 32.1155 8.25818 32.1155 6.984C32.1155 5.70982 32.4069 4.56848 32.9912 3.55739C33.5754 2.54759 34.3853 1.75703 35.4234 1.187C36.4603 0.616976 37.6287 0.333252 38.9249 0.333252C40.0095 0.333252 40.9947 0.522831 41.8782 0.903279C42.7629 1.28244 43.5057 1.83441 44.1067 2.55662L42.2676 4.31957C41.3829 3.35233 40.3164 2.86871 39.0667 2.86871C38.2762 2.86871 37.5746 3.04024 36.962 3.38328C36.3494 3.72633 35.871 4.20995 35.5292 4.83415C35.1874 5.45834 35.0159 6.17539 35.0159 6.984C35.0159 7.79261 35.1861 8.49032 35.5292 9.11451C35.871 9.7387 36.3455 10.2262 36.953 10.5744C37.5604 10.9239 38.2529 11.098 39.0306 11.098C39.856 11.098 40.5988 10.9149 41.2591 10.5473V6.78152V6.78281Z"
            fill="black"
          />
          <path
            id="Vector_3"
            d="M55.2507 10.8066H49.2925L48.1563 13.6632H45.114L50.8323 0.334595H53.765L59.5027 13.6632H56.3869L55.2507 10.8066ZM54.3157 8.46458L52.2806 3.36141L50.2455 8.46458H54.3144H54.3157Z"
            fill="black"
          />
        </g>
        <g id="Group_2">
          <path
            id="Vector_4"
            d="M-0.376331 0.334717H6.41886C6.48592 0.334717 6.54008 0.388882 6.54008 0.455944V2.57227C6.54008 2.63933 6.48592 2.69349 6.41886 2.69349H1.98116C1.91409 2.69349 1.85993 2.74766 1.85993 2.81472V5.76029C1.85993 5.79253 1.87283 5.82349 1.89475 5.84541L3.97883 7.93852C4.05492 8.01461 4.18647 7.96044 4.18647 7.8534V5.86089C4.18647 5.82864 4.17357 5.79898 4.15164 5.77577L2.92389 4.53512C2.8478 4.45903 2.90197 4.32878 3.0103 4.32878H6.41757C6.48463 4.32878 6.54008 4.38423 6.54008 4.45129C6.54008 10.4276 6.54008 10.7435 6.54008 13.5085C6.54008 13.6169 6.40983 13.671 6.33245 13.5949L-0.461448 6.83071C-0.484662 6.80749 -0.497559 6.77654 -0.497559 6.7443V0.455944C-0.497559 0.388882 -0.443393 0.334717 -0.376331 0.334717Z"
            fill="black"
          />
          <path
            id="Vector_5"
            d="M15.0922 0.334564H8.29701C8.22995 0.334564 8.17578 0.38873 8.17578 0.455792V2.57211C8.17578 2.63918 8.22995 2.69334 8.29701 2.69334H12.7347C12.8018 2.69334 12.8559 2.74751 12.8559 2.81457V5.76014C12.8559 5.79238 12.843 5.82333 12.8211 5.84526L10.737 7.93837C10.6609 8.01446 10.5294 7.96029 10.5294 7.85325V5.86073C10.5294 5.82849 10.5423 5.79754 10.5642 5.77562L11.792 4.53497C11.8681 4.45888 11.8139 4.32862 11.7056 4.32862H8.2983C8.23124 4.32862 8.17578 4.38408 8.17578 4.45114C8.17578 10.4274 8.17578 10.7434 8.17578 13.5084C8.17578 13.6167 8.30604 13.6709 8.38342 13.5948L15.1786 6.83184C15.2018 6.80863 15.2147 6.77768 15.2147 6.74544V0.455792C15.2147 0.38873 15.1605 0.334564 15.0935 0.334564"
            fill="black"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_136_398">
          <rect width="60" height="14" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

export const UaeLoadingIcon = () => {
  return (
    <svg
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="icon/waiting">
        <path
          id="Vector"
          d="M5.25 3H30.75"
          stroke="#333333"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          id="Vector_2"
          d="M5.25 33H30.75"
          stroke="#333333"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          id="Vector_3"
          d="M8.25 33C10.25 22.9958 13.5 17.9958 18 18C22.5 18.0042 25.75 23.0042 27.75 33H8.25Z"
          fill="#333333"
          stroke="#333333"
          strokeWidth="3"
          strokeLinejoin="round"
        />
        <path
          id="Vector_4"
          d="M27.75 3C25.75 13.0042 22.5 18.0042 18 18C13.5 17.9958 10.25 12.9958 8.25 3H27.75Z"
          fill="#333333"
          stroke="#333333"
          strokeWidth="3"
          strokeLinejoin="round"
        />
        <path
          id="Vector_5"
          d="M15.75 11.25H20.25"
          stroke="white"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          id="Vector_6"
          d="M14.25 28.5H21.75"
          stroke="white"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  )
}

export const UaeLoadingCloseIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Frame">
        <path
          id="Vector"
          d="M8.94284 7.99992L13.138 3.80458C13.2625 3.68116 13.3333 3.51368 13.3352 3.33841C13.3371 3.16314 13.2699 2.99418 13.1481 2.86809C13.0263 2.74201 12.8598 2.66896 12.6846 2.66474C12.5094 2.66053 12.3395 2.7255 12.2118 2.84558C12.2062 2.85091 12.2008 2.85641 12.1953 2.86191L8 7.05708L3.80484 2.86191C3.67936 2.73919 3.51054 2.6709 3.33502 2.67187C3.15951 2.67283 2.99145 2.74298 2.86733 2.86708C2.74321 2.99118 2.67304 3.15922 2.67204 3.33473C2.67104 3.51025 2.7393 3.67908 2.862 3.80458L7.05734 7.99992L2.862 12.1952C2.73597 12.319 2.66418 12.4876 2.66239 12.6642C2.66059 12.8408 2.72895 13.0109 2.85244 13.1372C2.97593 13.2634 3.14447 13.3355 3.32106 13.3376C3.49765 13.3397 3.66787 13.2717 3.79434 13.1484L3.80484 13.1379L8 8.94275L12.1953 13.1379C12.3255 13.2681 12.4962 13.3332 12.6667 13.3332C12.8372 13.3332 13.008 13.2681 13.138 13.1379C13.263 13.0129 13.3332 12.8434 13.3332 12.6666C13.3332 12.4898 13.263 12.3203 13.138 12.1952L8.94284 7.99992Z"
          fill="#6A7480"
        />
      </g>
    </svg>
  )
}
