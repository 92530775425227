import classNames from 'classnames'
import IconPark from '@/components/IconPark'

type TTokenCostDisplayProps = {
  cost: number
  className?: string
  size?: 'sm' | 'md'
}

export default function TokenCostDisplay({
  cost,
  className,
  size = 'md',
}: TTokenCostDisplayProps) {
  return (
    <div
      style={{
        lineHeight: 0,
      }}
      className={classNames(
        'flex items-center text-gold',
        size === 'md' && 'text-base',
        size === 'sm' && 'text-sm',
        className
      )}
    >
      <div className={'mr-0.5'}>
        <IconPark name={'diamond'} className={'text-gold'}></IconPark>
      </div>
      <div>{cost}</div>
    </div>
  )
}
