import { OSS_CONSTANT, OSSCdnUrl } from '@/config/config'
const OSS_ACCELERATE_URL = `https://${OSS_CONSTANT.Bucket}.${OSS_CONSTANT.Region}.aliyuncs.com/`
const OSS_ACCELERATE_URL_HTTP = `http://${OSS_CONSTANT.Bucket}.${OSS_CONSTANT.Region}.aliyuncs.com/`
const OSS_DOWNLOAD_ROOT_URL = OSS_CONSTANT.DownloadRootUrl
const OSS_DOWNLOAD_ROOT_URL_HTTP = OSS_CONSTANT.DownloadRootUrl.replace(
  'https',
  'http'
)
export class OssResourceUtils {
  private matchRegex = new RegExp(
    `${OSS_ACCELERATE_URL}|${OSS_ACCELERATE_URL_HTTP}|${OSS_DOWNLOAD_ROOT_URL}|${OSS_DOWNLOAD_ROOT_URL_HTTP}`
  )

  constructor(private url: string) {}

  getNormalizedUrl() {
    return (this.url as string).replace(this.matchRegex, OSSCdnUrl)
  }

  static getAccelerateUrl() {
    return OSS_ACCELERATE_URL
  }

  static getDownloadRootUrl() {
    return OSS_CONSTANT.DownloadRootUrl
  }

  static getCdnUrl() {
    return OSSCdnUrl
  }
}
