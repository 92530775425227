import { NSComment } from 'gga-types'
import { IResData, IResDataPaged } from '@/@types/global'
import { request } from '@/utils/request'

export function getCommentsTop(trialId: number) {
  const url = `/v2/Comment/homework/${trialId}`
  return request({
    url,
    method: 'get',
    data: {
      depth: 1,
    },
  })
}

// 发表评论
export type IPostCommentsV2Data = {
  content: string
  parent_id?: number
  attributes?: any
  attachments?: any[]
}

export function postCommentsV2(
  data: IPostCommentsV2Data,
  classUuid,
  nodeId,
  trialId: number
) {
  return request<IResData<NSComment.CommentItem>>({
    url: `/v2/comment/class/${classUuid}/syllabus/${nodeId}/trial/${trialId}/create`,
    method: 'post',
    data: data,
  })
}

// 获取 二级评论
export function getCommentsChild(trialId: number, root_id: number) {
  return request({
    url: `/v2/Comment/homework/${trialId}`,
    method: 'get',
    data: {
      root_id,
    },
  })
}

export const readComment = (trialId: number) => {
  return request({
    url: `/v2/Record/trial/${trialId}/read`,
    method: 'post',
  })
}

export const getPublicCourseComments = (
  syllabusId: number,
  page: number,
  pageSize: number,
  parentCommentId?: number
) => {
  return request<IResDataPaged<NSComment.CommentItem>>({
    url: `/v2/comment/syllabus/${syllabusId}/comments`,
    method: 'get',
    data: {
      page,
      pageSize,
      parentCommentId,
    },
  })
}

export const getChildCommentsFlat = (
  syllabusId: number,
  parentCommentId: number
) => {
  return request<IResDataPaged<NSComment.CommentItem>>({
    url: `/v2/comment/syllabus/${syllabusId}/comments/children`,
    data: {
      page: 1,
      pageSize: 99999,
      parentCommentId,
    },
  })
}

export const deleteComment = (commentId: number) => {
  return request({
    url: `/v2/comment/${commentId}`,
    method: 'delete',
  })
}
