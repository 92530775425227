import React, { useCallback, useEffect, useMemo } from 'react'
import { Button } from 'stripe-ui'
import { Controller, useForm } from 'react-hook-form'
import { Trans, useTranslation } from 'next-i18next'
import classNames from 'classnames'
import get from 'lodash/get'
import { v4 as uuidv4 } from 'uuid'
import { NSClass } from 'gga-types'
import { useOSSPut } from '@/hooks/useOSSUploader'
import { FileUploader } from '@/components/uploader/FileUploader'
import styles from '@/styles/myPage.module.scss'
import ReplayFileUploadInput from './ReplayFileUploadInput'
// import { getHomeworkAwsUploadUrl } from "@/services/class";
// import { multipartUpload } from "@/services/s3";

type IReplayCodeUploadFormProps = {
  onSubmit: (vals) => void
  className?: string
  onModalClose
  submissionStatus?: NSClass.SubmissionStatus | null
}

export default function ReplayFileUploadForm({
  onSubmit,
  className,
  onModalClose,
  submissionStatus,
}: IReplayCodeUploadFormProps) {
  const {
    formState: { isSubmitting, errors, isValid },
    handleSubmit,
    watch,
    setValue,
    setError,
    clearErrors,
    formState,
    control,
  } = useForm({
    mode: 'all',
    defaultValues: {
      replay_file_path: '',
      player_identity: '',
    },
  })
  const { t, i18n } = useTranslation(['common'])
  const canSubmit = useMemo(() => {
    return submissionStatus == null || submissionStatus.can_submit
  }, [submissionStatus])

  const {
    uploadStatus,
    uploadProgress,
    reupload,
    handleUploadCn,
    handleUploadKr,
  } = useOSSPut()

  const watchFile = watch('replay_file_path')

  const submitForm = useCallback(
    async data => {
      if (isSubmitting) {
        return
      } else {
        await onSubmit(data)
        onModalClose && onModalClose(undefined)
      }
    },
    [isSubmitting, onModalClose, onSubmit]
  )

  // 上传时间处理
  const fileChangedHandler = useCallback(
    async file => {
      // 有空用户点击没有选择文件
      if (file) {
        const ext = file.name.split('.').reverse()[0]

        const filename = uuidv4() + `.${ext}`

        let fileUrl: string | undefined
        if (i18n.language === 'kr') {
          fileUrl = await handleUploadKr(file, filename)
        } else if (i18n.language === 'zh_CN') {
          fileUrl = await handleUploadCn(file, filename)
        }

        if (fileUrl) {
          setValue('replay_file_path', fileUrl, { shouldValidate: true })
        } else {
          setError('replay_file_path', {
            type: 'manual',
            message: t('course_form.upload_failed'),
          })
        }
      }
    },
    [handleUploadCn, handleUploadKr, i18n.language, setError, setValue, t]
  )

  const handleCancelUpload = useCallback(() => {
    setValue('replay_file_path', '', { shouldValidate: true })
    reupload()
  }, [reupload, setValue])

  useEffect(() => {
    if (uploadStatus.status !== 'init') {
      onModalClose &&
        onModalClose(() => {
          return () =>
            Promise.resolve(
              confirm(
                t(
                  'components.courseware_modal.upload_form_close_confirm.confirm_tip'
                )
              )
            )
        })
    }
  }, [onModalClose, t, uploadStatus.status])

  return (
    <form className={classNames(className)} onSubmit={handleSubmit(submitForm)}>
      <div className="text-center mx-auto font-bold text-xl mb-4">
        {t('course_form.replay_file_title')}
      </div>
      <FileUploader
        onError={message => {
          setError('replay_file_path', {
            type: 'manual',
            message,
          })
        }}
        clearError={() => {
          clearErrors(['replay_file_path'])
        }}
        type="rofl"
        onChange={fileChangedHandler}
        progress={uploadProgress}
        message={uploadStatus.message}
        status={uploadStatus.status}
        error={get(errors, 'replay_file_path.message')}
        onCancel={handleCancelUpload}
      >
        <div className={styles.uploadTip}>
          *{' '}
          <Trans
            t={t}
            i18nKey="components.courseware_modal.solo_task_modal_content.rofl_upload_content.file_type_tip"
          />
        </div>
      </FileUploader>
      <div className="w-80 mx-auto">
        <Controller
          control={control}
          name="player_identity"
          rules={{ required: true }}
          render={props => {
            const { onChange } = props.field
            return <ReplayFileUploadInput onChange={onChange} />
          }}
        />
      </div>
      <div className="text-center my-4">
        <Button
          type="submit"
          variant="brand"
          loading={isSubmitting}
          disabled={!canSubmit || isSubmitting || !watchFile || !isValid}
        >
          <Trans t={t} i18nKey="course_form.submit" />
        </Button>
      </div>
      {submissionStatus && submissionStatus.submission_limit ? (
        <div className={classNames('text-center font-bold mb-3 text-black')}>
          <Trans
            t={t}
            i18nKey="course_form.submit_limit"
            values={{
              count: submissionStatus.remain_count,
            }}
          />
        </div>
      ) : null}
    </form>
  )
}
