import { useCallback, useState } from 'react'
import { request } from '@/utils/request'

type ConsultantInfo = {
  qr_code_url: string
  name: string
}

export type IRandomConsultantState = ReturnType<typeof useRandomConsultantState>

export default function useRandomConsultantState() {
  const [consultantInfo, setConsultantInfo] = useState<ConsultantInfo>()

  const fetchConsultantInfo = useCallback(async () => {
    if (!consultantInfo) {
      const result = await request<{ data: ConsultantInfo }>({
        url: '/v2/User/random/consultant',
      })
      setConsultantInfo(result.data?.data)
    }

    return consultantInfo
  }, [consultantInfo])
  return {
    fetchConsultantInfo,
  }
}
