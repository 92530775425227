import {
  DetailedHTMLProps,
  ImgHTMLAttributes,
  useCallback,
  useMemo,
} from 'react'
import isURL from 'validator/lib/isURL'
import isDataURI from 'validator/lib/isDataURI'
import classNames from 'classnames'
import Link from 'next/link'
import { IMAGE_PROXY_HOST } from '@/config/config'
import { OssResourceUtils } from '@/utils/oss-resource.utils'
import { ConditionalWrapper } from '@/lib/ui/conditional-wrapper'

type TImageComponentProps = DetailedHTMLProps<
  ImgHTMLAttributes<HTMLImageElement>,
  HTMLImageElement
> & {
  quality?: number
  hidePlaceholder?: boolean
}

export default function ImageComponent(props: TImageComponentProps) {
  const loader = useCallback(({ src, width, quality }) => {
    if (!src) {
      return src
    }
    const isFullUrl = isURL(src, {
      require_protocol: true,
      protocols: ['http', 'https'],
    })

    if (isFullUrl) {
      return new OssResourceUtils(src).getNormalizedUrl()
    } else if (isDataURI(src)) {
      return src
    }
    return `${IMAGE_PROXY_HOST}${src}`
  }, [])

  const url = useMemo(() => {
    return loader(props)
  }, [loader, props])

  if (!props.src) {
    return props.hidePlaceholder ? (
      <div {...props}></div>
    ) : (
      <img
        {...props}
        alt={props.alt || ''}
        className={classNames(
          props.className,
          props.hidePlaceholder ? 'invisible' : ''
        )}
        src="https://res.gengesports.cn/user-web/images/logo-dark-text.png"
      />
    )
  }
  return <img {...props} src={url} />
}
