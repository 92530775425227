import { yupResolver } from '@hookform/resolvers/yup'
import { useTranslation } from 'next-i18next'
import React, { useCallback, useMemo } from 'react'
import { get } from 'lodash'
import { Controller, useForm } from 'react-hook-form'
import { Anchor, Button, Stack } from '@mantine/core'
import Yup from '@/utils/yup'
import { loginApiByPhoneSms } from '@/services/user'
import PhoneNumberInput from '@/components/inputs/PhoneNumberInput'
import { SmsTypeEnum } from '@/services'
import { SmsVerification } from '@/components/FormComponents/SmsVerification'
import { useStyles } from './utils'

interface SmsLoginFormProps {
  onLogged?: () => void
  onRegister: () => void
  onLogin: () => void
}

type SmsLoginFormValue = {
  phone: {
    phone_code: string
    phone_number: string
  }
  sms_code: string
}

const schema = Yup.object().shape({
  sms_code: Yup.string().required(),
  phone: Yup.object({
    phone_code: Yup.string().required(),
    phone_number: Yup.string()
      .required()
      .omitStartZero()
      .phoneNumber('phone_code'),
  }),
})

export const SmsLoginForm: React.FC<SmsLoginFormProps> = ({
  onLogged,
  onLogin,
  onRegister,
}) => {
  const { t } = useTranslation()
  const { classes } = useStyles()
  const { control, formState, handleSubmit, watch, register } =
    useForm<SmsLoginFormValue>({
      mode: 'all',
      defaultValues: {
        phone: {
          phone_code: '86',
          phone_number: '',
        },
        sms_code: '',
      },
      resolver: yupResolver(schema),
    })
  const { errors, isSubmitting, isValid, isDirty } = formState

  const { phone_code: watchPhoneCode, phone_number: watchPhoneNumber } =
    watch('phone')

  const isValidPhoneNumber = useMemo(() => {
    return watchPhoneNumber && !errors?.phone?.phone_number?.message
  }, [errors?.phone?.phone_number?.message, watchPhoneNumber])

  const onSubmit = useCallback(
    async (values: SmsLoginFormValue) => {
      const { phone, ...rest } = values
      const postData = {
        ...rest,
        ...phone,
      }
      const response = await loginApiByPhoneSms(postData)
      if (response) {
        onLogged?.()
      }
    },
    [onLogged]
  )
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="text-center text-[22px] text-[#FAFAFA] font-bold mb-5">
        Gen.G电竞人才培养平台
      </div>

      <Stack>
        <Controller
          name="phone"
          control={control}
          render={({ field }) => {
            return (
              <PhoneNumberInput
                classes={{ label: classes.label }}
                label={t('phone_number')}
                placeholder={t('phone_number')}
                inputProps={{
                  maxLength: 36,
                  error: get(errors, 'phone.phone_number.message'),
                  description:
                    '使用电话号码登录时，请选择正确的国家/地区代码。',
                  descriptionProps: { sx: { color: '#FAFAFA', opacity: 0.5 } },
                  inputWrapperOrder: ['label', 'input', 'description', 'error'],
                }}
                {...field}
              />
            )
          }}
        />

        <Controller
          name="sms_code"
          control={control}
          render={({ field }) => {
            return (
              <div>
                <SmsVerification
                  classes={{ label: classes.label }}
                  mode={'imageCaptcha'}
                  phoneCode={watchPhoneCode}
                  phoneNumber={watchPhoneNumber}
                  id="sms_verify_code"
                  maxLength={6}
                  registerFn={() => register('sms_code')}
                  type={SmsTypeEnum.Register}
                  disabled={!isValidPhoneNumber}
                  invalid={get(errors, 'sms_verify_code.message')}
                  label={t('send_verification_code_via_text')}
                />

                <div className="mt-2">
                  <Anchor
                    sx={{ color: '#FAFAFA', opacity: 0.7 }}
                    onClick={onLogin}
                    underline
                    className="underline-offset-4"
                  >
                    密码登陆
                  </Anchor>
                </div>
              </div>
            )
          }}
        />

        <div className="text-center">
          <Button
            type="submit"
            color="brand"
            disabled={!isDirty || !isValid}
            loading={isSubmitting}
          >
            登录
          </Button>
        </div>
        <div className="text-center">
          <Anchor color="brand" underline={false} onClick={onRegister}>
            创建账号
          </Anchor>
        </div>
      </Stack>
    </form>
  )
}
