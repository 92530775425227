import React from 'react'
import { Icon } from 'stripe-ui'
import QRCode from 'react-qr-code'
import { Button } from '@mantine/core'

interface QrcodeCounterProps {
  qrcode?: string
  onBack: () => void
  onPurchased: () => void
}

export const QrcodeCounter = ({
  qrcode,
  onBack,
  onPurchased,
}: QrcodeCounterProps) => {
  return (
    <div className="flex flex-col space-y-8">
      <div className="flex flex-col space-y-4">
        <h3 className="text-base font-medium text-center">请使用扫码支付</h3>
        <div className="flex flex-row justify-center">
          {qrcode ? (
            <div className={'inline-block mx-auto'}>
              <QRCode value={qrcode} size={150} />
            </div>
          ) : (
            <span className="my-6">
              <Icon name="spin" size="large" />
            </span>
          )}
        </div>
      </div>

      <div className="flex space-x-4">
        <Button fullWidth variant="outline" onClick={onBack}>
          重新选择
        </Button>
        <Button fullWidth onClick={onPurchased}>
          我已完成支付
        </Button>
      </div>
    </div>
  )
}
