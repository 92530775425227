import classNames from 'classnames'
import React from 'react'

interface ModalFooterProps {
  children: React.ReactNode
  className?: string
}

export const ModalFooter = ({ children, className }: ModalFooterProps) => {
  return (
    <div
      className={classNames(
        '-mx-5 px-5 py-4 shadow-[0_-2px_8px_0_rgba(0,0,0,0.06)] flex justify-between items-center !-mb-5',
        className
      )}
    >
      {children}
    </div>
  )
}
