import { isDev } from '../is'
import { DataLayerObject } from './types'

declare global {
  interface Window {
    dataLayer?: object[]
  }
}

export const pushDataLayer = (dataLayerObject: DataLayerObject) => {
  window.dataLayer = window.dataLayer || []

  if (isDev) {
    console.log(`[GTM DataLayer]`, dataLayerObject)
  }

  window.dataLayer.push({ ecommerce: null })
  window.dataLayer.push(dataLayerObject)
}
