import { create } from 'zustand'
import { CoachApi } from '@/api/coach/index.api'

type TCheckoutStore = {
  hasUncommented: boolean | undefined
  fetchPendingFeedbackStatus(): void
}

const api = new CoachApi()

export const useUncommentedFeedback = create<TCheckoutStore>((set, get) => ({
  hasUncommented: undefined,
  async fetchPendingFeedbackStatus() {
    const data = await api.getUserCoachFeedbackStatus()
    set(() => ({
      hasUncommented: Boolean(data.data?.data),
    }))
  },
}))
