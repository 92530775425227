import { NSUserSettings } from 'gga-types'
import { IResData } from '@/@types/global'
import { request } from '@/utils/request'

export async function getUserSmsPreference() {
  return request<IResData<NSUserSettings.UserSmsPreference>>({
    url: '/v2/usersettings/sms-preference',
  })
}

export async function getUserGameInfoOptionConfig() {
  return request<IResData<NSUserSettings.UserGameInfoItem[]>>({
    url: `/v2/user/info/game/config`,
    method: 'get',
  })
}

export async function getUserGameInfoRawValue() {
  return request<IResData<NSUserSettings.UserGameInfoRawValue[]>>({
    url: `/v2/user/info/game`,
    method: 'get',
  })
}

type TPatchUserInfoRawValueParams = NSUserSettings.UserGameInfoRawValue[]

export async function patchUserInfoRawValue(
  values: TPatchUserInfoRawValueParams
) {
  return request({ url: `/v2/user/info/game`, method: 'patch', data: values })
}
