import { NSNotification } from 'gga-types'
import { expandNotificationContent } from '@/services/notification'

export const notificationHandler = {
  public_course_comment_created: async (nid: number) => {
    const res = await expandNotificationContent(nid)
    if (res && res.data && res.data.data) {
      const data = res.data.data
      if (!data.content.class_uuid) {
        throw new Error('not_in_class')
      }
      return data as NSNotification.Item<
        any,
        NSNotification.PublicCourseCommentNotificationContent
      >
    }
  },
}
