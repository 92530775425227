import Link from 'next/link'
import { useTranslation } from 'next-i18next'
import { FC, PropsWithChildren } from 'react'
import classNames from 'classnames'
import ImageComponent from '@/components/ImageComponent'
import { APP_LOCALE } from '@/constants/define'
import LanguageSwitch from '@/components/LanguageSwitch'
import { IPageProps } from '@/@types/global'

type TFooterProps = Omit<IPageProps, 'children'>
export default function Footer({ isOfficial }: TFooterProps) {
  const { t } = useTranslation()
  const krLinks = [
    {
      label: t('footer.gga_home'),
      href: 'https://ggacademy.gg/',
    },
    {
      label: t('footer.introduce'),
      href: `https://ggacademy.gg/about`,
    },
    {
      label: t('footer.cafe'),
      href: 'https://cafe.naver.com/ggaonline',
    },
    {
      label: t('footer.policy'), // Private Policy
      href: '/about/policy',
    },
    {
      label: t('footer.terms'), // term of service
      href: '/about/terms',
    },
    {
      label: t('footer.agreement'), // Agree to the PR/marketing promotion
      href: '/about/agreement',
    },
    {
      label: t('footer.refund_policy'),
      href: '/about/refund',
    },
    {
      label: t('footer.certificate_validate'),
      href: '/certificate-validate',
    },
    {
      label: t('footer.redeem'),
      href: '/key-claim',
    },
  ]

  const enLinks = [
    {
      label: t('footer.introduce'),
      href: 'https://ggacademy.gg/about?lang=en',
    },
    {
      label: t('footer.cafe'),
      href: 'https://cafe.naver.com/ggaonline',
    },
    {
      label: t('footer.policy'), // Private Policy
      href: '/about/policy',
    },
    {
      label: t('footer.terms'), // term of service
      href: '/about/terms',
    },
    {
      label: t('footer.agreement'), // Agree to the PR/marketing promotion
      href: '/about/agreement',
    },
    {
      label: t('footer.refund_policy'),
      href: '/about/refund',
    },
    {
      label: t('footer.redeem'),
      href: '/key-claim',
    },
  ]

  const links = APP_LOCALE === 'kr' ? krLinks : enLinks

  const Section: FC<PropsWithChildren<{ className?: string }>> = ({
    children,
    className,
  }) => {
    return (
      <div className={classNames('mx-2 flex flex-col mb-4', className)}>
        {children}
      </div>
    )
  }

  return (
    <footer className="bg-black text-white p-4 lg:p-12">
      <div className="flex flex-col md:flex-row md:flex-nowrap">
        <Section className="flex-1">
          <ImageComponent
            src={'/images/logo-gga-white.png'}
            width="270"
            height={'90'}
            className="mx-auto sm:mx-0"
          />
        </Section>
        <Section className="flex-1">
          <div className="text-lg mb-2">{t('footer.link')}</div>
          <ul>
            {links.map(link => {
              return (
                <li className="mb-2" key={link.href}>
                  <a
                    key={link.href}
                    href={link.href}
                    target={'_blank'}
                    className="no-underline"
                    rel="noreferrer"
                  >
                    {link.label}
                  </a>
                </li>
              )
            })}
            {APP_LOCALE === 'en_US' && <LanguageSwitch />}
          </ul>
        </Section>
        <Section className="flex-1">
          <div className="text-lg mb-2">{t('footer.contact_info')}</div>
          <ul>
            {APP_LOCALE === 'kr' && (
              <li className="mb-2">{t('footer.time')} 10:00 ~ 19:00</li>
            )}
            {APP_LOCALE === 'kr' && <li className="mb-2">02-511-3445</li>}
            <li className="mb-3">
              <a href={'mailto://gga@geng.gg'}>gga@geng.gg</a>
            </li>
            <li className="mb-2">{t('footer.company')}</li>
            <li className="mb-2">{t('footer.register_number')}</li>
            <li className="mb-2">{t('footer.ceo')}</li>
            <li className="mb-2">{t('footer.address')}</li>
            {APP_LOCALE === 'kr' && (
              <li className="mb-2">{t('footer.sales')}</li>
            )}
            {APP_LOCALE === 'kr' && isOfficial && (
              <li className="mb-2">{t('footer.gga')}</li>
            )}
            {APP_LOCALE === 'kr' && isOfficial && (
              <li className="mb-2">{t('footer.sales_address')}</li>
            )}
          </ul>
        </Section>
      </div>
    </footer>
  )
}
