import classNames from 'classnames'
import { useTranslation } from 'next-i18next'

import { ActionIcon } from '@mantine/core'
import { StarIcon } from '@heroicons/react/24/solid'
import { StarIcon as StarIconOutline } from '@heroicons/react/24/outline'

type IRatingProps = {
  max?: number
  onChange: (v: number) => void
  value?: number
  disabled?: boolean
}

export function Rating({ max = 5, onChange, value, disabled }: IRatingProps) {
  return (
    <div className={'flex'}>
      {Array(max)
        .fill(0)
        .map((j, index) => {
          const checked = value && value >= index + 1
          return (
            <div key={index} className={classNames('inline-block')}>
              <ActionIcon
                variant={'transparent'}
                data-value={index + 1}
                onClick={e => {
                  if (!disabled) {
                    const v = Number(e.currentTarget.dataset.value)
                    onChange(v)
                  }
                }}
              >
                {checked ? (
                  <StarIcon className={classNames('text-orange-500')} />
                ) : (
                  <StarIconOutline
                    className={classNames('scale-90 text-orange-200')}
                  />
                )}
              </ActionIcon>
            </div>
          )
        })}
    </div>
  )
}

type IRadioGroupProps<T> = {
  value?: T
  onChange: (v: T) => void
  disabled?: boolean
}

export function RadioGroup({
  value,
  onChange,
  disabled,
}: IRadioGroupProps<number>) {
  const { t } = useTranslation('common')
  return (
    <div
      className="flex space-x-4"
      onClick={e => {
        if (!disabled && e.target.value) {
          onChange(Number(e.target.value))
        }
      }}
    >
      <div className="flex space-x-2 items-center">
        <span className="text-base">{t('yes')}</span>
        <input
          disabled={disabled}
          type="radio"
          checked={value === 1}
          value={1}
          className="radio checked:focus:bg-gold checked:focus:border-gold checked:bg-gold"
        />
      </div>
      <div className="flex space-x-2 items-center">
        <span className="text-base">{t('no')}</span>
        <input
          disabled={disabled}
          type="radio"
          checked={value === 0}
          value={0}
          className="radio radio-sm checked:focus:bg-gold checked:focus:border-gold checked:bg-gold"
        />
      </div>
    </div>
  )
}
