import classNames from 'classnames'
import { Button, Textarea } from 'stripe-ui'
import { get } from 'lodash'
import { Trans, useTranslation } from 'next-i18next'
import React, { useCallback } from 'react'
import { useForm } from 'react-hook-form'
import { formatTime } from '@/utils/kits'
import { ICommentMode } from '@/components/CoursewareModal/SoloAndTeamTaskReview/components/HomeworkComments/type'

type IHomeworkCommentInputProps = {
  onSubmit: (data) => void
  onFocus: () => void
  currentTime?: number
  canSubmit?: boolean
  replyTo?: string
  onCancel?: () => void
  mode: ICommentMode
}

export default function HomeworkCommentInput({
  canSubmit = true,
  currentTime,
  onFocus,
  onSubmit,
  replyTo,
  onCancel,
}: IHomeworkCommentInputProps) {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { isSubmitting, errors },
    reset,
  } = useForm({
    mode: 'onSubmit',
  })

  const handleCancel = useCallback(() => {
    setValue('content', '', { shouldValidate: true })
    onCancel && onCancel()
  }, [onCancel, setValue])

  const { t } = useTranslation('common')

  return (
    <form
      className={classNames('py-2 px-3 bg-[#e9e9e9]')}
      onSubmit={handleSubmit(async values => {
        await onSubmit(values)
        reset()
      })}
    >
      {replyTo && (
        <label id="reply_child">
          <Trans i18nKey="course_form.reply" /> @{replyTo}
        </label>
      )}
      <Textarea
        placeholder={t('course_form.homework_comment_placeholder')}
        className={
          'text-codgray py-1 px-2 w-full border border-[#e1e1e1] outline-none resize-none rounded-sm'
        }
        onFocus={onFocus}
        {...register('content', {
          required: 'Please Enter Your Comments',
        })}
        rows={4}
      />
      {get(errors, 'content.message') && (
        <div className={'text-red-600 p-1'}>
          {get(errors, 'content.message')}
        </div>
      )}
      <div className={'text-right mt-2 space-x-3'}>
        {typeof currentTime !== 'undefined' && (
          <label className={'text-center bg-white px-3 py-1 text-codgray'}>
            {formatTime(currentTime)}
          </label>
        )}

        {onCancel && (
          <Button
            size="small"
            variant="base"
            onClick={handleCancel}
            type="button"
            loading={isSubmitting}
            disabled={isSubmitting}
          >
            <Trans i18nKey="course_form.cancel_btn" />
          </Button>
        )}

        <Button
          type="submit"
          variant="brand"
          size="small"
          disabled={!canSubmit}
          loading={isSubmitting}
        >
          <Trans i18nKey="course_form.send_btn" />
        </Button>
      </div>
    </form>
  )
}
