import classNames from 'classnames'
import React, { useEffect, useState } from 'react'
import { get } from 'lodash'
import ImageComponent from '@/components/ImageComponent'
import { useAuth } from '@/containers/auth'
import { useStore } from '@/context/store'

type ICustomerServiceQRProps = {
  dimension?: number
  className?: string
  qrcodeUrl?: string
}

export default function CustomerServiceQR({
  dimension = 128,
  className,
  qrcodeUrl,
}: ICustomerServiceQRProps) {
  const {
    randomConsultantState: { fetchConsultantInfo },
  } = useStore()
  const { user, isAuthenticated, loading } = useAuth()
  const [finalQrCodeUrl, setFinalQrCodeUrl] = useState(() => {
    return (
      qrcodeUrl ?? get(user, ['lead_info', 'consultant_info', 'qr_code_url'])
    )
  })

  useEffect(() => {
    if (!loading) {
      if (isAuthenticated && user) {
        const qrcodeUrl = get(user, [
          'lead_info',
          'consultant_info',
          'qr_code_url',
        ])
        if (qrcodeUrl) {
          setFinalQrCodeUrl(qrcodeUrl)
        } else {
          fetchConsultantInfo().then(res => setFinalQrCodeUrl(res?.qr_code_url))
        }
      } else if (!isAuthenticated) {
        fetchConsultantInfo().then(res => setFinalQrCodeUrl(res?.qr_code_url))
      }
    }
  }, [fetchConsultantInfo, isAuthenticated, loading, user])

  return (
    <ImageComponent
      crossOrigin={'anonymous'}
      width={dimension}
      height={dimension}
      className={classNames('border-4 border-white rounded-md', className)}
      src={finalQrCodeUrl}
      alt="联系客服"
      hidePlaceholder={true}
    />
  )
}
