import { Grid, Select, TextInputProps, Input, Group, Text } from '@mantine/core'
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { phoneNumberUtil } from 'gga-fe-lib'
import { PhoneNumberItem } from 'gga-fe-lib/dist/lib/phone-number-util'

type TItemProps = React.ComponentPropsWithoutRef<'div'> & {
  label: string
} & PhoneNumberItem

const SelectItem = forwardRef<HTMLDivElement, TItemProps>(
  (
    { label, flag, countryName, phoneCode, countryCode, ...others }: TItemProps,
    ref
  ) => (
    <div ref={ref} {...others} data-code={countryCode}>
      <Group noWrap>
        <Text size="sm">{`${flag} +${phoneCode} ${countryName}`}</Text>
      </Group>
    </div>
  )
)
SelectItem.displayName = 'SelectItem'

type TPhoneNumberInputProps = {
  inputProps?: Omit<
    TextInputProps,
    'label' | 'value' | 'defaultValue' | 'onChange'
  >
  disabled?: boolean
  disabledCode?: boolean
  disabledNumber?: boolean
  areaCodeOverwrite?: string[]
  label?: React.ReactNode
  value?: TSplitValueType
  placeholder?: string
  defaultValue?: TSplitValueType
  onChange?: (value: TSplitValueType) => void
  [props: string]: any
  hideAreaCode?: boolean
  classes?: any
}

type TSplitValueType = {
  phone_number?: string
  phone_code?: string
  validator?: (num: string) => boolean
}

const PhoneNumberInput = forwardRef<HTMLInputElement, TPhoneNumberInputProps>(
  (props, ref) => {
    const {
      inputProps,
      value,
      defaultValue,
      label,
      onChange,
      disabled,
      disabledCode,
      disabledNumber,
      hideAreaCode = false,
      classes,
      placeholder,
      ...rest
    } = props
    const [selectedCountryCode, setSelectedCountryCode] = useState<
      string | undefined | null
    >(phoneNumberUtil.getCountryCodeByPhoneCode(value?.phone_code || ''))
    const areaCode = useMemo(() => {
      return phoneNumberUtil.getPhoneItems().map(item => {
        return {
          label: `+${item.phoneCode}`,
          value: item.countryCode,
          ...item,
        }
      })
    }, [])

    const _handleChange = useCallback(
      (batchChange: { key: keyof TSplitValueType; val }[]) => {
        if (disabled) {
          return
        }
        const v = {
          ...(value || {}),
        }
        const result = batchChange.reduce((res, chan) => {
          return {
            ...res,
            [chan.key]: chan.val,
          }
        }, v)

        onChange && onChange(result)
      },
      [disabled, onChange, value]
    )

    useEffect(() => {
      if (value?.phone_code && value?.phone_number) {
        try {
          const newCountryCode = phoneNumberUtil.getCountryCodeByPhoneNumber(
            `+${value.phone_code}${value.phone_number}`
          )

          setSelectedCountryCode(newCountryCode)
        } catch (error) {
          // pass
        }
      }
      // const valueCountryCode = value?.phone_code
      //   ? phoneNumberUtil.getCountryCodeByPhoneCode(value.phone_code)
      //   : undefined;
      // if (valueCountryCode) {
      //   setSelectedCountryCode(valueCountryCode);
      // } else {
      //   setSelectedCountryCode(undefined);
      // }
    }, [value.phone_code, value.phone_number])

    return (
      <Input.Wrapper classNames={{ ...classes }} label={label} {...inputProps}>
        <Grid gutter={12}>
          {!hideAreaCode && (
            <Grid.Col span={5}>
              <Select
                searchable={true}
                icon={
                  selectedCountryCode
                    ? phoneNumberUtil.getPhoneItemByCountryCode(
                        selectedCountryCode
                      )?.flag
                    : undefined
                }
                filter={(value, item) => {
                  return (
                    item.phoneCode
                      .toLowerCase()
                      .includes(value.toLowerCase().trim()) ||
                    item.countryName
                      .toLowerCase()
                      .includes(value.toLowerCase().trim())
                  )
                }}
                styles={{
                  root: {
                    position: 'relative',
                  },
                  dropdown: {
                    width: '240px !important',
                    left: '0 !important',
                  },
                }}
                itemComponent={SelectItem}
                disabled={disabled || disabledCode}
                value={selectedCountryCode}
                onChange={v => {
                  setSelectedCountryCode(v)
                  _handleChange([
                    {
                      key: 'phone_code',
                      val: v
                        ? phoneNumberUtil.getPhoneCodeByCountryCode(v)
                        : null,
                    },
                    {
                      key: 'validator',
                      val: v ? phoneNumberUtil.getValidatorByCode(v) : null,
                    },
                  ])
                }}
                data={areaCode}
                error={Boolean(inputProps?.error)}
              />
            </Grid.Col>
          )}
          <Grid.Col span={hideAreaCode ? 12 : 7}>
            <Input
              ref={ref}
              {...rest}
              // icon={value?.phone_code}
              disabled={disabled || disabledNumber || !value?.phone_code}
              value={value?.phone_number}
              onChange={e => {
                _handleChange([
                  { key: 'phone_number', val: e.currentTarget.value },
                ])
              }}
              invalid={Boolean(inputProps?.error)}
              placeholder={placeholder}
            />
          </Grid.Col>
        </Grid>
      </Input.Wrapper>
    )
  }
)

PhoneNumberInput.displayName = 'PhoneNumberInput'

export default PhoneNumberInput
