import React, { useCallback } from "react";
import type { IPlaybackProgressProps } from "../components/PlaybackProgress";
import PlaybackProgress from "../components/PlaybackProgress";
import VolumeControl from "../components/VolumeControl";

type IRecordingPlaybackControlsProps = IPlaybackProgressProps & {
  playing?: boolean;
  onPlayButtonClick?: (play: boolean) => void;
  onExit?: () => void;
  volume: number;
  onVolumeChange: (volume: number) => void;
};

export default function RecordingPlaybackControls({
  current,
  playing = false,
  onPlayButtonClick,
  onExit,
  onSeekEnd,
  total,
  volume,
  onVolumeChange,
}: IRecordingPlaybackControlsProps) {
  const handlePlayClick = useCallback(() => {
    onPlayButtonClick && onPlayButtonClick(!playing);
  }, [onPlayButtonClick, playing]);
  const handleSeekEnd = useCallback(
    p => {
      onSeekEnd && onSeekEnd(p);
    },
    [onSeekEnd]
  );

  return (
    <div className="gvp-playback-container">
      {playing ? (
        <i
          style={{
            fontSize: 28,
          }}
          className="iconfont iconpause gvp-playback-play"
          onClick={handlePlayClick}
        />
      ) : (
        <i
          style={{
            fontSize: 28,
          }}
          className="iconfont iconplay-plain gvp-playback-play"
          onClick={handlePlayClick}
        />
      )}
      <div className="gvp-playback-progress-wrapper">
        <PlaybackProgress
          total={total}
          current={current}
          onSeekEnd={handleSeekEnd}
        />
      </div>
      <VolumeControl onChange={onVolumeChange} value={volume} />
      <button className="gvp-playback-exit" onClick={onExit}>
        Exit
      </button>
    </div>
  );
}
