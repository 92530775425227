import { useEffect, useMemo } from 'react'
import { Trans } from 'next-i18next'
import router from 'next/router'
import { get } from 'immutable'
import {
  AcademicCapIcon,
  CalendarIcon,
  DocumentTextIcon,
  IdentificationIcon,
  LockClosedIcon,
  PowerIcon,
} from '@heroicons/react/24/outline'
import { APP_LOCALE } from '@/constants/define'
import { useAuth } from '@/containers/auth'
import { logout } from '@/services/user'
import NotificationBell from '@/components/NotificationBell'
import TokenCostDisplay from '@/components/misc/TokenCostDisplay'
import { useUncommentedFeedback } from '@/store/uncommented-feedback-store'
import IconPark from '../IconPark'
import { ProfileDrawer } from './profile-drawer'

export default function Profile({ className = '' }: { className?: string }) {
  const { user, refreshUser, tokenBalance } = useAuth()

  const { hasUncommented, fetchPendingFeedbackStatus } =
    useUncommentedFeedback()

  useEffect(() => {
    fetchPendingFeedbackStatus()
  }, [fetchPendingFeedbackStatus])

  const menuItems = useMemo(
    () =>
      [
        {
          label: <Trans i18nKey="profile_dropdown.my_info" />,
          onClick: () => router.push('/information'),
          icon: <IdentificationIcon className={'h-5'} />,
        },
        ...(APP_LOCALE !== 'en_US'
          ? [
              {
                label: (
                  <div className="flex space-x-1 items-center justify-start">
                    <span>
                      <Trans i18nKey="profile_dropdown.session_history" />
                    </span>

                    {hasUncommented && (
                      <span className="bg-[#FF3B5C] rounded-full py-[1px] px-1.5 text-white text-[10px]">
                        NEW
                      </span>
                    )}
                  </div>
                ),
                onClick: () => router.push('/course-history'),
                icon: (
                  <span className="w-5 h-5 flex justify-center items-center">
                    <IconPark
                      name="course-review"
                      className="text-base text-white"
                    />
                  </span>
                ),
              },
            ]
          : []),
        {
          label: <Trans i18nKey="profile_dropdown.my_orders" />,
          onClick: () => router.push('/my-orders'),
          icon: <DocumentTextIcon className={'h-5'} />,
        },
        ...(APP_LOCALE !== 'en_US'
          ? [
              {
                label: <Trans i18nKey="profile_dropdown.my_subscription" />,
                onClick: () => router.push('/my-subscriptions'),
                icon: <CalendarIcon className={'h-5'} />,
              },
              {
                label: (
                  <Trans i18nKey="profile_dropdown.my_certificates"></Trans>
                ),
                onClick: () => router.push('/my-certificates'),
                icon: <AcademicCapIcon className={'h-5'} />,
              },
              {
                label: <Trans i18nKey="profile_dropdown.password" />,
                onClick: () => router.push('/password'),
                icon: <LockClosedIcon className={'h-5'} />,
              },
            ]
          : []),
      ].filter(v => v),
    [hasUncommented]
  )

  return (
    <div className="flex items-center space-x-2 px-1">
      {APP_LOCALE !== 'en_US' && (
        <>
          <NotificationBell />
          <TokenCostDisplay
            size={'sm'}
            cost={get(tokenBalance || {}, 'token_balance', 0)}
          />
        </>
      )}

      <ProfileDrawer
        user={user}
        hasUncommented={hasUncommented}
        menus={menuItems}
        onLogOut={async () => {
          await logout()
          refreshUser()
        }}
      />
    </div>
  )
}
