import { NSLiveSession, NSProduct } from 'gga-types'
import { useRouter } from 'next/router'
import { create } from 'zustand'

interface TModal<T> {
  isOpen: boolean
  params: T
}

export interface TModals {
  AddCreditModal: TModal<void>
  SelectCouponModal: TModal<void>
  BookingSuccessModal: TModal<{
    product: NSProduct.Product | null | undefined
    session?: NSLiveSession.LiveSessionItem
  }>
  CashierModalV2: TModal<{
    product: NSProduct.Product | null | undefined
    additionType?: 'Unknown' | 'EventEnrollment'
    session?: NSLiveSession.LiveSessionItem
    onDirectEnrollSuccess?: () => void
  }>
}

type TModalStore = {
  modals: TModals
  openModal: <T extends keyof TModals>(
    modalName: T,
    params: TModals[T]['params']
  ) => void
  closeModal: (modalName: keyof TModals) => void
  getModalState: <T extends keyof TModals>(modalName: T) => TModals[T]
}

export const useModalStore = create<TModalStore>((set, get) => {
  return {
    modals: {} as TModals,
    openModal: (modalName, params) => {
      set(state => ({
        modals: {
          ...state.modals,
          [modalName]: { isOpen: true, params },
        },
      }))
    },
    closeModal: modalName => {
      set(state => ({
        modals: {
          ...state.modals,
          [modalName]: { isOpen: false },
        },
      }))
    },
    getModalState: modalName => {
      return get().modals[modalName] || { isOpen: false }
    },
  }
})
