import { IGameCategory } from 'gga-types'
import { request } from '@/utils/request'
import { IResData } from '@/@types/global'

export async function getGameCategories() {
  return request<IResData<IGameCategory[]>>({
    url: '/v2/gameCategory',
    method: 'get',
  })
}
