import classNames from 'classnames'
import React from 'react'
import IconPark from '@/components/IconPark'

const paymentVendors = [
  {
    name: 'wechatpay',
    logo: 'Wechatpay',
    label: '微信支付',
  },
  {
    name: 'alipay',
    logo: 'Alipay',
    label: '支付宝',
  },
] as const

export type TVendors = typeof paymentVendors[number]['name']

interface ChinaPaymentVendorSelectorProps {
  value?: TVendors
  onChange?: (value?: TVendors) => void
  theme?: 'light' | 'dark'
}

export const ChinaPaymentVendorSelector = ({
  value,
  onChange,
  theme,
}: ChinaPaymentVendorSelectorProps) => {
  return (
    <div className="flex justify-center space-x-2.5 mx-auto w-full">
      {paymentVendors.map(vendor => {
        return (
          <div
            key={vendor.name}
            role="button"
            className={classNames(
              'flex-1 rounded cursor-pointer hover:border-gold-200 h-[50px] flex items-center pl-3 space-x-1',
              value === vendor.name
                ? 'ring-2 ring-brand-500 bg-brand-500/10'
                : 'ring-2 ring-[#EEE]',
              theme === 'light' ? 'bg-[#F6F6F6]' : ''
            )}
            onClick={() => {
              onChange?.(vendor.name)
            }}
          >
            <IconPark name={vendor.logo} className="text-2xl" />
            <span className="text-base">{vendor.label}</span>
          </div>
        )
      })}
    </div>
  )
}
