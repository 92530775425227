import { APP_LOCALE } from '@/constants/define'

export const channelIOLanguage = (local: typeof APP_LOCALE) => {
  switch (local) {
    case 'en_US':
      return 'en'
    case 'kr':
      return 'ko'
    default:
      return 'en'
  }
}

declare global {
  interface Window {
    ChannelIO?: IChannelIO
    ChannelIOInitialized?: boolean
  }
}

interface IChannelIO {
  c?: (...args: any) => void
  q?: [methodName: string, ...args: any[]][]
  (...args: any): void
}

interface BootOption {
  appearance?: string
  customLauncherSelector?: string
  hideChannelButtonOnBoot?: boolean
  hidePopup?: boolean
  language?: string
  memberHash?: string
  memberId?: string
  mobileMessengerMode?: string
  pluginKey: string
  profile?: Profile
  trackDefaultEvent?: boolean
  trackUtmSource?: boolean
  unsubscribe?: boolean
  unsubscribeEmail?: boolean
  unsubscribeTexting?: boolean
  zIndex?: number
}

interface Callback {
  (error: Error | null, user: CallbackUser | null): void
}

interface CallbackUser {
  alert: number
  avatarUrl: string
  id: string
  language: string
  memberId: string
  name?: string
  profile?: Profile | null
  tags?: string[] | null
  unsubscribeEmail: boolean
  unsubscribeTexting: boolean
}

interface UpdateUserInfo {
  language?: string
  profile?: Profile | null
  profileOnce?: Profile
  tags?: string[] | null
  unsubscribeEmail?: boolean
  unsubscribeTexting?: boolean
}

interface Profile {
  [key: string]: string | number | boolean | null
}

interface FollowUpProfile {
  name?: string | null
  mobileNumber?: string | null
  email?: string | null
}

interface EventProperty {
  [key: string]: string | number | boolean | null
}

type Appearance = 'light' | 'dark' | 'system' | null

export class ChannelIOClass {
  // constructor() {
  //   this.loadScript();
  // }

  boot(option: BootOption, callback?: Callback) {
    window.ChannelIO?.('boot', option, callback)
  }

  shutdown() {
    window.ChannelIO?.('shutdown')
  }

  showMessenger() {
    window.ChannelIO?.('showMessenger')
  }

  hideMessenger() {
    window.ChannelIO?.('hideMessenger')
  }

  openChat(chatId?: string | number, message?: string) {
    window.ChannelIO?.('openChat', chatId, message)
  }

  track(eventName: string, eventProperty?: EventProperty) {
    window.ChannelIO?.('track', eventName, eventProperty)
  }

  onShowMessenger(callback: () => void) {
    window.ChannelIO?.('onShowMessenger', callback)
  }

  onHideMessenger(callback: () => void) {
    window.ChannelIO?.('onHideMessenger', callback)
  }

  onBadgeChanged(callback: (alert: number) => void) {
    window.ChannelIO?.('onBadgeChanged', callback)
  }

  onChatCreated(callback: () => void) {
    window.ChannelIO?.('onChatCreated', callback)
  }

  onFollowUpChanged(callback: (profile: FollowUpProfile) => void) {
    window.ChannelIO?.('onFollowUpChanged', callback)
  }

  onUrlClicked(callback: (url: string) => void) {
    window.ChannelIO?.('onUrlClicked', callback)
  }

  clearCallbacks() {
    window.ChannelIO?.('clearCallbacks')
  }

  updateUser(userInfo: UpdateUserInfo, callback?: Callback) {
    window.ChannelIO?.('updateUser', userInfo, callback)
  }

  addTags(tags: string[], callback?: Callback) {
    window.ChannelIO?.('addTags', tags, callback)
  }

  removeTags(tags: string[], callback?: Callback) {
    window.ChannelIO?.('removeTags', tags, callback)
  }

  setPage(page: string) {
    window.ChannelIO?.('setPage', page)
  }

  resetPage() {
    window.ChannelIO?.('resetPage')
  }

  showChannelButton() {
    window.ChannelIO?.('showChannelButton')
  }

  hideChannelButton() {
    window.ChannelIO?.('hideChannelButton')
  }

  setAppearance(appearance: Appearance) {
    window.ChannelIO?.('setAppearance', appearance)
  }

  test(str: string) {
    console.log('test: ', str)
  }
}

const bootstrap = (pluginKey: string) => {
  return new Promise<void>((resolve, reject) => {
    const w = window
    if (w.ChannelIO) {
      resolve()
      return
    }
    const ch = function (...rest) {
      ch.c(rest)
    }
    ch.q = []
    ch.c = function (args) {
      ch.q.push(args)
    }
    w.ChannelIO = ch
    function l() {
      if (w.ChannelIOInitialized) {
        resolve()
        return
      }

      const s = document.createElement('script')
      s.type = 'text/javascript'
      s.async = true
      s.src = 'https://cdn.channel.io/plugin/ch-plugin-web.js'
      const x = document.getElementsByTagName('script')[0]
      if (x.parentNode) {
        x.parentNode.insertBefore(s, x)
      }

      s.addEventListener('load', () => {
        resolve()
      })
    }
    if (document.readyState === 'complete') {
      l()
    } else {
      w.addEventListener('DOMContentLoaded', l)
      w.addEventListener('load', l)
    }
  }).then(() => {
    return window.ChannelIO?.(
      'boot',
      {
        pluginKey,
        zIndex: 100,
      },
      () => {
        window.ChannelIOInitialized = true
      }
    )
  })
}

const instance = new ChannelIOClass()

export class ChannelIOService {
  // eslint-disable-next-line no-undef
  public static instance = new Proxy(instance, {
    get(target, prop, receiver) {
      if (!window.ChannelIOInitialized) {
        return (...args: any[]) => {
          const intervalId = setInterval(() => {
            if (window.ChannelIOInitialized) {
              clearInterval(intervalId)
              target[prop].call(target, ...args)
            }
          }, 500)
        }
      } else {
        return target[prop].bind(target)
      }
    },
  })

  public static bootstrap() {
    return bootstrap('bedeb256-a275-4a19-9de7-ef37a6b8b836')
  }
}
