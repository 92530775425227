import React, {
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { useTranslation } from 'next-i18next'
import { useThrottleFn } from 'ahooks'
import { NSClass } from 'gga-types'
import {
  ICoursewareModalContexts,
  useCoursewareModal,
} from '@/hooks/myPages/CoursewareModalProvider'
import { VideoUploadForm } from '@/components/Form/Courseware/VideoUploadForm'
import { submitPtHomework, submitWorkApiV2 } from '@/services/class'
import { PDFUploadForm } from '@/components/Form/Courseware/PDFUploadForm'
import useRawContent from '@/hooks/useRawContent'
import ReplayCodeUploadForm from '@/components/Form/Courseware/ReplayCodeUploadForm'
import ReplayFileUploadForm from '@/components/Form/Courseware/ReplayFileUploadForm'

type ISoloTaskUploadProps = {
  header?: ReactNode
  classId: number
  nodeId: number
  onSuccess: (record: NSClass.RecordType) => void
  submissionStatus?: NSClass.SubmissionStatus
  ctx?: ICoursewareModalContexts
}

export const SoloTaskUpload = ({
  header,
  classId,
  onSuccess,
  nodeId,
  submissionStatus,
  ctx = 'CLASS_MODAL_CONTEXT',
}: ISoloTaskUploadProps) => {
  const { courseware, requestClose, setBeforeClose, isGGAPro } =
    useCoursewareModal(ctx)

  const { parsed, renderReplacedVideo } = useRawContent({
    content: courseware.content || '',
  })

  const { i18n } = useTranslation()
  const [submitting, setSubmitting] = useState(false)

  // 通过i18n.language记录file_upload_platform值
  // 韩国使用aws而zh_CN使用aliyun
  const platform = useMemo(() => {
    switch (i18n.language) {
      case 'kr':
        return 'aws'
      default:
        return 'aliyun'
    }
  }, [i18n.language])

  const _onSubmit = useCallback(
    async data => {
      // Build POST Body Data
      setSubmitting(true)
      const postData = {
        description: data.description,
        content: data.url,
        file_path: data.file_name,
        replay_code: data.replay_code,
        replay_file_path: data.replay_file_path,
        file_upload_platform: data.file_upload_platform || platform,
        file_size: data.file_size,
        player_identity: data.player_identity,
      }

      try {
        const req = isGGAPro ? submitPtHomework : submitWorkApiV2
        const res = await req(classId, nodeId, postData)

        if (res && res.data && res.data.data) {
          onSuccess(res.data.data)
        }

        setSubmitting(false)
      } catch (e) {
        console.error(e)
        setSubmitting(false)
      }
    },
    [classId, isGGAPro, nodeId, onSuccess, platform]
  )
  const { run: onSubmit } = useThrottleFn(_onSubmit, { wait: 1000 })

  // 提交 SoloTask 作业
  const submissionType = useMemo(() => {
    return courseware.syllabus.submit_type
  }, [courseware])

  useEffect(() => {
    renderReplacedVideo()
  }, [renderReplacedVideo])

  return (
    <div
      className={
        "overflow-auto bg-repeat bg-[url('https://res.gengesports.cn/user-web/images/bg-faq.png')]"
      }
    >
      <div className={'max-w-screen-xl mx-auto bg-white bg-opacity-60 p-2'}>
        {header}
        <div
          className="w-full gga-page-view mb-12"
          dangerouslySetInnerHTML={{ __html: parsed }}
        />
        {submissionType === 'video' && (
          <VideoUploadForm
            className="pb-8"
            submissionStatus={submissionStatus}
            submitting={submitting}
            requestClose={requestClose}
            onSubmit={onSubmit}
            onModalClose={setBeforeClose}
          />
        )}
        {submissionType === 'replay_code' && (
          <ReplayCodeUploadForm
            className="pb-8"
            submissionStatus={submissionStatus}
            onSubmit={onSubmit}
            onModalClose={setBeforeClose}
          />
        )}
        {submissionType === 'replay_file' && (
          <ReplayFileUploadForm
            className="pb-8"
            submissionStatus={submissionStatus}
            onSubmit={onSubmit}
            onModalClose={setBeforeClose}
          />
        )}

        {submissionType === 'pdf' && (
          <PDFUploadForm
            className="pb-8"
            submitting={submitting}
            requestClose={requestClose}
            onSubmit={onSubmit}
            onModalClose={setBeforeClose}
          />
        )}
      </div>
    </div>
  )
}
