import { memo, ReactNode, useMemo } from 'react'
import { Alert, Button } from 'stripe-ui'
import { AlertVariant } from 'stripe-ui/dist/Alert'
import { Trans, useTranslation } from 'next-i18next'

type MetaItem = {
  variant: AlertVariant
  getLabel: (callbackParams: FeedbackBarProps & { t: any }) => ReactNode
}

type MetaStruct = Partial<Record<FeedbackStatus, MetaItem>>

const feedbackStatusMapping: MetaStruct = {
  submitted: {
    variant: 'info',
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    getLabel: ({ t }) => {
      return <Trans t={t} i18nKey="course_form.homework_submitted" />
    },
  },
  failed: {
    variant: 'error',
    getLabel: ({ canReupload, onReupload, t }) => {
      return (
        <span>
          <Trans t={t} i18nKey="course_form.homework_failed" />
          {canReupload && (
            <a
              className="ml-2 text-brand no-underline hover:text-brand-light hover:underline focus:outline-none"
              href="#"
              onClick={e => {
                e.preventDefault()
                onReupload()
              }}
            >
              <Trans i18nKey="course_form.upload" />
            </a>
          )}
        </span>
      )
    },
  },
  finished: {
    variant: 'success',
    getLabel: ({ canReupload, onReupload, t, onViewFeedback, hasFeedback }) => {
      return (
        <span>
          <Trans t={t} i18nKey="course_form.homework_finished" />
          {hasFeedback === true ? (
            <Button variant={'a'} onClick={onViewFeedback}>
              {t('course_form.view_rating')}
            </Button>
          ) : (
            <Button variant={'a'} onClick={onViewFeedback}>
              {t('course_form.rate')}
            </Button>
          )}
          {canReupload && (
            <a
              className="ml-2 text-brand no-underline hover:text-brand-light hover:underline focus:outline-none"
              href="#"
              onClick={e => {
                e.preventDefault()
                onReupload()
              }}
            >
              <Trans i18nKey="course_form.upload" />
            </a>
          )}
        </span>
      )
    },
  },
}

const trialFeedbackStatusMapping: MetaStruct = {
  finished: {
    variant: 'success',
    getLabel: ({ t, onViewFeedback, hasFeedback }) => {
      return (
        <span>
          <Trans t={t} i18nKey="course_form.homework_trial_finished" />
          {hasFeedback === true ? (
            <Button variant={'a'} onClick={onViewFeedback}>
              {t('course_form.view_rating')}
            </Button>
          ) : (
            <Button variant={'a'} onClick={onViewFeedback}>
              {t('course_form.rate')}
            </Button>
          )}
        </span>
      )
    },
  },
  failed: {
    variant: 'error',
    getLabel: ({ canReupload, onReupload, t }) => {
      return (
        <span>
          <Trans t={t} i18nKey="course_form.vod_err_reupload" />
          {canReupload && (
            <a
              className="ml-2 text-brand no-underline hover:text-brand-light hover:underline focus:outline-none"
              href="#"
              onClick={e => {
                e.preventDefault()
                onReupload()
              }}
            >
              <Trans i18nKey="course_form.upload" />
            </a>
          )}
        </span>
      )
    },
  },
}

const ggaProFeedbackStatusMapping: MetaStruct = {
  finished: {
    variant: 'success',
    getLabel: ({ t, onViewFeedback, hasFeedback }) => {
      return (
        <span>
          {t('course_form.gga_pro_finished')}
          {hasFeedback === true ? (
            <Button variant={'a'} onClick={onViewFeedback}>
              {t('course_form.view_rating')}
            </Button>
          ) : (
            <Button variant={'a'} onClick={onViewFeedback}>
              {t('course_form.rate')}
            </Button>
          )}
        </span>
      )
    },
  },
  failed: {
    variant: 'error',
    getLabel: ({ t }) => {
      return <span>{t('course_form.gga_pro_failed')}</span>
    },
  },
}

type FeedbackStatus = 'submitted' | 'failed' | 'finished'

interface FeedbackBarProps {
  status: FeedbackStatus
  onReupload: () => void
  canReupload: boolean
  isTrial: boolean
  children?: ReactNode
  isGGAPro?: boolean
  onViewFeedback: () => void
  hasFeedback?: boolean
}

const FeedbackBar = memo((props: FeedbackBarProps) => {
  const { status, isTrial, children, isGGAPro = false } = props
  const { t } = useTranslation()

  const meta: MetaItem | undefined = useMemo(() => {
    if (isTrial) {
      return trialFeedbackStatusMapping[status]
    } else if (isGGAPro) {
      return ggaProFeedbackStatusMapping[status]
    }
    return feedbackStatusMapping[status]
  }, [isGGAPro, isTrial, status])

  if (!meta) {
    return null
  }
  return (
    <Alert className={'mb-0'} variant={meta.variant as AlertVariant}>
      <div className={'text-center'}>
        {children ? children : meta.getLabel({ ...props, t })}
      </div>
    </Alert>
  )
})

FeedbackBar.displayName = 'FeedbackBar'

export default FeedbackBar
