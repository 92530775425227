import React from 'react'
import ImageComponent from '@/components/ImageComponent'

const Kakao = () => {
  return (
    <div className="fixed bottom-7 right-6 z-100">
      <a
        href="https://pf.kakao.com/_nlfAK"
        title="카카오톡으로 상담받기"
        target="_blank"
        className="block w-12"
        rel="noreferrer"
      >
        <ImageComponent src="https://www.ggacademy.gg/wp-content/uploads/2022/10/kakao-1.png" />
      </a>
    </div>
  )
}

export default Kakao
