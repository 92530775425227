import { isBrowser } from '@/services/Utils'

export function curryGetLocalStorage(key: string, parse = false) {
  return () => {
    if (isBrowser) {
      const data = window.localStorage.getItem(key)
      if (parse) {
        return data ? JSON.parse(data) : undefined
      }
      return data || ''
    }
    return ''
  }
}

export function currySetLocalStorage(key: string) {
  return (value: string) => {
    if (isBrowser) {
      window.localStorage.setItem(key, value)
    }
  }
}

export function curryGetSessionStorage(key: string, parse = false) {
  return () => {
    if (isBrowser) {
      const data = window.sessionStorage.getItem(key)
      if (parse) {
        return data ? JSON.parse(data) : undefined
      }
      return data || ''
    }
    return ''
  }
}

export function currySetSessionStorage(key: string) {
  return (value: string) => {
    if (isBrowser) {
      window.sessionStorage.setItem(key, value)
    }
  }
}

export function curryRemoveSessionStorage(key: string) {
  return () => {
    if (isBrowser) {
      window.sessionStorage.removeItem(key)
    }
  }
}

export function curryRemoveLocalStorage(key: string) {
  return () => {
    if (isBrowser) {
      window.localStorage.removeItem(key)
    }
  }
}
