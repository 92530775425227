export function padZero(
  str: string,
  len: number,
  direction: 'end' | 'start' = 'end'
) {
  if (direction === 'end') {
    return str.padEnd(len, '0')
  } else {
    return str.padStart(len, '0')
  }
}

export function formatNumberToLessThanTwoDecimals(num: number) {
  return Math.round(num * 10) / 10
}
