import React, {
  FC,
  forwardRef,
  PropsWithChildren,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { isMobile } from 'react-device-detect'
import classNames from 'classnames'
import { useRouter } from 'next/router'
import { isAfter, isSameDay } from 'date-fns'
import Link from 'next/link'
import IconPark from '@/components/IconPark'
import OneVOneCurtain from '@/components/curtains/one-v-one-curtain'
import { curryGetLocalStorage, currySetLocalStorage } from '@/utils/functions'
import ImageComponent from '@/components/ImageComponent'
import { ConsultantWechatModal } from '../modals/ConsultantWechatModal'

const FloatBtn = forwardRef<
  HTMLDivElement,
  PropsWithChildren<{
    onClick: () => void
    className?: string
  }>
>(({ onClick, children, className }, ref) => {
  return (
    <div
      ref={ref}
      className={classNames(
        'text-[#FAFAFA] flex flex-row justify-center items-center w-[48px] h-[48px] rounded-md p-3 cursor-pointer',
        className
      )}
      onClick={onClick}
    >
      {children}
    </div>
  )
})
FloatBtn.displayName = 'FloatBtn'

const DATE_TO_HIDE_PROMO = new Date(1701842400000) // Wed Dec 06 2023 14:00:00 GMT+0800 (中国标准时间)

const currySetCurtainOpened = currySetLocalStorage('_pm_op')
const curryGetCurtainOpened = curryGetLocalStorage('_pm_op')
export const FloatingContact = () => {
  const promoBtnRef = useRef<HTMLDivElement>(null)
  const [open, setOpen] = useState(false)
  const [openOneVOne, setOpenOneVOne] = useState(false)
  const [visible, setVisible] = useState(true)
  const router = useRouter()

  const isAfterPromoTime = useMemo(
    () => isAfter(new Date(), DATE_TO_HIDE_PROMO),
    []
  )

  //判断是否要默认打开 promo 弹窗
  useEffect(() => {
    const opened = curryGetCurtainOpened()
    const openDate = opened ? new Date(Number(opened)) : null
    const shouldOpen = !openDate || !isSameDay(new Date(), openDate)
    if (isAfterPromoTime) {
      // 是否已过赏味期限
      setOpenOneVOne(false)
    } else {
      if (
        (router.pathname.startsWith('/home') ||
          router.pathname.startsWith('/coach-platform')) &&
        shouldOpen
      ) {
        setOpenOneVOne(true)
        currySetCurtainOpened(String(Date.now()))
      } else {
        setOpenOneVOne(false)
      }
    }
  }, [isAfterPromoTime, router, router.asPath])

  useEffect(() => {
    if (!openOneVOne) {
      promoBtnRef.current?.classList.add(
        'scale-150',
        'animate-[shake_0.5s_ease-in-out_1000]'
      )
      setTimeout(() => {
        promoBtnRef.current?.classList.remove(
          'scale-150',
          'animate-[shake_0.5s_ease-in-out_1000]'
        )
      }, 1000)
    }
  }, [openOneVOne])

  useEffect(() => {
    if (isMobile) {
      let timeid: any
      setVisible(false)

      const handleScroll = () => {
        setVisible(true)
        clearTimeout(timeid)
        timeid = setTimeout(() => {
          setVisible(false)
        }, 1000)
      }

      window.addEventListener('scroll', handleScroll)

      return () => {
        clearTimeout(timeid)
        window.removeEventListener('scroll', handleScroll)
      }
    }
  }, [])

  const openKook = () => {
    window.open('https://kook.top/wOMeRH')
  }

  return (
    <>
      <div
        className={classNames(
          'fixed transform transition-transform	md:transition-none right-0 md:right-6 bottom-16 md:bottom-7 z-10 md:translate-x-0',
          {
            'translate-x-[calc(100%-32px)]': !visible,
            '-translate-x-1': visible,
          }
        )}
      >
        <div className={'flex flex-col space-y-1'}>
          {!isAfterPromoTime && (
            <FloatBtn
              ref={promoBtnRef}
              className={
                'transform ease-in-out transition-all duration-200 bg-brand'
              }
              onClick={() => {
                setOpenOneVOne(true)
              }}
            >
              <IconPark
                name={'youhuiquan'}
                className="text-[34px] fill-[#FAFAFA]"
              />
            </FloatBtn>
          )}

          <div
            className="w-[48px] h-[48px] cursor-pointer mb-5"
            onClick={openKook}
          >
            <ImageComponent
              src="/images/cs2page/kook.png"
              className="object-cover w-full"
            />
          </div>

          <FloatBtn
            onClick={() => {
              setOpen(true)
            }}
          >
            <IconPark
              name={'customer-service-new'}
              className="text-[48px] fill-[#FAFAFA]"
            />
          </FloatBtn>
        </div>
      </div>
      <ConsultantWechatModal open={open} setOpen={setOpen} />
      <OneVOneCurtain
        opened={openOneVOne}
        onClose={() => {
          setOpenOneVOne(false)
        }}
      />
    </>
  )
}
