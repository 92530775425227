import { useCallback } from 'react'
import usePagination from '@/hooks/usePagination'
import { NSComment } from 'gga-types'
import { getPublicCourseComments } from '@/services/comment'

export default function usePublicCourseComment(courseId) {
  const action = useCallback(
    async (p, ps) => {
      return getPublicCourseComments(courseId, p, ps)
    },
    [courseId]
  )
  const {
    total,
    page,
    pageSize,
    setPage,
    data,
    fetcher,
    clearList,
    hasNextPage,
    loading,
    unShiftNew,
    replaceItemAt,
    insertItemAt,
  } = usePagination<NSComment.CommentItem>(action, {
    initialPageSize: 10,
    appending: true,
  })

  return {
    total,
    page,
    pageSize,
    setPage,
    data,
    fetcher,
    clearList,
    hasNextPage,
    loading,
    unShiftNew,
    replaceItemAt,
    insertItemAt,
  }
}
