import { NSClass, NSCourse, NSSubscription } from 'gga-types'
import { request } from '@/utils/request'
import { IResDataArray, IPaginationQuery, IResData } from '@/@types/global'

type ExpandType = 'record' | 'schedule'

type IGetClassSyllabusParams = {
  expand?: ExpandType[]
  depth?: number
}

export function getClassSyllabuses(
  classId: number,
  params: IGetClassSyllabusParams = {}
) {
  const payload: any = { ...params }
  if (params.expand) {
    payload.expand = params.expand.join(',')
  }
  return request<IResData<NSClass.SyllabusItemStruct[]>>({
    url: `/v2/Classes/${classId}/syllabuses`,
    method: 'get',
    data: payload,
  })
}

/**
 * 获取课件详情，在打开课件时，进行获取
 * @param classId
 * @param nodeId
 * @returns
 */
export async function getCourseNodeDetailApiV2(classId, nodeId) {
  return request<IResData<NSClass.CoursewareType>>({
    url: `/v2/classes/${classId}/syllabuses/${nodeId}/courseware?expand=record`,
    method: 'get',
  })
}

/**
 * 每次打开 PageStd 时后台直接提交
 * @param courseId
 * @param syllabusCWNodeId
 * @returns
 */
export function finishUserRecordApi(courseId, syllabusCWNodeId) {
  const data = {
    status: 'finished',
  }

  return request<IResData<NSClass.TrialType>>({
    url: `/v2/classes/${courseId}/courseware/${syllabusCWNodeId}/record`,
    method: 'post',
    data: data,
  })
}

// 上传完视频，点提交时触发 for class_content(team_scrim视频属于某个class，而不是某个用户)
export function submitClassWorkApi(classId, id, data) {
  return request({
    url: `/v2/classes/${classId}/syllabuses/${id}/content`,
    method: 'post',
    data,
  })
}

/**
 * 提交作业
 * @param classId
 * @param coursewareId
 * @param payload {status: "submitted" | "finished" | "failed", score?: number}
 * @returns
 */
export function submitWorkApiV2(
  classId,
  coursewareId,
  payload?: {
    status: 'submitted' | 'finished' | 'failed'
    score?: number
  }
) {
  const data = { ...(payload || {}) }
  if (!data.status) {
    data.status = 'submitted'
  }
  return request({
    url: `/v2/classes/${classId}/courseware/${coursewareId}/record`,
    method: 'post',
    data,
  })
}

export function submitPtHomework(classId, coursewareId, data: any = {}) {
  data.status = 'submitted'

  return request<IResData<NSClass.TrialType>>({
    url: `/v2/classes/${classId}/courseware/${coursewareId}/personal-training/record`,
    method: 'post',
    data,
  })
}

export function queryPtRecordSubmissionsByGameId(id: number) {
  return request<IResData<NSClass.TrialType[]>>({
    url: `/v2/record/game/${id}`,
  })
}

export function getClassStudentsV2(classId) {
  return request({
    url: `/v2/classes/members/${classId}`,
    method: 'get',
  })
}

export function getNodeIdsOrderedByHotness(syllabusId: number) {
  return request({
    url: `/v2/classes/syllabuses/${syllabusId}/courseware/ordered/hot`,
    method: 'get',
  })
}

type TGetUserClassesParams = {
  expand: ExpandType[]
  category: NSClass.ClassItemCategory
}

export function getUserClassesApi(params?: Partial<TGetUserClassesParams>) {
  return request<IResData<NSClass.ClassItem[]>>({
    url: '/v2/Classes/user',
    data: params,
    method: 'get',
  })
}

export type IFetchClassesPublicParams = IPaginationQuery

export async function getUserClassByIdV2(id: number) {
  return request<IResData<NSClass.ClassItem>>({
    url: `/v2/classes/${id}`,
    method: 'get',
  })
}

export async function checkSubmissionStatus(classUuid: string) {
  return request<IResData<NSClass.SubmissionStatus>>({
    url: `/v2/classes/${classUuid}/submission-status`,
    method: 'get',
  })
}

export async function getHomeworkAwsUploadUrl(filename: string) {
  return request<IResData<string>>({
    url: `/v2/util/aws-s3-url/upload/homework/${filename}`,
    method: 'get',
  })
}

export async function getVideoRecordById(
  record_id: number,
  trialSequence: number
) {
  return request<IResData<NSClass.TrialType>>({
    url: `/v2/record/video-record/${record_id}/${trialSequence}`,
    method: 'get',
  })
}

export const fetchUserClassSubscriptionStatus = async (classId: number) => {
  return await request<IResData<NSSubscription.UserSubscriptionStatus>>({
    url: `/v2/classes/${classId}/user/subscriptionstatus`,
    method: 'get',
  })
}

export function checkIsGGAPro(classItem?: NSClass.ClassItem): boolean {
  const list = new Set(['basic', 'plus', 'pro'])
  return (
    !!classItem &&
    classItem.category === 'Solo' &&
    list.has(classItem?.program_sub_type?.name.toLowerCase() || '')
  )
}

export function getCourseNodeStatusTags(rootCourseId: number) {
  return request<IResData<NSCourse.ICoursewareStatusTag[]>>({
    url: `/v2/classes/course/${rootCourseId}/courseware/tag`,
  })
}

/**
 * 通过classid和syllabusid获取records 和它的trials
 * @returns
 */
export function getRecordByClassIdAndSyllabusId(
  classId: number,
  syllabusId: number
) {
  return request<IResData<NSClass.RecordType>>({
    url: `/v2/classes/${classId}/syllabuses/${syllabusId}/record`,
  })
}
