import { NSClass } from 'gga-types'
import { request } from '@/utils/request'
import { IResData } from '@/@types/global'

export type IPostCheckQuizResultRet = {
  passed: boolean
  rank: number
  answer?: NSClass.QuizAnswer
}

export const postCheckQuizResult = async (id, data: NSClass.QuizAnswer) => {
  return request<IResData<IPostCheckQuizResultRet>>({
    url: `/v2/quiz/check/${id}`,
    method: 'post',
    data,
  })
}
