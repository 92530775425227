/**
 * @description 获取异步媒体文件的duration
 */
export const getDuration = function (
  url: string,
  next: (duration: number) => any
) {
  // eslint-disable-next-line no-underscore-dangle
  const _player = new Audio(url);
  _player.addEventListener(
    "durationchange",
    function (e) {
      // eslint-disable-next-line @typescript-eslint/no-invalid-this,eqeqeq
      if (this.duration != Infinity) {
        // eslint-disable-next-line @typescript-eslint/no-invalid-this
        const { duration } = this;
        _player.remove();
        next(duration);
      }
    },
    false
  );
  _player.load();
  _player.currentTime = 24 * 60 * 60; // fake big time
  _player.volume = 0;
  _player.play();
  // waiting...
};
