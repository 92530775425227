import { request } from '@/utils/request'
import { IResData } from '@/@types/global'

type ICaptchaData = {
  image_base64: string
  id: string
}

export async function getCaptcha() {
  return request<IResData<ICaptchaData>>({
    url: `/v2/util/captcha`,
  })
}

type IVerifyCaptchaData = {
  id: string
  code: string
}

export async function verifyCaptcha(data: IVerifyCaptchaData) {
  return request<IResData<boolean>>({
    url: `/v2/util/captcha/verify`,
    method: 'post',
    data,
  })
}
