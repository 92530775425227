// 登录
import CryptoJS from 'crypto-js'
import { toast } from 'react-toastify'
import Router from 'next/router'
import { i18n } from 'next-i18next'
import { IGameInfo, NSUserProfile } from 'gga-types'
import { request } from '@/utils/request'
import { IResData, TUserTokenBalance } from '@/@types/global'
import { HOMEPAGE_DYNAMIC, isNoAuthRoute } from '@/constants/define'
import { getWebAnalyticsInfo } from '@/utils/referrerHelper'
import { utmHelper } from '@/utils/utmHelper'
import { Jwt } from '@/services/jwt'
import { pushDataLayer } from '@/utils/gtm'

export function LoginApi(data) {
  return request({
    url: '/v2/user/login',
    method: 'post',
    data,
  })
}

export function updateLeadInfoApi(data: { interested_program_id: number }) {
  return request({
    url: `/v2/user/lead/info`,
    method: 'patch',
    data,
  })
}

// 修改用户信息
// // params 对象的格式
// {
//   name: "",
//   email: "",
//   attribute: {
//     age: 23,
//     grade: "",
//     game_items: "",
//   }
// }
export function updateUserInfoApi(params) {
  return request({
    url: '/v2/user/info',
    method: 'patch',
    data: params,
  })
}

export function updateUserPassword(params) {
  return request({
    url: '/v2/user/password',
    method: 'patch',
    data: params,
  })
}

export type IRegisterApiParams = {
  phone_code: string
  phone_number: string
  sms_verify_code: string
  password: string
  name: string
  learning_purpose?: string
  interested_program?: string
  inquiry?: string
  discord_id?: string
}
// 用户注册
export const registerApi = async (data: IRegisterApiParams) => {
  let hashPassword = CryptoJS.SHA256(data.password)
  hashPassword = hashPassword.toString(CryptoJS.enc.Hex)
  const response = await request({
    url: `/v2/user/register`,
    method: 'post',
    data: {
      ...data,
      password: hashPassword,
    },
  })

  if (response.success) {
    pushDataLayer({ event: 'sign_up' })
  }

  return response
}

export type TCreateLeadResponse = { qr_code_url: string; name: string }

export const createLead = (data: {
  email?: string
  first_name: string
  phone: string
  country_call_code: string
  learning_purpose?: string
  interested_program?: string
  inquiry?: string
  lol_current_tier?: string
  wechat?: string
  discord_id?: string
}) => {
  return request<IResData<TCreateLeadResponse>>({
    url: `/v2/user/new`,
    method: 'post',
    data: {
      interested_program: utmHelper.get('game'), // get game from utm
      email: `${data.phone}-register@gmail.com`,
      ...data,
      ...getWebAnalyticsInfo(),
    },
  })
}

export const createLeadWithCode = (data: {
  email?: string
  first_name: string
  phone: string
  country_call_code: string
  interested_program?: string
  lol_current_tier?: string
  wechat?: string
  code: string
  extra_info?: string
}) => {
  return request<{
    data: {
      name: string
      qr_code_url: string
    } | null
  }>({
    url: `/v2/user/new-code`,
    method: 'post',
    data: {
      interested_program: utmHelper.get('game'), // get game from utm
      email: `${data.phone}-register@gmail.com`,
      ...data,
      ...getWebAnalyticsInfo(),
    },
  })
}

export const resetPasswordApi = (data: {
  phone_code: string
  phone_number: string
  sms_verify_code: string
  password: string
}) => {
  let hashPassword = CryptoJS.SHA256(data.password)
  hashPassword = hashPassword.toString(CryptoJS.enc.Hex)
  return request({
    url: `/v2/user/reset/password`,
    method: 'post',
    data: {
      ...data,
      password: hashPassword,
    },
  })
}
// 获取用户游戏信息
export const getUserGameInfo = () => {
  return request<IResData<IGameInfo>>({
    url: '/v2/user/info/game',
    method: 'get',
  })
}
export const putUserGameInfo = (data: Partial<IGameInfo>) => {
  return request({
    url: '/v2/user/info/game',
    method: 'patch',
    data,
  })
}
export const getCheckDupPhone = (params: {
  phone_code: string
  phone_number: string
}) => {
  return request<IResData<boolean>>({
    url: '/v2/user/register/availability',
    method: 'post',
    data: params,
  })
}

export const getUserGuardianInfo = () => {
  return request<IResData<NSUserProfile.TGuardianInfo[]>>({
    url: '/v2/User/guardian/info',
  })
}

export const patchUserGuardianInfo = (
  data: Partial<NSUserProfile.TGuardianInfo>
) => {
  return request({
    url: '/v2/User/guardian/info',
    method: 'patch',
    data,
  })
}

export async function login(params, callback?) {
  let hashPassword = CryptoJS.SHA256(params.password)
  hashPassword = hashPassword.toString(CryptoJS.enc.Hex)
  const result = await LoginApi({
    ...params,
    password: hashPassword,
  })
  callback && callback()
  if (result.success) {
    Jwt.setToken(result.data?.data?.access_token)
    return true
  } else {
    toast.error(result.message)
    return false
  }
}

export async function logout() {
  Jwt.clear()
  if (!isNoAuthRoute) {
    Router.push(HOMEPAGE_DYNAMIC(i18n))
  }
}

export async function relogin() {
  Jwt.clear()
  if (location.pathname !== '/') {
    Router.push('/login')
  }
}

export function getUserTokenBalance() {
  return request<IResData<TUserTokenBalance>>({
    url: '/v2/User/token/balance',
  })
}

type TLoginByPhoneSmsParams = {
  phone_number: string
  phone_code: string
  sms_code: string
  name?: string
  email?: string
  discord_id?: string
  registration_url?: string
  interested_program?: string
  registration_referring_site?: string
}

export function loginApiByPhoneSms(data: TLoginByPhoneSmsParams) {
  return request<IResData<{ access_token: string; is_register: boolean }>>({
    url: '/v2/User/login/verifycode',
    method: 'post',
    data: {
      interested_program: utmHelper.get('game'),
      email: `${data.phone_number}-register@gmail.com`,
      ...getWebAnalyticsInfo(),
      ...data,
    },
  }).then(result => {
    if (result.success && result.data) {
      Jwt.setToken(result.data.data.access_token)
      if (result.data.data.is_register) {
        pushDataLayer({ event: 'sign_up' })
      }
      return true
    } else {
      toast.error(result.message)
      return false
    }
  })
}

export function loginApiByPhoneSmsForUae(data: TLoginByPhoneSmsParams) {
  return request<IResData<{ access_token: string; is_register: boolean }>>({
    url: '/v2/User/login/phone',
    method: 'post',
    data: {
      interested_program: utmHelper.get('game'),
      email: `${data.phone_number}-register@gmail.com`,
      ...getWebAnalyticsInfo(),
      ...data,
    },
  }).then(result => {
    if (result.success && result.data) {
      Jwt.setToken(result.data.data.access_token)
      if (result.data.data.is_register) {
        pushDataLayer({ event: 'sign_up' })
      }
      return true
    } else {
      toast.error(result.message)
      return false
    }
  })
}
