import React, { useCallback, useMemo, useRef } from "react";
import { useDebounceFn, useSize } from "ahooks";
import type { DraggableEventHandler } from "react-draggable";
import Draggable from "react-draggable";
import { formatDuration } from "../utils/date";

export type IPlaybackProgressProps = {
  current: number; // 受控模式
  total: number;
  onSeeking?: (pos: number) => void;
  onSeekEnd?: (pos: number) => void;
  showValue?: boolean;
};

export default function PlaybackProgress({
  current,
  total,
  onSeeking,
  onSeekEnd,
  showValue = true,
}: IPlaybackProgressProps) {
  const progressRef = useRef<HTMLProgressElement>(null);
  const { width: pWidth } = useSize(progressRef) || {};
  const knobRef = useRef<HTMLDivElement>(null);
  const { width: kWidth } = useSize(knobRef) || {};

  const calcPercentage = useCallback((e: MouseEvent) => {
    const { x, width } =
      progressRef.current?.getBoundingClientRect() as DOMRect;
    let knobX = e.pageX;
    if (knobX < x) {
      knobX = x;
    } else if (knobX > x + width) {
      knobX = x + width;
    }

    const deltaX = knobX - x;
    const percentage = (deltaX / width) * 100;
    return percentage;
  }, []);

  const onDragStart: DraggableEventHandler = useCallback(e => {}, []);

  const onDragEnd: DraggableEventHandler = useCallback(
    e => {
      const percentage = calcPercentage(e as MouseEvent);

      onSeekEnd && onSeekEnd(percentage);
    },
    [calcPercentage, onSeekEnd]
  );

  const onDrag: DraggableEventHandler = useCallback(
    e => {
      const percentage = calcPercentage(e as MouseEvent);

      onSeeking && onSeeking(percentage);
    },
    [calcPercentage, onSeeking]
  );

  const handleProgressClick = useCallback(
    e => {
      const percentage = calcPercentage(e);
      onSeekEnd && onSeekEnd(percentage);
    },
    [calcPercentage, onSeekEnd]
  );

  const { run: debouceOnDrag } = useDebounceFn(onDrag, {
    wait: 20,
  });

  const knobPositionX = useMemo(() => {
    return pWidth ? (pWidth / 100) * current : 0;
  }, [pWidth, current]);

  return (
    <div className="gvp-playback-progress-container">
      <progress
        onMouseUp={handleProgressClick}
        ref={progressRef}
        className="gvp-playback-progress-bar"
        value={current}
        max={100}
      />
      <Draggable
        axis="x"
        bounds="parent"
        onStart={onDragStart}
        onDrag={debouceOnDrag}
        onStop={onDragEnd}
        position={{
          x: knobPositionX,
          y: 0,
        }}
      >
        <div ref={knobRef} className="gvp-playback-progress-knob" />
      </Draggable>
      {showValue && total && current ? (
        <div className="gvp-playback-progress-time">
          {formatDuration(`${total * (current / 100)}`)}
        </div>
      ) : null}
    </div>
  );
}
