import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { Trans, useTranslation } from 'next-i18next'
import {
  AudioFilled,
  CaretDownOutlined,
  CaretUpOutlined,
  FilePdfOutlined,
  FundFilled,
  PaperClipOutlined,
  PlaySquareFilled,
} from '@ant-design/icons'
import { toast } from 'react-toastify'
import classNames from 'classnames'
import scrollIntoView from 'scroll-into-view-if-needed'
import { Button } from 'stripe-ui'
import { get } from 'lodash'
import { NSComment, NSClass } from 'gga-types'
import { formatTime } from '@/utils/kits'
import { useCommentsChildList } from '@/hooks/myPages/useComments'
import { postCommentsV2 } from '@/services/comment'
import HomeworkCommentInput from '@/components/CoursewareModal/SoloAndTeamTaskReview/components/HomeworkComments/HomeworkCommentInput'
import { ICommentMode } from '@/components/CoursewareModal/SoloAndTeamTaskReview/components/HomeworkComments/type'

const renderTime = it => {
  const currentTime = (it.attributes && it.attributes.time) || 0
  return formatTime(currentTime)
}

type IHomeworkCommentItemProps<T = NSComment.CommentItem> = {
  index: number
  classUUID: string
  trialId: number
  item: T
  info: NSClass.SyllabusItemStruct
  onLabelClick?: (item: T, idx: number) => void
  onChildCommentSubmit?: () => void
  canSubmit?: boolean
  currentPlayingCommentId?: number
  isChild?: boolean
  childReplyToName?: string
  mode: ICommentMode
}

export default function HomeworkCommentItem({
  index,
  item,
  onLabelClick,
  info,
  onChildCommentSubmit,
  classUUID,
  trialId,
  canSubmit,
  currentPlayingCommentId,
  isChild = false,
  childReplyToName,
  mode,
}: IHomeworkCommentItemProps) {
  const {
    commentsChildList,
    refreshCommentList,
    loading: loadingComments,
  } = useCommentsChildList(item.root_id, trialId)
  const commentRef = useRef<HTMLDivElement | null>(null)

  const [showChildReplyInput, toggleChildReplyInput] = useState(false)
  const [showChildReplies, toggleChildReplies] = useState(false)
  const [replyTo, setReplyTo] = useState<NSComment.CommentItem>()
  const replyInputRef = useRef<HTMLDivElement>(null)
  const childCommentRef = useRef<HTMLDivElement>(null)
  const { t } = useTranslation()

  const handleChildReplySubmit = useCallback(
    async data => {
      await postCommentsV2(
        { ...data, parent_id: replyTo!.id },
        classUUID,
        info.id,
        trialId
      )
      refreshCommentList()
      toggleChildReplyInput(false)
      toggleChildReplies(true)
      toast.success(t('course_form.comment_success'))
      onChildCommentSubmit && onChildCommentSubmit()
    },
    [
      classUUID,
      info.id,
      onChildCommentSubmit,
      refreshCommentList,
      replyTo,
      t,
      trialId,
    ]
  )

  const renderLabel = useCallback(
    hasAttach => {
      const recordingIcons = (r: NSComment.CommentAttr['recording']) => {
        if (r.audioURL) {
          return (
            <>
              <PlaySquareFilled className="items-center flex" />
              <AudioFilled className="items-center flex" />
            </>
          )
        }
        return (
          <>
            <PlaySquareFilled className="items-center flex" />
          </>
        )
      }
      const attr = item.attributes ?? ({} as NSComment.CommentAttr)

      const audio =
        attr.recording &&
        (attr.recording.audioURL || attr.recording.drawingData)
          ? recordingIcons(attr.recording)
          : null

      const drawing = attr.drawing ? (
        <FundFilled className="items-center flex" />
      ) : null

      if (mode === 'normal') {
        return (
          <div className={'-skew-x-45'}>
            <span className="z-10 relative flex flex-nowrap flex-row space-x-2">
              <span>{renderTime(item)}</span>
              {audio} {drawing}
            </span>
          </div>
        )
      } else {
        const attach = hasAttach ? (
          <PaperClipOutlined className="items-center flex" />
        ) : null
        return (
          <div className={'-skew-x-45'}>
            <span className="z-10 relative flex flex-nowrap flex-row space-x-2">
              <span>P{attr.time || 1}</span>
              {audio} {drawing} {attach}
            </span>
          </div>
        )
      }
    },
    [item, mode]
  )

  const hasReplies = useMemo(() => !!item.reply_count, [item.reply_count])

  useEffect(() => {
    if (showChildReplies) {
      refreshCommentList()
    }
  }, [refreshCommentList, showChildReplies])

  useLayoutEffect(() => {
    if (showChildReplies) {
      scrollIntoView(childCommentRef.current!, {
        behavior: 'smooth',
        scrollMode: 'if-needed',
      })
    }
  }, [showChildReplies])
  useLayoutEffect(() => {
    if (showChildReplyInput) {
      scrollIntoView(replyInputRef.current!, {
        behavior: 'smooth',
        scrollMode: 'if-needed',
      })
    }
  }, [showChildReplies, showChildReplyInput])

  useEffect(() => {
    let currentTimeoutId
    if (commentRef.current) {
      if (item.id == currentPlayingCommentId && !currentTimeoutId) {
        commentRef.current.classList.add('border-gold')
        commentRef.current.classList.remove('border-white')
        scrollIntoView(commentRef.current, {
          behavior: 'smooth',
          scrollMode: 'if-needed',
        })
        currentTimeoutId = setTimeout(() => {
          if (commentRef.current) {
            commentRef.current.classList.remove('border-gold')
            commentRef.current.classList.add('border-white')
          }
          clearTimeout(currentTimeoutId)
        }, 1500)
      }
    }
    return () => {
      if (commentRef.current) {
        commentRef.current.classList.remove('border-gold')
        commentRef.current.classList.add('border-white')
      }
      clearTimeout(currentTimeoutId)
    }
  }, [currentPlayingCommentId, item])

  const emptyNamePlaceholder = useMemo(() => {
    const phone: string = get(item, 'creator.phone_number', '')
    return 'User' + phone.substring(phone.length - 4)
  }, [item])

  const childReplyTo = useCallback(
    childIndex => {
      const current = commentsChildList[childIndex]
      let name = ''

      for (let index = 0; index < commentsChildList.length; index++) {
        if (commentsChildList[index].id === current.parent_id) {
          name = commentsChildList[index].creator.name
          break
        }
      }
      if (name) {
        return '@' + name
      }

      return name
    },
    [commentsChildList]
  )

  return (
    <div className={classNames('relative')} ref={commentRef}>
      <div className={'flex items-end'}>
        {!isChild && (
          <>
            <div
              style={{
                clipPath: 'polygon(0 0, 78% 0, 100% 100%, 0% 100%)',
              }}
              className={
                'w-36 h-7 bg-[#e9e9e9] px-4 inline-block py-1 font-bold text-codgray line-clamp-1'
              }
              title={item.creator.name || emptyNamePlaceholder}
            >
              {item.creator.name || emptyNamePlaceholder}
            </div>
            <div
              className={
                'transform inline-block -translate-x-3.5 py-1 cursor-pointer text-white bg-gold px-4 skew-x-45 h-6'
              }
              onClick={() => onLabelClick && onLabelClick(item, index)}
            >
              {renderLabel(item.attachments && !!item.attachments.length)}
            </div>
          </>
        )}
      </div>
      <div className={'px-4 py-2 flex-1 text-codgray text-base bg-[#e9e9e9]'}>
        {isChild && (
          <div className={'mb-1'}>
            <div className={'flex justify-between'}>
              <label className={'text-sm'}>
                {item.creator.name || emptyNamePlaceholder}:
              </label>
              <Button
                variant="a"
                size="small"
                onClick={() => {
                  toggleChildReplyInput(prev => !prev)
                  setReplyTo(item)
                }}
              >
                <Trans i18nKey="course_form.reply" />
              </Button>
            </div>
            {childReplyToName && (
              <div className={'text-xs'}>{childReplyToName}</div>
            )}
          </div>
        )}
        <div
          className={classNames(
            (showChildReplies || showChildReplyInput) &&
              !isChild &&
              'border-b border-gold-400'
          )}
        >
          {item.attachments &&
            item.attachments[0] &&
            !isChild &&
            mode === 'pdf' && (
              <div className="my-2">
                <a
                  href={item.attachments[0].file_path}
                  target="_blank"
                  rel="noreferrer"
                  className="text-gold hover:text-gold-400 text-xs"
                >
                  <FilePdfOutlined className="mr-1 align-middle" />
                  <span>{item.attachments[0].name}</span>
                </a>
              </div>
            )}
          <p className={'pl-1 whitespace-pre-wrap text-sm break-words'}>
            {item.content}
          </p>

          {!isChild && (
            <div
              className={classNames(
                'mt-3 -mx-2',
                (showChildReplies || showChildReplyInput) && 'mb-3'
              )}
            >
              <Button
                size={'small'}
                variant="a"
                onClick={() => {
                  toggleChildReplyInput(prev => !prev)
                  setReplyTo(item)
                }}
              >
                <Trans i18nKey="course_form.reply" />
              </Button>

              {hasReplies && (
                <Button
                  size={'small'}
                  loading={loadingComments}
                  variant="a"
                  onClick={() => {
                    toggleChildReplies(prev => !prev)
                  }}
                  className="ml-4"
                >
                  <Trans i18nKey="course_form.view_replies" />
                  {showChildReplies ? (
                    <CaretUpOutlined className="ml-1 align-baseline" />
                  ) : (
                    <CaretDownOutlined className="ml-1 align-baseline" />
                  )}
                </Button>
              )}
            </div>
          )}
        </div>
      </div>

      <div ref={replyInputRef}>
        {showChildReplyInput && (
          <HomeworkCommentInput
            mode={mode}
            replyTo={replyTo!.creator.name}
            onSubmit={handleChildReplySubmit}
            onCancel={() => toggleChildReplyInput(false)}
            onFocus={() => {}}
            canSubmit={canSubmit}
          />
        )}
      </div>

      <div ref={childCommentRef}>
        {showChildReplies &&
          !isChild &&
          commentsChildList.map((child, idx) => {
            return (
              <HomeworkCommentItem
                mode={mode}
                childReplyToName={childReplyTo(idx)}
                isChild
                key={child.id}
                index={idx}
                classUUID={classUUID}
                item={child}
                info={info}
                trialId={trialId}
                onChildCommentSubmit={() => {
                  refreshCommentList()
                }}
              />
            )
          })}
      </div>
    </div>
  )
}
