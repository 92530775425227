import { Controller, useForm } from 'react-hook-form'
import { Button } from 'stripe-ui'
import { useTranslation } from 'next-i18next'
import { useMemo } from 'react'
import { IFeedbackFormProps } from '@/components/CoursewareModal/TrialFeedback/type'
import { RadioGroup, Rating } from './scatter'

export default function FormForTrialClass({
  onSubmit,
  viewData,
}: IFeedbackFormProps) {
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    mode: 'onSubmit',
    defaultValues: viewData
      ? {
          ...viewData,
          wanna_try_more: viewData.wanna_try_more ? 1 : 0,
        }
      : {
          coach_rating: undefined,
          platform_rating: undefined,
          wanna_try_more: undefined,
          other_notes: '',
          is_anonymous: false,
        },
  })

  const disabled = useMemo(() => !!viewData, [viewData])

  const { t } = useTranslation('my-class')

  return (
    <div className="flex flex-col items-center px-20 py-3">
      <h2 className="text-xl mb-3 font-semibold">
        {t('trial_feedback.title')}
      </h2>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="flex flex-col space-y-3"
      >
        <div className="relative flex space-x-2 items-center">
          <div className="text-lg font-semibold w-64 text-left">
            {t('trial_feedback.coach_rating')}
          </div>
          <Controller
            rules={{
              required: true,
            }}
            control={control}
            name="coach_rating"
            render={({ field: { onChange, value } }) => {
              return (
                <Rating disabled={disabled} onChange={onChange} value={value} />
              )
            }}
          />
          {errors && errors.coach_rating && (
            <div className="absolute text-red-600 -right-1 transform translate-x-full">
              {t('trial_feedback.please_fill')}
            </div>
          )}
        </div>
        <div className="relative flex space-x-2 items-center">
          <div className="text-lg font-semibold w-64 text-left">
            {t('trial_feedback.platform_rating')}
          </div>
          <Controller
            rules={{
              required: true,
            }}
            control={control}
            name="platform_rating"
            render={({ field: { onChange, value } }) => {
              return (
                <Rating disabled={disabled} onChange={onChange} value={value} />
              )
            }}
          />
          {errors && errors.platform_rating && (
            <div className="absolute text-red-600 -right-1 transform translate-x-full">
              {t('trial_feedback.please_fill')}
            </div>
          )}
        </div>
        <div className="relative flex space-x-2 items-center">
          <div className="text-lg font-semibold w-64 text-left">
            {t('trial_feedback.wanna_try_more')}
          </div>
          <Controller
            rules={{
              required: true,
            }}
            control={control}
            name="wanna_try_more"
            render={({ field: { onChange, value } }) => {
              return (
                <div className="flex-1 flex justify-center">
                  <RadioGroup
                    disabled={disabled}
                    onChange={onChange}
                    value={value}
                  />
                </div>
              )
            }}
          />
          {errors && errors.wanna_try_more && (
            <div className="absolute text-red-600 -right-1 transform translate-x-full">
              {t('trial_feedback.please_fill')}
            </div>
          )}
        </div>
        <div>
          <div className="text-lg font-semibold w-64 text-left">
            {t('trial_feedback.other_notes')}
          </div>
          <Controller
            control={control}
            name="other_notes"
            render={({ field: { onChange, value } }) => {
              return (
                <div className="mt-2">
                  <textarea
                    disabled={disabled}
                    maxLength={1000}
                    defaultValue={value}
                    onChange={onChange}
                    className="textarea h-32 w-full textarea-bordered"
                  />
                </div>
              )
            }}
          />
        </div>

        {!disabled && (
          <div className="flex items-center justify-center space-x-2">
            <Controller
              control={control}
              render={({ field: { onChange } }) => {
                return (
                  <div>
                    <input
                      onChange={onChange}
                      id={'anonymous'}
                      type={'checkbox'}
                      className={'mr-2 rounded'}
                    />
                    <label htmlFor={'anonymous'}>
                      {t('submit_anonymously')}
                    </label>
                  </div>
                )
              }}
              name={'is_anonymous'}
            />
            <Button type="submit" variant="brand">
              {t('course_form.submit', { ns: 'common' })}
            </Button>
          </div>
        )}
      </form>
    </div>
  )
}
