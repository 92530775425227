import { Button } from '@mantine/core'
import Link from 'next/link'
import React from 'react'
import { isMobile } from 'react-device-detect'
import { useTranslation } from 'next-i18next'
import ImageComponent from '@/components/ImageComponent'
import { APP_LOCALE } from '@/constants/define'

const ZhCNNotice = ({ hideButton = false }: { hideButton: boolean }) => {
  const { t } = useTranslation('payment-status')
  return (
    <div className="space-y-8">
      <div className="rounded-lg bg-gradient-to-r from-[#F6F6F6]/100 to-[#F6F6F6]/0 p-3 flex justify-start items-center space-x-3">
        <div>
          <p className="font-semibold">注意事项:</p>
          <ol className="list-decimal list-inside mt-2.5 space-y-2.5">
            <li>
              上课链接会在课前30分钟以
              <span className="font-semibold">短信形式</span>
              发送至您的购买手机号，请注意查收
            </li>
            <li className="lg:hidden">
              您也可以
              <span className="font-semibold">点击下方按钮</span>
              进入GGA小程序查看待参加活动
            </li>
            <li className="hidden lg:list-item">
              <span className="font-semibold">微信扫码</span>
              也可进入GGA小程序查看待参加活动
            </li>
          </ol>
        </div>
        {!isMobile && (
          <div className="flex-none">
            <ImageComponent
              src="https://res.gengesports.cn/user-web/images/mini-app-code%403x.png"
              className="w-[100px] h-[100px]"
            />
          </div>
        )}
      </div>

      <div className="flex flex-col items-center space-y-4">
        {!hideButton && (
          <Link href="/coach-platform">
            <Button className="!w-[288px]">{t('view_my_events')}</Button>
          </Link>
        )}

        {isMobile && (
          <Link href="https://www.gengacademy.com/webview/_miniapp/wechat/miniapp-proxy?_scheme_uid=38d7dec91b46466f98e08942a799a086">
            <Button variant="outline" className="!w-[288px]">
              前往GGA小程序
            </Button>
          </Link>
        )}
      </div>
    </div>
  )
}

const KrNotice = ({ hideButton = false }: { hideButton: boolean }) => {
  const { t } = useTranslation('payment-status')
  return (
    <div className="space-y-8">
      <div className="rounded-lg bg-gradient-to-r from-[#F6F6F6]/100 to-[#F6F6F6]/0 p-3 flex justify-start items-center space-x-3">
        <div>
          <p className="font-semibold text-brand-500">안내</p>
          <p className="mt-2.5">
            {t('payment-success-notice', { ns: 'coach-platform' })}
          </p>
        </div>
        <div className="flex-none">
          <ImageComponent
            src="https://res.gengesports.cn/user-web/images/1v1/gga_black_logo.png"
            className="w-[81px] h-[18px]"
          />
        </div>
      </div>

      {!hideButton && (
        <div className="flex flex-col items-center space-y-4">
          <Link href="/coach-platform">
            <Button className="!w-[288px]">{t('view_my_events')}</Button>
          </Link>
        </div>
      )}
    </div>
  )
}

export const CoachPlatformNotice = ({
  hideButton = false,
}: {
  hideButton?: boolean
}) => {
  if (APP_LOCALE === 'kr') {
    return <KrNotice hideButton={hideButton} />
  } else if (APP_LOCALE === 'zh_CN') {
    return <ZhCNNotice hideButton={hideButton} />
  }

  return null
}
