import qs from 'query-string'

const utmTypes = [
  'source',
  'campaign',
  'term',
  'content',
  'medium',
  'game', // GGA game category code
] as const

type UtmType = typeof utmTypes[number]

let queryObject: Record<string, string | string[] | null> = {}

const init = () => {
  queryObject = qs.parse(location.search)
}

/**
 * Get UTM value
 * @param type
 * @returns
 */
const get = (type: UtmType): string | string[] | null => {
  return queryObject[`utm_${type}`]
}

export const utmHelper = {
  init,
  get,
}
