export class QueryUtils {
  static getQueryItemFromRouter(
    query: any | undefined,
    key: string,
    isArray?: boolean
  ) {
    if (!query) {
      return
    }
    const item = query[key]
    if (!item) {
      return
    }
    if (Array.isArray(item)) {
      if (isArray) {
        return item
      } else {
        return item[0]
      }
    } else {
      return item
    }
  }
}
