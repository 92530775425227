import React, { useMemo } from 'react'
import { useTranslation } from 'next-i18next'
import { ClockCircleOutlined } from '@ant-design/icons'
import { NSClass } from 'gga-types'

type IWaitForCoachResponseProps = {
  record: NSClass.TrialType | NSClass.ClassContentType
}
export default function WaitForCoachResponse({
  record,
}: IWaitForCoachResponseProps) {
  const { t } = useTranslation('common')

  const playerIdentityComponent = useMemo(() => {
    try {
      const obj = JSON.parse(record.player_identity)
      return (
        <div>
          {Object.keys(obj).map(key => {
            return (
              <div key={key} className="flex space-x-2">
                <span className="w-1/2 text-right">{key}：</span>
                <span className="w-1/2 text-left">{obj[key]}</span>
              </div>
            )
          })}
        </div>
      )
    } catch (e) {
      return <>{record.player_identity}</>
    }
  }, [record.player_identity])

  switch (record.submit_type) {
    case 'replay_code':
      return (
        <div className="mx-auto text-center py-4 self-center text-codgray">
          <div className={'mb-4'}>
            <ClockCircleOutlined className={'text-6xl'} />
          </div>
          <div className="text-xl mb-4">
            {t('courseware.review.wait_for_coach.code_submitted')}
          </div>
          <div className="text-2xl mb-4">{record.replay_code}</div>
          <div className="text-lg mb-4">{record.player_identity}</div>
          <div className="text-xl mb-4">
            {record.status === 'failed'
              ? t('course_form.gga_pro_failed')
              : t('courseware.review.wait_for_coach.wait_for_vod')}
          </div>
        </div>
      )
    case 'replay_file':
      return (
        <div className="mx-auto text-center py-4 self-center text-codgray">
          <div className={'mb-4'}>
            <ClockCircleOutlined className={'text-6xl'} />
          </div>
          <div className="text-xl mb-4">
            {t('courseware.review.wait_for_coach.file_submitted')}
          </div>
          <div className="text-lg mb-4">{playerIdentityComponent}</div>
          <div className="text-xl mb-4">
            {record.status === 'failed'
              ? t('course_form.gga_pro_failed')
              : t('courseware.review.wait_for_coach.wait_for_vod')}
          </div>
        </div>
      )
    default:
      return null
  }
}
