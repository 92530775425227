import { useTranslation } from 'next-i18next'
import { useCallback, useEffect, useState } from 'react'
import classNames from 'classnames'
import { ILocalizationCode, NSComment } from 'gga-types'
import { formatDeclarativeTime } from '@/utils/time'
import IconPark from '@/components/IconPark'
import ReplyInput from '@/components/CoursewareModal/PageTask/PublicCourseComment/ReplyInput'
import {
  deleteComment,
  getChildCommentsFlat,
  postCommentsV2,
} from '@/services/comment'
import { useAuth } from '@/containers/auth'
import ImageComponent from '@/components/ImageComponent'

type IPublicCourseCommentItemProps = {
  comment: NSComment.CommentItem
  depth: number
  isMine: boolean
  syllabusId: number
  classUuid: string
  onChildrenCreated?: (rootId: number) => void
  onDelete?: () => void
  trialId: number
}

export default function PublicCourseCommentItem({
  comment,
  depth,
  isMine,
  syllabusId,
  classUuid,
  onChildrenCreated,
  onDelete,
  trialId,
}: IPublicCourseCommentItemProps) {
  const { i18n, t } = useTranslation()
  const { user } = useAuth()
  const [showReply, setShowReply] = useState(false)
  const [showChildComment, setShowChildComment] = useState(false)
  const [childComments, setChildComments] = useState<NSComment.CommentItem[]>()

  const onWriteReply = useCallback(() => {
    setShowReply(prev => !prev)
  }, [])

  const onViewReply = useCallback(async () => {
    setShowChildComment(prev => !prev)
  }, [])

  const fetchChildComments = useCallback(
    async (parentId: number) => {
      const res = await getChildCommentsFlat(syllabusId, parentId)
      if (res && res.data && res.data.data) {
        setChildComments(res.data.data.items)
      }
    },
    [syllabusId]
  )

  useEffect(() => {
    if (showChildComment) {
      fetchChildComments(comment.id)
    }
  }, [comment.id, fetchChildComments, showChildComment])

  return (
    <section>
      <div
        className={classNames(
          'flex items-start py-3 border-t',
          depth > 1 ? 'border-gold' : 'border-[#CDCDCD]'
        )}
      >
        <ImageComponent
          src={
            comment.creator.avatar_url ||
            'https://res.gengesports.cn/avatars/1.png'
          }
          className={'w-12 h-12 rounded-full mr-3'}
        />
        <div className={'flex-1'}>
          <div className={'text-lg text-gold mb-2'}>{comment.creator.name}</div>
          <div className={'text-[#5D5D5D] mb-2'}>
            {(() => {
              if (comment.deleted_at) {
                return (
                  <div className={'italic text-gray-400'}>
                    {t('comment_has_been_deleted')}
                  </div>
                )
              }
              switch (comment.status) {
                case 'sensitive':
                  return (
                    <div className={'italic text-gray-400'}>
                      {t('comment_is_sensitive')}
                    </div>
                  )
                default:
                  return (
                    <p className={'whitespace-pre-wrap break-words'}>
                      {depth > 1 && (
                        <span>{`${t('course_form.reply')}${
                          comment.parent_creator.name
                        }: `}</span>
                      )}
                      {comment.content}
                    </p>
                  )
              }
            })()}
          </div>

          <div className={'flex justify-between'}>
            <div
              className={'flex text-xs text-[#989898] space-x-2 items-center'}
            >
              <span>
                {formatDeclarativeTime(
                  new Date(comment.created_at),
                  i18n.language as ILocalizationCode
                )}
              </span>
              {isMine && !comment.deleted_at && (
                <span className={'flex items-end space-x-2'}>
                  <DeleteConfirmation
                    onDelete={async next => {
                      await deleteComment(comment.id)
                      onDelete && onDelete()
                      next()
                    }}
                  />
                </span>
              )}
            </div>
            <div className={'space-x-3 text-xs items-center'}>
              {comment.status === 'normal' && !comment.deleted_at && (
                <a
                  onClick={() => {
                    onWriteReply()
                  }}
                  className={
                    'cursor-pointer no-underline text-[#989898] hover:text-gold'
                  }
                >
                  {t('course_form.reply')}{' '}
                  {showReply && (
                    <span className={'align-middle text-[10px]'}>
                      <IconPark name={'close'} />
                    </span>
                  )}
                </a>
              )}
              {depth === 1 && comment.reply_count > 0 && (
                <a
                  onClick={() => {
                    onViewReply()
                  }}
                  className={
                    'cursor-pointer no-underline text-[#989898] hover:text-gold'
                  }
                >
                  {t('course_form.view_replies')}
                  {showChildComment && (
                    <span className={'align-middle text-[10px]'}>
                      <IconPark name={'close'} />
                    </span>
                  )}
                </a>
              )}
            </div>
          </div>
        </div>
      </div>
      {showReply && (
        <div className={'py-2'}>
          <ReplyInput
            maxCount={200}
            placeholder={`@${comment.creator.name}:`}
            onSubmit={async (data, next) => {
              if (depth > 1) {
                // 二级评论回复在同层
                const payload = {
                  ...data,
                  parent_id: comment.id,
                }
                await postCommentsV2(payload, classUuid, syllabusId, trialId)
              } else {
                await postCommentsV2(
                  { ...data, parent_id: comment.id },
                  classUuid,
                  syllabusId,
                  trialId
                )
                setShowChildComment(true)
                fetchChildComments(comment.id)
              }
              onChildrenCreated && onChildrenCreated(comment.root_id)
              setShowReply(false)
              next()
            }}
          />
        </div>
      )}
      {showChildComment &&
        childComments &&
        childComments.map((childComment, idx) => {
          return (
            <div key={childComment.id} className={'w-10/12 mx-auto'}>
              <PublicCourseCommentItem
                trialId={trialId}
                onChildrenCreated={parentId => {
                  fetchChildComments(parentId)
                }}
                onDelete={() => {
                  setChildComments(prev => {
                    return prev
                      ? [
                          ...prev?.slice(0, idx),
                          {
                            ...childComment,
                            deleted_at: new Date(),
                          },
                          ...prev?.slice(idx + 1),
                        ]
                      : undefined
                  })
                }}
                classUuid={classUuid}
                comment={childComment}
                depth={childComment.depth}
                isMine={Boolean(user && childComment.creator.id === user.id)}
                syllabusId={syllabusId}
              />
            </div>
          )
        })}
    </section>
  )
}

function DeleteConfirmation({
  onDelete,
}: {
  onDelete: (next: () => void) => void
}) {
  const [showDoubleConfirm, setShowDoubleConfirm] = useState(false)
  const { t } = useTranslation()
  return (
    <>
      {!showDoubleConfirm ? (
        <div
          className={'cursor-pointer text-[10px]'}
          onClick={() => {
            setShowDoubleConfirm(true)
          }}
        >
          <IconPark name={'trash'} />
        </div>
      ) : (
        <>
          <a
            className={'cursor-pointer'}
            onClick={() => {
              onDelete(() => {
                setShowDoubleConfirm(false)
              })
            }}
          >
            {t('confirm_delete')}
          </a>
          <a
            className={'cursor-pointer'}
            onClick={() => {
              setShowDoubleConfirm(false)
            }}
          >
            {t('course_form.cancel_btn')}
          </a>
        </>
      )}
    </>
  )
}
