import OSS from 'ali-oss'
import { useCallback, useState } from 'react'
import { OSS_CONSTANT } from '@/config/config'
import { getSTSAuth } from '@/services'
import { IUploadStatus } from '@/hooks/useUpload'
import { getHomeworkAwsUploadUrl } from '@/services/class'
import { multipartUpload } from '@/services/s3'

export const setupOssClient = async () => {
  const config = await getSTSAuth()

  return new OSS({
    accessKeyId: config.access_key_id,
    accessKeySecret: config.access_key_secret,
    stsToken: config.security_token,
    region: OSS_CONSTANT.Region,
    bucket: OSS_CONSTANT.Bucket,
  })
}

type UploadStateInfo = {
  status: IUploadStatus
  message: string
}

export const useOSSPut = () => {
  const [uploadProgress, setUploadProgress] = useState(0)
  const [uploadStatus, setUploadStatus] = useState<UploadStateInfo>({
    status: 'init',
    message: 'Init',
  })
  const ossPut = useCallback(async (fileName: string, file: File | Buffer) => {
    setUploadStatus({ status: 'uploading', message: 'Uploading...' })
    setUploadProgress(0)
    const OSSClient = await setupOssClient()
    const result = await OSSClient.multipartUpload(fileName, file, {
      partSize: 200 * 1024,
      progress: (p, checkpoint) => {
        setUploadProgress(p * 100)
      },
    })

    if (result && result.res && result.res.status === 200) {
      setUploadStatus({
        status: 'completed',
        message: 'Upload Completed',
      })
    } else {
      setUploadStatus({
        status: 'error',
        message: 'Upload Failed, Please try again',
      })
    }

    return result
  }, [])

  const reupload = useCallback(() => {
    setUploadStatus({
      status: 'init',
      message: 'Init',
    })
    setUploadProgress(0)
  }, [])

  const handleUploadKr = useCallback(async (file, fileName: string) => {
    setUploadStatus({ status: 'uploading', message: 'Uploading...' })
    setUploadProgress(0)
    try {
      const { data } = await getHomeworkAwsUploadUrl(fileName)
      if (data && data.data) {
        await multipartUpload(data.data, file, {
          onProgress: p => {
            setUploadProgress(p * 100)
          },
        })
        setUploadProgress(100)
        setUploadStatus({
          status: 'completed',
          message: 'Upload Completed',
        })

        return data.data.split('?')[0]
      }
    } catch (e) {
      setUploadStatus({
        status: 'error',
        message: 'Upload Failed, Please try again',
      })
    }
  }, [])

  const handleUploadCn = useCallback(
    async (file, fileName: string) => {
      const result = await ossPut(`courseware/${fileName}`, file)
      if (result.res && result.res.status === 200) {
        return OSS_CONSTANT.DownloadRootUrl + result.name
      }
    },
    [ossPut]
  )

  return {
    ossPut,
    uploadStatus,
    uploadProgress,
    reupload,
    handleUploadCn,
    handleUploadKr,
  }
}
