import { useRequest } from 'ahooks'
import { fetchCouponApi } from '@/services/payment'

export const useCouponCode = () => {
  const {
    data,
    loading: fetchCouponLoading,
    run: fetchCoupon,
  } = useRequest(productId => fetchCouponApi(productId), {
    manual: true,
  })

  const rawCoupons = data?.data?.data

  return { fetchCoupon, fetchCouponLoading, rawCoupons }
}
