export const isBrowser = (function () {
  return typeof window !== 'undefined'
})()

export const replacePhoneNumberByPhoneCode = (phoneCode, phoneNumber) => {
  //https://en.wikipedia.org/wiki/National_conventions_for_writing_telephone_numbers

  if (parseInt(phoneCode, 10) === 82) {
    return phoneNumber.replace(/^0?(.*)$/g, '$1')
  }

  return phoneNumber
}

export const capFirstLetter = (str?: string): string => {
  if (!str || !str.length) {
    return ''
  }
  return str.charAt(0).toUpperCase() + str.slice(1)
}

export const formatCurrency = (
  number: number | undefined,
  locale?: string
): string => {
  if (!number) {
    return Number(0).toLocaleString(locale)
  }
  return number.toLocaleString(locale)
}
