import { useCallback, useMemo, useRef, useState } from 'react'
import { useRouter } from 'next/router'
import { get } from 'lodash'
import { NSNotification } from 'gga-types'
import { request } from '@/utils/request'
import { IResData, IResDataPaged } from '@/@types/global'
import usePagination from '@/hooks/usePagination'
import { getNotificationStatus } from '@/services/notification'

export type INotificationState = ReturnType<typeof useNotificationState>

export default function useNotificationState() {
  const QUERY_KEY = '_noti'
  const { query, replace, pathname } = useRouter()
  const [urlNotification, setUrlNotification] = useState<NSNotification.Item>()
  const [initialOpen, setInitialOpen] = useState<boolean>()

  const action = useCallback(async (p, ps) => {
    return request<IResDataPaged<NSNotification.Item>>({
      url: `/v2/notification/index`,
      method: 'get',
      data: {
        page: p,
        pageSize: ps,
      },
    })
  }, [])

  const { total, page, pageSize, setPage, data, fetcher, clearList } =
    usePagination<NSNotification.Item>(action, {
      initialPageSize: 15,
    })

  const [hasNew, setHasNew] = useState(false)
  const fetchNotificationState = useCallback(async () => {
    const res = await getNotificationStatus()
    if (res && res.data && res.data.data) {
      setHasNew(res.data.data.has_new)
    }
  }, [])

  const readNotification = useCallback(
    (id: number) => {
      return request({
        url: `/v2/notification/read/${id}`,
        method: 'post',
      }).then(() => {
        return fetchNotificationState()
      })
    },
    [fetchNotificationState]
  )

  const fetchNotificationById = useCallback((id: number) => {
    return request<IResData<NSNotification.Item>>({
      url: `/v2/notification/${id}`,
      method: 'get',
    })
  }, [])

  const notiId = useMemo(() => {
    return Number(get(query, QUERY_KEY, NaN))
  }, [query])

  const consumeUrlQuery = useCallback(() => {
    const newQuery = {
      ...query,
    }
    delete newQuery[QUERY_KEY]
    return replace({ pathname, query: newQuery }, undefined, {
      shallow: true,
    })
  }, [pathname, query, replace])

  const clearUrlNotification = useCallback(() => {
    setUrlNotification(undefined)
  }, [])

  const checkShouldShow = useCallback(async () => {
    if (!isNaN(notiId)) {
      await consumeUrlQuery()
      try {
        const notificationItem = await fetchNotificationById(notiId)
        if (notificationItem.data && notificationItem.data.data) {
          setUrlNotification(notificationItem.data.data)
          return true
        }
      } catch (e) {
        console.error(e)
        return false
      }
    }
    return false
  }, [consumeUrlQuery, fetchNotificationById, notiId])

  const pullNotification = useCallback(async () => {
    checkShouldShow().then(res => {
      setInitialOpen(res)
    })
    await fetchNotificationState()
  }, [checkShouldShow, fetchNotificationState])

  const pollRef = useRef<ReturnType<typeof setTimeout>>()

  const startPolling = useCallback(
    (timeout: number) => {
      const fn = async () => {
        await pullNotification()
        pollRef.current = setTimeout(fn, timeout)
      }
      fn()
      return () => {
        if (pollRef.current) {
          clearTimeout(pollRef.current)
        }
      }
    },
    [pullNotification]
  )

  return {
    hasNew,
    fetchNotificationState,
    fetcher,
    total,
    page,
    setPage,
    pageSize,
    notification: data,
    readNotification,
    resetList: clearList,
    fetchNotificationById,
    urlNotification,
    initialOpen,
    clearUrlNotification,
    startPolling,
  }
}
