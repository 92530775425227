import { Button } from '@mantine/core'
import Link from 'next/link'
import React from 'react'
import { isMobile } from 'react-device-detect'
import ImageComponent from '@/components/ImageComponent'

export const TokenProductNotice = () => {
  return (
    <div className="space-y-8">
      <div className="rounded-lg bg-gradient-to-r from-[#F6F6F6]/100 to-[#F6F6F6]/0 p-3 flex justify-between items-center space-x-3">
        <div>
          <p className="font-semibold">注意事项:</p>
          <ol className="list-decimal list-inside mt-2.5 space-y-2.5">
            <li>可前往专项提升使用点数余额购买课程；</li>
            <li className="lg:hidden">
              您也可以
              <span className="font-semibold">点击下方按钮</span>
              进入GGA小程序购买课程
            </li>
            <li className="hidden lg:list-item">
              <span className="font-semibold">微信扫码</span>
              也可进入GGA小程序购买课程；
            </li>
          </ol>
        </div>
        {!isMobile && (
          <div className="flex-none">
            <ImageComponent
              src="https://res.gengesports.cn/user-web/images/mini-app-code%403x.png"
              className="w-[100px] h-[100px]"
            />
          </div>
        )}
      </div>

      <div className="flex flex-col items-center space-y-4">
        <Link href="/coach-platform">
          <Button className="!w-[288px]">专项提升</Button>
        </Link>

        {isMobile && (
          <Link href="https://www.gengacademy.com/webview/_miniapp/wechat/miniapp-proxy?_scheme_uid=38d7dec91b46466f98e08942a799a086">
            <Button variant="outline" className="!w-[288px]">
              前往GGA小程序
            </Button>
          </Link>
        )}
      </div>
    </div>
  )
}
