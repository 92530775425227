import classNames from 'classnames'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { toast } from 'react-toastify'
import { useTranslation } from 'next-i18next'
import { v4 as uuidv4 } from 'uuid'
import get from 'lodash/get'
import ReactDOM from 'react-dom'
import { isMobile } from 'react-device-detect'
import { NSComment, NSClass } from 'gga-types'
import { OSS_CONSTANT, AwsS3Url } from '@/config/config'
import { IGreatVideoPlayerHandle } from '@/UI-React/src/GreatVideoPlayer'
import { useComments } from '@/hooks/myPages/useComments'
import { NodeType } from '@/constants/define'
import { setupOssClient, useOSSPut } from '@/hooks/useOSSUploader'
import { ActionsInterval } from '@/UI-React/src/recordableDrawing/model/actionsInterval'
import { IRecorderState } from '@/UI-React/src/hooks/useRecorderOverlay'
import { useStore } from '@/context/store'
import { readComment } from '@/services/comment'
import { VideoSubmission } from '@/components/Submission/VideoSubmission'
import WaitForCoachResponse from '@/components/CoursewareModal/SoloAndTeamTaskReview/WaitForCoachResponse'
import { IUseVideoRefState } from '@/UI-React/src/hooks/useVideoRef'
import GreatPdfPlayer from '@/UI-React/src/GreatPdfPlayer'
import {
  ICoursewareModalContexts,
  useCoursewareModal,
} from '@/hooks/myPages/CoursewareModalProvider'

import { OssResourceUtils } from '@/utils/oss-resource.utils'
import { ProgressTip } from './components/scatters'
import HomeworkComments from './components/HomeworkComments'

function getUploadUrl(
  trial: NSClass.TrialType | NSClass.ClassContentType,
  filePath: string,
  isSeminar?: boolean
) {
  let hostname = OSS_CONSTANT.DownloadRootUrl
  if (
    trial.file_upload_platform === 'aws' &&
    (trial.submit_type === 'video' ||
      trial.submit_type === 'replay_code' ||
      trial.submit_type === 'replay_file' ||
      trial.submit_type === 'pdf' ||
      isSeminar)
  ) {
    hostname = AwsS3Url
  }
  return hostname + filePath
}

interface TrialProps {
  courseware: NSClass.CoursewareType
  homework: NSClass.TrialType
  syllabus: NSClass.SyllabusItemStruct
  ctx?: ICoursewareModalContexts
}

export const Trial = (props: TrialProps) => {
  const {
    homework,
    courseware,
    syllabus: info,
    ctx = 'CLASS_MODAL_CONTEXT',
  } = props
  const { isSolo, isTeam } = useCoursewareModal(ctx)
  const playerRef = useRef<IGreatVideoPlayerHandle>()
  const [videoLoaded, setVideoLoaded] = useState(false)
  const {
    soloLessonState: { refreshSoloCourse },
    teamEvents: { refreshTeamEvents },
  } = useStore()
  const [recordingData, setRecordingData] = useState({
    audioData: null as Blob | null,
    drawingData: null as ActionsInterval[] | null,
  })
  const [videoState, setVideoState] = useState<IUseVideoRefState>(
    {} as IUseVideoRefState
  )
  const [pdfPage, setPdfPage] = useState(1)
  const [commentItem, setCommentItem] = useState<NSComment.CommentItem>()

  const classUUID: string = useMemo(() => {
    return courseware.class.identity_id
  }, [courseware.class.identity_id])

  const { t } = useTranslation()

  const { ossPut } = useOSSPut()

  const { commentsList, newComment, refreshCommentList } = useComments(
    classUUID,
    info,
    homework.id
  )

  useEffect(() => {
    if (info.template !== 'seminar') {
      refreshCommentList()
    }
  }, [info.template, refreshCommentList])

  const baseRecord = useMemo(() => {
    if (info.template === NodeType.TeamScrim || info.template === 'seminar') {
      //team scrim video is unique per class
      return courseware.class_content
    } else {
      return homework
    }
  }, [courseware.class_content, homework, info.template])

  const filePath = useMemo(() => {
    return baseRecord.file_path
  }, [baseRecord.file_path])

  const fullFilePath = useMemo(() => {
    return new OssResourceUtils(
      getUploadUrl(baseRecord, filePath, info.template === 'seminar')
    ).getNormalizedUrl()
  }, [baseRecord, filePath, info.template])

  const [playerStatus, setPlayerStatus] = useState<IRecorderState['status']>()
  const isRecording = useMemo(() => playerStatus === 'rec', [playerStatus])
  const [currentPlayingCommentId, setCurrentPlayingCommentId] =
    useState<number>()

  useEffect(() => {
    // 打开之后就算评论已读
    if (homework && homework.read === false) {
      readComment(homework.id)
    }
  }, [homework])

  const uploadAudio = useCallback(
    async (data: Blob) => {
      try {
        const arrayBuffer = await data.arrayBuffer()
        const buffer = new Buffer(arrayBuffer)
        const guid = uuidv4()
        const fileName = guid + '.ogg'
        const result = await ossPut(fileName, buffer)
        return result.url
      } catch (e) {
        console.log(e)
      }
    },
    [ossPut]
  )

  const handleCommentsUpdate = useCallback(() => {
    if (isSolo) {
      refreshSoloCourse()
    } else if (isTeam) {
      refreshTeamEvents()
    }
    refreshCommentList()
  }, [isSolo, isTeam, refreshCommentList, refreshSoloCourse, refreshTeamEvents])

  const handleCommentSubmit = useCallback(
    async (data, srcType: 'pdf' | 'video') => {
      let audioURL
      let drawingData
      let drawing
      const recorderState = playerRef.current?.getRecorderState()
      if (recorderState?.status === 'rec:preview') {
        if (recordingData.audioData) {
          audioURL = await uploadAudio(recordingData.audioData)
        }
        if (recordingData.drawingData) {
          drawingData = recordingData.drawingData
        }
      } else if (recorderState?.status === 'draw') {
        const canvasData = playerRef.current?.getCanvasData() || ''
        // upload canvas data to oss to reduce db size
        const fileName = uuidv4() + '.txt'
        const blob = new Blob([canvasData], { type: 'plain/text' })
        const file = new File([blob], fileName)
        const client = await setupOssClient()
        const result = await client.put('/comment_drawing/' + fileName, file)
        drawing = new URL(result.url).pathname
      }

      const { width, height } = playerRef?.current?.getCanvasDimension() || {
        width: 0,
        height: 0,
      }

      const recPostData: any = {
        version: 1, //in case of future changes
        audioURL,
        drawingData,
        canvas: { width, height },
      }

      const time = srcType === 'pdf' ? pdfPage : videoState.videoTime

      await newComment({
        ...data,
        attributes: {
          time: time || 0,
          recording: recPostData,
          drawing,
        },
      })
      toast.success(t('course_form.comment_success'))
      handleCommentsUpdate()
      playerRef.current?.reset()
    },
    [
      handleCommentsUpdate,
      newComment,
      pdfPage,
      recordingData.audioData,
      recordingData.drawingData,
      t,
      uploadAudio,
      videoState.videoTime,
    ]
  )

  const handleCommentLabelClick = useCallback(
    async (it: NSComment.CommentItem) => {
      // 绘画或录制时不能点击播放。
      if (
        playerStatus === 'draw' ||
        playerStatus === 'rec' ||
        playerStatus === 'rec:preview'
      ) {
        return
      }
      setCommentItem(it)
      setPdfPage(it.attributes && it.attributes.time ? it.attributes.time : 1)
    },
    [playerStatus]
  )

  const reactToLabelClick = useCallback(async () => {
    if (commentItem) {
      const it = commentItem
      if (it.attributes && it.attributes.drawing) {
        let canvasData = ''
        try {
          // 兼容代码，如果是旧的dataUrl数据
          new URL(it.attributes.drawing)
          canvasData = it.attributes.drawing
        } catch (e) {
          // 如果不能处理drawing数据，说明是新的相对oss路径
          if ((e as any).name === 'TypeError') {
            const remoteData =
              OSS_CONSTANT.DownloadRootUrl + it.attributes.drawing
            const resp = await fetch(remoteData)
            const text = await resp.text()
            canvasData = text
          }
        }
        playerRef.current?.startPlayDrawing({
          playbackTime: it.attributes.time,
          canvasDataPlayback: canvasData,
        })
      } else if (
        it.attributes &&
        it.attributes.recording &&
        (it.attributes.recording.audioURL ||
          it.attributes.recording.drawingData)
      ) {
        const { recording } = it.attributes
        await playerRef.current?.startPlayRecording({
          recordingPlaybackWidth: get(it, 'attributes.recording.canvas.width'),
          drawingDataPlayback: recording.drawingData,
          audioPlayback: recording.audioURL,
          playbackTime: it.attributes.time,
        })
      } else {
        playerRef.current?.exitPlayback()
        if (it.attributes) {
          playerRef.current &&
            playerRef.current.seekTo &&
            playerRef.current.seekTo(it.attributes.time)
        }
        playerRef.current &&
          playerRef.current.pauseVideo &&
          playerRef.current.pauseVideo()
      }
    }
    setCommentItem(undefined)
  }, [commentItem])

  useEffect(() => {
    setTimeout(() => {
      reactToLabelClick()
    }, 200)
  }, [reactToLabelClick])

  const handleReplyClick = useCallback(() => {
    playerRef.current &&
      playerRef.current.pauseVideo &&
      playerRef.current.pauseVideo()
  }, [])

  const renderPDFSubmission = useMemo(() => {
    return (
      <>
        <div className={'overflow-auto p-1 flex-1 md:h-full'}>
          {fullFilePath && (
            <GreatPdfPlayer
              className="flex-1"
              init={player => {
                playerRef.current = player
              }}
              onStatusChange={setPlayerStatus}
              config={{
                id: 'pdf-player',
                filePath: fullFilePath,
                currentPage: pdfPage,
                btnProps: {
                  ...[
                    'colorPicker',
                    'strokeWidth',
                    'undo',
                    'redo',
                    'clear',
                    'discard',
                    'startDrawing',
                    'startRecording',
                    'stop',
                    'preview',
                    'exitPlayback',
                  ].reduce((r, i) => {
                    r[i] = {
                      hidden: isMobile || i === 'startRecording',
                      text:
                        i === 'startDrawing'
                          ? t('components.greatvideo.drawing_tool')
                          : '',
                    }
                    return r
                  }, {}),
                },
              }}
              onAudioRecorderSaved={(audio, drawing) => {
                setRecordingData({ audioData: audio, drawingData: drawing })
              }}
              onPageChange={page => {
                setPdfPage(page)
              }}
            />
          )}
        </div>
        <HomeworkComments
          mode={'pdf'}
          info={info}
          canSubmit={!isRecording}
          classUUID={classUUID}
          trialId={homework.id}
          comments={commentsList}
          onSubmit={data => handleCommentSubmit(data, 'pdf')}
          onFocus={handleReplyClick}
          onLabelClick={handleCommentLabelClick}
        />
      </>
    )
  }, [
    classUUID,
    commentsList,
    fullFilePath,
    handleCommentLabelClick,
    handleCommentSubmit,
    handleReplyClick,
    homework.id,
    info,
    isRecording,
    pdfPage,
    t,
  ])

  const onVideoLoaded = useCallback(() => {
    setVideoLoaded(true)
  }, [])

  useEffect(() => {
    if (videoLoaded) {
      commentsList.forEach(item => {
        if (
          item.attributes &&
          Math.floor(item.attributes.time) ===
            Math.floor(videoState.videoTime || 0)
        ) {
          setCurrentPlayingCommentId(item.id)
        }
      })
    }
  }, [commentsList, videoLoaded, videoState.videoTime])

  const renderVideoSubmission = useMemo(() => {
    if (!filePath) {
      return (
        <div className={'h-full flex flex-col items-center justify-center'}>
          <WaitForCoachResponse record={baseRecord} />
        </div>
      )
    }
    return (
      <VideoSubmission
        className="flex-1 min-h-0"
        filePath={fullFilePath}
        controllable={info.template !== 'seminar'}
        playerRef={playerRef}
        onAudioRecorderSaved={setRecordingData}
        onStatusChange={setPlayerStatus}
        onVideoLoaded={onVideoLoaded}
        onVideoState={setVideoState}
      />
    )
  }, [baseRecord, filePath, fullFilePath, info.template, onVideoLoaded])

  const submitType = useMemo(() => {
    return homework.submit_type || 'video'
  }, [homework.submit_type])

  useEffect(() => {
    if (videoLoaded) {
      const commentsMap: { [PropName: number]: number } = {}
      const dots: { time: number; text: HTMLElement | DocumentFragment }[] = []

      commentsList.forEach((comment, index) => {
        const time = (comment.attributes && comment.attributes.time) || 0

        if (commentsMap[time] !== undefined) {
          const tooltip = document.createDocumentFragment()
          const next = document.createElement('div')
          tooltip.appendChild(dots[commentsMap[time]].text)
          tooltip.appendChild(next)
          ReactDOM.render(
            <ProgressTip
              comment={comment}
              onCommentLabelClick={handleCommentLabelClick}
              follow={true}
            />,
            next
          )
          dots[commentsMap[time]].text = tooltip
        } else {
          const tooltip = document.createElement('div')
          ReactDOM.render(
            <ProgressTip
              comment={comment}
              onCommentLabelClick={handleCommentLabelClick}
            />,
            tooltip
          )
          dots.push({
            time: time,
            text: tooltip,
          })
          commentsMap[time] = dots.length - 1
        }
      })

      playerRef.current?.refreshProgressDots(dots)
    }
  }, [commentsList, videoLoaded, handleCommentLabelClick])

  const showComments = useMemo(() => {
    return (
      baseRecord &&
      info.template !== 'seminar' &&
      (submitType === 'video' ||
        submitType === 'replay_code' ||
        submitType === 'replay_file')
    )
  }, [baseRecord, info.template, submitType])

  const videoSubmissions = useMemo(() => {
    const module = (submitType === 'video' ||
      submitType === 'replay_file' ||
      submitType === 'replay_code') && (
      <div className="md:h-full w-full bg-gray-50">{renderVideoSubmission}</div>
    )
    return (
      <>
        {module}
        {showComments && (
          <HomeworkComments
            info={info}
            canSubmit={!isRecording}
            classUUID={classUUID}
            trialId={homework.id}
            comments={commentsList}
            onLabelClick={handleCommentLabelClick}
            onSubmit={data => handleCommentSubmit(data, 'video')}
            onFocus={handleReplyClick}
            currentTime={videoState.videoTime || 0}
            currentPlayingCommentId={currentPlayingCommentId}
          />
        )}
      </>
    )
  }, [
    classUUID,
    commentsList,
    currentPlayingCommentId,
    handleCommentLabelClick,
    handleCommentSubmit,
    handleReplyClick,
    homework.id,
    info,
    isRecording,
    renderVideoSubmission,
    showComments,
    submitType,
    videoState.videoTime,
  ])

  if (submitType === 'pdf') {
    return (
      <div
        className={
          'relative md:h-full w-full md:flex items-start space-y-2 md:space-y-0'
        }
      >
        {renderPDFSubmission}
      </div>
    )
  }

  return (
    <div
      className={classNames(
        'relative min-h-16 h-full w-full md:flex mx-auto justify-around space-y-2 md:space-y-0'
      )}
    >
      {videoSubmissions}
    </div>
  )
}
