import React from 'react'
import { useAuth } from '@/containers/auth'
import { IPageProps, IPagePropsBase } from '@/@types/global'
import Footer from '@/components/zh_CN/footer'
import HeaderBar from '@/components/HeaderBar'
import { FloatingContact } from '@/components/zh_CN/FloatingContact'
import PageMeta from '@/components/page-meta'
import { LogoBg } from '../LogoBg'

const Layout = (props: IPagePropsBase) => {
  const userBeau = useAuth()
  const pageProps: IPageProps = { ...props, ...userBeau }
  const childrenWithProps = React.Children.map(props.children, child => {
    return React.cloneElement(child, {
      ...userBeau,
    })
  })
  const { footerLink } = pageProps

  return (
    <>
      <PageMeta {...pageProps} />
      <div>
        <FloatingContact />
        <HeaderBar {...(pageProps as any)} />
        <LogoBg className="flex flex-col">{childrenWithProps}</LogoBg>
      </div>
      <Footer footerLink={footerLink} />
    </>
  )
}

export default Layout
