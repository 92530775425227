import cookie, { CookieAttributes } from 'js-cookie'

/**
 *
 * @param {string} name
 * @returns {string|null} cookieValue
 */
export const getCookieByName = (name: string) => {
  return cookie.get(name)
}

export function setCookie(key: string, value: any, options?: CookieAttributes) {
  cookie.set(key, value, options || {})
}
