import React, { useCallback, useMemo } from 'react'
import { Button, Field, Input } from 'stripe-ui'
import { useForm } from 'react-hook-form'
import { Trans, useTranslation } from 'next-i18next'
import classNames from 'classnames'
import { NSClass } from 'gga-types'

type IReplayCodeUploadFormProps = {
  onSubmit: (vals) => void
  className?: string
  onModalClose
  submissionStatus?: NSClass.SubmissionStatus | null
}

export default function ReplayCodeUploadForm({
  onSubmit,
  className,
  onModalClose,
  submissionStatus,
}: IReplayCodeUploadFormProps) {
  const {
    register,
    formState: { isSubmitting, isValid },
    handleSubmit,
    watch,
  } = useForm({
    mode: 'all',
    defaultValues: {
      replay_code: '',
      player_identity: '',
    },
  })
  const { t } = useTranslation(['common'])
  const canSubmit = useMemo(() => {
    return submissionStatus == null || submissionStatus.can_submit
  }, [submissionStatus])

  const watchCode = watch('replay_code')

  const submitForm = useCallback(
    data => {
      if (isSubmitting) {
        return
      } else {
        onSubmit(data)
        onModalClose && onModalClose(undefined)
      }
    },
    [isSubmitting, onModalClose, onSubmit]
  )
  return (
    <form className={classNames(className)} onSubmit={handleSubmit(submitForm)}>
      <div className="text-center mx-auto font-bold text-xl mb-4">
        {t('course_form.replay_code')}
      </div>
      <div className="w-80 mx-auto">
        <Field>
          <Input
            {...register('replay_code')}
            placeholder={t('course_form.replay_code_placeholder')}
          />
        </Field>
      </div>
      <div className="w-80 mx-auto">
        <Field>
          <Input
            {...register('player_identity', {
              required: true,
            })}
            placeholder={t(
              'components.courseware_modal.player_identity',
              'Please fill your in-game identity'
            )}
          />
        </Field>
      </div>
      <div className="text-center mb-4">
        <Button
          type="submit"
          variant="brand"
          loading={isSubmitting}
          disabled={!canSubmit || isSubmitting || !watchCode || !isValid}
        >
          <Trans t={t} i18nKey="course_form.submit" />
        </Button>
      </div>
      {submissionStatus && submissionStatus.submission_limit ? (
        <div className={classNames('text-center font-bold mb-3 text-black')}>
          <Trans
            t={t}
            i18nKey="course_form.submit_limit"
            values={{
              count: submissionStatus.remain_count,
            }}
          />
        </div>
      ) : null}
    </form>
  )
}
