import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Tabs, TabsPanel } from 'stripe-ui'
import classNames from 'classnames'
import { Trans } from 'next-i18next'
import get from 'lodash/get'
import { NSClass } from 'gga-types'
import {
  ICoursewareModalContexts,
  useCoursewareModal,
} from '@/hooks/myPages/CoursewareModalProvider'
import {
  finishUserRecordApi,
  getRecordByClassIdAndSyllabusId,
  submitWorkApiV2,
} from '@/services/class'
import TrialFeedback from '@/components/CoursewareModal/TrialFeedback'
import useTrialFeedback from '@/hooks/useTrialFeedback'
import usePlainModal, { IPlainModalControls } from '@/hooks/usePlainModal'
import { Trial } from './Trial'
import FeedbackBar from './FeedbackBar'

type ISoloAndTeamTaskReviewProps = {
  ctx?: ICoursewareModalContexts
}

export const SoloAndTeamTaskReview = ({
  ctx = 'CLASS_MODAL_CONTEXT',
}: ISoloAndTeamTaskReviewProps) => {
  const {
    info,
    courseware,
    setViewType,
    // refreshModalData,
    isTeam,
    classId,
    ggaProRecord,
    isTrial,
    setBeforeClose,
    isGGAPro,
  } = useCoursewareModal(ctx)

  const { trialFeedbackData, fetchHasTrialFeedback } = useTrialFeedback()
  const [record, setRecord] = useState<NSClass.RecordType>()
  const [currentIndex, setCurrentIndex] = useState(0)

  const fetchReocrdAndTrials = useCallback(async () => {
    const res = await getRecordByClassIdAndSyllabusId(
      courseware.class.id,
      info.id
    )
    if (res && res.data && res.data.data) {
      setRecord(res.data.data)
    }
  }, [courseware.class.id, info.id])

  const handleFinishRecordWhenItIsTeamScrim = useCallback(async () => {
    if (info && courseware) {
      const coursewareRecordTrials: NSClass.TrialType[] = get(
        courseware,
        'record.trials',
        []
      )
      if (info.template === 'seminar') {
        if (
          courseware.class_content &&
          coursewareRecordTrials.every(t => t.status !== 'finished')
        ) {
          await finishUserRecordApi(classId, info.id)
        }
      }
      if (info.template === 'team_scrim') {
        if (courseware.class_content) {
          if (
            courseware.class_content.status === 'finished' &&
            coursewareRecordTrials.every(t => t.status !== 'finished')
          ) {
            await finishUserRecordApi(classId, info.id)
          } else if (courseware.class_content.status === 'submitted') {
            await submitWorkApiV2(classId, info.id)
          }
        }
      }
    }
  }, [classId, courseware, info])

  useEffect(() => {
    handleFinishRecordWhenItIsTeamScrim().then(() => {
      fetchReocrdAndTrials()
    })
  }, [fetchReocrdAndTrials, handleFinishRecordWhenItIsTeamScrim])

  const trials = useMemo(() => {
    if (isGGAPro) {
      return [ggaProRecord]
    } else {
      if (record) {
        if (info.template === 'solo_task') {
          return [...record.trials].reverse()
        } else {
          return [[...record.trials].reverse()[0]]
        }
      }
      return []
    }
  }, [ggaProRecord, info.template, isGGAPro, record])

  const currentTrial = useMemo(
    () => trials[currentIndex],
    [currentIndex, trials]
  )

  const getTabLabel = useCallback((trial: NSClass.TrialType, index: number) => {
    const read = trial.read
    const status = trial.status
    return (
      <div className="relative">
        <span className="flex items-stretch">
          <span
            className={classNames('inline-block w-1.5 rounded-full mr-2', {
              'bg-red-500': status === 'failed',
              'bg-green-500': status === 'finished',
              hidden: ['failed', 'finished'].indexOf(status) < 0,
            })}
          />
          {index ? (
            <Trans
              i18nKey="course_form.trial_count"
              values={{ count: trial.trial_sequence }}
            />
          ) : (
            <Trans i18nKey="course_form.current_trial" />
          )}
        </span>
        {read === false && (
          <span className="absolute w-2 h-2 rounded-full bg-red-500 -top-1 -right-2" />
        )}
      </div>
    )
  }, [])

  const controls = useRef<IPlainModalControls>(null)
  const { create: createFeedbackModal } = usePlainModal({
    controlsRef: controls,
    defaultOpen: false,
    children: (
      <>
        {currentTrial && (
          <TrialFeedback
            isTrial={isTrial}
            trialId={currentTrial.id}
            onSuccess={() => controls.current!.toggle(false)}
          />
        )}
      </>
    ),
    onClose: () => {
      currentTrial && fetchHasTrialFeedback(currentTrial.id)
    },
  })

  const openFeedbackModal = useCallback(() => {
    if (currentTrial) {
      controls.current!.toggle(true)
    }
  }, [currentTrial])
  // 获取feedback状态
  useEffect(() => {
    if (
      currentTrial &&
      currentTrial.status === 'finished' &&
      info.template === 'solo_task'
    ) {
      fetchHasTrialFeedback(currentTrial.id)
    }
  }, [currentTrial, fetchHasTrialFeedback, info.template])

  useEffect(() => {
    if (
      trialFeedbackData &&
      !trialFeedbackData.has_feedback &&
      (isGGAPro || isTrial)
    ) {
      setBeforeClose(() => {
        return async () => {
          openFeedbackModal()
          return true
        }
      })
    } else {
      setBeforeClose(undefined)
    }
  }, [isGGAPro, isTrial, openFeedbackModal, setBeforeClose, trialFeedbackData])

  if (!currentTrial) {
    return <div />
  }

  return (
    <>
      {createFeedbackModal()}
      {(info.template === 'solo_task' || info.template === 'team_scrim') && (
        <Tabs
          onSelect={idx => {
            setCurrentIndex(idx)
          }}
        >
          {trials.map((r, i) => {
            return <TabsPanel key="coursewareType" label={getTabLabel(r!, i)} />
          })}
        </Tabs>
      )}

      <div className={'my-3'}>
        {info.template === 'solo_task' && (
          <FeedbackBar
            hasFeedback={trialFeedbackData?.has_feedback}
            onViewFeedback={openFeedbackModal}
            isTrial={isTrial}
            isGGAPro={isGGAPro}
            canReupload={currentIndex === 0 && !isGGAPro}
            status={currentTrial.status as any}
            onReupload={() => {
              if (info.template === 'solo_task') {
                setViewType('solo_upload')
              } else if (info.template === 'team_scrim') {
                setViewType('team_upload')
              }
            }}
          />
        )}
      </div>

      <div className={'relative h-full min-h-0'}>
        <Trial
          homework={currentTrial}
          courseware={courseware}
          syllabus={info}
        />
      </div>
    </>
  )
}
