import {
  MutableRefObject,
  ReactNode,
  useCallback,
  useImperativeHandle,
  useState,
} from 'react'
import PlainModalWrapper from '@/components/Modals/PlainModalWrapper'

export type IPlainModalControls = {
  toggle: (open: boolean) => void
}

type IUsePlainModalParams = {
  defaultOpen?: boolean
  children: ReactNode
  onClose: () => void
  controlsRef: MutableRefObject<IPlainModalControls | null>
  zIndex?: number
}
export default function usePlainModal({
  defaultOpen,
  children,
  onClose,
  controlsRef,
  zIndex = 999,
}: IUsePlainModalParams) {
  const [isOpen, setIsOpen] = useState(defaultOpen || false)

  const closeModal = useCallback(() => {
    onClose()
    setIsOpen(false)
  }, [onClose])

  useImperativeHandle(
    controlsRef,
    () => {
      return {
        toggle: (open: boolean) => {
          if (!open) {
            onClose()
          }
          setIsOpen(open)
        },
      }
    },
    [onClose]
  )

  const create = useCallback(() => {
    return (
      <PlainModalWrapper isOpen={isOpen} onClose={closeModal}>
        {children}
      </PlainModalWrapper>
    )
  }, [children, closeModal, isOpen])

  return {
    create,
  }
}
