import { useCallback, useMemo, useState } from 'react'
import { useRequest } from 'ahooks'
import { groupBy, sortBy, filter } from 'lodash'
import { useTranslation } from 'next-i18next'
import { NSUserProfile, NSUserSettings } from 'gga-types'
import { request } from '@/utils/request'

import {
  getUserGameInfoOptionConfig,
  getUserGameInfoRawValue,
  getUserSmsPreference,
} from '@/services/user-settings'
import { getUserGuardianInfo } from '@/services/user'

export type IUserSettingsState = ReturnType<typeof useUserSettingsState>

export default function useUserSettingsState() {
  const [userSmsPreference, setUserSmsPreference] = useState({
    type_marketing: true,
    type_notification: true,
    type_guardian: true,
  })

  const [userGuardianInfo, setUserGuardianInfo] =
    useState<NSUserProfile.TGuardianInfo[]>()

  const _fetchUserGuardianInfo = useCallback(async () => {
    const res = await getUserGuardianInfo()
    if (res && res.data && res.data.data) {
      setUserGuardianInfo(res.data.data)
    }
  }, [])

  const fetchUserSmsNotificationSettings = useCallback(async () => {
    const res = await getUserSmsPreference()
    if (res && res.data && res.data.data) {
      setUserSmsPreference(res.data.data)
    }
  }, [])

  const { refreshAsync, loading: loadingSmsPreference } = useRequest(
    fetchUserSmsNotificationSettings,
    {
      manual: true,
    }
  )

  const updateUserSmsNotificationSettings = useCallback(
    (key: keyof NSUserSettings.UserSmsPreference, isOn: boolean) => {
      setUserSmsPreference(prev => {
        return {
          ...prev,
          [key]: isOn,
        }
      })
      return request({
        url: '/v2/usersettings/sms-preference',
        method: 'post',
        data: {
          [key]: isOn,
        },
      })
    },
    []
  )

  const { i18n } = useTranslation()

  const {
    data: userGameInfoRawValue,
    refreshAsync: refreshUserGameInfoRawValue,
  } = useRequest<NSUserSettings.UserGameInfoRawValue[] | null, []>(
    async () => {
      const res = await getUserGameInfoRawValue()
      if (res && res.data) {
        return res.data.data
      }
      return null
    },
    {
      manual: true,
    }
  )

  const {
    data: userGameInfoOptionItem,
    refreshAsync: refreshUserGameInfoOptionItem,
  } = useRequest<NSUserSettings.UserGameInfoItem[] | null, []>(
    async () => {
      const res = await getUserGameInfoOptionConfig()
      if (res && res.data) {
        return res.data.data
      }
      return null
    },
    {
      manual: true,
    }
  )

  const optionGroup = useMemo(() => {
    if (userGameInfoRawValue && userGameInfoOptionItem) {
      userGameInfoRawValue.forEach(value => {
        const obj = userGameInfoOptionItem.find(
          i => i.id === value.game_info_id
        )
        if (obj) {
          if (obj.input_type === 'MultiOption') {
            obj.user_game_info = value.game_info_value.split(',')
          } else {
            obj.user_game_info = value.game_info_value
          }
        }
        value.game_info_id
      })
      return groupBy(
        sortBy(
          filter(
            userGameInfoOptionItem,
            x =>
              x.localization === null || x.localization.code === i18n.language
          ),
          'sequence'
        ),
        'type'
      )
    }
    return {}
  }, [i18n.language, userGameInfoOptionItem, userGameInfoRawValue])

  const getOptionsByType = useCallback(
    (type: NSUserSettings.UserGameInfoItem['type']) => {
      return optionGroup[type]
    },
    [optionGroup]
  )

  const { loading: guardianInfoLoading, runAsync: fetchUserGuardianInfo } =
    useRequest(_fetchUserGuardianInfo, {
      manual: true,
    })

  return {
    fetchUserGuardianInfo,
    userGuardianInfo,
    userSmsPreference,
    loading: loadingSmsPreference || guardianInfoLoading,
    fetchUserSmsNotificationSettings: refreshAsync,
    updateUserSmsNotificationSettings,
    userGameInfo: getOptionsByType('GameInfo'),
    userCrmInfo: getOptionsByType('CrmInfo'),
    userOptionalInfo: getOptionsByType('OptionalInfo'),
    fetchUserGameInfo: useCallback(async () => {
      await refreshUserGameInfoRawValue()
      await refreshUserGameInfoOptionItem()
    }, [refreshUserGameInfoOptionItem, refreshUserGameInfoRawValue]),
  }
}
