import { Drawer, createStyles } from '@mantine/core'
import { useDisclosure } from '@mantine/hooks'
import classNames from 'classnames'
import { IUserProfile } from 'gga-types'
import React, { useMemo } from 'react'
import { format, isBefore, startOfDay, subDays } from 'date-fns'
import { PowerIcon } from '@heroicons/react/24/outline'
import { ChevronDownIcon } from '@heroicons/react/20/solid'
import { DefaultAvatar, APP_LOCALE } from '@/constants/define'
import { useModalStore } from '@/store/use-modal-store'
import ImageComponent from '../ImageComponent'
import IconPark from '../IconPark'

interface MenuProps {
  label: React.ReactNode
  onClick: () => Promise<boolean>
  icon: React.ReactNode
}

interface ProfileDrawerProps {
  user?: IUserProfile | undefined
  hasUncommented?: boolean
  menus: MenuProps[]
  onLogOut: () => void
}

const useStyles = createStyles(theme => ({
  drawer: {
    backgroundColor: '#121212',
    color: 'white',
  },
  header: {
    display: 'flex',
    justifyContent: 'start',
    columnGap: '0.5rem',
  },
  title: { order: 2 },
  closeButton: {
    order: 1,
    ':hover': {
      backgroundColor: 'initial',
    },
  },
}))

const Block = ({
  children,
  className,
  onClick,
}: {
  className?: string
  children: React.ReactNode
  onClick?: () => void
}) => {
  return (
    <div
      onClick={onClick}
      className={classNames(
        'p-5 rounded bg-[#1B1B20] shadow-[0px_1px_0px_0px_#313136_inset]',
        className
      )}
    >
      {children}
    </div>
  )
}

export const ProfileDrawer = ({
  user,
  hasUncommented,
  menus,
  onLogOut,
}: ProfileDrawerProps) => {
  const [opened, { open, close }] = useDisclosure(false)
  const { classes } = useStyles()

  const openModal = useModalStore(state => state.openModal)

  const isBalanceActive = useMemo(() => {
    return user?.token_expire_date
      ? isBefore(
          startOfDay(new Date()),
          startOfDay(new Date(user?.token_expire_date))
        )
      : false
  }, [user?.token_expire_date])

  return (
    <>
      <Drawer
        opened={opened}
        onClose={close}
        title={
          <span className="text-[#FAFAFA] text-xl">
            {APP_LOCALE === 'zh_CN' ? '个人中心' : 'Personal center'}
          </span>
        }
        lockScroll
        position="right"
        classNames={{ ...classes }}
        size={560}
        padding="lg"
      >
        <div className="border-t border-[#313136] py-6">
          <div className="space-y-4">
            <Block className="flex gap-x-3">
              <div className="shrink-0">
                <ImageComponent
                  src={
                    user!.avatar_url === '' ? DefaultAvatar : user!.avatar_url
                  }
                  className="object-contain w-12 h-12 bg-white rounded-full md:w-14 md:h-14"
                />
              </div>
              <div className="flex-col justify-around flex-1 truncate ">
                <p className="text-xl font-bold truncate lg:text-2xl">
                  {user?.name}
                </p>
                {APP_LOCALE === 'zh_CN' && (
                  <p className="truncate text-white/70">
                    Wechat ID: {user?.wechat_id || '-'}
                  </p>
                )}
              </div>
              <div className="flex items-center justify-end shrink-0">
                <a
                  role="button"
                  className="no-underline flex space-x-1.5 items-center"
                  onClick={onLogOut}
                >
                  <PowerIcon className={'h-5'} />
                  <span>{APP_LOCALE === 'zh_CN' ? '退出' : 'Log out'}</span>
                </a>
              </div>
            </Block>

            {APP_LOCALE === 'zh_CN' && (
              <Block className="flex flex-col gap-y-6">
                <div className="flex flex-col gap-y-2">
                  <div className="flex items-center justify-between">
                    <span>点数余额:</span>

                    {isBalanceActive ? (
                      <span className="text-white/70">
                        (有效期至:{' '}
                        {user?.token_expire_date
                          ? format(
                              subDays(new Date(user.token_expire_date), 1),
                              'yyyy-MM-dd'
                            )
                          : '--'}
                        )
                      </span>
                    ) : (
                      <span className="text-[#F7C222]">
                        已过期 (再次充值可激活)
                      </span>
                    )}
                  </div>
                  <div className="flex items-center justify-start space-x-1">
                    {APP_LOCALE !== 'zh_CN' && (
                      <IconPark
                        name="coin"
                        className="w-5 h-5"
                        style={{ fontSize: '20px' }}
                      />
                    )}
                    <span className="text-xl">{user?.token_balance}</span>
                  </div>
                </div>

                <div>
                  <a
                    role="button"
                    className="flex items-center justify-center w-full h-8 text-center no-underline rounded bg-white/10 hover:bg-white/20"
                    onClick={() => {
                      openModal('AddCreditModal', undefined)
                      close()
                    }}
                  >
                    充值
                  </a>
                </div>
              </Block>
            )}

            <div className="grid grid-cols-2 gap-4">
              {menus.map((m, index) => (
                <Block
                  key={index}
                  className="flex items-center justify-start space-x-3 cursor-pointer"
                  onClick={() => {
                    m.onClick()
                    close()
                  }}
                >
                  <div>{m.icon}</div>
                  <div>{m.label}</div>
                </Block>
              ))}
            </div>
          </div>
        </div>
      </Drawer>

      <div
        className={classNames('flex items-center space-x-2 cursor-pointer')}
        role="button"
        onClick={open}
      >
        <div className="relative">
          <ImageComponent
            src={user!.avatar_url === '' ? DefaultAvatar : user!.avatar_url}
            className="object-contain w-8 h-8 bg-white rounded-full md:w-10 md:h-10"
          />
          {hasUncommented && (
            <div className="absolute bg-[#FF3B5C] rounded-full py-[1px] px-1.5 text-white text-[10px] top-0 right-0 translate-x-1/2 -translate-y-1/2">
              NEW
            </div>
          )}
        </div>

        <div className="hidden text-white md:block">
          {user!.name || user!.phone_number}
        </div>
        <div>
          <ChevronDownIcon className="w-5 h-5 text-white" />
        </div>
      </div>
    </>
  )
}
