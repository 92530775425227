import { useRequest } from 'ahooks'
import { get } from 'lodash'
import { useCallback, useEffect, useState } from 'react'
import { NSProduct } from 'gga-types'
import { getAllProductsByLocale } from '@/services/product'
import { request } from '@/utils/request'

export type IProductState = ReturnType<typeof useProductState>
export type IProductStateProps = {
  products?: NSProduct.Product[]
}

export default function useProductState({ products }: IProductStateProps = {}) {
  const [productsRet, setProductsRet] = useState(products || undefined)
  const {
    data,
    loading,
    runAsync: refresh,
  } = useRequest(getAllProductsByLocale(), {
    manual: true,
  })

  const getProductById = useCallback(id => {
    return request({ url: `/v2/product/${id}` })
  }, [])

  const getProductByIds = useCallback(ids => {
    return request({
      url: `/v2/product/by_ids`,
      method: 'post',
      data: {
        ids,
      },
    })
  }, [])

  useEffect(() => {
    if (productsRet === undefined) {
      refresh().then(() => {})
    }
  }, [productsRet, refresh])

  useEffect(() => {
    if (data) {
      setProductsRet(get(data, 'data.data', []) as NSProduct.Product[])
    }
  }, [data])

  return {
    products: productsRet,
    loading,
    refresh,
    getProductById,
    getProductByIds,
  }
}
