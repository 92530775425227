import {
  Modal as MantineModal,
  ModalProps as MantineModalProps,
} from '@mantine/core'
import { createStyles } from '@mantine/styles'
import React from 'react'

interface StyleProps {
  hideCloseButton?: boolean
  hideTitle?: boolean
}

const useStyles = createStyles(
  (theme, { hideCloseButton, hideTitle }: StyleProps) => {
    const initialProps = {
      title: {
        width: '100%',
        textAlign: 'center',
        fontWeight: 500,
        fontSize: '16px',
        margin: 0,
      },
    } as any

    if (hideCloseButton) {
      initialProps.close = { display: 'none' }
    }

    if (hideTitle) {
      initialProps.title.display = 'none'
    }

    return initialProps
  }
)

const sizeMapping = {
  xs: 300,
  sm: 480,
  md: 768,
  lg: 1140,
} as const

type Size = keyof typeof sizeMapping

export type ModalProps = Omit<MantineModalProps, 'classnames' | 'size'> &
  StyleProps & {
    size?: Size
  }

export const Modal = (props: ModalProps) => {
  const {
    children,
    size = 'md',
    hideCloseButton,
    hideTitle,
    ...restProps
  } = props
  const { classes } = useStyles({ hideCloseButton, hideTitle })

  return (
    <MantineModal
      {...restProps}
      size={sizeMapping[size]}
      classNames={{
        ...classes,
      }}
    >
      {children}
    </MantineModal>
  )
}
