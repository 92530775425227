import React, { useCallback } from 'react'
import { AudioFilled, FundFilled, PlaySquareFilled } from '@ant-design/icons'
import classNames from 'classnames'
import { NSComment } from 'gga-types'

interface CommentActionLabelsProps {
  comment: NSComment.CommentItem
  className?: string | undefined
}

const CommentActionLabels = (props: CommentActionLabelsProps) => {
  const { comment, className } = props
  const attr = comment.attributes ?? ({} as NSComment.CommentAttr)

  const recordingIcons = (r: NSComment.CommentAttr['recording']) => {
    if (r.audioURL) {
      return (
        <>
          <PlaySquareFilled className="items-center flex" />
          <AudioFilled className="items-center flex" />
        </>
      )
    }
    return <PlaySquareFilled className="items-center flex" />
  }

  return (
    <span className={classNames(className)}>
      {attr.recording &&
      (attr.recording.audioURL || attr.recording.drawingData) ? (
        recordingIcons(attr.recording)
      ) : attr.drawing ? (
        <FundFilled className="items-center flex" />
      ) : null}
    </span>
  )
}

export const ProgressTip = (props: {
  comment: NSComment.CommentItem
  onCommentLabelClick: (comment: NSComment.CommentItem) => void
  follow?: boolean
}) => {
  const { comment, follow, onCommentLabelClick } = props
  const splitter = follow
    ? {
        marginTop: '4px',
        paddingTop: '4px',
        borderTop: '1px solid #6B7280',
      }
    : {}

  const onClickPanel = useCallback(() => {
    onCommentLabelClick(comment)
  }, [comment, onCommentLabelClick])

  return (
    <div style={{ ...splitter }} className="text-gray-100">
      <div
        className="hover:bg-gray-900 cursor-pointer"
        style={{
          padding: '0.25rem',
        }}
        onMouseDown={() => {
          onClickPanel()
        }}
      >
        <div className="flex flex-row justify-between">
          <span
            style={{
              fontWeight: 'bold',
            }}
          >
            {comment.creator.name}
          </span>
          <span>
            <CommentActionLabels
              comment={comment}
              className="flex flex-row space-x-0.5 text-white"
            />
          </span>
        </div>
        <div
          style={{
            marginTop: '2px',
            display: '-webkit-box',
            maxWidth: '100%',
            WebkitLineClamp: 2,
            WebkitBoxOrient: 'vertical',
            overflow: 'hidden',
          }}
        >
          {comment.content}
        </div>
      </div>
    </div>
  )
}
