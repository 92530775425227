import { MobileOutlined, WechatOutlined } from '@ant-design/icons'
import classNames from 'classnames'
import React, { useCallback, useMemo, useState } from 'react'
import { ForgetPasswordForm } from '@/components/Form/ForgetPassword'
import { LoginFormOld } from '@/components/Form/Login'
import LoginByPhoneSms from '@/components/Form/Login/LoginByPhoneSms'
import { RegisterFormKR } from '@/components/Form/Register/components/RegisterForm_kr'
import WechatLogin from '@/components/wechat-login'
import { APP_LOCALE } from '@/constants/define'
import { LoginForm, RegisterForm, ResetForm, SmsLoginForm } from '.'

type IStage = 'register' | 'login' | 'smsLogin' | 'reset'

export type ILoginMethod = 'phone' | 'wechat'

export interface AuthFormProps {
  onLogged: () => void
  initialStage?: IStage
  onLogin?: () => void
  onSmsLogin?: () => void
  onRegister?: () => void
  onReset?: () => void
  initialLoginMethod?: ILoginMethod
}

export const AuthForm: React.FC<AuthFormProps> = ({
  onLogged,
  initialStage,
  onLogin,
  onReset,
  onSmsLogin,
  onRegister,
  initialLoginMethod,
}) => {
  const [stage, setStage] = useState<IStage>(initialStage || 'register')
  const handleOnLogin = useCallback(async () => {
    onLogin && (await onLogin())
    setStage('login')
  }, [onLogin])
  const [loginMethod, setLoginMethod] = useState<'phone' | 'wechat'>(
    initialLoginMethod || 'phone'
  )

  const handleOnSms = useCallback(async () => {
    onSmsLogin && (await onSmsLogin())
    setStage('smsLogin')
  }, [onSmsLogin])

  const handleOnRegister = useCallback(async () => {
    onRegister && (await onRegister())
    setStage('register')
  }, [onRegister])

  const handleOnReset = useCallback(async () => {
    onReset && (await onReset())
    setStage('reset')
  }, [onReset])

  const form = useMemo(() => {
    switch (stage) {
      case 'register':
        return APP_LOCALE !== 'zh_CN' ? (
          <RegisterFormKR onLogin={handleOnLogin} onSuccess={onLogged} />
        ) : (
          <RegisterForm onLogged={onLogged} onLogin={handleOnLogin} />
        )
      case 'login':
        return APP_LOCALE !== 'zh_CN' ? (
          <LoginFormOld
            {...{
              onForget: handleOnReset,
              onRegister: handleOnRegister,
              onSuccess: onLogged,
              onLoginBySms: handleOnSms,
            }}
          />
        ) : (
          <div className="flex flex-col space-y-4">
            {loginMethod == 'phone' ? (
              <LoginForm
                onLogged={onLogged}
                onSmsLogin={handleOnSms}
                onRegister={handleOnRegister}
                onReset={handleOnReset}
              />
            ) : (
              <>
                <div className="text-center text-[22px] text-[#FAFAFA] font-bold">
                  Gen.G电竞人才培养平台
                </div>
                <WechatLogin className="h-[280px] overflow-hidden" />
              </>
            )}

            <div className="flex justify-center space-x-2 items-center">
              <div
                role="button"
                onClick={() => setLoginMethod('phone')}
                title="手机登陆"
                className={classNames(
                  'bg-black/0 hover:bg-black/40 transition flex justify-center items-center w-11 h-11 rounded-full',
                  { 'bg-black/60 hover:bg-black/60': loginMethod === 'phone' }
                )}
              >
                <MobileOutlined className="text-2xl text-white -mt-1" />
              </div>
              <div
                role="button"
                onClick={() => setLoginMethod('wechat')}
                title="扫码登陆"
                className={classNames(
                  'bg-black/0 hover:bg-black/40 transition flex justify-center items-center w-11 h-11 rounded-full',
                  { 'bg-black/60 hover:bg-black/60': loginMethod === 'wechat' }
                )}
              >
                <WechatOutlined className="text-2xl text-white -mt-1" />
              </div>
            </div>
          </div>
        )
      case 'smsLogin':
        return APP_LOCALE !== 'zh_CN' ? (
          <LoginByPhoneSms
            {...{ onLogin: handleOnLogin, onSuccess: onLogged }}
          />
        ) : (
          <SmsLoginForm
            onLogged={onLogged}
            onRegister={handleOnRegister}
            onLogin={handleOnLogin}
          />
        )
      case 'reset':
        return APP_LOCALE !== 'zh_CN' ? (
          <ForgetPasswordForm onLogin={handleOnLogin} onSuccess={onLogged} />
        ) : (
          <ResetForm onLogged={onLogged} onLogin={handleOnLogin} />
        )
      default:
        return null
    }
  }, [
    stage,
    handleOnLogin,
    onLogged,
    handleOnReset,
    handleOnRegister,
    handleOnSms,
    loginMethod,
  ])

  return (
    <div className={classNames(APP_LOCALE === 'zh_CN' ? '' : 'bg-white')}>
      {form}
    </div>
  )
}
