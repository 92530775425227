import { ReactElement } from 'react'
import GoogleGtmScript from '@/components/monitor/GoogleGtmScript/index_kr'
import PageMeta from '@/components/page-meta'
import { IMetaTags } from '../type'

export default function MetaTagsKr({ pageProps }): ReactElement<IMetaTags> {
  return (
    <>
      <GoogleGtmScript />
      <PageMeta {...pageProps} />
      <meta property="og:site_name" content="젠지 글로벌 아카데미" />
    </>
  )
}
