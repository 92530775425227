import { useCallback, useState } from 'react'
import { useRequest } from 'ahooks'
import { NSComment } from 'gga-types'
import { NSClass } from 'gga-types'
import {
  getCommentsChild,
  getCommentsTop,
  IPostCommentsV2Data,
  postCommentsV2,
} from '@/services/comment'

export const useComments = (classUUID, info, trialId: number) => {
  const [commentsList, setCommentsList] = useState<NSComment.CommentItem[]>([])

  const getCommentsList = useCallback(() => {
    getCommentsTop(trialId).then(({ data: comments }) => {
      comments.data.sort((a, b) => {
        const ta = a.attributes ? a.attributes.time : 0
        const tb = b.attributes ? b.attributes.time : 0
        return ta - tb
      })

      setCommentsList(comments.data)
    })
  }, [trialId])

  const newComment = useCallback(
    async (postData: IPostCommentsV2Data) => {
      // const id = info.class_type === "team" ? courseId : soloClassId;
      await postCommentsV2(postData, classUUID, info.id, trialId)
      getCommentsList()
    },
    [classUUID, getCommentsList, info.id, trialId]
  )

  const refreshCommentList = useCallback(() => {
    if (classUUID && info) {
      getCommentsList()
    }
  }, [classUUID, info, getCommentsList])

  return { commentsList, newComment, refreshCommentList }
}

export const useCommentsChildList = (rootId: number, trialId: number) => {
  const [commentsChildList, setCommentsChildList] = useState<
    NSComment.CommentItem[]
  >([])

  const { runAsync, loading } = useRequest(getCommentsChild, { manual: true })

  const getCommentsChildList = useCallback(() => {
    runAsync(trialId, rootId).then(({ data: comments }) => {
      // Sort
      const sortComments = comments.data.sort((a, b) => {
        return +new Date(a.created_at) - +new Date(b.created_at)
      })
      setCommentsChildList(sortComments)
    })
  }, [rootId, runAsync, trialId])

  return {
    commentsChildList,
    refreshCommentList: getCommentsChildList,
    loading,
  }
}
