import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { NSClass, NSNotification } from 'gga-types'
import { checkIsGGAPro, getCourseNodeDetailApiV2 } from '@/services/class'
import useModal from '@/components/modal/useModal'
import { useStore } from '@/context/store'

export type ICoursewareViewType =
  | 'quiz'
  | 'team_upload'
  | 'solo_upload'
  | 'seminar_upload'
  | 'page'
  | 'review'

type ICoursewareModalContextType = ReturnType<typeof useProvideCoursewareModal>

const coursewareModalContextFactory = (name: string) => {
  const ctx = createContext<ICoursewareModalContextType | any>({})
  ctx.displayName = name
  return ctx
}

function useProvideCoursewareModal() {
  const { isOpen, toggle } = useModal()
  /**
   * @description Course Node InformationPage
   */
  const [courseware, setCourseware] = useState<NSClass.CoursewareType>(
    {} as NSClass.CoursewareType
  )

  const info = useMemo(() => courseware.syllabus, [courseware.syllabus])

  const isTrial = useMemo(() => {
    return courseware.class && courseware.class.type === 'Trial'
  }, [courseware.class])

  const [fullHeight, setFullHeight] = useState(false)

  const isVodLibrary = useMemo(() => {
    return (
      courseware &&
      courseware.class &&
      courseware.class.course &&
      courseware.class.course.node_type === 'course' &&
      courseware.class.course.template === 'course_pub'
    )
  }, [courseware])

  const {
    soloLessonState: {
      refreshSoloCourse,
      fetchSubmissionStatus,
      fetchHistoryRecords,
    },
    vodLibraryState: { refresh: refreshVodLibrary },
    teamEvents: { refreshTeamEvents },
  } = useStore()

  const [requestClose, setRequestClose] = useState<boolean>(false)
  const [classId, setClassId] = useState<number>()
  const [syllabusId, setSyllabusId] = useState<number>()

  const [viewType, setViewType] = useState<ICoursewareViewType>()

  const [ggaProRecord, setGGAProRecord] = useState<NSClass.TrialType>()

  const [notification, setNotification] = useState<NSNotification.Item>()

  const isGGAPro = useMemo(
    () =>
      // 从ggaPro页面点进去的时候,非ggaPro的作业也当做ggaPro来处理
      (courseware && checkIsGGAPro(courseware.class as any)) ||
      (ggaProRecord && ggaProRecord.id),
    [courseware, ggaProRecord]
  )

  const fetchCourseware = useCallback(async (courseId, infoId) => {
    const res = await getCourseNodeDetailApiV2(courseId, infoId)
    if (res && res.data && res.data.data) {
      setCourseware(res.data.data)
    }
  }, [])

  const refreshModalData = useCallback(async () => {
    await fetchCourseware(classId, syllabusId)
  }, [classId, fetchCourseware, syllabusId])

  const [beforeClose, setBeforeClose] = useState<
    (() => Promise<boolean>) | undefined
  >(undefined)

  // modal的状态由外部发起，不再需要通过判断info，只关心自己的状态
  const initModal = useCallback(
    async (
      syllabusId,
      classId,
      viewType: ICoursewareViewType,
      options?: {
        gga_pro_record?: NSClass.TrialType
        notification?: NSNotification.Item
      }
    ) => {
      await fetchCourseware(classId, syllabusId)
      if (options && options.gga_pro_record) {
        setGGAProRecord(options.gga_pro_record)
      } else {
        setGGAProRecord(undefined)
      }
      if (options && options.notification) {
        setNotification(options.notification)
      } else {
        setNotification(undefined)
      }
      setViewType(viewType)
      setSyllabusId(syllabusId)
      setClassId(classId)
      toggle()
    },
    [fetchCourseware, toggle]
  )

  const isSolo = useMemo(
    () => courseware && courseware.syllabus && courseware.syllabus.depth === 4,
    [courseware]
  )

  const isTeam = useMemo(
    () => courseware && courseware.syllabus && courseware.syllabus.depth === 2,
    [courseware]
  )

  const isInit = useMemo(
    () =>
      !courseware ||
      !courseware.record ||
      !courseware.record.status ||
      courseware.record.status === 'init',
    [courseware]
  )

  const _close = useCallback(() => {
    setRequestClose(true)
    toggle()
  }, [setRequestClose, toggle])

  const closeTaskModal = useCallback(() => {
    if (beforeClose) {
      Promise.resolve(beforeClose()).then(close => {
        if (close) {
          _close()
        }
      })
    } else {
      _close()
    }
  }, [_close, beforeClose])

  const onAfterClose = useCallback(() => {
    setRequestClose(false)
    setBeforeClose(undefined)
  }, [])

  useEffect(() => {
    if (requestClose) {
      if (isVodLibrary) {
        refreshVodLibrary()
      }
      if (isSolo) {
        refreshSoloCourse()
        fetchSubmissionStatus()
        if (isGGAPro) {
          fetchHistoryRecords()
        }
      } else if (isTeam) {
        refreshTeamEvents()
      }
    }
  }, [
    fetchHistoryRecords,
    fetchSubmissionStatus,
    isGGAPro,
    isSolo,
    isTeam,
    isVodLibrary,
    refreshSoloCourse,
    refreshTeamEvents,
    refreshVodLibrary,
    requestClose,
  ])

  return {
    isOpen,
    toggle,
    courseware,
    info,
    requestClose,
    refreshModalData,
    classId,
    beforeClose,
    setBeforeClose,
    initModal,
    isInit,
    isSolo,
    isTeam,
    viewType,
    setViewType,
    syllabusId,
    onAfterClose,
    closeTaskModal,
    isGGAPro,
    ggaProRecord,
    setGGAProRecord,
    isTrial,
    fullHeight,
    setFullHeight,
    isVodLibrary,
    notification,
  }
}

export type ICoursewareModalContexts =
  | 'CLASS_MODAL_CONTEXT'
  | 'NOTIFICATION_MODAL_CONTEXT'

const ContextMapper = {
  CLASS_MODAL_CONTEXT: coursewareModalContextFactory('CLASS_MODAL_CONTEXT'),
  NOTIFICATION_MODAL_CONTEXT: coursewareModalContextFactory(
    'NOTIFICATION_MODAL_CONTEXT'
  ),
}

export const CoursewareModalProvider = (props: {
  ctx: ICoursewareModalContexts
  children: React.ReactNode
}) => {
  const { children, ctx } = props

  const value = useProvideCoursewareModal()
  const Context = ContextMapper[ctx]

  return <Context.Provider value={value}>{children}</Context.Provider>
}

export const useCoursewareModal = (
  ctx: ICoursewareModalContexts = 'CLASS_MODAL_CONTEXT'
): ICoursewareModalContextType => {
  const c = ContextMapper[ctx]
  return useContext(c)
}
