import { ILocalizationCode } from 'gga-types'
import { useCallback } from 'react'
import { useRouter } from 'next/router'
import { addYears } from 'date-fns'
import { LangCookieName } from '@/constants/define'
import { useTranslation } from 'next-i18next'
import { setCookie } from '@/utils/cookie'

export type IUseLangState = ReturnType<typeof useLangState>
/**
 *
 * @param lang 从服务端请求中的cookie传进来的lang，用于界面上显示
 * @returns
 */
export default function useLangState() {
  const router = useRouter()
  const { i18n } = useTranslation()

  const handleSwitchLang = useCallback(
    (locale: ILocalizationCode) => {
      setCookie(LangCookieName, locale, {
        path: '/',
        expires: addYears(new Date(), 1),
      })
      router.replace(
        {
          pathname: router.pathname,
          query: {
            ...router.query,
          },
        },
        undefined,
        {
          locale: locale,
        }
      )
    },
    [router]
  )

  return {
    lang: i18n.language,
    handleSwitchLang,
  }
}
