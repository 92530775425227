const HttpApi = require('i18next-http-backend/cjs')
const { initReactI18next } = require('react-i18next')

const APP_LOCALE = process.env.APP_LOCALE

console.log('APP_LOCALE: ', APP_LOCALE)

const translationPath = 'translations/'
// const translationPath =
//   'translations/' +
//   (process.env.APP_ENV === 'production' ? '' : process.env.APP_ENV + '/')

const HttpApiInstance = new HttpApi(null, {
  loadPath: `https://res.gengesports.cn/${translationPath}{{lng}}/{{ns}}.json`,
  crossDomain: true,
  allowMultiLoading: true,
  reloadInterval: 60000, // 服务端一分钟去拉取一次更新
  requestOptions: {
    cache: 'no-cache',
  },
})

module.exports = {
  i18n: {
    locales: ['kr', 'zh_CN', 'en_US'],
    defaultLocale: APP_LOCALE,
    localeDetection: true,
  },
  serializeConfig: false,
  use: [HttpApiInstance, initReactI18next],
  react: { useSuspense: false },
  ns: [
    'common',
    'homepage',
    'buynow',
    'my-class',
    'dashboard',
    'information',
    'my-orders',
    'notification',
    'payment-subscription',
    'validation',
    'live-session',
    'game_info',
    'cashier',
    'payment-status',
    'coach-platform',
  ],
  defaultNS: 'common',
  lng: APP_LOCALE,
  supportedLngs: ['kr', 'zh_CN', 'en_US'],
  fallbackLng: 'kr',
  debug: false,
  load: 'currentOnly',
  preload: false,
  // 这个参数用于开发，生产环境使用上面的reloadOnInterval, 因为getServerSideProps每次请求都会去做这个reload
  reloadOnPrerender: process.env.APP_ENV === 'development',
  backend: {
    backends: [HttpApiInstance, initReactI18next],
  },
}
