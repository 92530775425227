import React, { useCallback, useEffect, useState } from "react";
import classNames from "classnames";

type IVolumeControlProps = {
  className?: string;
  value: number;
  onChange: (v: number) => void;
};

export default function VolumeControl({
  className,
  value,
  onChange,
}: IVolumeControlProps) {
  const [showPopup, setShowPopup] = useState(false);

  const handleClick = useCallback(
    (isAdd: boolean) => {
      if (isAdd) {
        if (value === 100) {
        } else {
          onChange(value + 10);
        }
      } else if (value === 0) {
      } else {
        onChange(value - 10);
      }
    },
    [onChange, value]
  );

  useEffect(() => {
    const listener = (e: MouseEvent) => {
      const ele = document.getElementById("gvp-volume-control__popup");
      if (e.target !== ele && showPopup) {
        setShowPopup(false);
      }
    };
    window.addEventListener("click", listener);
    return () => {
      window.removeEventListener("click", listener);
    };
  }, [showPopup]);

  return (
    <div className={classNames("gvp-volume-wrapper", className)}>
      <div
        className="gvp-volume-control"
        style={{
          fontSize: 16,
        }}
        onClick={() => {
          setShowPopup(prev => !prev);
        }}
      >
        <i className="iconfont iconvolume gvp-volume-control-icon" />
        <div
          className={classNames(
            "gvp-volume-opener",
            showPopup && "gvp-volume-opener__show"
          )}
          id="gvp-volume-control__popup"
        >
          <div className="gvp-volume-opener-inner">
            <span
              style={{
                fontSize: 12,
              }}
              className={classNames(
                "iconfont iconminus gvp-volume-icon",
                value === 0 && "gvp-volume-icon__disabled"
              )}
              onClick={e => {
                e.stopPropagation();
                handleClick(false);
              }}
            />
            <div>{value}%</div>
            <span
              style={{
                fontSize: 12,
              }}
              className={classNames(
                "iconfont iconplus gvp-volume-icon",
                value === 100 && "gvp-volume-icon__disabled"
              )}
              onClickCapture={e => {
                e.stopPropagation();
                handleClick(true);
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
