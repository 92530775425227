import { Menu, UnstyledButton } from '@mantine/core'
import { FC, useMemo } from 'react'
import { LanguageIcon } from '@heroicons/react/24/outline'
import { ILocalizationCode } from 'gga-types'
import { useStore } from '@/context/store'

const supportedLangs: { value: ILocalizationCode; name: string }[] = [
  {
    name: 'English',
    value: 'en_US',
  },
  {
    name: '简体中文',
    value: 'zh_CN',
  },
  {
    name: '한국인',
    value: 'kr',
  },
]

const LanguageSwitch: FC = () => {
  const {
    langState: { handleSwitchLang, lang },
  } = useStore()
  const getCurrent = useMemo(() => {
    return supportedLangs.find(l => l.value === lang)
  }, [lang])

  return (
    <Menu>
      <Menu.Target>
        <UnstyledButton>
          <div className="flex items-center">
            <LanguageIcon className="text-white h-5 w-5" />
            <span className="text-white text-sm ml-1">{getCurrent?.name}</span>
          </div>
        </UnstyledButton>
      </Menu.Target>
      <Menu.Dropdown>
        {supportedLangs.map(l => {
          return (
            <Menu.Item
              key={l.value}
              onClick={() => {
                handleSwitchLang(l.value)
              }}
            >
              {l.name}
            </Menu.Item>
          )
        })}
      </Menu.Dropdown>
    </Menu>
  )
}

export default LanguageSwitch
