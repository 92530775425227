import { FC, useEffect, useState } from 'react'
import { Modal, ModalProps } from '@mantine/core'
import { createStyles } from '@mantine/styles'
import { XCircleIcon } from '@heroicons/react/24/outline'
import router from 'next/router'
import ImageComponent from '@/components/ImageComponent'
import { ConfigValueApi } from '@/api/config-value/index.api'

const useStyles = createStyles(() => ({
  close: { display: 'none' },
  overlay: {
    backgroundColor: 'rgba(0,0,0,0.4) !important',
  },
  header: {
    display: 'none',
  },
  body: {
    backgroundColor: 'transparent',
  },
  inner: {
    color: '#FAFAFA',
    alignItems: 'center',
  },
  modal: {
    background: 'transparent',
    padding: '0 !important',
    width: '360px !important',
  },
}))

type TOneVOneCurtain = Omit<ModalProps, 'classnames'>

const OneVOneCurtain: FC<TOneVOneCurtain> = ({ ...props }) => {
  const [data, setData] = useState<{ name: string; value: string }[]>()
  const { classes } = useStyles()

  const fetchConfigValues = async () => {
    const api = new ConfigValueApi()
    const res = await api.getConfigValues()
    if (res && res.data && res.data.data) {
      setData(res.data.data)
    }
  }

  useEffect(() => {
    fetchConfigValues()
  }, [])

  return (
    <Modal {...props} classNames={{ ...classes }}>
      <ImageComponent
        src={
          'https://res.gengesports.cn/user-web/images/campaign/1v1/pop-up.png'
        }
        className={'hidden md:block w-full'}
      />
      {/*移动端*/}
      <div className={'w-full relative md:hidden'}>
        <ImageComponent
          src={
            'https://res.gengesports.cn/user-web/images/campaign/1v1/pop-up-mobile.png'
          }
        />
        <div
          className={
            'absolute w-full h-full top-0 left-0 right-0 flex flex-col items-center justify-end'
          }
        >
          <div className={'pb-6'}>
            <div
              onClick={async () => {
                const link = data?.find(d => d.name === '1v1-promo')
                if (link) {
                  await router.push(link.value)
                }
              }}
              className={
                'w-[210px] h-[44px] text-center text-[#333] text-base mx-auto rounded-full flex items-center justify-center cursor-pointer'
              }
              style={{
                background: 'linear-gradient(92deg, #F2E5C3 0%, #EEC9A2 99%)',
              }}
            >
              跳转到GGA小程序
            </div>
          </div>
        </div>
      </div>
      <div className={'flex items-center justify-center my-4'}>
        <XCircleIcon
          onClick={() => {
            props.onClose()
          }}
          className={
            'w-[44px] h-[44px] text-[#ececec] hover:text-white cursor-pointer font-light'
          }
        />
      </div>
    </Modal>
  )
}

export default OneVOneCurtain
