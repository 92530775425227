import { useCallback, useEffect, useMemo, useState } from 'react'
import get from 'lodash/get'
import cloneDeep from 'lodash/cloneDeep'
import { useSetState } from 'ahooks'
import { NSClass, NSSubscription } from 'gga-types'
import {
  checkIsGGAPro,
  checkSubmissionStatus,
  fetchUserClassSubscriptionStatus,
  getClassSyllabuses,
  getUserClassByIdV2,
  queryPtRecordSubmissionsByGameId,
} from '@/services/class'
import {
  ClassLockContext,
  classLockStrategyMapping,
  IStrategies,
} from '@/services/classLockStrategy'

class SetRealCurrent {
  private initialCurrentNode

  run(nodes: any[], rootIndex?) {
    const depth = nodes[0].depth

    nodes.forEach((node, index) => {
      let hasCurrent = false
      node.children.forEach((c, i, arr) => {
        if (get(c, 'record.current')) {
          if (c.is_finished && i < arr.length - 1) {
            arr[i + 1]._current = true
            hasCurrent = true
          } else {
            c._current = true
            hasCurrent = true
          }

          if (this.initialCurrentNode) {
            delete this.initialCurrentNode._current
            this.initialCurrentNode = null
          }
        }
      })

      // 一个current都没有设置过，说明用户还未开始学习，设置第一个节点为current
      if (rootIndex === 0 && depth === 3 && index === 0 && !hasCurrent) {
        if (node.children.length && !this.initialCurrentNode) {
          node._current = true
          this.initialCurrentNode = node
        }
      }

      if (!node.children.length) {
        return
      } else {
        // 只在第一层设置root index
        if (depth === 1) {
          rootIndex = index
        }
        return this.run(node.children, rootIndex)
      }
    })
  }
}

const getTreeIdMap = (tree: any[], map: any = {}) => {
  tree.forEach(node => {
    map[node.id] = node
    if (node.children && node.children.length) {
      map = getTreeIdMap(node.children, map)
    }
  })
  return map
}

// 查找树中第一个可上传节点
const findFirstUploadNode = (tree: any[]) => {
  for (let i = 0; i < tree.length; i++) {
    const item = tree[i]
    if (item.template === 'solo_task') {
      return item
    } else if (item.children.length) {
      const r = findFirstUploadNode(item.children)
      if (r) {
        return r
      }
    }
  }
  return null
}

export type ISoloLessonState = ReturnType<typeof useSoloLessonState>

const getCurrent = (nodes: any[]) => {
  if (!nodes || !nodes.length) {
    return {}
  }
  let result = nodes[0]
  for (let i = 0; i < nodes.length; i++) {
    if (nodes[i] && nodes[i].record && nodes[i].record.current) {
      result = nodes[i]
      break
    }
  }
  return result
}

export const useSoloLessonState = () => {
  const [treeIdMap, setTreeIdMap] = useState<any>({})
  const [treeNodes, setTreeNodes] = useState<any>([])

  const [selectedUnit, setSelectedUnit] = useState<NSClass.SyllabusItemStruct>(
    {} as any
  )
  const [selectedLevel, setSelectedLevel] =
    useState<NSClass.SyllabusItemStruct>({} as any)
  const [postLockStrategy, setPostLockStrategy] = useState<IStrategies>()
  const [submissionStatus, setSubmissionStatus] =
    useState<NSClass.SubmissionStatus>()

  const [subscriptionStatus, setSubscriptionStatus] =
    useState<NSSubscription.UserSubscriptionStatus>()

  const lessonsList = useMemo(() => {
    return get(treeIdMap, [selectedUnit?.id, 'children'], [])
  }, [selectedUnit?.id, treeIdMap])

  const [soloClass, setSoloClass] = useState<NSClass.ClassItem>()
  const [loading, setLoading] = useState(false)

  const progress = useMemo(() => {
    const items = lessonsList.reduce((res, item) => {
      return [...res, ...item.children]
    }, [] as NSClass.SyllabusItemStruct[])

    const total = items.length
    const finishedCount = items.reduce((count, i) => {
      return (
        count +
        (i.record &&
        i.record?.trials?.reduce((p, c) => p || c.status === 'finished', false)
          ? 1
          : 0)
      )
    }, 0)

    return total === 0 ? 0 : finishedCount / total
  }, [lessonsList])

  const fetchSubmissionStatus = useCallback(async () => {
    if (soloClass && soloClass.identity_id) {
      const { data } = await checkSubmissionStatus(soloClass.identity_id)
      if (data && data.data) {
        setSubmissionStatus(data.data)
      }
    }
  }, [soloClass])

  useEffect(() => {
    // 获取课程subscription状态
    if (soloClass && soloClass.id) {
      fetchUserClassSubscriptionStatus(soloClass.id).then(res => {
        if (res && res.data && res.data.data) {
          setSubscriptionStatus(res.data.data)
        }
      })
    }
  }, [soloClass])

  const overrideLockStrategy = useCallback((st: IStrategies) => {
    setPostLockStrategy(st)
  }, [])

  const lockClassObjects = useCallback(
    (clazz: NSClass.ClassItem | undefined, nodes: any[]) => {
      if (clazz && nodes) {
        const classLockContext = new ClassLockContext(
          classLockStrategyMapping[clazz.process_type] ||
            classLockStrategyMapping['No Limit'],
          postLockStrategy && classLockStrategyMapping[postLockStrategy]
        )
        classLockContext.doLock(nodes)
        setTreeIdMap(getTreeIdMap(nodes))
      }
    },
    [postLockStrategy]
  )

  useEffect(() => {
    lockClassObjects(soloClass, treeNodes)
  }, [lockClassObjects, soloClass, treeNodes])

  const courseTreeFetcher = useCallback(async (clazz: NSClass.ClassItem) => {
    try {
      setLoading(true)
      const { data } = await getClassSyllabuses(clazz.id, {
        expand: ['record'],
        depth: 4,
      })

      if (data && data.data) {
        const nodes = cloneDeep(data.data)

        // setRealCurrent(nodes);
        new SetRealCurrent().run(nodes)
        setLoading(false)
        setTreeNodes(nodes)
        return nodes
      } else {
        setTreeNodes([])
      }

      setLoading(false)
    } catch (e) {
      console.error(e)
      setLoading(false)
    }
  }, [])

  const initializeSoloLessonData = useCallback(nodes => {
    const level = getCurrent(nodes)
    setSelectedLevel(level)
  }, [])

  const units = useMemo(() => {
    return selectedLevel && selectedLevel.children ? selectedLevel.children : []
  }, [selectedLevel])

  // 选择的level变需要更改unit的选择
  useEffect(() => {
    setSelectedUnit(units[0] || {})
  }, [units])

  const setSelected = useCallback(
    (type: 'level' | 'unit', id: number) => {
      const node = treeIdMap[id]
      if (node) {
        if (type === 'level') {
          setSelectedLevel(node)
        } else if (type === 'unit') {
          setSelectedUnit(node)
        }
      }
    },
    [treeIdMap]
  )

  const refreshSoloCourse = useCallback(() => {
    if (soloClass) {
      courseTreeFetcher(soloClass)
    }
  }, [courseTreeFetcher, soloClass])

  const fetchSoloClass = useCallback(
    async (id: number) => {
      const { data } = await getUserClassByIdV2(id)
      if (data && data.data) {
        const nodes = await courseTreeFetcher(data.data)
        initializeSoloLessonData(nodes)
        setSoloClass(data.data)
      }
    },
    [courseTreeFetcher, initializeSoloLessonData]
  )

  const getDepth = useCallback((arr, depth: number, result = []) => {
    for (let i = 0; i < arr.length; i++) {
      const node = arr[i]
      if (node.depth === depth) {
        result.push(node)
      } else {
        return getDepth(node.children, result)
      }
    }
    return result
  }, [])

  const levels = useMemo(() => {
    return getDepth(treeNodes, 1)
  }, [getDepth, treeNodes])

  const isGGAPro = useMemo(() => {
    return checkIsGGAPro(soloClass)
  }, [soloClass])

  // 学员上传历史记录 （play/pro only)
  const [historyRecordsPagination, setHistoryRecordsPagination] = useSetState({
    page: 1,
    pageSize: 8,
    total: 0,
  })
  const [historyRecords, setRecords] = useState<NSClass.TrialType[]>([])
  const fetchHistoryRecords = useCallback(async () => {
    if (soloClass && soloClass.game_category_id) {
      const res = await queryPtRecordSubmissionsByGameId(
        soloClass.game_category_id
      )
      if (res && res.data && res.data.data) {
        const histories = res.data.data
        setRecords(histories)
        setHistoryRecordsPagination(prev => {
          if (res.data && res.data.data.length === 0) {
            return {
              page: 1,
              total: 0,
            }
          }
          const newPageCount = Math.ceil(res.data!.data.length / prev.pageSize)
          return {
            page: newPageCount >= prev.page ? prev.page : newPageCount,
            total: res.data!.data.length,
          }
        })
      }
    }
  }, [setHistoryRecordsPagination, soloClass])
  // hardcode, gga pro课程约定上只存在一个课程组件并拥有唯一个solo track的可上传组件
  const ggaProUploadTarget = useMemo(() => {
    return findFirstUploadNode(treeNodes)
  }, [treeNodes])

  const isVodLibrary = useMemo(() => {
    return (
      soloClass &&
      soloClass.course &&
      soloClass.course.node_type === 'course' &&
      soloClass.course.template === 'course_pub'
    )
  }, [soloClass])

  return {
    lessonsList,
    soloClassId: soloClass?.id,
    allCourses: treeNodes,
    selectedLevel,
    selectedUnit,
    setSelected,
    refreshSoloCourse,
    loading,
    soloClass,
    fetchSoloClass,
    fetchSubmissionStatus,
    submissionStatus,
    overrideLockStrategy,
    progress,
    units,
    levels,
    isGGAPro,
    historyRecords,
    fetchHistoryRecords,
    historyRecordsPagination,
    setHistoryRecordsPagination,
    ggaProUploadTarget,
    subscriptionStatus,
    isVodLibrary,
  }
}
