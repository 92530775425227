import classNames from 'classnames'
import { ReactNode, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Button } from 'stripe-ui'
import { useHover } from '@mantine/hooks'
import { NSProduct } from 'gga-types'
import ImageComponent from '@/components/ImageComponent'
import { Currency } from '@/utils/currency'
import styles from './styles.module.scss'

type ICardProps = {
  product: NSProduct.Product
  buttonText: ReactNode
  onClick: (product: NSProduct.Product) => void
}

export function Card({ product, onClick, buttonText }: ICardProps) {
  const { ref, hovered: showMask } = useHover()

  const { t, i18n } = useTranslation('common')
  const { t: tbuy } = useTranslation('buynow')

  const productLocale = useMemo(
    () => product.localization.code,
    [product.localization.code]
  )

  const prefix = useMemo(() => {
    return product.discount_amount
      ? product.discount_activity_name || t('discount')
      : tbuy('price')
  }, [product.discount_activity_name, product.discount_amount, t, tbuy])

  const price = useMemo(() => {
    const amount = product.discount_amount || product.amount
    return new Currency(amount, productLocale).renderWithSymbol()
  }, [product.amount, product.discount_amount, productLocale])

  const originalPrice = useMemo(() => {
    return product.discount_amount
      ? new Currency(product.amount, productLocale).renderWithSymbol()
      : null
  }, [product.amount, product.discount_amount, productLocale])

  const isProductSoldOut = useMemo(() => {
    return product.availability === 'Limited' && product.stock <= 0
  }, [product.availability, product.stock])
  return (
    <div
      ref={ref}
      className={classNames(
        'relative rounded w-64 h-[300px] mx-auto cursor-normal shadow-md flex flex-col',
        styles.card
      )}
    >
      {showMask && (
        <div
          className={classNames(
            'absolute w-full h-full top-0 left-0 z-1 rounded px-4 py-2 flex flex-col justify-between',
            styles.hoverable
          )}
        >
          <div className="overflow-hidden text-center">
            <div className="text-white text-lg line-clamp-1">
              {product.name}
            </div>
            {product.sub_name ? (
              <div className="text-gold line-clamp-1">{product.sub_name}</div>
            ) : (
              <div className="text-transparent">&nbsp;</div>
            )}
          </div>
          <div className="overflow-hidden">
            <div className="py-2 px-4 overflow-auto">
              {product.description?.split('\n').map((l, i) => (
                <li key={i} className="leading-tighter text-xs text-white mb-1">
                  {l}
                </li>
              ))}
            </div>
          </div>
          <div className="flex-1 text-center flex items-end justify-center pb-2">
            <div>
              <Button
                disabled={product.in_recurring_subscription || isProductSoldOut}
                className="px-10 py-2"
                variant="brand"
                size="small"
                onClick={() => {
                  if (!isProductSoldOut) {
                    onClick(product)
                  }
                }}
              >
                {isProductSoldOut ? tbuy('sold_out_btn_text') : buttonText}
              </Button>
            </div>
          </div>
        </div>
      )}

      <div className={'relative w-full h-36'}>
        <ImageComponent
          src={product.cover_path || '/images/logo-gga.png'}
          alt={product.name}
          className={classNames(
            'rounded-t w-full h-full',
            !product.cover_path && 'object-contain bg-codgray-400 p-4'
          )}
        />
        <div className={'absolute bottom-0 left-0 right-0'}>
          {product.availability === 'Limited' && product.stock < 10 && (
            <div
              className={'bg-red-500 bg-opacity-40 py-1 text-white text-center'}
            >
              {product.stock <= 0
                ? tbuy('product_sold_out')
                : tbuy('product_few_left')}
            </div>
          )}
        </div>
      </div>
      <div className="p-4 pb-2 pt-2 h-1 flex-1 flex flex-col">
        <div className="overflow-hidden flex-1">
          <div className="text-white text-lg line-clamp-2 mb-1 leading-tight">
            {product.name}
          </div>
          {product.sub_name && (
            <div className="text-gold line-clamp-2 leading-tight">
              {product.sub_name}
            </div>
          )}
        </div>
        <div className={'flex justify-between items-end mt-auto'}>
          {product.availability === 'Limited' && product.stock < 10 && (
            <div
              className={classNames(
                product.stock <= 0 ? 'text-red-600' : 'text-gray-400'
              )}
            >
              {tbuy('product_stock_count')}
              <span>{product.stock < 0 ? 0 : product.stock}</span>
            </div>
          )}
          <div className="text-gray-100 text-right flex-1">
            <div className="line-clamp-1">{prefix}</div>
            <div className="line-clamp-1">
              {originalPrice && (
                <span className="mr-1 text-xs">
                  <span className="line-through tracking-tight">
                    {originalPrice}
                  </span>
                </span>
              )}
              <span className="text-xl text-gold tracking-tight">{price}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
