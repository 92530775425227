import { useRouter } from 'next/router'
import React, { FC, useMemo } from 'react'
import classNames from 'classnames'
import qs from 'query-string'
import NavLink from '@/components/NavLink'

type IActiveLinkProps = {
  className?: string
  href: string
  text: string
  parent?: string
  isBlank?: boolean
}

const ActiveLink: FC<IActiveLinkProps> = ({
  href,
  text,
  className,
  parent,
  isBlank,
  ...props
}) => {
  const { asPath } = useRouter()

  const isActive = useMemo(() => {
    if (parent) {
      return asPath.startsWith(parent)
    }
    return asPath.startsWith(href)
  }, [asPath, href, parent])

  return (
    <NavLink
      className={classNames(
        'flex flex-col text-white justify-center items-center px-4 no-underline',
        isActive ? 'bg-gold' : 'bg-black'
      )}
      href={href}
      target={isBlank ? '_blank' : '_self'}
      {...props}
    >
      {text}
    </NavLink>
  )
}

export default ActiveLink
