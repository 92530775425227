import React, { useEffect, useState } from 'react'
import dynamic from 'next/dynamic'
// import GreatVideoPlayer from "@/UI-React/src/GreatVideoPlayer";
import { useTranslation } from 'next-i18next'
import classNames from 'classnames'
import { isMobile } from 'react-device-detect'
import { ActionsInterval } from '@/UI-React/src/recordableDrawing/model/actionsInterval'
import { getVideoFileSrc, IGetVideoFileSrcRet } from '@/utils/video'
import { IUseVideoRefState } from '@/UI-React/src/hooks/useVideoRef'
import { Pending } from '@/components/Pending'

const GreatVideoPlayerDynamic = dynamic(
  () => import('@/UI-React/src/GreatVideoPlayer'),
  { ssr: false }
)

interface VideoSubmissionProps {
  filePath: string
  controllable?: boolean
  playerRef: any
  onStatusChange: (status) => void
  onAudioRecorderSaved: ({
    audioData,
    drawingData,
  }: {
    audioData: Blob | null
    drawingData: ActionsInterval[]
  }) => void
  onVideoLoaded?: () => void
  onVideoState: (state: IUseVideoRefState) => void
  className?: string
}

export const VideoSubmission = (props: VideoSubmissionProps) => {
  const {
    filePath,
    controllable = true,
    playerRef,
    onStatusChange,
    onAudioRecorderSaved,
    onVideoLoaded,
    onVideoState,
    className,
  } = props
  const [homeworkURL, setHomeworkURL] = useState<IGetVideoFileSrcRet>()
  const { t } = useTranslation()

  useEffect(() => {
    const url = filePath
    getVideoFileSrc(url).then(res => {
      setHomeworkURL(res)
    })
  }, [filePath])

  return (
    <Pending className="flex-1" loading={!homeworkURL}>
      {homeworkURL && (
        <GreatVideoPlayerDynamic
          init={player => (playerRef.current = player)}
          config={{
            id: 'task-player',
            videoSrc: homeworkURL.src,
            sources: homeworkURL.hls ? homeworkURL.hls : [],
            type: homeworkURL.type,
            controllable: controllable,
            definitionTipsConfig: {
              label: t('components.greatvideo.switchdefinitiontips'),
            },
            btnProps: {
              ...[
                'colorPicker',
                'strokeWidth',
                'undo',
                'redo',
                'clear',
                'discard',
                'startDrawing',
                'startRecording',
                'stop',
                'preview',
                'exitPlayback',
              ].reduce((r, i) => {
                r[i] = {
                  hidden: isMobile || i === 'startRecording',
                  text:
                    i === 'startDrawing'
                      ? t('components.greatvideo.drawing_tool')
                      : '',
                }
                return r
              }, {}),
            },
          }}
          className={classNames(className)}
          onAudioRecorderSaved={(audioData, drawingData) => {
            onAudioRecorderSaved({ audioData, drawingData })
          }}
          onVideoPlaying={videoState => {
            onVideoState(videoState)
          }}
          onStatusChange={onStatusChange}
          onVideoLoaded={onVideoLoaded}
        />
      )}
    </Pending>
  )
}
