import { useTranslation } from 'next-i18next'
import { useMemo } from 'react'
import { APP_LOCALE } from '@/constants/define'
import { ENV } from '@/config/config'

export function useMenu() {
  const { t } = useTranslation()
  const menu = useMemo(() => {
    if (APP_LOCALE === 'zh_CN') {
      return [
        {
          text: '电竞训练营',
          children: [
            {
              text: '无畏契约',
              href: '/val',
              image: '/images/menu/val.png',
            },
            {
              text: '英雄联盟',
              href: '/lol',
              image: '/images/menu/lol.png',
            },
            {
              text: 'CS2',
              href: '/cs2',
              image: '/images/menu/cs2.png',
            },
            // {
            //   text: '王者荣耀',
            //   href: '/hok',
            //   image: '/images/menu/hok.png',
            // },
          ],
        },
        { text: '专项提升', href: '/coach-platform' },
        {
          text: '青训营',
          href: '/youth-camp',
        },
        {
          text: '电竞留学',
          href: 'https://www.gengabroad.com',
          isBlank: true,
        },
        {
          text: '关于GGA',
          href: '/about',
        },
        {
          text: '学习中心',
          auth: true,
          children: [
            {
              text: '我的课程',
              href: '/dashboard',
              image: '/images/menu/course.png',
              image1: '/images/menu/course1.png',
            },
            {
              text: '活动日程',
              href: '/live-session/history',
              image: '/images/menu/calendar.png',
              image1: '/images/menu/calendar1.png',
            },
          ],
        },
      ]
    } else if (APP_LOCALE === 'en_US') {
      return [
        {
          text: t('nav_link.course_center'),
          href: '/course-center',
        },
        {
          text: t('nav_link.platform'),
          href: '/platform',
        },
      ]
    } else {
      return [
        {
          text: t('nav_link.dashboard'),
          href: '/dashboard',
          auth: true,
        },
        {
          text: t('nav_link.live_session'),
          parent: '/live-session',
          href: '/live-session/history',
          auth: true,
        },
        {
          text: t('nav_link.coach_platform'),
          href: '/coach-platform',
        },
        {
          text: t('nav_link.course_center'),
          href: '/course-center',
        },
      ].filter(v => !!v)
    }
  }, [t])
  return {
    menu,
  }
}
